import {createSlice} from "@reduxjs/toolkit";

const newSendingGoodsInitialValue = {
    id: 1,
    no: 1,
    name: '',
    isActive: 'active'
}

const newCarNumbersInitialValue = {
    id: 1,
    no: 1,
    number: '',
    isActive: 'active'
}

const newPaymentTypesInitialValue = {
    id: 1,
    no: 1,
    name: '',
    type: '',
    typeName: '',
    isActive: 'active',
    myDataPaymentId: "",
    options: {
        preSelectedPosBankAccount: "",
        preSelectedPosBankAccountName: "",
        autopayment: 'inactive',
        documentType: "",
        preNumber: "",
        number: "",
        installation: "",
        documentTypeName: "",
        paymentReason: "",
    }
}

const salesGeneralSettingsInitailValue = {
    sendingGoods: [],
    carNumbers: [],
    forbitChange: 'inactive',
    paymentTypes: [],
    paymentReason: "",
    emailSettings: {},
    isActive: "active",
    userId: "",
}

const salesEmailSettingsInitialValue = {
    subject: "",
    emailBody: "",
    attachments: [],
}
const syncProviderLogInitialValue = {
    syncs: []
}

const initialState = {
    salesGeneralSettingsInitailValue: salesGeneralSettingsInitailValue,
    salesGeneralSettings: salesGeneralSettingsInitailValue,
    newSendingGoodsInitialValue: newSendingGoodsInitialValue,
    newSendingGoods: newSendingGoodsInitialValue,
    newCarNumbersInitialValue: newCarNumbersInitialValue,
    newCarNumbers: newCarNumbersInitialValue,
    newPaymentTypesInitialValue: newPaymentTypesInitialValue,
    newPaymentTypes: newPaymentTypesInitialValue,
    salesEmailSettingsInitialValue: salesEmailSettingsInitialValue,
    salesEmailSettings: salesEmailSettingsInitialValue,
    syncInvoicesProviderLog: syncProviderLogInitialValue,
}

export const SalesSettingsSlice = createSlice({
    name: "Sales Settings",
    initialState,
    reducers: {
        setSalesGeneralSettings: (state, {payload}) => {
            state.salesGeneralSettings = payload;
            state.salesEmailSettings = payload?.emailSettings;
        },
        setSalesPaymentTypes: (state, {payload}) => {
            state.salesGeneralSettings.paymentTypes = payload;
        },
        setNewSendingGoods: (state, {payload}) => {
            state.newSendingGoods = payload;
        },
        setNewCarNumbers: (state, {payload}) => {
            state.newCarNumbers = payload;
        },
        setNewPaymentTypes: (state, {payload}) => {
            state.newPaymentTypes = payload;
        },
        setSalesEmailSettings: (state, {payload}) => {
            state.salesEmailSettings = payload;
            state.salesGeneralSettings.emailSettings = payload;
        },
        setSyncInvoicesProviderLog: (state, {payload}) => {
            state.syncInvoicesProviderLog = payload;
        },
    },
});

export const {
    setSalesPaymentTypes,
    setSalesGeneralSettings,
    setNewSendingGoods,
    setNewCarNumbers,
    setNewPaymentTypes,
    setSalesEmailSettings,
    setSyncInvoicesProviderLog,
} = SalesSettingsSlice.actions;
