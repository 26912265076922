import React, {useEffect, useState} from "react";
import {toast} from 'react-toastify';
import LogTable from "../../../primer/LogTable";
import EditableTable from "../../../primer/Editable-Table";
import {Button, Col, Modal, Row, Spinner} from 'react-bootstrap';
import Dropdown from "../../../primer/Dropdown";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {useDispatch, useSelector} from "react-redux";
import {addNewCategory, fetchCategory, putCategory} from "../../../../_apis/api";
import {getLabelByValue, getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {
  checkPermission,
  getMaskCode,
  getMaskCodePrefix,
  getOptionsByData,
  isEmpty
} from "../../../../_helpers/commonFunctions";
import {
  resetTempNewDefaultProperties,
  setNewCategory,
  setNewProductsCategoryMyData,
  setTempNewDefaultProperties
} from "../../../../_reducers/DataPanel/Products/productCategorySlice";
import getMyData, {
  getICCategoryOptions,
  getICTypeOptions,
  getInvoiceTypesOptions, getMyDataRowTypes, getVatExpenseClassificationType
} from '../../../../_helpers/sharedmydata';
import {useTranslation} from "react-i18next";
import Input from "../../../common/Input";
import BetterCheckBox from "../../../common/BetterCheckBox";
import axios from "axios";
import ModalInstructions from "../../../primer/POPUP-COMPONENTS/Instructions";

export const ProductSettingsCategories = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch()
  const invoiceTypes = getInvoiceTypesOptions();
  const icCategory = getICCategoryOptions();
  const icType = getICTypeOptions();
  const myDataRowTypes = getMyDataRowTypes();
  const vatExpenseTypes = getVatExpenseClassificationType();
  const zeroVatReasonOptions = getMyData({type: 'zerovatreason'});
  const productsColumns = [
    {
      name: t('ProductsNewGeneral.table.rowType'),
      field: "rowType",
      width: "10%",
      editable:true,
      inputType: {
        type: 'select',
        options: myDataRowTypes
      }
    },
    {
      name: t('ProductsSettings.table.invoiceType'),
      field: "invoiceType",
      editable: true,
      width: "30%",
      inputType: {
        type: 'select',
        multiselect: true,
        options: invoiceTypes
      }
    },
    {
      name: t('ProductsSettings.table.category'),
      field: "category",
      editable: true,
      width: "20%",
      inputType: {
        type: 'select',
        options: icCategory
      }
    },
    {
      name: t('ProductsSettings.table.type'),
      field: 'type',
      editable: true,
      width: "20%",
      inputType: {
        type: 'select',
        options: icType
      }
    },
    {
      name: t('ProductsNewGeneral.table.vatExpenseType'),
      field: 'vatExpenseType',
      editable: true,
      width: "20%",
      inputType: {
        type: 'select',
        options: vatExpenseTypes,
      }
    }
  ];

  const productCategoryColumns = [
    {
      name: t('ProductsSettings.smallTable.categoryName'),
      field: "title",
      width: "30%",
      editable:true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ProductsSettings.smallTable.codeMask'),
      field: "maskCode",
      width: "30%",
      editable:true,
      mask:true,
      inputType: {
        type: 'text',
      }
    },
  ];

  const propertiesColumns = [
    {
      name: 'Όνομα Ιδιότητας',
      field: 'name',
      editable: true,
      width: "20%",
      inputType: {
        type: 'text',
      }
    },
    {
      name: 'Τιμές Ιδιότητας',
      field: 'values',
      editable: true,
      width: "80%",
      inputType: {
        type: 'text',
      }
    },
  ];

  const propertiesInstructions = {
    title: "Οδηγίες Εισαγωγής Ιδιοτήτων",
    content: "<div>Εισάγετε ιδιότητες ειδών χωρίζοντάς τις τιμές με κόμμα(,).<br><br>Καθορίστε αυξομειώσεις τιμής σε συγκεκριμένες παραλλαγές με ανω-κάτω τελεία(:)<br><br><u>Παράδειγμα</u><br>Όνομα Ιδιότητας: Χρώμα<br>Τιμές Ιδιότητας: μαύρο,κόκκινο:3,κίτρινο:-3,μπλε</div>"
  };

  // Redux State
  const SETTINGS = useSelector(state => state.SETTINGS);
  const PRODUCTS_DATA = useSelector(state => state.PRODUCTS_DATA);
  const PRODUCTS_CATEGORY = useSelector(state => state.PRODUCTS_CATEGORY);
  const company = useSelector(state => state.COMPANY_DATA.company)
  const categoryData = PRODUCTS_CATEGORY.categories;
  const newCategory = PRODUCTS_CATEGORY.newCategory;
  let pMDDSettings = SETTINGS.primerMyDataData;
  // Tax Data From Excel
  let vatTaxes      = getMyData({type: 'vat'});
  let zeroVatReason = getMyData({type: 'zerovatreason'});
  let otherTaxes    = getOptionsByData(pMDDSettings?.otherTaxes !== undefined && pMDDSettings?.otherTaxes !== '' ? (pMDDSettings.otherTaxes) : SETTINGS.otherHoldTaxes, 'code', 'name');
  let taxesWithhold = getOptionsByData(pMDDSettings?.withHoldTaxes !== undefined && pMDDSettings?.withHoldTaxes !== '' ? (pMDDSettings.withHoldTaxes) : SETTINGS.withHoldTaxes, 'code', 'name');
  let taxesStamp    = getMyData({type: 'stamp'});
  let taxesFees     = getMyData({type: 'fees'});
  // let measurementUnits     = getOptionsByData(PRODUCTS_DATA.measurementUnits, 'code', 'name'); // from api
  const measurementUnits     = getMyData({type: 'mu'}); // from json
  const permissionsData = JSON.parse(localStorage.getItem("permissions"));

  // Fix Blank In the first position
  vatTaxes = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...vatTaxes]
  otherTaxes = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...otherTaxes]
  taxesWithhold = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesWithhold]
  taxesStamp = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesStamp]
  taxesFees = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...taxesFees]

  const [loadingApplyToAll, setLoadingApplyToAll] = useState(false);
  const [showApplyToAll, setShowApplyToAll] = useState(false);

  const requestData = {
    type:"product",
    company:company.id,
    year:company.year,
    isActive: 'active'
  }
  const [editing, setEditing] = useState(false);
  const [forceRender, setForceRender] = useState(0);
  const [showZeroVatReson, setShowZeroVatReson] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [taxes, setTaxes] = useState({});
  const [mydata, setMyData] = useState([]);
  const [showExtraDetails, setShowExtraDetails] = useState(false);

  useEffect(() => {
    setForceRender(0);
    if (Object.keys(taxes).length > 0)  {
      if((taxes.vat === '0%') || (taxes.vat === '0')) {
        setShowZeroVatReson(true);
      } else {
        setShowZeroVatReson(false);
      }
    }
  }, [dispatch, forceRender]);
  useEffect(() => {
    dispatch(fetchCategory(requestData));
  }, [dispatch]);

  // Show 0% VAT Reason
  const toggleVatReasonField = (e) => {
    if ((e.value === '0%') || (e.value === '0')) {
      setShowZeroVatReson(true);
    } else {
      setShowZeroVatReson(false);
    }
  }

  const handleOnFieldChange = (e, name = '', inputType = '') => {
    setEditing(true);
    setForceRender(forceRender + 1)

    const newtaxes = {}
    if (inputType === 'ac' || inputType === 'dd') {
      newtaxes[name] = e.value;
      if (name === 'vat') {
        toggleVatReasonField(e);
      }
    }
    setTaxes({...taxes, ...newtaxes});
  }

  const handleOnChange = (e, name = '', inputType = '') => {
    setEditing(true);

    const muObj = {}
    if (inputType === 'ac' || inputType === 'dd') {
      muObj[name] = e.value;
      setSelectedCategory({...selectedCategory, ...muObj})
    } else if (inputType === "input") {
      setSelectedCategory({...selectedCategory, [name] : e.target.value});
    } else if (inputType === "checkbox") {
      setSelectedCategory({...selectedCategory, [name] : e.target.checked === true ? "active" : "inactive"});
    }
  }

  const propertiesActions = {
    insert: (e, data) => {
      if (isEmpty(data.value)) return;
      setEditing(true);
      let tempNewProperty = structuredClone(PRODUCTS_CATEGORY.tempNewDefaultProperties);
      tempNewProperty[data.field] = data.value;
      if (isEmpty(tempNewProperty.name) || isEmpty(tempNewProperty.values)) {
        dispatch(setTempNewDefaultProperties(tempNewProperty));
      } else {
        let defaultProperties = Object.assign([], selectedCategory.defaultProperties);
        if(defaultProperties.filter((prop) => prop.name === tempNewProperty.name).length !== 0) return toast.error("Διπλότυπη Ιδιότητα.");
        defaultProperties.push(tempNewProperty);
        dispatch(resetTempNewDefaultProperties());
        setSelectedCategory({...selectedCategory, defaultProperties: defaultProperties});
      }
    },
    update: (data, id, rowIndex) => {
      setEditing(true);
      let defaultProperties = structuredClone(selectedCategory.defaultProperties);
      if(defaultProperties.filter((prop, index) => index !== rowIndex && prop.name === data.name).length !== 0) return toast.error("Διπλότυπη Ιδιότητα.");
      defaultProperties[rowIndex] = {...defaultProperties[rowIndex], ...data};
      setSelectedCategory({...selectedCategory, defaultProperties: defaultProperties});
    },
    selected: () => {},
    delete: (id, rowIndex) => {
      setEditing(true);
      let defaultProperties = structuredClone(selectedCategory.defaultProperties);
      defaultProperties.splice(rowIndex, 1);
      setSelectedCategory({...selectedCategory, defaultProperties: defaultProperties});
    }
  }

  const handleSaveCategory = () => {
    if (editing) {
      let saveCategory = newCategory;

      // In case of updating existing category
      if ((Object.keys(selectedCategory).length !== 0)) {
        saveCategory = selectedCategory;
      }
      let isValid = true;
      saveCategory = {...saveCategory, ...{taxes: JSON.stringify(taxes)}}
      saveCategory = {...saveCategory, ...{mydata: JSON.stringify(mydata)}}
      saveCategory.id = saveCategory._id;
      mydata.map((row) => {
        if (row.rowType === "" || row.invoiceType === "" || row.category === "" || row.type === "") {
          toast.error('Ελέγξτε όλα τα υποχρεωτικά πεδία MyData.');
          isValid = false;
        }
        if(row.rowType === "expense" && !row?.vatExpenseType){
          toast.error('Το πεδίο Τύπος Εξόδων για Φ.Π.Α. είναι υποχρεωτικό.');
          isValid = false;
        }
        return row;
      });
      if(isValid) {
        if ((Object.keys(selectedCategory).length !== 0)) {
          dispatch(putCategory(saveCategory, saveCategory._id, 'update', requestData)); // Update API Call
        } else {
          dispatch(addNewCategory(saveCategory, requestData));  // Save new category with taxes and mydata
        }
      }
      setEditing(false);
      setForceRender(forceRender + 1)
    }
  }

  const parentActions = {
    insert: (e, data) => {
      let tempNewCategory = Object.assign({},  PRODUCTS_CATEGORY.newCategory);
      if (e.key !== "Enter") {
        if (data.field === 'maskCode') {
          tempNewCategory[data.field] = getMaskCode(data.value);
          tempNewCategory['maskCodePrefix'] = getMaskCodePrefix(data.value);
          tempNewCategory['mask'] = data.value;
        } else {
          tempNewCategory[data.field] = data.value;
          // tempNewCategory['maskCode'] = `PCM-${categoryData.length + 1}`;
        }
        dispatch(setNewCategory(tempNewCategory)); // Update redux new category state

        // On Value Enter
        if (tempNewCategory.title !== "" && tempNewCategory.maskCode !== "") {
            let updateNewCategory = Object.assign({},  PRODUCTS_CATEGORY.newCategory);
            updateNewCategory.maskCode = tempNewCategory.maskCode;
            updateNewCategory.mask = tempNewCategory.mask;
            updateNewCategory.maskCodePrefix = getMaskCodePrefix(data.value);
            dispatch(addNewCategory({...updateNewCategory, ...requestData}, requestData));  // Add New Category API call
            dispatch(setNewCategory(PRODUCTS_CATEGORY.newCategoryInitialValue)); // Reset new category data
        }

      }

      // If pressed enter
      if (e.key === "Enter") {
        if (data.field === 'title' && data.value === '') {
          toast.error("Category name should not be empty.");
        } else {
          let updateNewCategory = Object.assign({},  PRODUCTS_CATEGORY.newCategory);

          if (data.field === 'title' && data.value !== '') {
            updateNewCategory.title = data.value;
          }

          if (updateNewCategory?.title !== '') {
            if (data.field === 'maskCode' && data.value !== '') {
              updateNewCategory.mask = data.value;
              updateNewCategory.maskCode = getMaskCode(data.value);
              updateNewCategory.maskCodePrefix = getMaskCodePrefix(data.value);
            }
            dispatch(addNewCategory({...updateNewCategory, ...requestData}, requestData));  // Add New Category API call
            dispatch(setNewCategory(PRODUCTS_CATEGORY.newCategoryInitialValue)); // Reset new category data
          } else {
            toast.error("Category name should not be empty.");
          }
        }
      }
    },
    selected: (data) => {
      if (editing) {
        toast.warning('Please save your changes.');
      } else {
        if (typeof (data._id) !== 'undefined' ) {
          const selected = getObjectFromArrayOfObjects(categoryData, data._id,'_id')['0'];
          setSelectedCategory(selected);

          // Set Proposed My Data for selected category
          if ((Object.keys(selected).length !== 0)) {
            if (selected.mydata !== '') {
              try {
                setMyData(JSON.parse(selected.mydata))
              } catch (e) {
                console.log('mydata is not an json object');
                setMyData([]) // Clear the MyData table
              }
            }
          } else {
            setMyData([]) // Clear my data table
          }

          // Set Taxes for selected category
          if ((Object.keys(selected).length !== 0)) {
            if (selected.taxes !== '') {
              try {
                const jsonObj = JSON.parse(selected.taxes);
                setTaxes(jsonObj);
                if (Object.keys(jsonObj).length > 0)  {
                  if((jsonObj.vat === '0%') || (jsonObj.vat === '0')) {
                    setShowZeroVatReson(true);
                  }
                } else {
                  setShowZeroVatReson(false);
                }
              } catch (e) {
                console.log('taxes is not an json object');
                setTaxes({}); // clear taxes fields
              }
            }
          } else {
            setTaxes({}); // clear taxes fields
          }
          setShowExtraDetails(true);
        } else {
          setShowExtraDetails(false);
        }
      }
    },
    update: (data, id) => {
      setForceRender(forceRender + 1)

      if (data?.title === '') {
        toast.error('Category name should not be empty.');
      } else {
        let tempCategoryData = categoryData.slice();
        if (tempCategoryData) {

          const objindx = tempCategoryData.findIndex(obj => obj._id === id);
          let catObj = tempCategoryData[objindx];
          let titleMatch = (data.maskCode === catObj.maskCode); // it will check if same text
          let tempUpdateObj  = {...catObj, ...data}
          tempUpdateObj.id = id;

          if (data?.maskCode === '') {
            toast.error('Mask code should not be empty.');
          } else if (data.maskCode && data.maskCode !== '') {
            tempUpdateObj.mask = getMaskCodePrefix(data.maskCode) + '-' + tempUpdateObj.maskCodeCounter;
            tempUpdateObj.maskCode = getMaskCode(data.maskCode);
            tempUpdateObj.maskCodePrefix = getMaskCodePrefix(data.maskCode);
          }

          if (!titleMatch) {
            setSelectedCategory(tempUpdateObj);
            dispatch(putCategory(tempUpdateObj, id, 'update', requestData));
            setEditing(true);
          }
        }
      }
    },
    delete: (id) => {
      let deleteCategory = getObjectFromArrayOfObjects(categoryData, id,'_id')['0'];
      const deleteItem =  {...deleteCategory, ...{ id: id, isActive: 'delete', deleted: true }};
      dispatch(putCategory(deleteItem, id, 'delete', requestData)); // Update API Call
    }
  }

  const parentMydataActions = {
    insert: (e, data) => {
      setEditing(true);
      setForceRender(forceRender + 1)
      let tempPcMyData = Object.assign({},  PRODUCTS_CATEGORY.newProductsCategoryMyData);
      if (e.key !== "Enter") {
        tempPcMyData[data.field] = data.value;
        dispatch(setNewProductsCategoryMyData(tempPcMyData)); // Update redux my data values
      }

      // If validated fields done add new row
      if (tempPcMyData.rowType !== '' || tempPcMyData.invoiceType !== '' || tempPcMyData.category !== '' || tempPcMyData.type !=='') {
        let updateNewPcMydata = Object.assign({},  PRODUCTS_CATEGORY.newProductsCategoryMyData);
        updateNewPcMydata.id = mydata.length + 1;

        // set the last updated value
        if (tempPcMyData.type) {
          updateNewPcMydata.type = tempPcMyData.type;
        }

        if (tempPcMyData.rowType) {
          updateNewPcMydata.rowType = tempPcMyData.rowType;
        }

        if (tempPcMyData.invoiceType) {
          updateNewPcMydata.invoiceType = tempPcMyData.invoiceType;
        }

        if (tempPcMyData.category) {
          updateNewPcMydata.category = tempPcMyData.category;
        }
        setMyData([...mydata, updateNewPcMydata]); // update data in state
        dispatch(setNewProductsCategoryMyData(PRODUCTS_CATEGORY.newProductsCategoryMyDataInitialValue)); // Reset new category mydata
      }

      // Add on press enter
      if (e.key === "Enter" && data.field !== 'add') {
        if (data.field === 'rowType' && data.value === '') {
          toast.error("My Data Row Type should not be empty.");
        } else {
          let updateNewPcMydata = Object.assign({},  PRODUCTS_CATEGORY.newProductsCategoryMyData);
          if (updateNewPcMydata.field === 'rowType' && updateNewPcMydata.value === '') {
            toast.error("My Data Row Type should not be empty.");
          } else {
              updateNewPcMydata.id = mydata.length + 1;
              updateNewPcMydata.rowType = data.value;
              setMyData([...mydata, updateNewPcMydata]); // update data in state
              dispatch(setNewProductsCategoryMyData(PRODUCTS_CATEGORY.newProductsCategoryMyDataInitialValue)); // Reset new category mydata
          }
        }
      }

      // Fix allow empty row insert on check box click
      if (e.key === "Enter" && data.field === 'add') {
        let updateNewPcMydata = Object.assign({},  PRODUCTS_DATA.newProductsMyData);
        updateNewPcMydata.id = mydata.length + 1;
        updateNewPcMydata.rowType = "";
        updateNewPcMydata.invoiceType = "";
        updateNewPcMydata.category = "";
        updateNewPcMydata.type = "";
        setMyData([...mydata, updateNewPcMydata]); // update data in state
        dispatch(setNewProductsCategoryMyData(PRODUCTS_CATEGORY.newProductsCategoryMyDataInitialValue)); // Reset new category mydata
      }
    },
    update: (data, id) => {
      setEditing(true);
      setForceRender(forceRender + 1)
      if (data.rowType && data.rowType === '') {
        toast.error("Row Type is mandatory field.");
      } else {
        if (mydata.find(item => item.id === id) !== undefined) {
          mydata[id-1] = {...mydata[id - 1], ...data};
          if (data.invoiceType) {
            mydata[id-1].category = "";
            mydata[id-1].type = "";
            mydata[id-1].vatExpenseType = "";
          } else if (data.category) {
            mydata[id-1].type = "";
            mydata[id-1].vatExpenseType = "";
          } else if (data.type) {
            mydata[id-1].vatExpenseType = "";
          }
          setMyData(mydata);
        }
      }
    },
    selected: () => {},
    delete: (id, rowIndex) => {
      setEditing(true);
      let clonedMyData = structuredClone(mydata);
      clonedMyData.splice(rowIndex, 1);
      setMyData(clonedMyData);
      setForceRender(forceRender + 1)
    }
  }

  const handleApplyToAll = () => {
    setLoadingApplyToAll(true);
    axios.post(process.env.REACT_APP_API_URL2 + "/category/apply-accounting-settings-to-all", {
      company: company.id,
      title: selectedCategory.title,
      accountingCode: selectedCategory?.accountingCode ? selectedCategory.accountingCode : "",
      netAmountAccountingCode: selectedCategory?.netAmountAccountingCode ? selectedCategory.netAmountAccountingCode : "",
      vatAccountingCode: selectedCategory?.vatAccountingCode ? selectedCategory.vatAccountingCode : "",
      taxAccountCode: selectedCategory?.taxAccountCode ? selectedCategory.taxAccountCode : "",
    }, {
      headers: {"Content-Type": "application/json"},
    }).then((res) => {
      setLoadingApplyToAll(false);
      if (res.data.status === "200") {
        toast.success("Η διαδικασία ολοκληρώθηκε επιτυχώς.");
        setShowApplyToAll(false);
      } else {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      setLoadingApplyToAll(false);
      toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      console.log(err);
    })
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="product-categories">
            <EditableTable
              tableName = "Product Category" // Do not change name
              selectedRow={selectedCategory._id}
              onUpdate={parentActions}
              key={Math.random()}
              allowActions={true}
              allowInsertRow={ true }
              data={categoryData}
              columns={ productCategoryColumns }
            />
          </div>
        </div>
        <div className="col-lg-6 ">
          <p className="text-muted"><i>{t('ProductsSettings.instructions')}</i></p>
          <p>{t('ProductsSettings.text1')}</p>
          <p>{t('ProductsSettings.text2')}</p>
        </div>
      </div>

      {/* Category Extra Details */}
      { showExtraDetails ?
          <>
            <br/>
            <p className="text-center border-bottom pb-3 pt-3"><i>{t('ProductsSettings.categorySettings')}</i></p>
            {checkPermission("products-products/new-variants-write", permissionsData) === true && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-12">
                      <div className="text-muted mb-2 d-flex align-items-center">
                        <i>{t("ProductVariants.productProperties")}</i>
                        <div className="ml-2">
                          <ModalInstructions data={propertiesInstructions}/>
                        </div>
                      </div>
                      <EditableTable
                          tableName="Default Category Properties" // Do not change name
                          key={Math.random()}
                          onUpdate={propertiesActions}
                          allowActions={true}
                          allowInsertRow={true}
                          columns={propertiesColumns}
                          data={selectedCategory.defaultProperties}
                          enableNewRowCheckbox={true}
                      />
                    </div>
                  </div>
                  <hr/>
                </React.Fragment>
            )}
            <div className="row">
              <div className="col-12">
                <p className="text-muted"><i>{t('ProductsSettings.proposedMyDataValue')}</i></p>
                <EditableTable
                    tableName="Product Category My Data" // Do not change name
                    key={Math.random()}
                    onUpdate={parentMydataActions}
                    allowActions={true}
                    allowInsertRow={true}
                    columns={productsColumns}
                    data={mydata}
                    enableNewRowCheckbox={true}
                />
              </div>
              {/* { process.env.REACT_APP_DEBUG_MODE === 'true' && <pre>{JSON.stringify(mydata, null, 2)}</pre> } */}

              {/* <div className="col-lg-6 ">
            <p className="text-muted"><i>Accounting</i></p>
          </div> */}
            </div>
            <hr/>
            <div className="row">
              <div className="col-12">
                <p className="text-muted"><i>{t('ProductsSettings.taxes')}</i></p>
              </div>
              <div className="col-lg-4">
                {taxes.vat !== 'undefined' ?
                    <Dropdown key={Math.random()} defaultValue={{value: taxes.vat, label: taxes.vat, color: "#76AEB7"}}
                              onChange={(e) => handleOnFieldChange(e, 'vat', 'dd')} label={t('ProductsSettings.vat%')}
                              options={vatTaxes}/>
                    :
                    <Dropdown key={Math.random()} onChange={(e) => handleOnFieldChange(e, 'vat', 'dd')}
                              label={t('ProductsSettings.vat%')} options={vatTaxes}/>
                }
              </div>
              {showZeroVatReson &&
                  <div className="col-lg-4">
                    {taxes.vatreason !== 'undefined' ?
                        <Dropdown defaultValue={zeroVatReasonOptions.find((el) => el.value === String(taxes.vatreason))}
                                  key={Math.random()} label={t('ProductsSettings.0%vatReason')}
                                  onChange={(e) => handleOnFieldChange(e, 'vatreason', 'dd')} options={zeroVatReason}/>
                        :
                        <Dropdown key={Math.random()} label={t('ProductsSettings.0%vatReason')}
                                  onChange={(e) => handleOnFieldChange(e, 'vatreason', 'dd')} options={zeroVatReason}/>
                    }
                  </div>
              }
            </div>
            <div className="row">
              <div className="col-lg-4">
                <AdvancedCombobox key={Math.random()} defaultValue={{
                  value: taxes.otherTaxes,
                  label: getLabelByValue(otherTaxes, taxes.othertaxes, 'value'),
                  color: "#76AEB7"
                }} onChange={(e) => handleOnFieldChange(e, 'othertaxes', 'ac')} searchableDropdownEnable={true}
                                  label={t('ProductsSettings.otherTaxes')} data={otherTaxes}/>
              </div>
              <div className="col-lg-4">
                <AdvancedCombobox key={Math.random()} defaultValue={{
                  value: taxes.taxesWithhold,
                  label: getLabelByValue(taxesWithhold, taxes.witholdtaxes, 'value'),
                  color: "#76AEB7"
                }} onChange={(e) => handleOnFieldChange(e, 'witholdtaxes', 'ac')} searchableDropdownEnable={true}
                                  label={t('ProductsSettings.withHoldTaxes')} data={taxesWithhold}/>
              </div>
              <div className="col-lg-4">
                <AdvancedCombobox key={Math.random()} defaultValue={{
                  value: taxes.stamp,
                  label: getLabelByValue(taxesStamp, taxes.stamp, 'value'),
                  color: "#76AEB7"
                }} onChange={(e) => handleOnFieldChange(e, 'stamp', 'ac')} searchableDropdownEnable={true}
                                  label={t('ProductsSettings.stamp')} data={taxesStamp}/>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <AdvancedCombobox key={Math.random()} defaultValue={{
                  value: taxes.fees,
                  label: getLabelByValue(taxesFees, taxes.fees, 'value'),
                  color: "#76AEB7"
                }} onChange={(e) => handleOnFieldChange(e, 'fees', 'ac')} searchableDropdownEnable={true}
                                  label={t('ProductsSettings.fees')} data={taxesFees}/>
              </div>
              <div className="col-lg-4">
                <label>{t('ProductsSettings.measurementUnit')}</label>
                <AdvancedCombobox
                    key={Math.random()}
                    searchableDropdownEnable={true}
                    defaultValue={{
                      value: selectedCategory.measurementUnit,
                      label: getLabelByValue(measurementUnits, selectedCategory.measurementUnit, 'value')
                    }}
                    onChange={(e) => handleOnChange(e, 'measurementUnit', 'ac')}
                    data={measurementUnits}
                />
              </div>
            </div>
            {checkPermission("products-products/new-accounting-write", permissionsData) && (
                <React.Fragment>
                  <hr/>
                  <p className="text-muted"><i>Λογιστική</i></p>
                  <Row>
                    <Col md={4}>
                      <Input
                          name={"accountingCode"}
                          className="mb-3"
                          charLimit={20}
                          placeholder="Εισάγετε Πρόθεμα Κωδικού Λογαριασμού"
                          label="Πρόθεμα Κωδικού Λογαριασμού"
                          value={selectedCategory.accountingCode ? selectedCategory.accountingCode : ""}
                          autoComplete={"off"}
                          onChange={(e) => handleOnChange(e, "accountingCode", "input")}
                      />
                    </Col>
                    <Col md={4}>
                      <BetterCheckBox
                          name={"accountingNonDeductibleVAT"}
                          className={"mt-3"}
                          text={"Μη Εκπιπτόμενος Φ.Π.Α."}
                          checked={selectedCategory.accountingNonDeductibleVAT === "active"}
                          onChange={(e) => handleOnChange(e, "accountingNonDeductibleVAT", "checkbox")}
                      />
                    </Col>
                  </Row>
                  <div className="text-muted mb-2"><i>Παράκαμψη Λογιστικής Γέφυρας:</i></div>
                  <Row>
                    <Col md={4}>
                      <Input
                          name={"netAmountAccountingCode"}
                          className="mb-3"
                          charLimit={20}
                          placeholder="Εισάγετε Κωδικό Λογαριασμού Καθαρής Αξίας"
                          label="Κωδικός Λογαριασμού Καθαρής Αξίας"
                          value={selectedCategory.netAmountAccountingCode ? selectedCategory.netAmountAccountingCode : ""}
                          autoComplete={"off"}
                          onChange={(e) => handleOnChange(e, "netAmountAccountingCode", "input")}
                      />
                    </Col>
                    <Col md={4}>
                      <Input
                          name={"vatAccountingCode"}
                          className="mb-3"
                          charLimit={20}
                          placeholder="Εισάγετε Κωδικό Λογαριασμού ΦΠΑ"
                          label="Κωδικός Λογαριασμού ΦΠΑ"
                          value={selectedCategory.vatAccountingCode ? selectedCategory.vatAccountingCode : ""}
                          autoComplete={"off"}
                          onChange={(e) => handleOnChange(e, "vatAccountingCode", "input")}
                      />
                    </Col>
                    <Col md={4}>
                      <Input
                          name={"taxAccountCode"}
                          className={"mb-3"}
                          charLimit={20}
                          placeholder={"Εισάγετε Κωδικό Λογαριασμού Φόρου"}
                          label={"Κωδικός λογαριασμού Φόρου"}
                          value={selectedCategory.taxAccountCode ? selectedCategory.taxAccountCode : ""}
                          autoComplete={"off"}
                          onChange={(e) => handleOnChange(e, "taxAccountCode", "input")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Button onClick={() => setShowApplyToAll(true)}>Εφαρμογή Λογιστικής σε όλα τα είδη αυτής της
                        κατηγορίας</Button>
                    </Col>
                  </Row>
                </React.Fragment>
            )}
          </>
          : ''}
      <Modal backdrop={"static"} show={showApplyToAll} onHide={() => setShowApplyToAll(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Εφαρμογή Λογιστικής σε όλα τα είδη</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Αποθηκεύστε τις ρυθμίσεις πρωτού ξεκινήσετε αυτήν την διαδικασία.</strong><br/>
          Είσαστε σίγουροι ότι θέλετε να εφαρμόσετε τις τωρινές ρυθμίσεις λογιστικής σε όλα τα είδη της
          κατηγορίας {selectedCategory.title};<br/>
          Συγκεκριμένα θα τοποθετηθεί:<br/>
          - Πρόθεμα Κωδικού
          λογαριασμού: {selectedCategory?.accountingCode ? selectedCategory.accountingCode : "Κενό"}<br/>
          - Πρόθεμα Λογαριασμού παράκαμψης καθαρής
          αξίας: {selectedCategory?.netAmountAccountingCode ? selectedCategory.netAmountAccountingCode : "Κενό"}<br/>
          - Πρόθεμα Λογαριασμού παράκαμψης ΦΠΑ: {selectedCategory?.vatAccountingCode ? selectedCategory.vatAccountingCode : "Κενό"}<br/>
          - Πρόθεμα Λογαριασμού παράκαμψης Φόρου: {selectedCategory?.taxAccountCode ? selectedCategory.taxAccountCode : "Κενό"}<br/>
          Η διαδικασία μπορεί να πάρει λίγο χρόνο.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowApplyToAll(false)}>Όχι, κλείσιμο</Button>
          <Button variant="primary" onClick={() => handleApplyToAll()} disabled={loadingApplyToAll}>Ναι, εφαρμογή σε όλα {loadingApplyToAll && <Spinner animation="border" variant="dark" />}</Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end">
        {checkPermission("products-settings/productcategories-settings-write", permissionsData) &&
            <Button disabled ={!editing} onClick={(e) => handleSaveCategory(e)} className="ml-2 mb-3">{t('ProductsSettings.save')}</Button>
        }
      </div>
    </>
  )
}

// Products Settings for datapanel component
export const ProductSettingsLog = () => {
  const { t } = useTranslation('common');

  return (
      <React.Fragment>
        <div className="text-muted mb-2"><i>{t('Logs.productCategoryLog')}</i></div>
        <LogTable model="productCategory"/>
      </React.Fragment>
  )
};

// Product Settings for datapanel component
export const ProductSettings = () => {
  return (
    <>
      <p>There is no information for this page in wireframe.</p>
    </>
  );
};
