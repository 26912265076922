import React, { useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { putBankAccounts, putBasicType, putCashRegisters, putClient, putClientMovements, putPOS, putPriceList, putProduct, putProductMovements, putSupplier, putSupplierMovements, putSupplierPayment } from "../../../_apis/api";
import {useTranslation} from "react-i18next";

const QuickEdit = (props) => {
  const [t] = useTranslation('common');
  const formData = props.data;
  const formFields = props.columns;
  const slideingPaneWidth = props.width ? props.width : '400px';

  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
    editId: props.id,
  });

  const dispatch = useDispatch();
  const company = useSelector(state => state.COMPANY_DATA.company)
  const [formUpdatedData, setFormUpdatedData] = useState(props.data);

  let requestData = {
    company:company.id,
    year:company.year.toString(),
  }

  const handleInputChange = (e) => {
    setFormUpdatedData({
      ...formUpdatedData,
      [e.target.name]: e.target.value
    })
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let updatedClientData = formUpdatedData;
    updatedClientData.id = formUpdatedData._id;
    updatedClientData =  {...updatedClientData, ...requestData}

    if (props.tableName && props.tableName === "Sales Products List") {
      dispatch(putProduct(updatedClientData, requestData, 'update'))
    } else if (props.tableName && props.tableName === "Search Products") {
        dispatch(putProduct(updatedClientData, requestData, 'update'))
    } else if (props.tableName && props.tableName === "Search Product Movements") {
        dispatch(putProductMovements(updatedClientData))
    } else if (props.tableName && props.tableName === "Search Product Price List") {
        dispatch(putPriceList(updatedClientData, requestData, 'update'))
    } else if (props.tableName && props.tableName === "Bank Account Search") {
        dispatch(putBankAccounts(updatedClientData, requestData))
    } else if (props.tableName && props.tableName === "POS Search") {
        dispatch(putPOS(updatedClientData, requestData))
    } else if (props.tableName && props.tableName === "Cash Registers Search") {
        dispatch(putCashRegisters(updatedClientData, requestData))
    } else if (props.tableName && props.tableName === "Search Product Movements") {
        dispatch(putProductMovements(updatedClientData))
    } else if (props.tableName && props.tableName === "Basic Types Search") {
        dispatch(putBasicType(updatedClientData, requestData, 'update'))
    } else if (props.tableName && props.tableName === "Client Search") {
        dispatch(putClient(updatedClientData, requestData, 'update'))
    } else if (props.tableName && props.tableName === "Supplier Search") {
        dispatch(putSupplier(updatedClientData, requestData, 'update'))
    } else if (props.tableName && props.tableName === "Client Movements Search") {
        dispatch(putClientMovements(updatedClientData))
    } else if (props.tableName && props.tableName === "Supplier Movements Search") {
        dispatch(putSupplierMovements(updatedClientData))
    } else if (props.tableName && props.tableName === "Supplier Payments Search") {
        dispatch(putSupplierPayment(updatedClientData))
    } else {
      props.updateData(e, 'edit', formUpdatedData);
    }
  };

  return (
    <>
      <Button variant="default" title={props.title ? props.title : "Quick Edit"}
        onClick={() => {
          setState({ isPaneOpen: true });
        }}
        size="sm"
      >
        <i
          id={state.editId}
          className="far fa-edit"
        ></i>
      </Button>

      <SlidingPane
          className="some-custom-class side-bar-form-quick-edit"
          overlayClassName="some-custom-overlay-class"
          isOpen={state.isPaneOpen}
          title="Quick Edit"
          subtitle=""
          width={slideingPaneWidth}
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setState({ isPaneOpen: false });
          }}
        >
          <div style={{width: "30px !important"}}>
            <Form onSubmit={(e) => handleFormSubmit(e)} autoComplete='off'>
              {
              formFields && formFields.map((item, ffind) => {

                // Excluding the actions fields from the Quick form
                if(item.field === 'actions' || item.field === 'action' || item.field === 'select_checkbox') {
                  return '';
                }

                let disabledField = false;
                const disabledFields = ['code', 'systemCode', 'categoryName', 'clientCode', 'date', 'documentTypeName', 'sum', 'products_list'];
                if (disabledFields.includes(item.field)) {
                  disabledField = true;
                }

                return <Form.Group key={ffind} className='mb-3' controlId={`${item.name}-form`} autoComplete="off">
                  <Form.Label className="mb-0">{item.name}</Form.Label>
                  {!props.hideCurrentValue && !disabledField && formData[item.field] && <Form.Text>{t('searchSideTable.currentValue')} {`${formData[item.field]}`}</Form.Text>}
                    {
                      (item.inputType && (item.inputType.type === 'select' || item.inputType.type === 'radio' || item.inputType.type === 'checkbox' || item.inputType.type === 'dropdown')) ?
                        <Form.Select
                          onChange={(e) => handleInputChange(e)}
                          defaultValue={formUpdatedData[item.field]}
                          value={formUpdatedData[item.field]}
                          name={item.field}
                          disabled={disabledField}
                        >
                          {
                            item.inputType.options.map((op, opi) =>
                              <option key={opi} value={ op.value }>{ op.label }</option>
                            )
                          }
                        </Form.Select>
                      :
                        <Form.Control
                          onChange={(e) => handleInputChange(e)}
                          defaultValue={formUpdatedData[item.field]}
                          name={item.field}
                          disabled={disabledField}
                          value={formUpdatedData[item.field]}
                          placeholder={t('searchSideTable.enterNew') + ' ' + item.name}

                        />
                    }
                </Form.Group>
              })
              }
              <div className="quick-edit-actions">
                <Button
                  onClick={() => setState({ isPaneOpen: false })}
                  variant="outline-primary"
                  type="button"
                  className="mr-2"
                >
                  {t('searchSideTable.cancel')}
                </Button>

                <Button
                type="submit"
                >
                  {t('searchSideTable.save')}
                </Button>
              </div>
            </Form>
          </div>
          <br />
      </SlidingPane>
    </>
  );
};

export default QuickEdit;
