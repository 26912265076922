import {createSlice} from "@reduxjs/toolkit";

const initialState = [];

export const NavbarSlice = createSlice({
    name: "navbar-slice",
    initialState,
    reducers: {
        activePath: (state, action) => (state = action.payload),
    },
});

export const {activePath} = NavbarSlice.actions;
