import React from "react";
import "./index.css";

const BetterCheckBox = ({ text, id, className, name, value, onChange, disabled, checked }) => {
    let classes = 'form-group';
    if (className) {
        classes = className;
    }
    const inputName = name ? name : 'val-terms';
    return (
        <div className={classes} style={{opacity: disabled ? "0.6" : "1"}}>
            <label className="css-control css-checkbox" htmlFor={id} style={{display: "flex"}}>
                <input
                    disabled = {disabled}
                    type="checkbox"
                    className="css-control-input mr-2"
                    id={id}
                    checked={checked}
                    name={inputName}
                    value={value}
                    onChange={(e) => onChange(e)}
                />
                <span className="normal-text">{text}</span>
                <span className="checkmark"></span>
            </label>
        </div>
    )
}

export default BetterCheckBox;
