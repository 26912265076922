import React, {useState} from "react";
import {useDispatch} from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {postTemplate} from "../../../_apis/api";
import {saveAsTemplateClearTableFields} from "../../../_helpers/commonFunctions";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Input from "../Input";
import {Spinner} from "react-bootstrap";

const SaveAsTemplate = ({templateData, requestData, templateType}) => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const [templateName, setTemplateName] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = () => {
        setLoading(true);
        let updatedTemplateData = Object.assign({}, templateData);

        // Do Not save "Product code", because it is produced by the system
        // Do Not save "Product Name"
        // Do not save "Alternative code", "EAN Barcode"
        if (templateType === 'product') {
            updatedTemplateData.name = updatedTemplateData.alternateName = updatedTemplateData.code = updatedTemplateData.alternateCode = updatedTemplateData.barcode = '';
        }

        // Do Not save "Date", "Quantity" column
        if (templateType === 'productMovements') {
            updatedTemplateData.date = updatedTemplateData.number = '';
            updatedTemplateData.products = saveAsTemplateClearTableFields(updatedTemplateData.products, ['quantity']);
        }

        // Do Not save "Name, Columns: Price, V.A.T."
        if (templateType === 'priceList') {
            updatedTemplateData.name = '';
            updatedTemplateData.productList = saveAsTemplateClearTableFields(updatedTemplateData.productList, ['price', 'vat']);
        }

        // Do Not save "Name, Columns: Price, V.A.T."
        if (templateType === 'sales') {
            updatedTemplateData.name = '';
        }

        // Template Data
        let postData = {
            name: templateName,
            templateData: JSON.stringify(updatedTemplateData)
        }

        // Add Template
        let postTemplateData = {...postData, ...requestData};

        if (templateName && templateName !== '') {
            if (templateData && Object.keys(templateData).length > 0) {
                dispatch(postTemplate(postTemplateData, requestData, clearStates));
            } else {
                setLoading(false);
                toast.error("Template data not found.");
            }
        } else {
            setLoading(false);
            toast.error("Template name is mandatory field.");
        }
    }

    const clearStates = () => {
        setTemplateName("");
        setLoading(false);
        handleClose();
    }

    return (
        <React.Fragment>
            <Button backdrop={"static"} size="sm" onClick={() => handleShow()}>{t('SaveAsTemplateIndex.saveAsTemplate')}</Button>
            <Modal backdrop={"static"} className="themeModal" show={show} onHide={() => handleClose()}>
                <Modal.Header closeButton style={{fontSize: "16px", textAlign: "center"}}>
                    Εισαγωγή νέου προτύπου
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-center justify-content-center mb-3">
                        <Input
                            name={"newTemplateName"}
                            label={t('SaveAsTemplateIndex.nameOfNewTemplate')}
                            placeholder={t('SaveAsTemplateIndex.placeholder')}
                            value={templateName}
                            required={true}
                            autoComplete={"off"}
                            onChange={(e) => setTemplateName(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-actions">
                        <Button variant="outline-primary" onClick={() => handleClose()} disabled={loading} className="mr-2">{t('SaveAsTemplateIndex.cancel')}</Button>
                        <Button variant="primary" onClick={() => handleClick()} disabled={loading}>
                            {t('SaveAsTemplateIndex.save')} {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default SaveAsTemplate;
