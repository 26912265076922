import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector, useDispatch} from "react-redux";
import {deleteObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import {getOptionsByCommaString, getCurrentUser} from "../../../../_helpers/commonFunctions";
import EditableTable from "../../../primer/Editable-Table";
import {toast} from "react-toastify";
import {setSaveEditClient, setSaveNewClient} from "../../../../_reducers/activitySlice";
import {useTranslation} from "react-i18next";
import {
    resetNewCommunicationHistory,
    setClientsSettings,
    setNewClient,
    setNewCommunicationHistory
} from "../../../../_reducers/DataPanel/Clients/clientSlice";

const apiURL2 = process.env.REACT_APP_API_URL2;

function NewClientCommunicationHistory() {
    const [t] = useTranslation('common');
    const company = useSelector(state => state.COMPANY_DATA.company)
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
    const clientSubjects = CLIENTS_DATA.clientsSettings.clientCommunicationHistory;
    const subjectsOptionsInit = getOptionsByCommaString(clientSubjects);
    const [subjectsOptions, setSubjectsOptions] = useState(subjectsOptionsInit);
    let chData = CLIENTS_DATA.newClient.communicationHistory.slice();
    const dispatch = useDispatch();
    let chColumns = [
        {
            name: "No",
            field: "no",
            width: "15px",
            editable: false,
        },
        {
            name: t('ClientsNewCommunicationHistory.table.date'),
            field: "date",
            width: "80px",
            editable: true,
            inputType: {
                type: 'datebox',
            }
        },
        {
            name: t('ClientsNewCommunicationHistory.table.time'),
            field: "time",
            width: "50px",
            editable: true,
            inputType: {
                type: 'time',
            }
        },
        {
            name: t('ClientsNewCommunicationHistory.table.subject'),
            field: "subject",
            width: "100px",
            editable: true,
            inputType: {
                type: 'dropdown',
                options: subjectsOptions
            }
        },
        {
            name: t('ClientsNewCommunicationHistory.table.details'),
            field: "details",
            editable: true,
            width: "400px",
            inputType: {
                type: 'textarea',
            }
        },
        /*{
          name: t('ClientsNewCommunicationHistory.table.followUpDate'),
          field: "followUpDate",
          editable: true,
          width: "100px",
          inputType: {
            type: 'datebox',
          }
        },
        {
          name: t('ClientsNewCommunicationHistory.table.done'),
          field: "done",
          editable: true,
          width: "90px",
          inputType: {
            type: 'checkbox',
            options: [{
              value: true,
              label: 'Yes'
            }, {
              value: false,
              label: 'No'
            }]
          }
        },*/
    ]
    const [tableColumns, setTableColumns] = useState(chColumns.slice())
    const currentUser = getCurrentUser()
    useEffect(() => {
        axios.post(apiURL2 + `/client/list-client-setting`, JSON.stringify({
            company: company.id,
            year: company.year.toString(),
            isActive: "active",
            userId: currentUser.id
        }), {headers: {'Content-Type': 'application/json'}})
            .then(res => {
                if (res.data && res.data.status === '200') {
                    if (res.data.data.length > 0) {
                        dispatch(setClientsSettings(res.data.data[res.data.data.length - 1]));
                        sessionStorage.setItem('clientsSettingsData', JSON.stringify(res.data.data[res.data.data.length - 1]));
                        const subjectsOptionsUp = getOptionsByCommaString(res.data.data[res.data.data.length - 1]?.clientCommunicationHistory);
                        setSubjectsOptions(subjectsOptionsUp);
                    }
                }
            }).catch((err) => {
            console.log(err);
        });
        setTableColumns(chColumns); // set options
    }, [company.id, company.year, dispatch]);

    const tableActions = {
        insert: (e, data) => {
            if (!ACTIVITY.saveNewClient) dispatch(setSaveNewClient(true));

            let tempCommunicationHistory = Object.assign({}, CLIENTS_DATA.newCommunicationHistory);
            tempCommunicationHistory[data.field] = data.value;
            dispatch(setNewCommunicationHistory(tempCommunicationHistory)); // Update redux
            // Save Data
            if (tempCommunicationHistory && tempCommunicationHistory.subject !== '' && tempCommunicationHistory.details !== "" && tempCommunicationHistory.date !== '' && tempCommunicationHistory.time !== '') {
                let UpdatedComHist = Object.assign({}, tempCommunicationHistory);
                let oldComHist = CLIENTS_DATA.newClient.communicationHistory;
                UpdatedComHist.no = oldComHist.length + 1;
                UpdatedComHist.id = oldComHist.length + 1;
                dispatch(setNewClient({
                    ...CLIENTS_DATA.newClient,
                    communicationHistory: [UpdatedComHist, ...oldComHist]
                }));
                dispatch(resetNewCommunicationHistory()); // Reset
            }
        },
        update: (data, id) => {
            if (!ACTIVITY.saveNewClient) dispatch(setSaveEditClient(true));

            if (data.date && (data.date === "")) {
                toast.error("Date is mandatory field.");
            } else if (data.time && (data.time === "")) {
                toast.error("Time  is mandatory field.");
            } else {
                let communicationHistory = CLIENTS_DATA.newClient.communicationHistory.slice();
                const index = communicationHistory.findIndex(item => item.id === id);
                if (index !== -1) {
                    // Update Code
                    communicationHistory[index] = {...communicationHistory[index], ...data};
                    dispatch(setNewClient({...CLIENTS_DATA.newClient, communicationHistory: communicationHistory})); // update data
                }
            }
        },
        selected: () => {},
        delete: (id) => {
            // Set if editing the form
            if (!ACTIVITY.saveNewClient) dispatch(setSaveNewClient(true));

            let communicationHistory = CLIENTS_DATA.newClient.communicationHistory.slice();
            if (id !== '' && Number.isInteger(id)) {
                communicationHistory = deleteObjectFromArrayOfObjects(communicationHistory, id, 'id');
                dispatch(setNewClient({...CLIENTS_DATA.newClient, communicationHistory: communicationHistory}))
            }
            if (id !== '' && !Number.isInteger(id)) {
                communicationHistory = deleteObjectFromArrayOfObjects(communicationHistory, id, '_id');
                dispatch(setNewClient({...CLIENTS_DATA.newClient, communicationHistory: communicationHistory}))
            }
        }
    }

    return (
        <div className="search-products">
            <EditableTable
                className="client-communication-history"
                tableName="Client Communication History"
                allowActions={true}
                key={Math.random()}
                actionButtons={{
                    edit: false,
                    print: false,
                    export: false,
                    delete: true,
                }}
                allowFilter={true}
                allowPagination={false}
                allowBulkActions={true}
                allowPrintResults={false}
                columns={tableColumns}
                allowInsertRowFromTop={true}
                allowFilterTable={false}
                data={chData}
                onUpdate={tableActions}
                enableNewRowCheckbox={true}
            />
        </div>
    )
}

export default NewClientCommunicationHistory;
