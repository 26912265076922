import React, {useEffect, useState} from "react";
import {Badge, Col, Row} from "react-bootstrap";
import EditableTable from "../../../primer/Editable-Table";
import Input from "../../../common/Input";
import {setSaveSalesSettings} from "../../../../_reducers/activitySlice";
import {useDispatch, useSelector} from "react-redux";
import {
    setNewCarNumbers,
    setNewPaymentTypes,
    setNewSendingGoods,
    setSalesGeneralSettings,
    setSalesPaymentTypes,
} from "../../../../_reducers/DataPanel/Sales/salesSettingsSlice";
import {
    fetchAdvancedTypes,
    fetchBankAccounts,
    fetchPOS,
    fetchSalesGeneralSettings,
} from "../../../../_apis/api";
import {
    deleteObjectFromArrayOfObjects,
    getLabelByValue,
    getObjectFromArrayOfObjects,
} from "../../../../_helpers/helperFunctions";
import {
    getCurrentUser,
    getDefaultOptionByValue,
    getOptionsByData,
    salesPaymentReasonWildcardsList,
} from "../../../../_helpers/commonFunctions";
import {toast} from "react-toastify";
import Dropdown from "../../../primer/Dropdown";
import Checkbox from "../../../common/CheckBox";
import {salesPaymentTypeData} from "../../../../_data/sales/sales-payment-types";
import {preNumberGreek} from "../../../../_data/settings/advanced-types-search";
import {useTranslation} from "react-i18next";
import TextArea from "../../../common/TextArea";
import LogTable from "../../../primer/LogTable";
import BetterCheckBox from "../../../common/BetterCheckBox";

const SalesGeneralSettings = () => {
    const [t] = useTranslation('common');
    const salesTypeColumns = [
        {
            name: "No",
            field: "no",
            editable: false,
        },
        {
            name: t('SalesGeneralSettings.name'),
            field: "name",
            editable: true,
            inputType: {
                type: "text",
            },
        },
    ];

    const carNumbersColumns = [
        {
            name: "No",
            field: "no",
            editable: false,
        },
        {
            name: t('SalesGeneralSettings.number'),
            field: "number",
            editable: true,
            inputType: {
                type: "text",
            },
        },
    ];

    const paymentTypesColumns = [
        {
            name: "No",
            field: "no",
            editable: false,
        },
        {
            name: t('SalesGeneralSettings.name'),
            field: "name",
            editable: true,
            inputType: {
                type: "text",
            },
        },
        {
            name: t('SalesGeneralSettings.type'),
            field: "type",
            editable: true,
            width: "200px",
            inputType: {
                type: "dropdown",
                options: getOptionsByData(salesPaymentTypeData, "value", "label"),
            },
        },
    ];

    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const dispatch = useDispatch();

    const [cptOptions, setSelectedPaymentType] = useState({});
    const [showPaymentTypeOptions, setShowPaymentTypeOptions] = useState(false);
    const [showWildcardsList, setShowWildcardsList] = useState(false);

    const currentUser = getCurrentUser();

    const requestData = {
        company: company.id,
        year: company.year.toString(),
        isActive: "active",
        userId: currentUser.id,
    }

    useEffect(() => {
        dispatch(fetchSalesGeneralSettings(requestData));
        dispatch(fetchPOS(requestData));
        dispatch(fetchBankAccounts(requestData));
        dispatch(fetchAdvancedTypes(requestData));
    }, [dispatch]);

    const handleOnChange = (e, type = "default", ddname = "") => {
        if (!ACTIVITY.saveSalesSettings) dispatch(setSaveSalesSettings(true));

        let name;
        let value;
        if (type === "dd") {
            name = ddname;
            value = e.value;
        } else if (type === "cb") {
            name = e.target.name;
            value = e.target.checked ? "active" : "inactive";
        } else {
            name = e.target.name;
            value = e.target.value;
        }
        let newSettings = Object.assign({}, SALES_SETTINGS.salesGeneralSettings);
        let newSettingsData = {};
        newSettingsData[name] = value;
        newSettings = {...newSettings, ...newSettingsData};
        dispatch(setSalesGeneralSettings(newSettings));
    }

    const handlePaymentOptionsChange = (e, type = "default", ddname = "") => {
        if (!ACTIVITY.saveSalesSettings) dispatch(setSaveSalesSettings(true));
        let name;
        let value;
        if (type === "dd") {
            name = ddname;
            value = e.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }
        let newSettings = Object.assign({}, SALES_SETTINGS.salesGeneralSettings);
        let newPaymentOptions = {};
        newPaymentOptions[name] = value;
        if (name === "documentType") {
            newPaymentOptions["documentTypeName"] = e.label;
            newPaymentOptions.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
        }
        if (name === "preSelectedPosBankAccount") {
            newPaymentOptions["preSelectedPosBankAccountName"] = e.label;
        }
        let newPaymentTypes = [];
        let oldPaymentTypes = SALES_SETTINGS.salesGeneralSettings.paymentTypes.slice();
        if (oldPaymentTypes && oldPaymentTypes.length > 0) {
            let cptOptionsName = cptOptions.name;
            const ptIndex = oldPaymentTypes.findIndex(
                (obj) => obj.name === cptOptionsName
            );
            if (ptIndex >= 0) {
                let paymentTypeObject = oldPaymentTypes[ptIndex];
                let paymentTypeOldOptions = oldPaymentTypes[ptIndex].options;
                let tempUpdateObj = {
                    ...paymentTypeObject,
                    options: {...paymentTypeOldOptions, ...newPaymentOptions},
                };
                oldPaymentTypes[ptIndex] = tempUpdateObj;
                setSelectedPaymentType(tempUpdateObj); // To current state
            }
            newPaymentTypes = oldPaymentTypes;
        }
        dispatch(setSalesGeneralSettings({...newSettings, paymentTypes: newPaymentTypes}));
    };

    const sendingGoodsActions = {
        insert: (e, data) => {
            // Set if editing the form
            if (!ACTIVITY.saveSalesSettings) {
                dispatch(setSaveSalesSettings(true));
            }

            let tempSendingGoods = Object.assign({}, SALES_SETTINGS.newSendingGoods);
            if (e.key !== "Enter") {
                tempSendingGoods[data.field] = data.value;
                dispatch(setNewSendingGoods(tempSendingGoods)); // Update redux

                if (tempSendingGoods && tempSendingGoods.name !== "") {
                    let updatedSendingGood = Object.assign({}, tempSendingGoods);
                    let oldSendingGood = SALES_SETTINGS.salesGeneralSettings.sendingGoods;
                    updatedSendingGood.no = oldSendingGood.length + 1;
                    updatedSendingGood.id = oldSendingGood.length + 1;
                    dispatch(
                        setSalesGeneralSettings({
                            ...SALES_SETTINGS.salesGeneralSettings,
                            sendingGoods: [...oldSendingGood, updatedSendingGood],
                        })
                    );
                    dispatch(
                        setNewSendingGoods(SALES_SETTINGS.newSendingGoodsInitialValue)
                    ); // Reset new category mydata
                }
            } else {
                if (data.field === "name" && data.value === "") {
                    toast.error("Name should not be empty.");
                } else {
                    let updatedSendingGood = Object.assign(
                        {},
                        SALES_SETTINGS.newSendingGoods
                    );
                    let addCheck = true;

                    if (data.field === "name") {
                        updatedSendingGood.name = data.value;
                    }

                    let oldSendingGood = SALES_SETTINGS.salesGeneralSettings.sendingGoods;
                    if ((data.field === "add" || e.key === "Enter") && addCheck) {
                        updatedSendingGood.no = oldSendingGood.length + 1;
                        updatedSendingGood.id = oldSendingGood.length + 1;
                        dispatch(
                            setSalesGeneralSettings({
                                ...SALES_SETTINGS.salesGeneralSettings,
                                sendingGoods: [...oldSendingGood, updatedSendingGood],
                            })
                        );
                        dispatch(
                            setNewSendingGoods(SALES_SETTINGS.newSendingGoodsInitialValue)
                        ); // Reset new category mydata
                    }
                }
            }
        },
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveSalesSettings) {
                dispatch(setSaveSalesSettings(true));
            }

            if (data.name && data.name.length === 0) {
                toast.error("Name is mandatory field.");
            } else {
                setSaveSalesSettings(true);
                let sendingGoods =
                    SALES_SETTINGS.salesGeneralSettings.sendingGoods.slice();
                if (sendingGoods.find((item) => item.id === id) !== undefined) {
                    sendingGoods[id - 1] = {...sendingGoods[id - 1], ...data};
                    dispatch(
                        setSalesGeneralSettings({
                            ...SALES_SETTINGS.salesGeneralSettings,
                            sendingGoods: sendingGoods,
                        })
                    ); // update data
                }
            }
        },
        selected: () => {},
        delete: (id) => {
            // Set if editing the form
            if (!ACTIVITY.saveSalesSettings) {
                dispatch(setSaveSalesSettings(true));
            }

            let sendingGoods =
                SALES_SETTINGS.salesGeneralSettings.sendingGoods.slice();

            if (id !== "" && Number.isInteger(id)) {
                let deletedSendingGoods = deleteObjectFromArrayOfObjects(
                    sendingGoods,
                    id,
                    "id"
                );
                dispatch(
                    setSalesGeneralSettings({
                        ...SALES_SETTINGS.salesGeneralSettings,
                        sendingGoods: deletedSendingGoods,
                    })
                );
            }

            if (id !== "" && !Number.isInteger(id)) {
                let deletedSendingGoods = deleteObjectFromArrayOfObjects(
                    sendingGoods,
                    id,
                    "_id"
                );
                dispatch(
                    setSalesGeneralSettings({
                        ...SALES_SETTINGS.salesGeneralSettings,
                        sendingGoods: deletedSendingGoods,
                    })
                );
            }
        },
    };

    const carNumbersActions = {
        insert: (e, data) => {
            // Set if editing the form
            if (!ACTIVITY.saveSalesSettings) dispatch(setSaveSalesSettings(true));

            let tempCarNumbers = Object.assign({}, SALES_SETTINGS.newCarNumbers);
            if (e.key !== "Enter") {
                tempCarNumbers[data.field] = data.value;
                dispatch(setNewCarNumbers(tempCarNumbers)); // Update redux

                if (tempCarNumbers && tempCarNumbers.number !== "") {
                    let UpdatedCarNumbers = Object.assign({}, tempCarNumbers);
                    let oldCarNumbers = SALES_SETTINGS.salesGeneralSettings.carNumbers;
                    UpdatedCarNumbers.no = oldCarNumbers.length + 1;
                    UpdatedCarNumbers.id = oldCarNumbers.length + 1;
                    dispatch(
                        setSalesGeneralSettings({
                            ...SALES_SETTINGS.salesGeneralSettings,
                            carNumbers: [...oldCarNumbers, UpdatedCarNumbers],
                        })
                    );
                    dispatch(setNewCarNumbers(SALES_SETTINGS.newCarNumbersInitialValue)); // Reset new category mydata
                }
            } else {
                if (data.field === "number" && data.value === "") {
                    toast.error("Number should not be empty.");
                } else {
                    let UpdatedCarNumbers = Object.assign(
                        {},
                        SALES_SETTINGS.newCarNumbers
                    );
                    let addCheck = true;

                    if (data.field === "number") {
                        UpdatedCarNumbers.number = data.value;
                    }

                    let oldCarNumbers = SALES_SETTINGS.salesGeneralSettings.carNumbers;
                    if ((data.field === "add" || e.key === "Enter") && addCheck) {
                        UpdatedCarNumbers.no = oldCarNumbers.length + 1;
                        UpdatedCarNumbers.id = oldCarNumbers.length + 1;
                        dispatch(
                            setSalesGeneralSettings({
                                ...SALES_SETTINGS.salesGeneralSettings,
                                carNumbers: [...oldCarNumbers, UpdatedCarNumbers],
                            })
                        );
                        dispatch(
                            setNewCarNumbers(SALES_SETTINGS.newCarNumbersInitialValue)
                        ); // Reset new category mydata
                    }
                }
            }
        },
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveSalesSettings) {
                dispatch(setSaveSalesSettings(true));
            }

            if (data.number && data.number.length === 0) {
                toast.error("Number is mandatory field.");
            } else {
                setSaveSalesSettings(true);
                let carNumbers = SALES_SETTINGS.salesGeneralSettings.carNumbers.slice();
                if (carNumbers.find((item) => item.id === id) !== undefined) {
                    carNumbers[id - 1] = {...carNumbers[id - 1], ...data};
                    dispatch(
                        setSalesGeneralSettings({
                            ...SALES_SETTINGS.salesGeneralSettings,
                            carNumbers: carNumbers,
                        })
                    ); // update data
                }
            }
        },
        selected: () => {},
        delete: (id) => {
            // Set if editing the form
            if (!ACTIVITY.saveSalesSettings) {
                dispatch(setSaveSalesSettings(true));
            }

            let carNumbers = SALES_SETTINGS.salesGeneralSettings.carNumbers.slice();

            if (id !== "" && Number.isInteger(id)) {
                let deletedCarNumbers = deleteObjectFromArrayOfObjects(
                    carNumbers,
                    id,
                    "id"
                );
                dispatch(
                    setSalesGeneralSettings({
                        ...SALES_SETTINGS.salesGeneralSettings,
                        carNumbers: deletedCarNumbers,
                    })
                );
            }

            if (id !== "" && !Number.isInteger(id)) {
                let deletedCarNumbers = deleteObjectFromArrayOfObjects(
                    carNumbers,
                    id,
                    "_id"
                );
                dispatch(
                    setSalesGeneralSettings({
                        ...SALES_SETTINGS.salesGeneralSettings,
                        carNumbers: deletedCarNumbers,
                    })
                );
            }
        },
    };

    const paymentTypesActions = {
        insert: (e, data) => {
            // Set if editing the form
            if (!ACTIVITY.saveSalesSettings) {
                dispatch(setSaveSalesSettings(true));
            }

            let tempPaymentTypes = Object.assign({}, SALES_SETTINGS.newPaymentTypes);
            if (e.key !== "Enter") {
                tempPaymentTypes[data.field] = data.value;
                if (data.field === "type") {
                    tempPaymentTypes["myDataPaymentId"] = getDefaultOptionByValue(salesPaymentTypeData, data.value, 'value', 'myDataId', 'value').label;
                }
                dispatch(setNewPaymentTypes(tempPaymentTypes)); // Update redux
                if (
                    tempPaymentTypes &&
                    tempPaymentTypes.name !== "" &&
                    tempPaymentTypes.type !== ""
                ) {
                    let UpdatedPaymentTypes = Object.assign({}, tempPaymentTypes);
                    let oldPaymentTypess =
                        SALES_SETTINGS.salesGeneralSettings.paymentTypes;
                    UpdatedPaymentTypes.no = oldPaymentTypess.length + 1;
                    UpdatedPaymentTypes.id = oldPaymentTypess.length + 1;
                    dispatch(
                        setSalesGeneralSettings({
                            ...SALES_SETTINGS.salesGeneralSettings,
                            paymentTypes: [...oldPaymentTypess, UpdatedPaymentTypes],
                        })
                    );
                    dispatch(
                        setNewPaymentTypes(SALES_SETTINGS.newPaymentTypesInitialValue)
                    ); // Reset new category mydata
                }
            } else {
                if (data.field === "name" && data.value === "") {
                    toast.error("Name should not be empty.");
                } else {
                    let UpdatedPaymentTypes = Object.assign(
                        {},
                        SALES_SETTINGS.newPaymentTypes
                    );
                    let addCheck = true;

                    if (data.field === "name") {
                        UpdatedPaymentTypes.name = data.value;
                    }

                    let oldPaymentTypess =
                        SALES_SETTINGS.salesGeneralSettings.paymentTypes;
                    if ((data.field === "add" || e.key === "Enter") && addCheck) {
                        UpdatedPaymentTypes.no = oldPaymentTypess.length + 1;
                        UpdatedPaymentTypes.id = oldPaymentTypess.length + 1;
                        dispatch(
                            setSalesGeneralSettings({
                                ...SALES_SETTINGS.salesGeneralSettings,
                                paymentTypes: [...oldPaymentTypess, UpdatedPaymentTypes],
                            })
                        );
                        dispatch(
                            setNewPaymentTypes(SALES_SETTINGS.newPaymentTypesInitialValue)
                        ); // Reset new category mydata
                    }
                }
            }
        },
        update: (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveSalesSettings) dispatch(setSaveSalesSettings(true));

            if (data.name && data.name.length === 0) {
                toast.error("Name is mandatory field.");
            } else {
                dispatch(setSaveSalesSettings(true));
                let paymentTypes = SALES_SETTINGS.salesGeneralSettings.paymentTypes.slice();
                if (paymentTypes.find((item) => item.id === id) !== undefined) {
                    if (data.type) {
                        data["myDataPaymentId"] = getDefaultOptionByValue(salesPaymentTypeData, data.type, 'value', 'myDataId', 'value').label;
                    }
                    const updatedPmProducts = {...paymentTypes[id - 1], ...data};
                    paymentTypes[id - 1] = updatedPmProducts;
                    setSelectedPaymentType(updatedPmProducts); // Update selected payment type
                    dispatch(
                        setSalesGeneralSettings({
                            ...SALES_SETTINGS.salesGeneralSettings,
                            paymentTypes: paymentTypes,
                        })
                    ); // update data
                }
            }
        },
        selected: (data) => {
            if (typeof data._id !== "undefined") {
                let paymentTypesData =
                    SALES_SETTINGS.salesGeneralSettings.paymentTypes.slice();
                const selectedPaymentTypeData = getObjectFromArrayOfObjects(
                    paymentTypesData,
                    data._id,
                    "_id"
                );
                if (selectedPaymentTypeData && selectedPaymentTypeData.length > 0) {
                    let selectedPaymentType = selectedPaymentTypeData["0"];
                    setSelectedPaymentType(selectedPaymentType); // Set data to state
                    setShowPaymentTypeOptions(true);
                }
            }
            // }
        },
        delete: (id) => {
            // Set if editing the form
            if (!ACTIVITY.saveSalesSettings) {
                dispatch(setSaveSalesSettings(true));
            }

            let paymentTypes =
                SALES_SETTINGS.salesGeneralSettings.paymentTypes.slice();

            if (id !== "" && Number.isInteger(id)) {
                let deletedPaymentTypes = deleteObjectFromArrayOfObjects(
                    paymentTypes,
                    id,
                    "id"
                );
                dispatch(
                    setSalesGeneralSettings({
                        ...SALES_SETTINGS.salesGeneralSettings,
                        paymentTypes: deletedPaymentTypes,
                    })
                );
            }

            if (id !== "" && !Number.isInteger(id)) {
                let deletedPaymentTypes = deleteObjectFromArrayOfObjects(
                    paymentTypes,
                    id,
                    "_id"
                );
                dispatch(
                    setSalesGeneralSettings({
                        ...SALES_SETTINGS.salesGeneralSettings,
                        paymentTypes: deletedPaymentTypes,
                    })
                );
            }
        },
    };

    let advancedTypes = SETTINGS.settings.advancedTypes;
    const paymentDocumentTypes = getObjectFromArrayOfObjects(
        advancedTypes,
        "Client Payments",
        "section"
    );

    let posData = CASH_DATA.posList;
    let bankData = CASH_DATA.bankAccounts;

    let posOptions = getOptionsByData(posData, "_id", "name");
    posOptions = [{value: "", label: t("Null")}, ...posOptions];

    let bankOptions = getOptionsByData(bankData, "_id", "name");
    bankOptions = [{value: "", label: t("Null")}, ...bankOptions];

    let posbankAccountOptions = [];
    if (showPaymentTypeOptions) {
        if (cptOptions.type === "pos") {
            posbankAccountOptions = posOptions;
        }
        if (cptOptions.type === "bank-account") {
            posbankAccountOptions = bankOptions;
        }
    }

    let documentTypesOptions = getOptionsByData(
        paymentDocumentTypes,
        "_id",
        "name"
    );
    documentTypesOptions = [
        {value: "", label: t("Null")},
        ...documentTypesOptions,
    ];
    let wildcardsList = salesPaymentReasonWildcardsList();
    const handleAddWildcard = (e, item, _id) => {
        // Set if editing the form
        if (!ACTIVITY.saveSalesSettings) {
            dispatch(setSaveSalesSettings(true));
        }

        // Set settings data
        let generalSettings = Object.assign(
            {},
            SALES_SETTINGS.salesGeneralSettings
        );
        let paymentTypes = generalSettings.paymentTypes.slice();

        const pyIndex = paymentTypes.findIndex((item) => _id === item._id);
        let cptOptions = Object.assign({}, paymentTypes[pyIndex].options);
        cptOptions.paymentReason = `${cptOptions?.paymentReason} ${item}`;
        paymentTypes[pyIndex] = {
            ...paymentTypes[pyIndex],
            ...{options: cptOptions},
        }

        setSelectedPaymentType(paymentTypes[pyIndex]);
        dispatch(setSalesPaymentTypes(paymentTypes));
    }

    return (
        <div className="client-settings">
            <div className="row">
                <div className="col-12">
                    <p className="text-muted">
                        <i>{t('SalesGeneralSettings.salesDocument')}</i>
                    </p>
                </div>
                <div className="col-lg-6 col-12">
                    <p>{t('SalesGeneralSettings.waysOfSendingGoods')}</p>
                    <EditableTable
                        className="mb-3"
                        tableName="Sales Ways Of Sending Goods" // Do not change name
                        data={SALES_SETTINGS.salesGeneralSettings.sendingGoods}
                        columns={salesTypeColumns}
                        onUpdate={sendingGoodsActions}
                        key={Math.random()}
                        allowActions={true}
                        allowInsertRow={true}
                    />
                </div>
                <div className="col-lg-6  col-12">
                    <p>{t('SalesGeneralSettings.carNumbers')}</p>
                    <EditableTable
                        className="mb-3"
                        tableName="Sales Car Numbers" // Do not change name
                        data={SALES_SETTINGS.salesGeneralSettings.carNumbers}
                        columns={carNumbersColumns}
                        onUpdate={carNumbersActions}
                        key={Math.random()}
                        allowActions={true}
                        allowInsertRow={true}
                    />
                </div>
                <div className="h-100 mb-3 d-flex align-items-center justify-content-start">
                    <Checkbox
                        className="m-0"
                        text={t('SalesGeneralSettings.forbidChangeInPricesInSalesProducts')}
                        name="forbitChange"
                        key={Math.random()}
                        defaultValue={SALES_SETTINGS.salesGeneralSettings.forbitChange}
                        value={
                            SALES_SETTINGS.salesGeneralSettings.forbitChange === "active"
                                ? "inactive"
                                : "active"
                        }
                        onChange={(e) => handleOnChange(e)}
                    />
                </div>
                <BetterCheckBox
                    className={"m-0"}
                    text={"Να μην επιτρέπετε πώληση ειδών με μηδενικό απόθεμα"}
                    name={"forbid0Stock"}
                    checked={SALES_SETTINGS.salesGeneralSettings?.forbid0Stock === "active"}
                    onChange={(e) => handleOnChange(e, "cb")}
                />
                <Row>
                    <Col md={12}>
                        <div className={"text-muted mb-2"}><i>Προκαθορισμένες σημειώσεις παραστατικού</i></div>
                        <TextArea
                            name={"proposedSaleNotes"}
                            rows={"4"}
                            placeholder={""}
                            defaultValue={SALES_SETTINGS.salesGeneralSettings?.proposedSaleNotes ? SALES_SETTINGS.salesGeneralSettings?.proposedSaleNotes : ""}
                            onBlur={(e) => handleOnChange(e)}
                        />
                    </Col>
                </Row>
            </div>
            <hr/>
            <div className="row">
                <div className="col-12">
                    <p className="text-muted">
                        <i>{t('SalesGeneralSettings.paymentTypes')}</i>
                    </p>
                </div>
                <div className="col-lg-6 col-12">
                    <EditableTable
                        tableName="Sales Settings Payment Types" // Do not change name
                        selectedRow={cptOptions._id}
                        data={SALES_SETTINGS.salesGeneralSettings.paymentTypes}
                        columns={paymentTypesColumns}
                        onUpdate={paymentTypesActions}
                        key={Math.random()}
                        allowInsertRow={true}
                    />
                </div>
            </div>

            {showPaymentTypeOptions && (
                <div className="p-3 border mb-3 bg-light">
                    <div className="row">
                        <div className="col-12">
                            <p className="text-muted">
                                <i>{t('SalesGeneralSettings.paymentOptions')}</i>
                            </p>
                        </div>
                        <div className="col-12">
                            <p>
                                <i>{t('SalesGeneralSettings.paymentType')} {cptOptions.name}</i>
                            </p>
                        </div>
                        {(cptOptions.type === "pos" ||
                            cptOptions.type === "bank-account") && (
                            <div className="col-12 col-md-4">
                                <div className="d-flex align-items-center justify-content-center">
                                    <Dropdown
                                        label={t('SalesGeneralSettings.posBankAccount')}
                                        key={Math.random()}
                                        defaultValue={getDefaultOptionByValue(
                                            posbankAccountOptions,
                                            cptOptions.options?.preSelectedPosBankAccount,
                                            "value",
                                            "label",
                                            "value"
                                        )}
                                        name="preSelectedPosBankAccount"
                                        options={posbankAccountOptions}
                                        onChange={(e) =>
                                            handlePaymentOptionsChange(
                                                e,
                                                "dd",
                                                "preSelectedPosBankAccount"
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-12  col-md-4">
                            <div className="h-100 d-flex align-items-end justify-content-start">
                                <Checkbox
                                    className="mb-3"
                                    text={t('SalesGeneralSettings.createAutomaticPaymentInSales')}
                                    name="autopayment"
                                    key={Math.random()}
                                    defaultValue={cptOptions.options?.autopayment}
                                    value={
                                        cptOptions.options?.autopayment === "active"
                                            ? "inactive"
                                            : "active"
                                    }
                                    onChange={(e) => handlePaymentOptionsChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="mb-3">
                                <Dropdown
                                    label={t('SalesGeneralSettings.paymentDocumentType')}
                                    key={Math.random()}
                                    defaultValue={getDefaultOptionByValue(
                                        documentTypesOptions,
                                        cptOptions.options?.documentType,
                                        "value",
                                        "label",
                                        "value"
                                    )}
                                    // required= {true}
                                    name="documentType"
                                    options={documentTypesOptions}
                                    onChange={(e) =>
                                        handlePaymentOptionsChange(e, "dd", "documentType")
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="mb-3">
                                <Dropdown
                                    label={t('SalesGeneralSettings.preNumber')}
                                    name="preNumber"
                                    key={Math.random()}
                                    defaultValue={getDefaultOptionByValue(preNumberGreek, cptOptions.options?.preNumber, 'value', 'label', 'value')}
                                    options={preNumberGreek}
                                    onChange={(e) => handlePaymentOptionsChange(e, "dd", "preNumber")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="mb-3">
                                <Input
                                    label={t('SalesGeneralSettings.paymentReason.paymentReason')}
                                    name="paymentReason"
                                    charLimit={150}
                                    placeholder={t('SalesGeneralSettings.paymentReason.placeholder')}
                                    value={cptOptions.options?.paymentReason}
                                    onChange={(e) => handlePaymentOptionsChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex align-items-end">
                            <button
                                type="button"
                                className="btn btn-primary btn-sm mb-3"
                                onClick={() => setShowWildcardsList(!showWildcardsList)}
                            >
                                {t('SalesGeneralSettings.addWildcard')}
                            </button>
                        </div>
                    </div>
                    {/* Wildcards List */}
                    {showWildcardsList && (
                        <React.Fragment>
                            <div style={{borderRadius: "8px", border: "1px solid lightgray", padding: "10px"}}>
                                {t('SalesGeneralSettings.wildCards')}{" "}{t('SalesGeneralSettings.clickOnWildcardToAddInThePaymentReason')}<br/>
                                {wildcardsList.map((item, idx) => (
                                    <Badge bg={"primary"} style={{cursor: "pointer"}} key={`wildcard${idx}`}
                                           onClick={(e) => handleAddWildcard(e, item, cptOptions._id)}
                                           className={"mr-1 mt-1"}>{item}</Badge>
                                ))}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
        </div>
    )
}

export const SalesGeneralSettingsLog = () => {
    const {t} = useTranslation('common');

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.salesGeneralSettingsLog')}</i></div>
            <LogTable model="salesSetting"/>
        </React.Fragment>
    )
};

export default SalesGeneralSettings;
