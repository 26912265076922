import React, { useState } from "react";
import {useSelector} from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import {checkPermission} from "../../../../_helpers/commonFunctions";
import {useTranslation} from "react-i18next";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Input from "../../../common/Input";

export const SettingsOfRestore = () => {
    const [t] = useTranslation("common");
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [formData, setFormData] = useState({});
    const [consoleMSG, setConsoleMSG] = useState([]);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    const [showModal1Backup, setShowModal1Backup] = useState(false);
    const [showModal2Backup, setShowModal2Backup] = useState(false);
    const [showModal1CDT, setShowModal1CDT] = useState(false);
    const [showModal2CDT, setShowModal2CDT] = useState(false);
    const [showModal1Accounting, setShowModal1Accounting] = useState(false);
    const [showModal2Accounting, setShowModal2Accounting] = useState(false);

    const handleOnChange = (e, type = "default") => {
        if(type === "file") {
            const selected = e.target.files[0];
            setFormData({ ...formData, file: selected });
        }
    }
    const preCheck = (typeName) => {
        let error = false;
        if (!formData.file) {
            error = true;
            toast.error(t("SettingsRestore.pleaseSelectFile"));
            setConsoleMSG([t("SettingsRestore.pleaseSelectFile")]);
            setBtnDisabled(false);
        } else if (formData.file) {
            const allowedExtensions = ["json"];
            const fileExtension = formData.file.name.split(".").pop().toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                error = true;
                toast.error(t("SettingsRestore.onlyJSONFiles"));
                setConsoleMSG([t("SettingsRestore.onlyJSONFiles")]);
                setBtnDisabled(false);
            }
        }
        if(!error) {
            if (typeName === "backup") {
                setShowModal1Backup(true);
            } else if (typeName === "CDT") {
                setShowModal1CDT(true);
            } else if (typeName === "accounting") {
                setShowModal1Accounting(true);
            }
        }
    }
    const restoreBackupClicked = () => {
        setShowModal2Backup(false);
        setConsoleMSG([t("SettingsRestore.restoring")]);
        setBtnDisabled(true);
        let error = false;
        if (!formData.file) {
            error = true;
            toast.error(t("SettingsRestore.pleaseSelectFile"));
            setConsoleMSG([t("SettingsRestore.pleaseSelectFile")]);
            setBtnDisabled(false);
        } else if (formData.file) {
            const allowedExtensions = ["json"];
            const fileExtension = formData.file.name.split(".").pop().toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                error = true;
                toast.error(t("SettingsRestore.onlyJSONFiles"));
                setConsoleMSG([t("SettingsRestore.onlyJSONFiles")]);
                setBtnDisabled(false);
            }
        }
        if (!error) {
            let data = new FormData();
            data.append("file", formData.file);
            data.append("company", company.id);
            data.append("year", company.year);
            axios.post(process.env.REACT_APP_API_URL2 + "/restore/restore", data, {headers: {"Content-Type": "application/form-data"}})
                .then((response) => {
                    if (response.data.status === "200") {
                        toast.success(response.data.message);
                        console.log(response.data);
                        setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
                        setConsoleMSG(consoleMSG => [...consoleMSG, t("SettingsRestore.restorationComplete")]);
                        setBtnDisabled(false);
                    } else {
                        toast.error(response.data.message);
                        console.log(response.data);
                        setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
                        setConsoleMSG(consoleMSG => [...consoleMSG, t("SettingsRestore.restorationFailed")]);
                        setBtnDisabled(false);
                    }
                })
                .catch((error) => {
                    toast.error(error);
                    console.error(error);
                });
        }
    }
    const restoreCompanyTemplateClicked = () => {
        setShowModal2CDT(false);
        setConsoleMSG(["Restoring company template."]);
        setBtnDisabled(true);
        let error = false;
        if (!formData.file) {
            error = true;
            toast.error(t("SettingsRestore.pleaseSelectFile"));
            setConsoleMSG([t("SettingsRestore.pleaseSelectFile")]);
            setBtnDisabled(false);
        } else if (formData.file) {
            const allowedExtensions = ["json"];
            const fileExtension = formData.file.name.split(".").pop().toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                error = true;
                toast.error(t("SettingsRestore.onlyJSONFiles"));
                setConsoleMSG([t("SettingsRestore.onlyJSONFiles")]);
                setBtnDisabled(false);
            }
        }
        if (!error) {
            let reader = new FileReader();
            reader.readAsText(formData.file, "UTF-8");
            reader.onload = (evt) => {
                try {
                    JSON.parse(String(evt.target.result));
                    axios.post(process.env.REACT_APP_API_URL2 + "/company/uploadCompanyTemplate", {
                            companyId: company.id,
                            template: JSON.stringify(JSON.parse(String(evt.target.result)))
                        },
                        {
                            headers: {"Content-Type": "application/json"}
                        }).then((response) => {
                        if (response.data.status === "200") {
                            toast.success(response.data.message);
                            setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
                            setBtnDisabled(false);
                        } else {
                            toast.error(response.data.message);
                            setBtnDisabled(false);
                        }
                    }).catch((error) => {
                        toast.error(error);
                        console.error(error);
                        setBtnDisabled(false);
                    });
                } catch (e) {
                    toast.error("Could not parse JSON file.");
                    setBtnDisabled(false);
                }
            }
        }
    }
    const restoreCompanyAccountingClicked = () => {
        setShowModal2Accounting(false);
        setConsoleMSG(["Restoring company accounting template..."]);
        setBtnDisabled(true);
        let error = false;
        if (!formData.file) {
            error = true;
            toast.error(t("SettingsRestore.pleaseSelectFile"));
            setConsoleMSG([t("SettingsRestore.pleaseSelectFile")]);
            setBtnDisabled(false);
        } else if (formData.file) {
            const allowedExtensions = ["json"];
            const fileExtension = formData.file.name.split(".").pop().toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                error = true;
                toast.error(t("SettingsRestore.onlyJSONFiles"));
                setConsoleMSG([t("SettingsRestore.onlyJSONFiles")]);
                setBtnDisabled(false);
            }
        }
        if (!error) {
            let reader = new FileReader();
            reader.readAsText(formData.file, "UTF-8");
            reader.onload = (evt) => {
                try {
                    JSON.parse(String(evt.target.result));
                    axios.post(process.env.REACT_APP_API_URL2 + "/company/uploadCompanyTemplateAccounting", {
                            companyId: company.id,
                            template: JSON.stringify(JSON.parse(String(evt.target.result)))
                        },
                        {
                            headers: {"Content-Type": "application/json"}
                        }).then((response) => {
                        if (response.data.status === "200") {
                            toast.success(response.data.message);
                            setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
                            setBtnDisabled(false);
                        } else {
                            toast.error(response.data.message);
                            setBtnDisabled(false);
                        }
                    }).catch((error) => {
                        toast.error(error);
                        console.error(error);
                        setBtnDisabled(false);
                    });
                } catch (e) {
                    toast.error("Could not parse JSON file.");
                    setBtnDisabled(false);
                }
            }
        }
    }

    const applyTemplateBuys = () => {
        setConsoleMSG(["Magic happening...."]);
        setBtnDisabled(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/company/apply-template-to-company", {company: company.id, templateType: "buys"}, {
            headers: { "Content-Type": "application/json" },
        }).then((response) => {
            setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
            setBtnDisabled(false);
        }).catch((error) => {
            toast.error(error);
            console.log(error);
            setBtnDisabled(false);
        })
    }

    const [compInput, setCompInput] = useState("");

    const alexImport = () => {
        setConsoleMSG(["Magic happening...."]);
        setBtnDisabled(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/settings/alex-import", {compInput: compInput}, {
            headers: { "Content-Type": "application/json" },
        }).then((response) => {
            setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
            setBtnDisabled(false);
        }).catch((error) => {
            toast.error(error);
            console.log(error);
            setBtnDisabled(false);
        })
    }

    const alexImportSyncBridge = () => {
        setConsoleMSG(["Magic happening...."]);
        setBtnDisabled(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/settings/alex-import-sync-bridge", {compInput: compInput}, {
            headers: { "Content-Type": "application/json" },
        }).then((response) => {
            setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
            setBtnDisabled(false);
        }).catch((error) => {
            toast.error(error);
            console.log(error);
            setBtnDisabled(false);
        })
    }

    const posSync = () => {
        const userId = JSON.parse(localStorage.getItem("user"))?._id;
        setConsoleMSG(["Magic happening...."]);
        setBtnDisabled(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/cash/master-sync-pos", {userId: userId}, {
            headers: { "Content-Type": "application/json" },
        }).then((response) => {
            setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
            setBtnDisabled(false);
        }).catch((error) => {
            toast.error(error);
            console.log(error);
            setBtnDisabled(false);
        })
    }

    const emronSyncOrders = () => {
        const userId = JSON.parse(localStorage.getItem("user"))?._id;
        setConsoleMSG(["Magic happening...."]);
        setBtnDisabled(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/emron/emron-sync-orders", {userId: userId}, {
            headers: { "Content-Type": "application/json" },
        }).then((response) => {
            setConsoleMSG(consoleMSG => [...consoleMSG].concat(response.data.forConsole));
            setBtnDisabled(false);
        }).catch((error) => {
            toast.error(error);
            console.log(error);
            setBtnDisabled(false);
        })
    }

    const pAdmCache = () => {
        const userId = JSON.parse(localStorage.getItem("user"))?._id;
        setConsoleMSG(["Magic happening...."]);
        setBtnDisabled(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/settings/primer-admin-report-cache-reset", {userId: userId}, {
            headers: { "Content-Type": "application/json" },
        }).then((response) => {
            setBtnDisabled(false);
            if (response.data.status === "200") {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            toast.error(error);
            console.log(error);
            setBtnDisabled(false);
        })
    }

    return (
        <React.Fragment>
            <p className="text-danger">{t("SettingsRestore.warningMessage")}</p>
            { checkPermission("settings-backuprestore/restore-write", permissionsData) &&
                <>
                    <input
                        type="file"
                        className="form-control mb-3"
                        accept="application/JSON"
                        onChange={(e) => handleOnChange(e, "file")}
                    />
                    <p className="text-danger">{t("SettingsRestore.warningMessage2")}</p>
                    <button className="btn btn-primary mb-3 mr-2"
                            onClick={() => preCheck("backup")}
                            disabled={btnDisabled}
                    >{t("SettingsRestore.buttonRestoreBackup")}</button>
                    <button className="btn btn-primary mb-3 mr-2"
                            onClick={() => preCheck("CDT")}
                            disabled={btnDisabled}
                    >{t("SettingsRestore.buttonRestoreCompanyTemplate")}</button>
                    <button className="btn btn-primary mb-3 mr-2"
                            onClick={() => preCheck("accounting")}
                            disabled={btnDisabled}
                    >Επαναφορά Προτύπου Λογιστικής</button>
                </>
            }
            <div className="row">
                <div className="mb-2 col-12">
                    <p>Console</p>
                    <div className="bg-light w-100 border p-3">
                        {consoleMSG.map(e =>
                            <p> { e } </p>
                        )}
                    </div>
                </div>
            </div>
            <hr />
            <Row>
                <Col md={2}>
                    <Button size={"sm"} className="mb-3 mr-2" onClick={() => applyTemplateBuys()} disabled={btnDisabled}>Apply buys template to current company</Button>
                </Col>
                <Col md={2}>
                    <Button size={"sm"} className={"mb-3 mr-2"} onClick={() => posSync()} disabled={btnDisabled}>POS Sync</Button>
                </Col>
                {company.vatNumber === "800434990" && (
                    <Col md={2}>
                        <Button size={"sm"} className={"mb-3 mr-2"} onClick={() => emronSyncOrders()} disabled={btnDisabled}>EMRON Sync orders</Button>
                    </Col>
                )}
                {company.vatNumber === "800566476" && (
                    <Col md={2}>
                        <Button size={"sm"} className={"mb-3 mr-2"} onClick={() => pAdmCache()} disabled={btnDisabled}>Primer admin report reset cache</Button>
                    </Col>
                )}
            </Row>
            <Row>
                <Col md={4}>
                    <Input
                        name={"compInput"}
                        label={"ALEX IMPORT COMPANY"}
                        value={compInput}
                        onChange={(e) => setCompInput(e.target.value)}
                    />
                </Col>
                <Col md={2}>
                    <Button size={"sm"} className={"mb-3 mt-4"} onClick={() => alexImport()} disabled={btnDisabled}>ALEX IMPORT</Button>
                </Col>
                <Col md={2}>
                    <Button size={"sm"} className={"mb-3 mt-4"} onClick={() => alexImportSyncBridge()} disabled={btnDisabled}>ALEX IMPORT SYNC BRIDGE</Button>
                </Col>
            </Row>

            <Modal backdrop={"static"} show={showModal1Backup} onHide={() => setShowModal1Backup(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Επαναφορά</Modal.Title>
                </Modal.Header>
                <Modal.Body>Η επαναφορά από το αντίγραφο ασφαλείας είναι μία επικίνδυνη διαδικασία. Οι πωλήσεις που έχουν γίνει, δεν επαναφέρονται αλλά ούτε διαγράφονται.</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowModal1Backup(false)}>
                        Πίσω
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setShowModal1Backup(false);
                        setShowModal2Backup(true);
                    }}>
                        Εντάξει
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={showModal2Backup} onHide={() => setShowModal2Backup(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Επιβεβαίωση Επαναφοράς</Modal.Title>
                </Modal.Header>
                <Modal.Body>Καταλαβαίνω την βαρύτητα της διαδικασίας και συμφωνώ ό,τι κατά την επαναφορά μπορεί να χαθούν στοιχεία που δεν υπάρχουν στο αντίγραφο ασφαλείας.</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowModal2Backup(false)}>
                        Όχι
                    </Button>
                    <Button variant="primary" style={{backgroundColor: "red", borderColor: "red"}} onClick={() => restoreBackupClicked()}>
                        Να γίνει επαναφορά
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={showModal1CDT} onHide={() => setShowModal1CDT(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Επαναφορά Προτύπου</Modal.Title>
                </Modal.Header>
                <Modal.Body>Η επαναφορά από εταιρικό πρότυπο θα επιστρέψει όλη την εταιρεία στην αρχική κατάσταση που περιέχει το πρότυπο. Αν υπάρχουν πωλήσεις, η διαδικασία είναι αδύνατη.</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowModal1CDT(false)}>
                        Πίσω
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setShowModal1CDT(false);
                        setShowModal2CDT(true);
                    }}>
                        Εντάξει
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal backdrop={"static"} show={showModal2CDT} onHide={() => setShowModal2CDT(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Επιβεβαίωση Επαναφοράς Προτύπου</Modal.Title>
                </Modal.Header>
                <Modal.Body>Καταλαβαίνω την βαρύτητα της διαδικασίας και συμφωνώ ό,τι κατά την επαναφορά από εταιρικό πρότυπο μπορεί να χαθούν στοιχεία που δεν υπάρχουν στο εταιρικό πρότυπο.</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowModal2CDT(false)}>
                        Όχι
                    </Button>
                    <Button variant="primary" style={{backgroundColor: "red", borderColor: "red"}} onClick={() => restoreCompanyTemplateClicked()}>
                        Να γίνει επαναφορά προτύπου
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal backdrop={"static"} show={showModal1Accounting} onHide={() => setShowModal1Accounting(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Επαναφορά Προτύπου Λογιστικής</Modal.Title>
                </Modal.Header>
                <Modal.Body>Η επαναφορά λογιστικής από εταιρικό πρότυπο θα επαναφέρει την λογιστική της εταιρείας.</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowModal1Accounting(false)}>
                        Πίσω
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setShowModal1Accounting(false);
                        setShowModal2Accounting(true);
                    }}>
                        Εντάξει
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal backdrop={"static"} show={showModal2Accounting} onHide={() => setShowModal2Accounting(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Επιβεβαίωση Επαναφοράς Προτύπου Λογιστικής</Modal.Title>
                </Modal.Header>
                <Modal.Body>Καταλαβαίνω την βαρύτητα της διαδικασίας και συμφωνώ ό,τι κατά την επαναφορά από εταιρικό πρότυπο μπορεί να χαθούν στοιχεία που δεν υπάρχουν στο εταιρικό πρότυπο.</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowModal2Accounting(false)}>
                        Όχι
                    </Button>
                    <Button variant="primary" style={{backgroundColor: "red", borderColor: "red"}} onClick={() => restoreCompanyAccountingClicked()}>
                        Να γίνει επαναφορά προτύπου
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}
