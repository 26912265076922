import React, {useEffect, useState} from "react";
import {classicStyleBelowNavbar} from "../Statics";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Row, Modal, Spinner, Badge} from "react-bootstrap";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import {
    setEditClientPayment,
    setEmronSettlementsReportRequestData,
    setEmronSettlementsReportResponseData
} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import DateBox from "../../../primer/DateBox";
import {getGreekCurrencyFormat, isEmpty} from "../../../../_helpers/commonFunctions";
import moment from "moment";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {toast} from "react-toastify";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import BetterCheckBox from "../../../common/BetterCheckBox";
import Input from "../../../common/Input";
import TextArea from "../../../common/TextArea";
import {addTab, updateActiveMainTab} from "../../../../_reducers/TabsSlice";

export const ReportsEmronSettlements = () => {
    const [t] = useTranslation('common');
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const requestData = useSelector((state) => state.CLIENTS_DATA.emronSettlementsReportRequestData);
    const responseData = useSelector((state) => state.CLIENTS_DATA.emronSettlementsReportResponseData);

    const [loading, setLoading] = useState(false);
    const [clientsList, setClientsList] = useState([]);

    const [viewSettlement, setViewSettlement] = useState({});
    const [showSettlementModal, setShowSettlementModal] = useState(false);

    const [pageChanged, setPageChanged] = useState(false);

    useEffect(() => {
        loadClientsListData(null, setClientsList);
    }, [])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchReport(true);
        }
    }, [pageChanged])

    const clientDropdownColumns = [
        {
            label: t('SalesBalanceSheet.table3.clientName'),
            name: "name",
        },
        {
            label: t('SalesBalanceSheet.table3.vatNumber'),
            name: "vatNumber",
        },
        {
            label: t('SalesBalanceSheet.table3.phoneNumber'),
            name: "phone",
        },
    ]

    const loadClientsListData = (keyword, setData) => {
        let reqData = {
            company: company.id,
        }
        if (!isEmpty(keyword)) reqData.name = keyword;
        axios.post(process.env.REACT_APP_API_URL2 + `/client/list-client`, reqData, {
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    if (setData) setData(res.data.data);
                } else {
                    if (setData) setData([]);
                }
            } else {
                if (setData) setData([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const handleOnChange = (e, type, name) => {
        if (type === "datebox") {
            if (moment(e).isValid()) {
                dispatch(setEmronSettlementsReportRequestData({...requestData, [name] : moment(e).format("DD/MM/YYYY")}));
            } else {
                dispatch(setEmronSettlementsReportRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "acdd") {
            if (name === "clientName") {
                dispatch(setEmronSettlementsReportRequestData({...requestData, clientId: e.row._id, clientName : e.row.name}));
            }
        } else if (type === "checkbox") {
            dispatch(setEmronSettlementsReportRequestData({...requestData, [name] : e.target.checked}));
        }
    }

    const fetchReport = (fromPagination = false) => {
        setLoading(true);
        let reqData = {
            ...requestData,
            company: company.id,
        }
        if (!fromPagination || requestData.doseAnalysis !== responseData.doseAnalysis) {
            reqData.page = 1;
            dispatch(setEmronSettlementsReportRequestData({...requestData, page: 1}));
        }
        axios.post(`${process.env.REACT_APP_API_URL2}/client/emron-settlements-report`, reqData, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                let cloneData = structuredClone(res.data.data);
                if (res.data.data?.length === 0) {
                    toast.info("Δεν βρέθηκαν δεδομένα.");
                } else {
                    if (!fromPagination) {
                        toast.success("Τα δεδομένα ανανεώθηκαν", {autoClose: 1000});
                    }
                    if (res.data.doseAnalysis === true) {
                        cloneData.sort((a, b) => moment(a.date) < moment(b.date) ? -1 : 1);
                        for (let row of cloneData) {
                            row["date"] = moment(row["date"]).format("DD/MM/YYYY HH:mm:ss");
                            row["amount"] = getGreekCurrencyFormat(row["amount"]);
                        }
                    }
                }
                dispatch(setEmronSettlementsReportResponseData({
                    ...responseData,
                    data: cloneData,
                    doseAnalysis: res.data.doseAnalysis,
                    totalPaidAmount: res.data.totalPaidAmount,
                    totalRemainingAmount: res.data.totalRemainingAmount,
                    totalSettledAmount: res.data.totalSettledAmount,
                    totalPages: res.data.totalPages,
                }));
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const getRowColor = (row) => {
        if (row.status === "paid") {
            return "#d7fcd8";
        } else if (row.status === "overdue") {
            return "#f78b8b";
        } else {
            return "";
        }
    }

    const handleOpenClientDetails = (clientId, subscription_id) => {
        if (clientId) {
            axios.get(`${process.env.REACT_APP_API_URL2}/client/get-client-id-by/${clientId}`, {
                headers: {"Content-Type": "application/json"},
            }).then((res) => {
                if (res.data.status === "200") {
                    const subscriptions = res.data.data?.subscriptions;
                    const findSub = subscriptions?.find((el) => el.subscription_id === subscription_id);
                    if (findSub) {
                        if (!isEmpty(findSub.wooResponse)) {
                            findSub.wooResponse = JSON.parse(findSub.wooResponse);
                        }
                        setViewSettlement(findSub);
                        setShowSettlementModal(true);
                    } else {
                        toast.error("Ο διακανονισμός δεν βρέθηκε.");
                    }
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        } else {
            toast.error("Σφάλμα κατά την αναζήτηση πελάτη.");
        }
    }

    const dateNiceFormat = (dateString) => {
        if (moment(dateString).isValid()) {
            return moment(dateString).format("DD/MM/YYYY HH:mm:ss");
        } else {
            return "-";
        }
    }

    const handleOrderTransactionViewClicked = (transactionId) => {
        if (!isEmpty(transactionId)) {
            axios.get(process.env.REACT_APP_API_URL2 + "/emron/find-settlement-payment", {
                headers: { 'Content-Type': 'application/json' },
                params: {
                    company: company.id,
                    transactionId: transactionId,
                }
            }).then((res) => {
                if (res.data.status === "200") {
                    dispatch(setEditClientPayment(res.data.data));
                    const idx = TABS_DATA.findIndex((item) => "edit-client-payments" === item);
                    if(idx === -1) {
                        dispatch(addTab("edit-client-payments"));
                        dispatch(updateActiveMainTab(TABS_DATA.length));
                    } else {
                        dispatch(updateActiveMainTab(idx));
                    }
                } else {
                    toast.error(res.data.message);
                }
            }).catch((error) => {
                console.log(error);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        } else {
            toast.error("Δεν βρέθηκε η είσπραξη, παρακαλώ δοκιμάστε αργότερα.")
        }
    }

    const getSettlementPaidStatus = (settlement, settlementIdx, wooOrders) => {
        /* Statuses:
        - paid
        - overdue
        - none
        returns array with status at 0, color at 1
         */
        let successfulOnes = wooOrders?.filter((el) => !isEmpty(el.transaction_id))
        if (successfulOnes?.length > 0) {
            if (settlementIdx <= successfulOnes.length - 1) {
                return ["paid", "#c7ffb0"];
            }
        }
        if (Math.abs(moment().diff(settlement.date, "hours")) > 12 && moment() > moment(settlement.date)) {
            return ["overdue", "#ff9696"];
        }
        return ["none", ""];
    }

    const toFirstPage = () => {
        dispatch(setEmronSettlementsReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }
    const toPreviousPage = () => {
        dispatch(setEmronSettlementsReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const toNextPage = () => {
        dispatch(setEmronSettlementsReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }
    const toLastPage = () => {
        dispatch(setEmronSettlementsReportRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }

    const getBackgroundGradient = (row) => {
        let percentGreen = Math.min(+Number((row.totalPaid / row.totalPrice) * 100).toFixed(2), 100);
        let percentRed = Math.min(+Number((row.totalFailed / row.totalPrice) * 100).toFixed(2), 100);

        if (percentGreen < 0.1 || isNaN(percentGreen)) percentGreen = 0;
        if (percentRed < 0.1 || isNaN(percentRed)) percentRed = 0;

        if (percentGreen > 0 && percentRed === 0) {
            return {
                backgroundColor: "#c2ffc8",
                width: `${percentGreen}%`,
            }
        } else if (percentGreen > 0 && percentRed > 0) {
            return {
                background: `linear-gradient(to right, #c2ffc8 ${percentGreen}%, #ff9e9e ${percentGreen}%, #ff9e9e ${percentGreen + percentRed}%, white ${percentGreen + percentRed}%)`
            }
        } else if (percentGreen === 0 && percentRed > 0) {
            return {
                backgroundColor: "#ff9e9e",
                width: `${percentRed}%`,
            }
        } else if (percentGreen === 0 && percentRed === 0) {
            return {}
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <Row className={"mb-2"}>
                <Col md={3}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => dispatch(setEmronSettlementsReportRequestData({
                            ...requestData,
                            date_from: from,
                            date_to: to,
                        }))}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name="date_from"
                        selected={isEmpty(requestData.date_from) ? "" : moment(requestData.date_from, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateFrom')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_from")}
                    />
                </Col>
                <Col md={2}>
                    <DateBox
                        name="date_to"
                        selected={isEmpty(requestData.date_to) ? "" : moment(requestData.date_to, "DD/MM/YYYY").toDate()}
                        label={t('SalesBalanceSheet.dateTo')}
                        onChange={(e) => handleOnChange(e, "datebox", "date_to")}
                    />
                </Col>
                <Col md={5}>
                    <AdvancedCombobox
                        name="clientName"
                        ACName="Sales Client" // do not change name
                        className="w-100 ac-select-dropdown"
                        searchableDropdownEnable={false}
                        defaultValue={requestData.clientName}
                        label={t('SalesBalanceSheet.table.client')}
                        data={clientsList}
                        onChange={(e) => handleOnChange(e, "acdd", "clientName")}
                        resetFilter={() => dispatch(setEmronSettlementsReportRequestData({...requestData, clientId: "", clientName: ""}))}
                        onChangeSearch={(keyword, setData) => {
                            if (String(keyword).length >= 2) {
                                loadClientsListData(keyword, setData);
                            }
                        }}
                        excludeColumns={[]}
                        columns={clientDropdownColumns}
                        editEnable={false}
                        deleteEnable={false}
                        searchTableEnable={false}
                        showTableOptionOnSelect={true}
                        placeholder={t('SalesBalanceSheet.table.client')}
                        required={true}
                        showInsertAddButton={true}
                        hideSaveButton={true}
                        hideInsertNewRow={true}
                        inputFieldClasses="form-control"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <BetterCheckBox
                        name={"doseAnalysis"}
                        text={"Ανάλυση δόσεων"}
                        checked={requestData.doseAnalysis}
                        className={"mt-2"}
                        onChange={(e) => handleOnChange(e, "checkbox", "doseAnalysis")}
                    />
                </Col>
                {requestData.doseAnalysis && (
                    <Col md={3}>
                        <BetterCheckBox
                            name={"showOverdue"}
                            text={"Προβολή αποτυχημένων πληρωμών"}
                            checked={requestData.showOverdue}
                            className={"mt-2"}
                            onChange={(e) => handleOnChange(e, "checkbox", "showOverdue")}
                        />
                    </Col>
                )}
                {!requestData.doseAnalysis && (
                    <Col md={3}>
                        <BetterCheckBox
                            name={"showFullyPaid"}
                            text={"Προβολή ολοκληρωμένων διακανονισμών"}
                            checked={requestData.showFullyPaid}
                            className={"mt-2"}
                            onChange={(e) => handleOnChange(e, "checkbox", "showFullyPaid")}
                        />
                    </Col>
                )}
            </Row>
            <Row>
                <Col md={12}>
                    <Button onClick={() => fetchReport()} className="btn btn-primary float-right" disabled={loading}>
                        {t('General.applyFilter')}
                    </Button>
                </Col>
            </Row>
            {responseData.totalPages > 0 && (
                <React.Fragment>
                    <hr/>
                    <Col md={12}>
                        <div className="d-flex justify-content-end mb-2">
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toFirstPage()}
                                disabled={requestData.page === 1 || loading}
                            >
                                {t('SalesBalanceSheet.first')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toPreviousPage()}
                                disabled={requestData.page === 1 || loading}
                            >
                                {t('SalesBalanceSheet.previous')}
                            </Button>
                            <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                   bg="primary">{t('SalesBalanceSheet.page')} {requestData.page} / {responseData.totalPages}</Badge>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toNextPage()}
                                disabled={requestData.page === responseData.totalPages || loading}
                            >
                                {t('SalesBalanceSheet.next')}
                            </Button>
                            <Button
                                size={"sm"}
                                className={"mr-1"}
                                onClick={() => toLastPage()}
                                disabled={requestData.page === responseData.totalPages || loading}
                            >
                                {t('SalesBalanceSheet.last')}
                            </Button>
                        </div>
                    </Col>
                </React.Fragment>
            )}
            {responseData?.data?.length > 0 && (
                <React.Fragment>
                    {responseData.doseAnalysis ? (
                        <React.Fragment>
                            <Row>
                                <Col md={12}>
                                    <table style={{tableLayout: "fixed", width: "100%", border: "1px solid gray", textAlign: "center"}}>
                                        <colgroup>
                                            <col span={1} style={{width: "25%"}}></col>
                                            <col span={1} style={{width: "15%"}}></col>
                                            <col span={1} style={{width: "25%"}}></col>
                                            <col span={1} style={{width: "20%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "5%"}}></col>
                                        </colgroup>
                                        <thead>
                                        <tr style={{backgroundColor: "#e0e0e0"}}>
                                            <th style={{border: "1px solid gray"}}>Όνομα Πελάτη</th>
                                            <th style={{border: "1px solid gray"}}>ΑΦΜ Πελάτη</th>
                                            <th style={{border: "1px solid gray"}}>Όνομα Διακανονισμού (Κωδικός WooCommerce)</th>
                                            <th style={{border: "1px solid gray"}}>Ημερομηνία Πληρωμής</th>
                                            <th style={{border: "1px solid gray"}}>Ποσό πληρωμής</th>
                                            <th style={{border: "1px solid gray"}}>&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {responseData?.data?.map((row, idx) => (
                                            <tr style={{backgroundColor: getRowColor(row)}} key={"ems" + idx}>
                                                <td style={{border: "1px solid gray"}}>{row["name"]}</td>
                                                <td style={{border: "1px solid gray"}}>{row["vatNumber"]}</td>
                                                <td style={{border: "1px solid gray"}}>{row["sub_name"]}</td>
                                                <td style={{border: "1px solid gray"}}>{row["date"]}</td>
                                                <td style={{border: "1px solid gray"}}>{row["amount"]}</td>
                                                <td style={{border: "1px solid gray"}}>
                                                    <i className="fa-regular fa-file-lines"
                                                       style={{padding: 0, cursor: "pointer"}} title={"Λεπτομέρειες Διακανονισμού"}
                                                       onClick={() => handleOpenClientDetails(row._id, row.subscription_id)}
                                                    ></i>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Row>
                                <Col md={12}>
                                    <table style={{
                                        tableLayout: "fixed",
                                        width: "100%",
                                        border: "1px solid gray",
                                        textAlign: "center"
                                    }}>
                                        <colgroup>
                                            <col span={1} style={{width: "25%"}}></col>
                                            <col span={1} style={{width: "15%"}}></col>
                                            <col span={1} style={{width: "25%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "10%"}}></col>
                                            <col span={1} style={{width: "5%"}}></col>
                                        </colgroup>
                                        <thead>
                                        <tr style={{backgroundColor: "#e0e0e0"}}>
                                            <th style={{border: "1px solid gray"}}>Όνομα Πελάτη</th>
                                            <th style={{border: "1px solid gray"}}>ΑΦΜ Πελάτη</th>
                                            <th style={{border: "1px solid gray"}}>Όνομα Διακανονισμού (Κωδικός
                                                WooCommerce)
                                            </th>
                                            <th style={{border: "1px solid gray"}}>Ημερομηνία Επόμενης Πληρωμής</th>
                                            <th style={{border: "1px solid gray"}}>Ποσό πληρωμής</th>
                                            <th style={{border: "1px solid gray"}}>Πρόοδος</th>
                                            <th style={{border: "1px solid gray"}}>&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {responseData?.data?.map((row, idx) => (
                                            <tr key={"ems2" + idx}>
                                                <td style={{border: "1px solid gray"}}>{row["name"]}</td>
                                                <td style={{border: "1px solid gray"}}>{row["vatNumber"]}</td>
                                                <td style={{border: "1px solid gray"}}>{row["sub_name"]}</td>
                                                <td style={{border: "1px solid gray"}}>{!isEmpty(row["next_payment_date"]) ? moment(row["next_payment_date"]).format("DD/MM/YYYY HH:mm") : ""}</td>
                                                <td style={{border: "1px solid gray"}}>{row["amount"]}</td>
                                                <td style={{border: "1px solid gray", position: "relative"}}>
                                                    <div style={{
                                                        position: "absolute",
                                                        left: 0,
                                                        top: 0,
                                                        height: "100%",
                                                        width: "100%",
                                                        zIndex: "-1",
                                                        opacity: "0.8",
                                                        ...getBackgroundGradient(row),
                                                    }}>
                                                    </div>
                                                    <span style={{zIndex: "5"}}>{getGreekCurrencyFormat(row["totalPaid"])} / {getGreekCurrencyFormat(row["totalPrice"])}</span>
                                                </td>
                                                <td style={{border: "1px solid gray"}}>
                                                    <i className="fa-regular fa-file-lines"
                                                       style={{padding: 0, cursor: "pointer"}}
                                                       title={"Λεπτομέρειες Διακανονισμού"}
                                                       onClick={() => handleOpenClientDetails(row._id, row.subscription_id)}
                                                    ></i>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                    <Row className="justify-content-end mt-3 mb-3">
                        <Col md={3} className="d-flex justify-content-end">
                            <table style={{
                                tableLayout: "fixed",
                                width: "100%",
                                border: "1px solid gray",
                                textAlign: "center"
                            }}>
                                <tbody>
                                <tr>
                                    <td style={{border: "1px solid gray"}}><strong>Συνολικό εξοφλημένο ποσό</strong>
                                    </td>
                                    <td style={{border: "1px solid gray"}}>{getGreekCurrencyFormat(responseData.totalPaidAmount)}</td>
                                </tr>
                                <tr>
                                    <td style={{border: "1px solid gray"}}><strong>Συνολικό ποσό προς είσπραξη</strong>
                                    </td>
                                    <td style={{border: "1px solid gray"}}>{getGreekCurrencyFormat(responseData.totalRemainingAmount)}</td>
                                </tr>
                                <tr>
                                    <td style={{border: "1px solid gray"}}><strong>Συνολικό διακανονισμένο ποσό</strong>
                                    </td>
                                    <td style={{border: "1px solid gray"}}>{getGreekCurrencyFormat(responseData.totalSettledAmount)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Modal backdrop={"static"} show={showSettlementModal} dialogClassName={"modal6OPercentWidth"} onHide={() => setShowSettlementModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Προβολή διακανονισμού</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {Object.keys(viewSettlement).length > 0 ? (
                                <React.Fragment>
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={6}>
                                                    <Input
                                                        name={"type"}
                                                        label={"Τύπος"}
                                                        defaultValue={"Διακανονισμός"}
                                                        disabled={true}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <Input
                                                        label={"Ποσό διακανονισμού"}
                                                        disabled={true}
                                                        value={getGreekCurrencyFormat(viewSettlement.price)}
                                                    />
                                                </Col>
                                                <Col md={12}>
                                                    <Input name="subscriptionName" className="mb-1" label={"Όνομα Διακανονισμού"} disabled={true} value={viewSettlement?.subscriptionName} />
                                                </Col>
                                                <Col md={6}>
                                                    <DateBox
                                                        name="startDate"
                                                        className="mb-2"
                                                        label="Ημερομηνία Έναρξης"
                                                        disabled={true}
                                                        selected={moment(viewSettlement?.startDate).toDate()}
                                                        minDate={moment().add(1, "day").toDate()}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <DateBox
                                                        className="mt-1"
                                                        name="endDate"
                                                        label="Ημερομηνία Λήξης"
                                                        disabled={true}
                                                        allowOtherYears={true}
                                                        selected={moment(viewSettlement?.endDate).toDate()}
                                                        minDate={moment(viewSettlement.startDate).add(1, "days").toDate()}
                                                    />
                                                </Col>
                                                <div style={{textAlign: "center", fontWeight: "600", fontStyle: "italic"}}>
                                                    <h6 className={"mt-2"}>Πίνακας δόσεων</h6>
                                                </div>
                                                <Col md={12}>
                                                    <table style={{
                                                        tableLayout: "fixed",
                                                        border: "1px solid gray",
                                                        textAlign: "center",
                                                        width: "100%"
                                                    }}>
                                                        <colgroup>
                                                            <col style={{width: "20%"}}></col>
                                                            <col style={{width: "40%"}}></col>
                                                            <col style={{width: "40%"}}></col>
                                                        </colgroup>
                                                        <thead>
                                                        <tr>
                                                            <th style={{border: "1px solid gray"}}>Αρ. δόσης</th>
                                                            <th style={{border: "1px solid gray"}}>Ημερομηνία</th>
                                                            <th style={{border: "1px solid gray"}}>Ποσό</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {viewSettlement.settlements.map((settlement, idx) => {
                                                            const stat = getSettlementPaidStatus(settlement, idx, viewSettlement?.orders);

                                                            return (
                                                                <tr style={{backgroundColor: stat[1]}}>
                                                                    <td style={{border: "1px solid gray"}}
                                                                        key={`settlement${idx}ar`}>{idx + 1}</td>
                                                                    <td style={{border: "1px solid gray"}}
                                                                        key={`settlement${idx}date`}>{moment(settlement.date).format("DD/MM/YYYY")}</td>
                                                                    <td style={{border: "1px solid gray"}}
                                                                        key={`settlement${idx}am`}>{getGreekCurrencyFormat(settlement.amount)}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr>
                                                            <td colSpan={2} style={{
                                                                textAlign: "right",
                                                                border: "1px solid gray",
                                                                fontWeight: "700"
                                                            }}>Σύνολο
                                                            </td>
                                                            <td style={{border: "1px solid gray"}}>{getGreekCurrencyFormat(viewSettlement.price)}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6} style={{borderLeft: "1px solid lightgray"}}>
                                            <Row className={"mb-2"}>
                                                <Col md={12}>
                                                    <div style={{textAlign: "center", fontSize: "15px"}}>Πληροφορίες Συνδρομής
                                                        WooCommerce
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className={"mb-2"}>
                                                <Col md={6}>
                                                    <Input label={"Αρ. Συνδρομής"} disabled={true}
                                                           value={viewSettlement.wooResponse.id}/>
                                                </Col>
                                                <Col md={6}>
                                                    <Input label={"Κατάσταση"} disabled={true}
                                                           value={viewSettlement.wooResponse.status}/>
                                                </Col>
                                            </Row>
                                            <Row className={"mb-2"}>
                                                <Col md={6}>
                                                    <Input label={"Ημερομηνία Έναρξης"} disabled={true}
                                                           value={dateNiceFormat(viewSettlement.wooResponse.start_date)}/>
                                                </Col>
                                                <Col md={6}>
                                                    <Input label={"Ημερομηνία Λήξης"} disabled={true}
                                                           value={dateNiceFormat(viewSettlement.wooResponse.end_date)}/>
                                                </Col>
                                            </Row>
                                            <Row className={"mb-2"}>
                                                <Col md={6}>
                                                    <Input label={"Ημερομηνία Επόμενης Πληρωμής"} disabled={true}
                                                           value={dateNiceFormat(viewSettlement.wooResponse.next_payment_date)}/>
                                                </Col>
                                                <Col md={6}>
                                                    <Input label={"Ποσό Επόμενης Πληρωμής"} disabled={true}
                                                           value={isEmpty(viewSettlement.wooResponse.total) ? "-" : viewSettlement.wooResponse.total} />
                                                </Col>
                                            </Row>
                                            {viewSettlement?.orders?.length > 0 && (
                                                <Row>
                                                    <Col md={12}>
                                                        <div style={{textAlign: "center"}}><h6>Ιστορικό παραγγελιών</h6></div>
                                                        <table style={{tableLayout: "fixed", width: "100%", textAlign: "center"}}>
                                                            <thead>
                                                            <tr>
                                                                <th style={{border: "1px solid gray"}}>Ημ/νια πληρωμής</th>
                                                                <th style={{border: "1px solid gray"}}>Αρ. Παραγγελίας</th>
                                                                <th style={{border: "1px solid gray"}}>Transaction ID</th>
                                                                <th style={{border: "1px solid gray"}}>Ποσό πληρωμής</th>
                                                                <th style={{border: "1px solid gray", width: "25px"}}>&nbsp;</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {viewSettlement?.orders?.map((order, idx) => (
                                                                <tr key={`orderRowEP${idx}`} style={{backgroundColor: isEmpty(order.transaction_id) ? "#ff9696" : ""}}>
                                                                    <td style={{border: "1px solid gray"}}>{isEmpty(order.date_paid) ? "-" : moment(order.date_paid).format("DD/MM/YYYY HH:mm:ss")}</td>
                                                                    <td style={{border: "1px solid gray"}}>{order.id}</td>
                                                                    <td style={{border: "1px solid gray"}}>{isEmpty(order.transaction_id) ? "Αποτυχία χρέωσης" : order.transaction_id}</td>
                                                                    <td style={{border: "1px solid gray"}}>{getGreekCurrencyFormat(Number(order.total))}</td>
                                                                    <td style={{border: "1px solid gray", width: "25px"}}>{!isEmpty(order.transaction_id) && (
                                                                        <i className="fa-regular fa-file-lines" style={{padding: 0, cursor: "pointer"}} title={"Προβολή Είσπραξης"} onClick={() => handleOrderTransactionViewClicked(order.transaction_id)}></i>
                                                                    )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row className={"mt-2 mb-2"}>
                                                <Col md={12}>
                                                    <TextArea
                                                        label={"Σημειώσεις"}
                                                        rows={3}
                                                        disabled={true}
                                                        value={viewSettlement.notes}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            ) : (
                                <Spinner className={"ml-2"} animation={"border"} variant={"dark"}/>
                            )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowSettlementModal(false)}>
                            Κλείσιμο
                        </Button>
                    </Modal.Footer>
                </Modal>
                </React.Fragment>
                )}
        </div>
    )
}