import React, {useEffect, useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../../../primer/POPUP-COMPONENTS/modalWidths.css";
import SimpleFile from "./SimpleFile";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {checkPermission} from "../../../../../_helpers/commonFunctions";
import SimpleContextMenu from "./SimpleContextMenu";
import {formatPath, getParentPath} from "../Components/constants";
import {toast} from "react-toastify";
import {addTab, updateActiveMainTab} from "../../../../../_reducers/TabsSlice";
import {setAutoPreview, setCurrentPath} from "../../../../../_reducers/cloudSlice";
import TextArea from "../../../../common/TextArea";

const CloudHooksModal = ({documentId, section, className}) => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const isEmron = useSelector((state) => state.ACTIVITY.isEmron);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    const miniBrowserRef = useRef(null);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuX, setContextMenuX] = useState(0);
    const [contextMenuY, setContextMenuY] = useState(0);
    const [contextMenuSelection, setContextMenuSelection] = useState("");

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteName, setDeleteName] = useState("");
    const [deletePath, setDeletePath] = useState("");
    const [deleteCause, setDeleteCause] = useState("");

    const [openCloudTab, setOpenCloudTab] = useState(false);

    const miniBrowserStyle = {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        gap: "10px 10px",
        minHeight: "50vh",
        maxHeight: "60vh",
        position: "relative",
        padding: "10px",
        overflowY: "auto",
        width: "100%",
        alignItems: "flex-start",
        alignContent: "flex-start",
    }

    useEffect(() => {
        if (show) fetchData();
    }, [show])

    useEffect(() => {
        if (!showDeleteModal) {
            setDeleteName("");
            setDeletePath("");
            setDeleteCause("");
        }
    }, [showDeleteModal])

    useEffect(() => {
        if (openCloudTab) {
            setOpenCloudTab(false);
            const findMatchingTab = TABS_DATA.findIndex((item) => item === "cloud");
            if (findMatchingTab === -1) {
                dispatch(addTab("cloud"));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            } else {
                dispatch(updateActiveMainTab(findMatchingTab));
            }
        }
    }, [openCloudTab])

    const fetchData = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL2}/cloud/get-correlated-files`, {
            headers: { "Content-Type": "application/json" },
            params: {
                documentId: documentId,
                section: section,
                company: company.id,
                year: company.year,
            }
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                setData(res.data.data);
            } else {
                setData([]);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }

    const onContextMenu = (e, name = "") => {
        e.stopPropagation();
        e.preventDefault();

        const bounds = miniBrowserRef.current?.getBoundingClientRect();
        setShowContextMenu(true);
        let initX = e.clientX - bounds.left;
        if (initX + 120 > bounds.width) {
            initX -= 120;
        }
        setContextMenuX(initX);

        let initY = e.clientY - bounds.top;
        if (initY + 50 > bounds.height) {
            initY -= 50;
        }
        initY += miniBrowserRef.current.scrollTop;
        setContextMenuY(initY);

        setContextMenuSelection(name);
    }

    const handleCloseContext = () => {
        if (showContextMenu) {
            setShowContextMenu(false);
            setContextMenuSelection("");
        }
    }

    const onOpenInCloud = () => {
        const findMatching = data.find((el) => el.name === contextMenuSelection);
        if (findMatching) {
            const path = getParentPath(findMatching.path);
            dispatch(setCurrentPath(path));
            dispatch(setAutoPreview(findMatching.name));
            setOpenCloudTab(true);
        }
    }

    const onDecorrelate = () => {
        const findMatching = data.find((el) => el.name === contextMenuSelection);
        if (findMatching) {
            axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
                action: "dehook",
                currentPath: formatPath(findMatching.path),
                documentHook: {
                    _id: documentId,
                    section: section,
                },
                company: company.id,
                year: company.year,
            }).then((res) => {
                if (res.data.status === "200") {
                    toast.success("Επιτυχής ενέργεια.");
                    fetchData();
                } else {
                    toast.error(res.data.message);
                    fetchData();
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        }
    }

    const handleDeletion = () => {
        const findMatching = data.find((el) => el.name === contextMenuSelection);
        if (findMatching) {
            setDeletePath(findMatching.path);
            setDeleteName(findMatching.name);
            setShowDeleteModal(true);
        }
    }

    const handleSaveDeletion = () => {
        if (isEmron) {
            onEmronDelete(deletePath, deleteCause);
            setShowDeleteModal(false);
        } else {
            onFileDelete(deletePath, deleteCause);
            setShowDeleteModal(false);
        }
    }

    const onFileDelete = (path, deleteCause = "") => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "deleteFile",
            currentPath: formatPath(path),
            deleteCause: deleteCause,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής διαγραφή αρχείου.");
                fetchData();
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const onEmronDelete = (path, deleteCause = "") => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronDelete",
            currentPath: formatPath(path),
            deleteCause: deleteCause,
            company: company.id,
            year: company.year,
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Επιτυχής ενέργεια.");
                fetchData();
            } else {
                toast.error(res.data.message);
                fetchData();
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    return (
        <React.Fragment>
            {checkPermission("custom-cloud", permissionsData) && (
                <React.Fragment>
                    <Button onClick={() => setShow(true)} style={{height: "50px", width: "70px", padding: "5px"}} className={className}>Αρχεία</Button>

                    <Modal backdrop={"static"} show={show} onHide={() => setShow(false)} dialogClassName={"modal65PercentWidth"} style={{opacity: showDeleteModal ? "0.6" : "1"}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Συσχετιζόμενα αρχεία</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {loading ? (
                                <div>Γίνεται αναζήτηση παρακαλώ περιμένετε...</div>
                            ) : (
                                <React.Fragment>
                                    {data.length === 0 ? (
                                        <div>Δεν βρέθηκαν συσχετιζόμενα αρχεία.</div>
                                    ) : (
                                        <div style={miniBrowserStyle} ref={miniBrowserRef}
                                             onClick={() => handleCloseContext()}
                                             onMouseLeave={() => handleCloseContext()}
                                        >
                                            <SimpleContextMenu
                                                show={showContextMenu}
                                                posX={contextMenuX}
                                                posY={contextMenuY}

                                                onOpenInCloud={onOpenInCloud}
                                                onDecorrelate={onDecorrelate}
                                                onFileDelete={handleDeletion}
                                            />
                                            {data.map((file, idx) => (
                                                <SimpleFile
                                                    name={file.name}
                                                    key={`simpleFile-${idx}`}
                                                    selected={contextMenuSelection === file.name}
                                                    onContextMenu={onContextMenu}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    <Modal backdrop={"static"} show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Διαγραφή αρχείου</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            Είσαστε σίγουροι ότι θέλετε να διαγράψετε το αρχείο <strong>{deleteName}</strong>;
                                            <div style={{borderTop: "1px solid lightgray"}} className={"mt-3 mb-1"}></div>
                                            <TextArea
                                                rows={3}
                                                value={deleteCause}
                                                label={"Αιτιολογία"}
                                                autoComplete={"off"}
                                                onChange={(e) => setDeleteCause(e.target.value)}
                                            />
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={() => handleSaveDeletion()}>
                                                Ναι, διαγραφή
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </React.Fragment>
                            )}
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default CloudHooksModal;
