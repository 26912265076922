import React, {useEffect, useMemo, useRef, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import {
  fetchBuysGeneralSettings, fetchCashRegisters,
  sendInvoiceToMyDataBuy,
} from "../../../../_apis/api";
import EditableTable from "../../../primer/Editable-Table";
import {useDispatch, useSelector} from "react-redux";
import {
  buysMyDataStatus,
  getBuysPrintData,
  getCurrentUser,
  getDefaultOptionByValue,
  getDOYOptions,
  getGreekCurrencyFormat,
  getOptionsByData,
  isEmpty,
} from "../../../../_helpers/commonFunctions";
import {
  getLabelByValue,
  getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import TextArea from "../../../common/TextArea";
import {getBuysTotals} from "../../../../_helpers/product-buys-calculations";
import BuyPrintA4Greek from "../../PrintLayout/buy-print-a4-greek";
import SendEmail from "../../../primer/POPUP-COMPONENTS/Send-Email";
import BuyPrintA4English from "../../PrintLayout/buy-print-a4-english";
import Alert from "react-bootstrap/Alert";
import {AccountingView} from "../../../primer/AccountingView/AccountingView";
import {useTranslation} from "react-i18next";
import CloudHooksModal from "../Cloud/Modal Components/CloudHooksModal";

const ViewBuys = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const ACTIVITY = useSelector((state) => state.ACTIVITY);
  const BUYS = useSelector((state) => state.BUYS);
  const company = useSelector((state) => state.COMPANY_DATA.company);
  const SETTINGS = useSelector((state) => state.SETTINGS);
  const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
  const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
  const CASH_DATA = useSelector((state) => state.CASH_DATA);
  const currentUser = getCurrentUser();

  const requestData2 = {
    company: company.id,
    year: company.year.toString(),
    isActive: "active",
    userId: currentUser.id
  }

  useEffect(() => {
    dispatch(fetchBuysGeneralSettings(requestData2))
    dispatch(fetchCashRegisters(requestData2));
  }, [dispatch]);

  const productCodeColumns = [
    {
      label: t("General.code"),
      name: "code",
    },
    {
      label: t("General.alternateCode"),
      name: "alternateCode",
    },
    {
      label: t("General.productName"),
      name: "name",
    },
    {
      label: t("General.alternateName"),
      name: "alternateName",
    },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const [loadingSendToMyData, setLoadingSendToMyData] = useState(false);
  const [showMyDataErrorModal, setShowMyDataErrorModal] = useState(false);

  // Options for Document Type Dropdown
  let pMDDSettings = SETTINGS.primerMyDataData;
  const COMPANY_DATA = useSelector((state) => state.COMPANY_DATA);
  const waysOfSendingGoodsOptions = getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.sendingGoods, "_id", "name");
  const carNumbersOptions = getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.carNumbers, "_id", "number");
  let otherTaxesOptions = useMemo(() => getOptionsByData(pMDDSettings?.otherTaxes !== undefined && pMDDSettings?.otherTaxes !== '' ? pMDDSettings.otherTaxes.filter(otherTax => otherTax.taxPer === "true" && otherTax.taxLevel === 1) : SETTINGS.otherHoldTaxes, 'code', 'name'),[pMDDSettings, SETTINGS.otherHoldTaxes]);
  let withHoldTaxesOptions = useMemo(() => getOptionsByData(pMDDSettings?.withHoldTaxes !== undefined && pMDDSettings?.withHoldTaxes !== '' ? pMDDSettings.withHoldTaxes.filter(withHoldTax => withHoldTax.taxPer === "true" && withHoldTax.taxLevel === 1) : SETTINGS.withHoldTaxes, 'code', 'name'), [pMDDSettings, SETTINGS.withHoldTaxes]);
  let stampOptions = useMemo(() => getOptionsByData(pMDDSettings?.stamp !== undefined && pMDDSettings?.stamp !== '' ? pMDDSettings.stamp.filter(stamp => stamp.taxPer === "true" && stamp.taxLevel === 1) : SETTINGS.stamp, 'code', 'name'), [pMDDSettings, SETTINGS.stamp]);
  let feesOptions = useMemo(() => getOptionsByData(pMDDSettings?.fees !== undefined && pMDDSettings?.fees !== '' ? pMDDSettings.fees.filter(fee => fee.taxPer === "true" && fee.taxLevel === 1) : SETTINGS.fees, 'code', 'name'), [pMDDSettings, SETTINGS.fees]);
  otherTaxesOptions = [{ value: "", label: t("General.empty"), color: "#76AEB7" }, ...otherTaxesOptions]
  withHoldTaxesOptions = [{ value: "", label: t("General.empty"), color: "#76AEB7" }, ...withHoldTaxesOptions]
  stampOptions = [{ value: "", label: t("General.empty"), color: "#76AEB7" }, ...stampOptions]
  feesOptions = [{ value: "", label: t("General.empty"), color: "#76AEB7" }, ...feesOptions]
  const buysMyDataStatusOptions = buysMyDataStatus();
  const myDataStatusDefault = getDefaultOptionByValue(buysMyDataStatusOptions, BUYS.editBuys.myDataStatus ? BUYS.editBuys.myDataStatus : 'not_sent', 'value', 'label', 'value');
  const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
  const installationOptions = getOptionsByData(installations, "installationMasterId", "address");
  let disabledFields = false;
  if (BUYS.editBuys.documentType === undefined || BUYS.editBuys.documentType === '') {
    disabledFields = true;
  }
  const componentRefA4 = useRef();
  const componentRefA4En = useRef();
  const SALES = useSelector((state) => state.SALES); // Will only be used for printLayout
  let printTemplateData = getBuysPrintData("editBuys", BUYS, COMPANY_DATA, null, SALES.printLayout);

  const supplierProductsColumns = [
    {
      name: t("General.no"),
      field: "no",
      width: "40px",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.productCode"),
      field: "code",
      width: "130px",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: [],
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: 'Select Product Code'
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t("General.productName"),
      field: "name",
      width: "400px",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: productCodeColumns,
          data: [],
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: 'Select Product Name'
        },
        type: 'advanced-combobox',
      }
    },
    {
      name: t("General.quantity"),
      field: "quantity",
      width: "100px",
      editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'accounting',
        config: {
          maxLength: 6 // Given in the wireframe
        }
      }
    },
    {
      name: t("General.measurementUnit"),
      field: "muLabel",
      editable: false,
      width: "60px",
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.unitPrice"),
      field: "unitPrice",
      editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          // maxLength: 5
          maxLength: 10 // Given in the wireframe
        }
      }
    },
    {
      name: t("General.discount"),
      field: "discount",
      editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
    {
      name: "%",
      field: "isPerDiscount",
      editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'radio',
        name: 'discountType',
        radioLogic: 'oneperrow',
        options: [{
          value: true,
          label: 'True'
        }, {
          value: false,
          label: 'False'
        }]
      }
    },
    {
      name: "€",
      field: "isDiscountAmount",
      editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'radio',
        radioLogic: 'oneperrow',
        name: 'discountType',
        options: [{
          value: true,
          label: 'True'
        }, {
          value: false,
          label: 'False'
        }]
      }
    },
    {
      name: t("General.vatPercentage"),
      field: "vatPercentage",
      editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.netValue"),
      field: "netValue",
      editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
    {
      name: t("General.vatValue"),
      field: "vat",
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 7
        }
      }
    },
    {
      name: t("General.taxes"),
      field: "taxes",
      editable: false,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
    {
      name: t("General.totalValue"),
      field: "totalValue",
      editable: true,
      inputType: {
        type: 'accounting',
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10
        }
      }
    },
  ]

  const sendToMyData = () => {
    let data = {
      companyId: company.id,
      year: company.year.toString(),
      buyId: BUYS.editBuys._id
    }
    dispatch(sendInvoiceToMyDataBuy(data, null, setLoadingSendToMyData, TABS_DATA.length));
  }
  const notEditableFields = true;

  let tableWrClasses = "product-form buys-products-list position-relative disabled-page";
  if (BUYS.editBuys.supplier !== '') {
    tableWrClasses += " normalIndex";
  }

  let cashRegistersData = CASH_DATA.cashRegisters;
  let cashRegisterOptions = [{value: "", label: t("General.empty")}, ...getOptionsByData(cashRegistersData, "_id", "name")];

  const editBuysTab = [
    {
      tabName: t("General.products"),
      tabData: (BUYS.editBuys.extraData.length > 0 &&
        <div className={tableWrClasses} style={{pointerEvents: "none"}}>
          {((BUYS.editBuys.supplier === '' && BUYS.editBuys.newSupplier === '') && BUYS.editBuys.employee === '') && <div className="overlay_w">{t("Required.selectSupplierFirst")}</div>}
          {BUYS.editBuys?.products && BUYS.editBuys?.products?.length === 0 && <span className="text-danger">{t("Required.productList")}</span>}
          <EditableTable
            tableName="Buys Products List"
            key={Math.random()}
            actionButtons={{
              print: false,
              delete: false,
              export: false,
              edit: true,
            }}
            columns={supplierProductsColumns}
            data={BUYS.editBuys.products}
            allowInsertRow={false}
            allowActions={true}
            allowToggleColumns={false}
            allowPrintResults={false}
          />
        </div>
      ),
    },
    {
      tabName: t("General.extraData"),
      tabData: (
        <>
          <Row className="disabled-page">
            <Col sm={12} md={3} className="mb-2">
              <Dropdown
                label={t("General.wayOfSendingGoods")}
                className="mb-2 w-100"
                name="wayOfSendingGoods"
                options={waysOfSendingGoodsOptions}
                key={Math.random()}
                defaultValue={getDefaultOptionByValue(waysOfSendingGoodsOptions, BUYS.editBuys.extraData[0]?.wayOfSendingGoods, 'value', 'label', 'value')?.label}
                disabled={notEditableFields}
              />
            </Col>
            {BUYS.editBuys.extraData[0]?.deliveryLocation === "custom" && (
                <Col></Col>
            )}
            <Col sm={12} md={3} className="mb-2">
              <Dropdown
                  label={t("General.otherTaxes")}
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(otherTaxesOptions, BUYS.editBuys.extraData[0]?.otherTax, 'value', 'label', 'value')}
                  name="otherTax"
                  options={otherTaxesOptions}
              />
            </Col>
            <Col sm={12} md={3} className="mb-2">
              <Dropdown
                  label={t("General.withholdTaxes")}
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(withHoldTaxesOptions, BUYS.editBuys.extraData[0]?.withHoldTax, 'value', 'label', 'value')}
                  name="withHoldTax"
                  options={withHoldTaxesOptions}
              />
            </Col>
          </Row>
          <Row className="disabled-page">
            <Col sm={12} md={3}>
              <DateBox
                classes="w-100 mb-2"
                label={t("General.goodsSendDate")}
                name="goodsSendDate"
                selected={BUYS.editBuys.extraData[0]?.goodsSendDate}
                disabled={notEditableFields}
              />
            </Col>
            <Col sm={12} md={3} className="mb-2">
              <Dropdown
                  label={t("General.carNumber")}
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(carNumbersOptions, BUYS.editBuys.extraData[0]?.carNumber, 'value', 'label', 'value')}
                  name="carNumber"
                  options={carNumbersOptions}
              />
            </Col>
            <Col sm={12} md={3} className="mb-2">
              <Dropdown
                  label={t("General.fees")}
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(feesOptions, BUYS.editBuys.extraData[0]?.fee, 'value', 'label', 'value')}
                  name="fee"
                  options={feesOptions}
              />
            </Col>
            <Col sm={12} md={3} className="mb-2">
              <Dropdown
                  label={t("General.stampDuty")}
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(stampOptions, BUYS.editBuys.extraData[0]?.stamp, 'value', 'label', 'value')}
                  name="stamp"
                  options={stampOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Dropdown
                  name={"cashRegister"}
                  label={t("General.cashRegister")}
                  key={Math.random()}
                  defaultValue={cashRegisterOptions.find((el) => el.value === BUYS.editBuys.extraData[0]?.cashRegister) ? cashRegisterOptions.find((el) => el.value === BUYS.editBuys.extraData[0]?.cashRegister) : ""}
                  options={cashRegisterOptions}
                  disabled={true}
              />
            </Col>
          </Row>
        </>
      ),
    },
  ];

  // Data For Quick Edit Supplier Details on selection
  let quickEditSupplierData = {};
  if (BUYS.editBuys.supplierType === 'Existing') {
    let selectedSupplier = getObjectFromArrayOfObjects([], BUYS.editBuys.supplier, '_id');
    if (selectedSupplier.length > 0) {
      let sSupplier = selectedSupplier['0'];
      quickEditSupplierData.name = sSupplier.name;
      quickEditSupplierData.id = sSupplier._id;
      quickEditSupplierData.vatCategory = sSupplier.vatCategory;
      quickEditSupplierData.address = sSupplier.address;
      quickEditSupplierData.vatNumber = sSupplier.vatNumber;
      quickEditSupplierData.doy = getLabelByValue(getDOYOptions(), sSupplier.DOY, 'value');
      quickEditSupplierData.city = sSupplier.city;
      quickEditSupplierData.pc = sSupplier.postalCode;
      quickEditSupplierData.email = sSupplier.email;
      quickEditSupplierData.phone = sSupplier.phone;
    }
  }

  let buysTaxesColumns = [
    {
      name: t("General.vatPercentage"),
      field: "vatPercentage",
      editable: false,
    },
    {
      name: t("General.netValue"),
      field: "netValue",
      editable: false,
    },
    {
      name: t("General.vatValue"),
      field: "vatEuro",
      editable: false,
    },
    {
      name: t("General.withholdTaxes"),
      field: "withholdTaxes",
      editable: false,
    },
    {
      name: t("General.otherTaxes"),
      field: "otherTaxes",
      editable: false,
    },
    {
      name: t("General.fees"),
      field: "fees",
      editable: false,
    },
    {
      name: t("General.stampDuty"),
      field: "stamp",
      editable: false,
    }
  ];
  let totalTaxes = 0;
  let withHoldTaxes = 0;
  let productTotals = useMemo(() => getBuysTotals(BUYS.editBuys.products, SETTINGS), [BUYS.editBuys.products]);
  let buysTaxesData = BUYS.editBuys.buysTaxes;
  if(buysTaxesData.length > 0 && buysTaxesData[0].vatPercentage === "Invoice Level Tax"){
    totalTaxes = parseFloat( (Number(buysTaxesData[0].otherTaxes) + Number(buysTaxesData[0].fees) + Number(buysTaxesData[0].stamp)).toString());
    withHoldTaxes = parseFloat(buysTaxesData[0].withholdTaxes).toFixed(2);
  }
  let totalQuantity = productTotals.totalQuantity;
  let totalBeforeDiscount = productTotals.totalBeforeDiscount;
  let totalDiscount = productTotals.totalDiscount;
  let totalNetValue = productTotals.totalNetValue;
  let totalAmount = productTotals.totalAmount;
  let paymentAmount = productTotals.paymentAmount;
  let totalVatAmount = productTotals.totalVatValue;
  if(totalTaxes !== 0 || withHoldTaxes !== 0){
    totalAmount = parseFloat(totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes);
    paymentAmount = parseFloat(paymentAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes);
  }
  totalTaxes += Number(productTotals.totalTaxesAmount);
  withHoldTaxes = parseFloat(parseFloat(productTotals.withHoldTaxes) + parseFloat(withHoldTaxes));
  return (
    <div className="new-buys">
        {disabledFields && <div className="overlay_w">{t("Required.selectDocumentFirst")}</div>}
        <AccountingView document={BUYS.editBuys} />
        {(BUYS.editBuys.myDataStatus === "sent" || !isEmpty(BUYS.editBuys.mark)) && (
            <Alert variant={"secondary"}>
              Η αγορά δεν είναι επεξεργάσιμη επειδή {BUYS.editBuys.fromMyDataReport === "true" ? `αντιστοιχεί σε αγορά από το My Data.` : `έχει σταλεί στο My Data.`}
              {BUYS.editBuys.mark && (
                  <React.Fragment>
                    {BUYS.editBuys.mark && (
                        <React.Fragment>
                          <br/>
                          <span style={{fontWeight: "600"}}>MARK: </span><span>{BUYS.editBuys.mark}</span>
                        </React.Fragment>
                    )}
                    {BUYS.editBuys.uid && (
                        <React.Fragment>
                          <br/>
                          <span style={{fontWeight: "600"}}>UID: </span><span>{BUYS.editBuys.uid}</span>
                        </React.Fragment>
                    )}
                    {BUYS.editBuys.authCode && (
                        <React.Fragment>
                          <br/>
                          <span style={{fontWeight: "600"}}>AUTH CODE: </span><span>{BUYS.editBuys.authCode}</span>
                        </React.Fragment>
                    )}
                  </React.Fragment>
              )}
            </Alert>
        )}
        <Row className="border-bottom mb-2 disabled-page">
          <Col sm={12} md={3} className="showWithOverlay">
            <Input
              className="mb-2 w-100"
              label={t('General.documentType')}
              name="documentType"
              value={BUYS.editBuys.documentTypeName}
              disabled={notEditableFields}
            />
          </Col>
          <Col sm={12} md={9}>
            <div className="d-flex justify-content-end">
              <CloudHooksModal
                  documentId={BUYS.editBuys._id}
                  section={"Buys"}
                  className={"mr-2 mt-2"}
              />
              <div className="mydata-status">
                <Dropdown
                  label={t("General.myDataStatus")}
                  key={Math.random()}
                  disabled={true}
                  options={buysMyDataStatusOptions}
                  defaultValue={myDataStatusDefault}
                  name="myDataStatus"
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className={"mb-2"}>
          <Col sm="12" md="3">
            <DateBox
              classes="w-100"
              label={t('General.date')}
              name="date"
              selected={new Date(BUYS.editBuys.date)}
              readOnly={true}
              disabled={notEditableFields}
            />
          </Col>
          <Col sm="12" md="3">
            <Input
                name={"time"}
                label={t("General.time")}
                value={BUYS.editBuys.time}
                disabled={true}
            />
          </Col>
          <Col
            sm="12"
            md="6"
            className="text-right d-flex align-items-center justify-content-end"
          >
            {BUYS.editBuys?.myDataError !== "" && BUYS.editBuys?.myDataError !== undefined && (
            <Button className="view-sales-buttons" disabled = {BUYS.editBuys.myDataStatus === "sent"} onClick={() => setShowMyDataErrorModal(true)}>Show MyData Error</Button>
            )}
            <Modal backdrop={"static"} className="themeModal" show={showMyDataErrorModal}>
              <Modal.Header>
                <Modal.Title>Warning!</Modal.Title>
              </Modal.Header>
              <Modal.Body>The document failed to be sent to MyData! <br /> The Error is: <br /> {BUYS.editBuys?.myDataError}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => setShowMyDataErrorModal(false)}>OK</Button>
              </Modal.Footer>
            </Modal>
             <Button className="view-sales-buttons" disabled = {BUYS.editBuys.myDataStatus === "sent" || loadingSendToMyData || isEmpty(BUYS.editBuys?.myDataType)} onClick={() => sendToMyData()}>
            {loadingSendToMyData && (
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            )}{t("General.sendToMyData")}</Button>
            {(SALES.printLayout === "buy-print-a4-greek" || !SALES.printLayout) && (BUYS.editBuys?.myDataType === "3.1" || BUYS.editBuys?.myDataType === "3.2") && (<SendEmail className="view-sales-buttons" supplierId={BUYS.editBuys.supplier} modalContent="Do you want to send invoice mail to the client?" mailTo={BUYS.editBuys.email} mailSubject="Your Invoice" mailContent="Here is your invoice" documentType="buy" documentId={BUYS.editBuys._id} pdf={componentRefA4.current} />)}
            {SALES.printLayout === "buy-print-a4-english" && (BUYS.editBuys?.myDataType === "3.1" || BUYS.editBuys?.myDataType === "3.2") && (<SendEmail className="view-sales-buttons" supplierId={BUYS.editBuys.supplier} modalContent="Do you want to send invoice mail to the client?" mailTo={BUYS.editBuys.email} mailSubject="Your Invoice" mailContent="Here is your invoice" documentType="buy" documentId={BUYS.editBuys._id} pdf={componentRefA4En.current} />)}
          </Col>
        </Row>
        <Row className="disabled-page mb-2">
          <Col sm="12" md="3">
            <Input
              className="w-100"
              label={t("General.installation")}
              name="installation"
              value={getDefaultOptionByValue(installationOptions, BUYS.editBuys.installation, 'value', 'label', 'value')?.label}
              disabled={notEditableFields}
            />
          </Col>
          <Col sm="12" md="3">
            <Input
              className="w-100"
              label={t("General.preNumber")}
              name="preNumber"
              value={BUYS.editBuys.preNumber !== "noprefix" ? BUYS.editBuys.preNumber : "Blank"}
              disabled={notEditableFields}
            />
          </Col>
          <Col sm="12" md="3">
            <Input
              className="w-100"
              label={t("General.number")}
              name="number"
              value={BUYS.editBuys.number.replace(BUYS.editBuys.preNumber,'')}
              disabled={notEditableFields}
            />
          </Col>
        </Row>
        <Row className={"mb-2"}>
          {BUYS.editBuys.supplierType === 'Existing' &&
          <Col sm="30" md="5">
            <Input
                className="w-100"
                label={t("General.supplier")}
                name="supplierName"
                value={BUYS.editBuys.supplierName}
                disabled={notEditableFields}
            />
            {ACTIVITY.saveBuys && BUYS.editBuys.supplierType === 'New' && BUYS.editBuys.newSupplier === "" && <div className="text-danger">Supplier is required.</div>}
            {ACTIVITY.saveBuys && BUYS.editBuys.supplierType === 'Existing' && BUYS.editBuys.supplier === "" && <div className="text-danger">Supplier is required.</div>}
          </Col>
          }
          {BUYS.editBuys.supplierType === 'Employee' &&
              <Col sm="30" md="5">
                <Input
                    className="w-100"
                    label="Employee"
                    name="employeeName"
                    value={BUYS.editBuys.employeeName}
                    disabled={notEditableFields}
                />
                {ACTIVITY.saveBuys && BUYS.editBuys.supplierType === 'New' && BUYS.editBuys.newSupplier === "" && <div className="text-danger">Supplier is required.</div>}
                {ACTIVITY.saveBuys && BUYS.editBuys.supplierType === 'Existing' && BUYS.editBuys.supplier === "" && <div className="text-danger">Supplier is required.</div>}
              </Col>
          }
          {BUYS.editBuys.supplierType === 'Existing' &&
            <Col sm="12" md="2">
              <Input
                  className="w-100"
                  label={t("General.supplierInstallation")}
                  name="supplierInstallation"
                  value={BUYS.editBuys.supplierInstallationType}
                  disabled={notEditableFields}
              />
            </Col>
          }
          {BUYS.editBuys.supplierType === 'Existing' &&
              <Col sm="12" md="2">
                <Input
                    className="w-100"
                    label={t("General.vatNumber")}
                    name="vat"
                    value={BUYS.editBuys.vat}
                    disabled={true}
                    readonly
                />
            </Col>
          }
          <Col sm="12" md="2">
            <Input
                className="w-100"
                label={t("General.address")}
                name="address"
                value={BUYS.editBuys.address}
                disabled={notEditableFields}
            />
          </Col>
        </Row>
        <Row className={"mb-2"}>
          {BUYS.editBuys.supplierType === 'Existing' &&
          <Col sm="12" md="2">
            <Input
              className="w-100"
              label={t("General.doy")}
              name="doy"
              value={BUYS.editBuys.doy}
              disabled={notEditableFields}
              readonly
            />
          </Col>
          }
          <Col sm="12" md="2">
            <Input
              className="w-100"
              label={t("General.city")}
              name="city"
              value={BUYS.editBuys.city}
              disabled={notEditableFields}
              readonly
            />
          </Col>
          <Col sm="12" md="2">
            <Input
              className="w-100"
              label={t("General.postalCode")}
              name="pc"
              value={BUYS.editBuys.pc}
              disabled={notEditableFields}
              readonly
            />
          </Col>
        </Row>

        <Row className="disabled-page">
          <Col sm="12" md="4">
            <Input
              className="mb-2 w-100"
              label={t("General.paymentType")}
              name="paymentType"
              value={BUYS.editBuys?.paymentTypeName}
              disabled={notEditableFields}
            />
          </Col>
        </Row>

        <Row>
          <Col md="12" className="mb-2">
            <ul className="react-tabs__tab-list tab-style tab-min">
              {editBuysTab.map((tab, i) => (
                <li key={i} className="react-tabs__tab">
                  <button
                    type="button"
                    onClick={() => setActiveTab(i)}
                    className={`customNavBtn ${activeTab === i && "btnActive"}`}
                  >
                    <span className="textWrapper">{tab.tabName}</span>
                  </button>
                </li>
              ))}
            </ul>
            <div className="innerTabPanel">{editBuysTab[activeTab].tabData}</div>
          </Col>
        </Row>

        <Row className="disabled-page">
          <Col sm="12" md="8">
            <EditableTable
              className="buys-taxes-calculations"
              allowActions={false}
              key={Math.random()}
              data={buysTaxesData}
              columns={buysTaxesColumns}
              actionButtons={{
                print: false,
                delete: false,
                export: false,
                edit: false,
              }}
            />
          </Col>
          <Col sm="12" md="4" className="d-flex justify-content-end">
            <span className="mr-3 fw-bold">{t("General.totals")}</span>
            <table className="table table-bordered mb-2">
              <tbody>
              <tr>
                <td className="fw-bold">{t("General.totalQuantity")}</td>
                <td>{totalQuantity}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t("General.totalBeforeDiscount")}</td>
                <td>{getGreekCurrencyFormat(totalBeforeDiscount)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t("General.totalDiscount")}</td>
                <td>{getGreekCurrencyFormat(totalDiscount)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t("General.totalNetValue")}</td>
                <td>{getGreekCurrencyFormat(totalNetValue)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t("General.totalVatValue")}</td>
                <td>{getGreekCurrencyFormat(totalVatAmount)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t("General.totalWithheldAmount")}</td>
                <td>{getGreekCurrencyFormat(withHoldTaxes)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t("General.totalTaxesAmount")}</td>
                <td>{getGreekCurrencyFormat(totalTaxes)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t("General.totalAmount")}</td>
                <td>{getGreekCurrencyFormat(totalAmount)}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t("General.paymentAmount")}</td>
                <td>{getGreekCurrencyFormat(paymentAmount)}</td>
              </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row className="disabled-page">
          <div style={{width: "100%"}}>
            <div className="text-muted mb-2"><i>{t("General.notes")}</i></div>
            <TextArea
                rows="4"
                placeholder={t("Placeholders.notes")}
                name="notes"
                value={BUYS.editBuys.notes}
                disabled={notEditableFields}
            />
          </div>
        </Row>
      <BuyPrintA4Greek ref={componentRefA4} data={printTemplateData}/>
      <BuyPrintA4English ref={componentRefA4En} data={printTemplateData}/>
    </div>
  )
}

export default ViewBuys;
