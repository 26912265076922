import React, {useEffect, useMemo, useState} from "react";
import {Col, Row} from "react-bootstrap";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import EditableTable from '../../../primer/Editable-Table';
import Dropdown from "../../../primer/Dropdown";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setSaveEditProductMovements} from "../../../../_reducers/activitySlice";
import {fetchAdvancedTypes, fetchProducts} from "../../../../_apis/api";
import {
    getDefaultOptionByValue,
    getGreekCurrencyFormat,
    getLastHighestPrefixNumber,
    getModeByInstallation,
    getNumberByInstallation,
    getOptionsByData,
    getPreNumberByInstallation,
    getSelectedInstallation, isEmpty,
    removeDuplicateObjects
} from "../../../../_helpers/commonFunctions";
import {
    setEditProductMovements,
    setProductMovementsEditProduct
} from "../../../../_reducers/DataPanel/Products/productsSlice";
import {
    getLabelByValue,
    getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import getMyData from "../../../../_helpers/sharedmydata";
import {preNumberGreek} from "../../../../_data/settings/advanced-types-search";
import LogTable from "../../../primer/LogTable";
import {useTranslation} from "react-i18next";
import {
    getDiscountExclusiveVat, getNetValue, getNetValueByTotalValue,
    getSalesTotals, getTaxes, getTotalValue,
    getUnitPrice,
    getVatPercent, getVatValue, salesDuplicateProductCheck
} from "../../../../_helpers/product-sales-calculations";
import axios from "axios";
import {vatCategoryData} from "../../../../_data/mydata/vatCategory";
import {AccountingView} from "../../../primer/AccountingView/AccountingView";
import CloudHooksModal from "../Cloud/Modal Components/CloudHooksModal";
import {measurementUnitsData} from "../../../../_data/mydata/measurementUnits";

export const EditProductMovementsGeneral = () => {
    const [t] = useTranslation("common");
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const company = useSelector(state => state.COMPANY_DATA.company)
    const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const [incrKey, setIncrKey] = useState(0);

    let possibleInnerMovements = [];
    if (installations.length > 0) {
        installations.forEach((inst) => {
            possibleInnerMovements.push({
                value: inst.installationMasterId,
                label: inst.type + " " + inst.address + " " + inst.city,
            })
        })
    }

    const productCodeColumns = [
        {
            label: t('ProductsMovementsNewEdit.smallTable.code'),
            name: "code",
        },
        {
            label: t('ProductsMovementsNewEdit.smallTable.productName'),
            name: "name",
        },
        {
            label: t('ProductsMovementsNewEdit.smallTable.alternativeName'),
            name: "alternateName",
        },
    ];

    const productMovementColumns = [
        {
            name: "No",
            field: "no",
            editable: false,
        },
        {
            name: t('ProductsMovementsNewEdit.table.productCode'),
            field: "code",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: productCodeColumns,
                    data: PRODUCTS_DATA.products,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t('ProductsMovementsNewEdit.table.productCodePlaceholder')
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t('ProductsMovementsNewEdit.table.productName'),
            field: "name",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: productCodeColumns,
                    data: PRODUCTS_DATA.products,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t('ProductsMovementsNewEdit.table.productNamePlaceholder')
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t('ProductsMovementsNew.table.mu'),
            field: 'muLabel',
            editable: false,
        },
        {
            name: t('ProductsMovementsNew.table.quantity'),
            field: 'quantity',
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "nosuffix",
                    maxLength: 10
                }
            }
        },
        {
            name: t('SalesNew.productTable.vat'),
            field: "vatPercentage",
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesNew.productTable.netValue'),
            field: "netValue",
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: t('SalesNew.productTable.vatValue'),
            field: "vat",
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: t('SalesNew.productTable.totalValue'),
            field: "totalValue",
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
    ];

    const requestData = {
        company: company.id,
        year: company.year.toString(),
    }

    // Options for Document Type Dropdown
    const allDocumentTypes = SETTINGS.settings.advancedTypes;
    const clientMovemnetDT = getObjectFromArrayOfObjects(allDocumentTypes, 'Product Movements', 'section');
    const documentTypesOptions = getOptionsByData(clientMovemnetDT, '_id', 'name')

    let selectedDocumentTypeData = [];
    let dtInstallationsOptions = [];

    let disabledFields = false;
    if (PRODUCTS_DATA.editProductMovements.documentType === undefined || PRODUCTS_DATA.editProductMovements.documentType === '') {
        disabledFields = true;
    } else {
        let dtData = getObjectFromArrayOfObjects(clientMovemnetDT, PRODUCTS_DATA.editProductMovements.documentType, '_id');
        if (dtData.length > 0) {
            selectedDocumentTypeData = dtData['0'];
            dtInstallationsOptions = getOptionsByData(selectedDocumentTypeData['documentNumbering'], "installationMasterId", "installation");
            dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
        }
    }

    const handleOnChange = (e, type = "default", ddname = '') => {
        if (!ACTIVITY.saveEditProductMovements) dispatch(setSaveEditProductMovements(true));

        let name;
        let value;

        if (type === 'dd' || type === 'ac') {
            value = e.value;
            name = ddname;
        } else if (type === 'dp') {
            value = e;
            name = ddname;
        } else {
            value = e.target.value;
            name = e.target.name;
        }

        let productData = Object.assign({}, PRODUCTS_DATA);
        let newProductMovementData = {};
        newProductMovementData[name] = value;

        if (name === 'documentType') {
            newProductMovementData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');

            let dtData = getObjectFromArrayOfObjects(clientMovemnetDT, value, '_id');
            if (dtData.length > 0) {
                selectedDocumentTypeData = dtData['0'];
                let selectedInstallation = getSelectedInstallation(selectedDocumentTypeData['documentNumbering']);
                // Selected Installation Fields on Document Type Select
                if (Object.keys(selectedInstallation).length > 0) {
                    newProductMovementData.installation = selectedInstallation.installation;
                    newProductMovementData.preNumber = selectedInstallation.preNumber;
                    // Logic For Higest Number
                    let installationNumber = selectedInstallation.number
                    let lastHigestNumber = getLastHighestPrefixNumber(PRODUCTS_DATA.productMovements, 'number', selectedInstallation.preNumber);
                    if (installationNumber > lastHigestNumber) {
                        newProductMovementData.number = installationNumber;
                    } else {
                        newProductMovementData.number = lastHigestNumber + 1;
                    }
                    newProductMovementData.installationMode = selectedInstallation.installationMode;
                } else {
                    newProductMovementData.installation = "";
                    newProductMovementData.preNumber = "";
                    newProductMovementData.number = "";
                    newProductMovementData.installationMode = "";
                }
            }
        }

        // Update Installation Field Values on Change
        if (name === 'installation') {
            if (productData.editProductMovements?.innerMovement) {
                if (productData.editProductMovements?.innerMovement === value) {
                    newProductMovementData.innerMovement = "";
                }
            }
            newProductMovementData.installationName = getLabelByValue(dtInstallationsOptions, value, 'value');
            newProductMovementData.preNumber = getPreNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installation');
            newProductMovementData.number = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installation');
            // newProductMovementData.number = getLastHighestPrefixNumber(PRODUCTS_DATA.productMovements, 'number', newProductMovementData.preNumber);
            // Logic For Highest Number
            let installationNumber = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installation')
            let lastHigestNumber = getLastHighestPrefixNumber(PRODUCTS_DATA.productMovements, 'number', newProductMovementData.preNumber);
            if (installationNumber > lastHigestNumber) {
                newProductMovementData.number = installationNumber;
            } else {
                newProductMovementData.number = lastHigestNumber + 1;
            }
            newProductMovementData.installationMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installation');
        }

        // Update Installation Field Values on Change
        if (name === 'preNumber') {
            // Logic For Highest Number
            let installationNumber = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installation')
            let lastHigestNumber = getLastHighestPrefixNumber(PRODUCTS_DATA.productMovements, 'number', newProductMovementData.preNumber);
            if (installationNumber > lastHigestNumber) {
                newProductMovementData.number = installationNumber;
            } else {
                newProductMovementData.number = lastHigestNumber + 1;
            }
        }

        dispatch(setEditProductMovements({...productData.editProductMovements, ...newProductMovementData}));
    }

    useEffect(() => {
        dispatch(fetchProducts(requestData))
        dispatch(fetchAdvancedTypes(requestData))
        // calculate sum of quantity
        const productQuantitySum = getSumOfDataValues(PRODUCTS_DATA.editProductMovements.products, 'quantity');
        dispatch(setEditProductMovements({...PRODUCTS_DATA.editProductMovements, sum: productQuantitySum}));
    }, [dispatch])

    const getSumOfDataValues = (data, field = 'sum') => {
        let sum = 0;
        if (data && data.length > 0) {
            data.forEach(item => {
                sum += +item[field] ?? 0;
            })
        }
        return sum;
    }
    const parentMydataActions = {
        insert: (e, data) => {
            if (e.key === "Enter") return;
            if (!ACTIVITY.saveEditProductMovements) dispatch(setSaveEditProductMovements(true));

            if (["name", "code"].includes(data.field)) {
                let cloneProducts = structuredClone(PRODUCTS_DATA.editProductMovements.products);
                const productData = e.row;
                cloneProducts.push({
                    _id: productData._id,
                    no: String(cloneProducts.length + 1),
                    id: String(cloneProducts.length + 1),
                    pid: productData._id,
                    name: productData.name,
                    code: productData.code,
                    mu: productData.measurementUnit,
                    muLabel: measurementUnitsData.find((el) => el.id === String(productData.measurementUnit)).name,
                    quantity: 1,
                    netValue: 0,
                    vat: 0,
                    totalValue: 0,
                    vatPercentage: isNaN(+productData.vat) ? 0 : +productData.vat,
                });
                dispatch(setEditProductMovements({
                    ...PRODUCTS_DATA.editProductMovements,
                    products: cloneProducts,
                    sum: getSumOfDataValues(cloneProducts, 'quantity'),
                    totalQuantity: getSumOfDataValues(cloneProducts, 'quantity'),
                    totalNetValue: getSumOfDataValues(cloneProducts, 'netValue'),
                    totalVatValue: getSumOfDataValues(cloneProducts, 'vat'),
                    totalAmount: getSumOfDataValues(cloneProducts, "totalValue"),
                }));
            }
        },
        update: (data, id, rowIndex, e) => {
            if (e.key === "Enter") return;
            if (!ACTIVITY.saveEditProductMovements) dispatch(setSaveEditProductMovements(true));
            const cloneProducts = structuredClone(PRODUCTS_DATA.editProductMovements.products);
            let findMatching = cloneProducts[rowIndex];

            if (e?.row) { // Its product advanced combo box
                const productData = e.row;
                findMatching._id = productData._id;
                findMatching.pid = productData._id;
                findMatching.name = productData.name;
                findMatching.code = productData.code;
                findMatching.mu = productData.measurementUnit;
                findMatching.muLabel = measurementUnitsData.find((el) => el.id === String(productData.measurementUnit)).name;
                findMatching.vatPercentage = isNaN(productData.vat) ? 0 : +productData.vat;
            } else {
                if (!isEmpty(data.netValue)) {
                    findMatching.netValue = +Number(data.netValue).toFixed(3);
                    findMatching.vat = +Number((findMatching.vatPercentage) / 100 * +findMatching.netValue).toFixed(2);
                    findMatching.totalValue = +Number(+findMatching.netValue + +findMatching.vat).toFixed(2);
                } else if (!isEmpty(data.totalValue)) {
                    if (findMatching.vatPercentage === 0) {
                        findMatching.totalValue = +Number(data.totalValue).toFixed(2);
                        findMatching.netValue = findMatching.totalValue;
                        findMatching.vat = 0;
                    } else {
                        findMatching.netValue = +Number(+data.totalValue / (1 + (+findMatching.vatPercentage / 100))).toFixed(2);
                        findMatching.vat = +Number(+data.totalValue - findMatching.netValue).toFixed(2);
                        findMatching.totalValue = +Number(findMatching.netValue + findMatching.vat).toFixed(2);
                    }
                } else if (!isEmpty(data.quantity)) {
                    findMatching.quantity = +Number(data.quantity).toFixed(2);
                }
            }

            dispatch(setEditProductMovements({
                ...PRODUCTS_DATA.editProductMovements,
                products: cloneProducts,
                sum: getSumOfDataValues(cloneProducts, 'quantity'),
                totalQuantity: getSumOfDataValues(cloneProducts, 'quantity'),
                totalNetValue: getSumOfDataValues(cloneProducts, 'netValue'),
                totalVatValue: getSumOfDataValues(cloneProducts, 'vat'),
                totalAmount: getSumOfDataValues(cloneProducts, "totalValue"),
            }));
        },
        selected: () => {},
        delete: (id, rowIndex) => {
            if (!ACTIVITY.saveEditProductMovements) dispatch(setSaveEditProductMovements(true));

            const cloneProducts = structuredClone(PRODUCTS_DATA.editProductMovements.products);
            cloneProducts.splice(rowIndex, 1);
            cloneProducts.forEach((row, idx) => {
                row.no = String(idx + 1);
                row.id = String(idx + 1);
            })
            dispatch(setEditProductMovements({
                ...PRODUCTS_DATA.editProductMovements,
                products: cloneProducts,
                sum: getSumOfDataValues(cloneProducts, 'quantity'),
                totalQuantity: getSumOfDataValues(cloneProducts, 'quantity'),
                totalNetValue: getSumOfDataValues(cloneProducts, 'netValue'),
                totalVatValue: getSumOfDataValues(cloneProducts, 'vat'),
                totalAmount: getSumOfDataValues(cloneProducts, "totalValue"),
            }))
        }
    }

    const selectedDocumentType = getDefaultOptionByValue(documentTypesOptions, PRODUCTS_DATA.editProductMovements.documentType, 'value', 'label', 'value');
    const selectDocumentInstallation = getDefaultOptionByValue(dtInstallationsOptions, PRODUCTS_DATA.editProductMovements.installation, "value", "label", "value");
    const selectedPreNumber = getDefaultOptionByValue(preNumberGreek, PRODUCTS_DATA.editProductMovements.preNumber, 'value', 'label', 'value');
    let productMovementsTableData = PRODUCTS_DATA.editProductMovements.products;
    let productTotals = useMemo(() => getSalesTotals(PRODUCTS_DATA.editProductMovements.products, SETTINGS), [PRODUCTS_DATA.editProductMovements.products]);
    let totalQuantity = productTotals.totalQuantity;
    let totalNetValue = productTotals.totalNetValue;
    let totalAmount = productTotals.totalAmount;
    let totalVatAmount = productTotals.totalVatValue;

    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [productMovementsTableData, PRODUCTS_DATA.products])

    return (
        <React.Fragment>
            <div className="product-form position-relative">
                <AccountingView document={PRODUCTS_DATA.editProductMovements}/>
                {disabledFields && PRODUCTS_DATA.editProductMovements.documentType === "" &&
                    <div className="overlay_w">{t('ProductsMovementsNewEdit.message')}</div>}
                <Row>
                    <Col sm={12} md={3} className="showWithOverlay">
                        <Dropdown
                            label={t('ProductsMovementsNewEdit.documentTypes')}
                            required={true}
                            name="documentType"
                            value={Object.keys(selectedDocumentType).length !== 0 ? selectedDocumentType : ''}
                            enabledValue={true}
                            disabled={true}
                            options={documentTypesOptions}
                            onChange={(e) => handleOnChange(e, 'dd', 'documentType')}
                        />
                    </Col>
                    <Col sm={12} md={9} className="text-right">
                        <CloudHooksModal
                            documentId={PRODUCTS_DATA.editProductMovements._id}
                            section={"Product Movements"}
                            className={"mr-2 mt-2"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3}>
                        <Dropdown
                            label={t('ProductsMovementsNewEdit.installation')}
                            name="installation"
                            value={Object.keys(selectDocumentInstallation).length !== 0 ? selectDocumentInstallation : ''}
                            enabledValue={true}
                            disabled={true}
                            options={dtInstallationsOptions}
                            onChange={(e) => handleOnChange(e, 'dd', 'installation')}
                        />
                    </Col>
                    <Col sm={12} md={2}>
                        <Dropdown
                            label={t('ProductsMovementsNewEdit.preNumber')}
                            name="preNumber"
                            value={selectedPreNumber}
                            enabledValue={true}
                            disabled={true}
                            options={preNumberGreek}
                            onChange={(e) => handleOnChange(e, 'dd', 'preNumber')}
                        />
                    </Col>
                    <Col sm="12" md="3">
                        <Input
                            className="mb-3 w-100"
                            label={t('ProductsMovementsNewEdit.number.number')}
                            name="number"
                            charLimit={1000}
                            disabled={true}
                            value={PRODUCTS_DATA.editProductMovements.number}
                            placeholder={t('ProductsMovementsNewEdit.number.placeholder')}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </Col>
                    <Col sm="12" md="4">
                        <DateBox
                            classes="w-100 mb-3"
                            label={t('ProductsMovementsNewEdit.date')}
                            name="date"
                            selected={PRODUCTS_DATA.editProductMovements.date ? new Date(PRODUCTS_DATA.editProductMovements.date) : ''}
                            enabledValue={true}
                            onChange={(e) => handleOnChange(e, 'dp', 'date')}
                        />
                    </Col>
                </Row>
                {(possibleInnerMovements.length > 1 && PRODUCTS_DATA.editProductMovements?.isCensus !== "active") && (
                    <Row>
                        <Col md={3} className={"mb-3"}>
                            <Dropdown
                                name="innerMovement"
                                className={"mb-0"}
                                label={t('ProductsMovementsNew.innerMovement')}
                                key={Math.random()}
                                defaultValue={possibleInnerMovements?.filter((el) => el.value === PRODUCTS_DATA.editProductMovements.innerMovement)[0]}
                                options={[{
                                    label: "Κενό",
                                    value: ""
                                }].concat(possibleInnerMovements.filter((el) => el.value !== selectDocumentInstallation.value))}
                                onChange={(e) => handleOnChange(e, 'dd', 'innerMovement')}
                            />
                            <span className={"text-muted"}
                                  style={{margin: 0}}>{t('ProductsMovementsNew.innerMovementMessage')}</span>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col sm="12">
                        <Input
                            className="mb-3 w-100"
                            label={t('ProductsMovementsNewEdit.reason.reason')}
                            name="reason"
                            value={PRODUCTS_DATA.editProductMovements.reason}
                            onChange={(e) => handleOnChange(e)}
                            placeholder={t('ProductsMovementsNewEdit.reason.placeholder')}
                        />
                    </Col>
                </Row>
                <hr className="mb-4"/>
                <Row>
                    <div className="et-table-ui">
                        {
                            ACTIVITY.saveEditProductMovements &&
                            productMovementsTableData &&
                            productMovementsTableData.length <= 0 &&
                            <div className="text-danger">{t('ProductsMovementsNewEdit.table.required')}</div>
                        }
                        <EditableTable
                            tableName="Edit Product Movements Products" // Do not change name
                            key={"Edit Product Movements Products" + incrKey}
                            allowActions={true}
                            onUpdate={parentMydataActions}
                            allowInsertRow="true"
                            columns={productMovementColumns}
                            enableNewRowCheckbox={true}
                            data={productMovementsTableData}
                        />
                    </div>
                </Row>
                <hr/>
                <Row>
                    <Col sm="12" md="8"></Col>
                    <Col sm="12" md="4" className="d-flex justify-content-end">
                        <span className="mr-3 fw-bold">{t('SalesNew.totalsTable.title')}</span>
                        <table className="table table-bordered mb-3">
                            <tbody>
                            <tr>
                                <td className="fw-bold">{t('SalesNew.totalsTable.totalQuantity')}</td>
                                <td>{totalQuantity}</td>
                            </tr>
                            <tr>
                                <td className="fw-bold">{t('SalesNew.totalsTable.totalNetValue')}</td>
                                <td>{getGreekCurrencyFormat(totalNetValue)}</td>
                            </tr>
                            <tr>
                                <td className="fw-bold">{t('SalesNew.totalsTable.totalVatAmount')}</td>
                                <td>{getGreekCurrencyFormat(totalVatAmount)}</td>
                            </tr>
                            <tr>
                                <td className="fw-bold">{t('SalesNew.totalsTable.totalAmount')}</td>
                                <td>{getGreekCurrencyFormat(totalAmount)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <div style={{width: "100%"}}>
                        <div className="text-muted mb-2"><i>{t('ProductsMovementsNewEdit.movementsNotes')}</i></div>
                        <TextArea
                            rows="4"
                            placeholder={t('ProductsMovementsNewEdit.addMovementsNotes')}
                            name="notes"
                            value={PRODUCTS_DATA.editProductMovements.notes}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                </Row>
            </div>
        </React.Fragment>
    );
};

export const ProductMovementsEditLog = () => {
    const {t} = useTranslation('common');
    const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.productMovementLog')}</i></div>
            <LogTable itemId={PRODUCTS_DATA.editProductMovements._id}/>
        </React.Fragment>
    )
};
