import {createSlice} from "@reduxjs/toolkit";
import {suppliersCategoryData} from "../../../_data/suppliers/suppliers-settings";
import * as moment from "moment";
import {getCurrentUser} from "../../../_helpers/commonFunctions";

const currentDate = moment().format('DD/MM/YYYY');
const currentTime = moment().format('H:mm');
const currentUser = getCurrentUser();
const cUserName = currentUser?.name;
let date = new Date();
const currentMonth = moment().format("MM");
const current = new Date();

const newInstallationInitialValue = {
    no: 1,
    type: '',
    address: '',
    DOY: '',
    city: '',
    id: 1,
    vatCategory: '',
    vatReason: '',
    customVat: ''
}

const newSupplierInitialValue = {
    category: "",
    categoryName: "",
    systemCode: "",
    manualCode: "",
    name: "",
    smallName: "",
    vatCategory: "Normal VAT",
    vatReason: "",
    customVat: "",
    vatNumber: "",
    DOY: "",
    activity: "",
    amka: "",
    country: "",
    address: "",
    city: "",
    postalCode: "",
    email: "",
    gemhNo: "",
    webpage: "",
    phone: "",
    contactPoints: [{
        no: 1,
        id: 1,
        name: "",
        mobile: "",
        landline: "",
        otherPhone: "",
        email: "",
        contactNotes: ""
    }],
    supplierNotes: "",
    informationNotes: "",
    notesNotes: "",
    installations: [newInstallationInitialValue],
    communicationHistory: [],
    information: [],
    notes: {},
    emron: "",
    accounting: "",
    bypassAccounting: "",
    suppliersInfo: [],
    isActive: 'active',
    balance: 0,
}

export const supplierSettingsInitialValues = {
    categories: suppliersCategoryData,
    googleCalendarApi: "",
    supplierCommunicationHistory: "",
    suppliersInfo: [],
    type: "suppliersSettings",
    isActive: "active"
}

const supplierMovementsInitialValues = {
    documentType: '',
    documentTypeName: '',
    document_file: '',
    number: '',
    date: date,
    reason: '',
    sum: 0,
    suppliers: [],
    notes: '',
}

const priceListInitialValues = {
    name: "",
    price: "",
    supplierList: [],
    notes: "",
}

const newContactPointInitialValue = {
    no: 1,
    id: 1,
    name: "",
    mobile: "",
    landline: "",
    otherPhone: "",
    email: "",
    // acceptsNotifications: false,
    contactNotes: ""
}

const newInformationInitialValue = {
    no: 1,
    description: '',
    details: '',
    date: currentDate.toString(),
    time: currentTime.toString(),
    lastEditedBy: cUserName,
}

const supplierMovementsNewSupplierInitialValue = {
    no: 1,
    supplierId: "",
    name: "",
    code: "",
    measurementUnit: "",
    quantity: 0,
}

const newCommunicationHistoryInitialValue = {
    no: 1,
    id: 1,
    date: new Date(),
    time: currentTime.toString(),
    subject: "",
    details: "",
    followUpDate: "",
    createCalenderEvent: false,
    done: false
}

const newSupplierPaymentInitialValue = {
    documentType: "",
    documentTypeName: "",
    installation: "",
    preNumber: "",
    number: "",
    numberingMode: "manual",
    date: null,
    paymentType: "",
    paymentTypeName: "",
    supplier: "",
    supplierName: "",
    vatNumber: "",
    address: "",
    country: "",
    phone: "",
    email: "",
    reason: "",
    paymentNotes: "",
    amount: "0",
    posDetails: [{
        no: 1,
        id: 1,
        name: "",
        registrationNumber: "",
        amount: "0",
        notes: ""
    }],
    bankDetails: [{
        no: 1,
        id: 1,
        name: "",
        accountNumber: "",
        amount: "0",
        notes: ""
    }],
    chequeDetails: [{
        no: 1,
        id: 1,
        name: "",
        registrationNumber: "",
        amount: "0",
        notes: ""
    }],
}


const newSupplierEmronPasswordInitialValue = {
    no: 1,
    id: 1,
    pid: "",
    type: "",
    typeName: "",
    username: "",
    password: "",
    notes: "",
    isActive: 'active',
}

const newSupplierEmronStockholderInitialValue = {
    no: 1,
    id: 1,
    name: "",
    surname: "",
    vat: "",
    position: "",
    percentage: "",
    stockValue: 0,
    dateFrom: "",
    dateTo: "",
    isActive: 'active',
}

const newCategoryInitialValue = {
    _id: 1,
    category: '',
    mask: 'CCM-0001',
    maskCode: '',
    maskCodePrefix: 'CCM',
    maskCodeCounter: 1,
    isActive: 'active',
    type: 'supplier',
}

const initialState = {
    supplierSettingsInitialValues: supplierSettingsInitialValues,
    newSuppliersSettings: supplierSettingsInitialValues,
    suppliersSettings: supplierSettingsInitialValues,
    suppliersListData: [],
    suppliersPayment: [],
    supplierPayments: [],
    newSupplierPaymentInitialValue: newSupplierPaymentInitialValue,
    newSupplierPayment: newSupplierPaymentInitialValue,
    editSupplierPayment: newSupplierPaymentInitialValue,
    loading: false,
    hasErrors: false,
    suppliers: [],
    installations: [],
    newSupplierInitialValue: newSupplierInitialValue,
    newSupplier: newSupplierInitialValue,
    editSupplier: newSupplierInitialValue,
    newContactPointInitialValue: newContactPointInitialValue,
    newContactPoint: newContactPointInitialValue,
    editContactPoint: newContactPointInitialValue,
    newInstallationInitialValue: newInstallationInitialValue,
    newInstallation: newInstallationInitialValue,
    newInformationInitialValue: newInformationInitialValue,
    newInformation: newInformationInitialValue,
    newNotesInitialValue: newInformationInitialValue,
    newNotes: {},
    newCommunicationHistoryInitialValue: newCommunicationHistoryInitialValue,
    newCommunicationHistory: newCommunicationHistoryInitialValue,
    searchSuppliers: [],
    totalItemsSSS: 0,
    perPageSSS: 0,
    searchSupplierMovements: [],
    supplierReportBalanceData: [],
    supplierMovementsClientList: [],
    totalItemsSMS: 0,
    perPageSMS: 0,
    totalItemsSMSS: 0,
    perPageSMSS: 0,
    searchSupplierPayments: [],
    perPageSPS: 0,
    totalItemsSPS: 0,
    selectedStartDateFrom: null,
    selectedStartDateTo: null,
    selectedDateBalance: current,
    selectedZero: false,
    selectedInactive: false,
    selectedZeroBalance: false,
    selectedInactiveBalance: false,
    selectedFilterBy: currentMonth,
    searchSuppliersMovementsReport: [],
    searchCommunicationHistory: [],
    selectedSupplier: {},
    selectedSupplierFrom: {},
    supplierMovementsSearchByBalance: {},
    selectedSupplierTo: {},
    selectedBalanceSupplierFrom: {},
    selectedBalanceSupplierTo: {},
    selectedSalesSupplierFrom: {},
    selectedSalesSupplierTo: {},
    selectedSalesBalanceSupplierFrom: {},
    selectedSalesBalanceSupplierTo: {},
    selectedSalesGraphicSupplierFrom: {},
    selectedSalesGraphicSupplierTo: {},
    selectedBuysSupplierFrom: {},
    selectedBuysSupplierTo: {},
    selectedBuysBalanceSupplierFrom: {},
    selectedBuysBalanceSupplierTo: {},
    selectedBuysGraphicSupplierFrom: {},
    selectedBuysGraphicSupplierTo: {},
    selectedSupplierMovement: {},
    supplierTemplates: [],
    selectedSupplierTemplate: {},
    supplierMovements: [],
    supplierMovementsTemplates: [],
    selectedSupplierMovementsTemplate: {},
    supplierMovementsNewSupplierInitialValue: supplierMovementsNewSupplierInitialValue,
    supplierMovementsNewSupplier: supplierMovementsNewSupplierInitialValue,
    supplierPriceListTemplates: [],
    selectedSupplierPriceListTemplate: {},
    supplierMovementReportData: [],
    submitting: false,
    selectedCategoryData: {},
    supplierMovementsInitialValues: supplierMovementsInitialValues,
    supplierMovementsSuppliers: [],
    newSupplierMovements: supplierMovementsInitialValues,
    editSupplierMovements: {},
    priceListInitialValues: priceListInitialValues,
    priceListSuppliers: [],
    newPriceList: priceListInitialValues,
    newQuickSupplier: newSupplierInitialValue,
    supplierEmron: {
        password: [],
        capital: '',
        stockholders: [],
        supplierId: '',
    },
    newSupplierEmronPasswordInitialValue: newSupplierEmronPasswordInitialValue,
    newSupplierEmronPassword: newSupplierEmronPasswordInitialValue,
    editSupplierEmronPassword: newSupplierEmronPasswordInitialValue,
    newSupplierEmronStockholderInitialValue: newSupplierEmronStockholderInitialValue,
    newSupplierEmronStockholder: newSupplierEmronStockholderInitialValue,
    editSupplierEmronStockholder: newSupplierEmronStockholderInitialValue,
    supplierPaymentsTemplates: [],
    selectedSupplierPaymentsTemplate: {},
    newCategoryInitialValue: newCategoryInitialValue,
    newCategory: newCategoryInitialValue,
    categories: suppliersCategoryData,

    // Supplier Balance Report
    reportSupplierBalanceRequestData: {
        page: 1,
        date_to: moment().format("DD/MM/YYYY"),
        supplier: "",
        includeInactive: false,
        includeZeroBalance: false,
    },
    reportSupplierBalanceResponseData: {
        data: [],
        totalPages: 0,
        totalBalance: 0,
    },

    // Supplier Movements Report
    supplierMovementsReportRequestData: {
        page: 1,
        date_from: "",
        date_to: "",
        supplierName: "",
        supplier_zero: "inactive",
        supplier_inactive: "inactive",
        showNotes: "inactive",
    },
    supplierMovementsReportResponseData: {
        totalPages: 0,
        pageData: [],
        pageSupplierData: {},
    },

    // Supplier Isozygio Report
    supplierIsozygioReportRequestData: {
        firstLoad: true, // For date setup
        zeroBalanceSuppliers: false,
        page: 1,
        date_from: "",
        date_to: "",
        supplierName: "",
        supplierId: "",
    },
    supplierIsozygioReportResponseData: {
        totalPages: 0,
        data: [],
    },
    supplierIsozygioReportTotals: [],
}

// Supplier Slice
export const SuppliersSlice = createSlice({
    name: "supplier",
    initialState,
    reducers: {
        setSupplierNewCategory: (state, {payload}) => {
            state.newCategory = payload;
        },

        resetSupplierNewCategory: (state) => {
            state.newCategory = state.newCategoryInitialValue;
        },

        setSuppliers: (state, {payload}) => {
            state.suppliers = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setNewSupplier: (state, {payload}) => {
            state.newSupplier = payload;
        },

        resetNewSupplier: (state) => {
            state.newSupplier = state.newSupplierInitialValue;
        },

        setNewSupplierStatus: (state, {payload}) => {
            state.newSupplier.isActive = payload;
        },

        setEditSupplier: (state, {payload}) => {
            state.editSupplier = payload;
        },

        setEditSupplierStatus: (state, {payload}) => {
            state.editSupplier.isActive = payload;
        },

        setNewContactPoint: (state, {payload}) => {
            state.newContactPoint = payload;
        },

        setEditContactPoint: (state, {payload}) => {
            state.editContactPoint = payload;
        },

        setNewInstallations: (state, {payload}) => {
            state.newInstallation = payload;
        },

        resetNewInstallations: (state) => {
            state.newInstallation = state.newInstallationInitialValue;
        },

        setNewInformation: (state, {payload}) => {
            state.newInformation = payload;
        },

        setNewNotes: (state, {payload}) => {
            state.newNotes = payload;
        },

        setNewCommunicationHistory: (state, {payload}) => {
            state.newCommunicationHistory = payload;
        },

        resetNewCommunicationHistory: (state) => {
            state.newCommunicationHistory = state.newCommunicationHistoryInitialValue;
        },

        setSearchSuppliers: (state, {payload}) => {
            state.searchSuppliers = payload;
            //  state.loading = false;
            //state.hasErrors = false;
        },

        setSearchSupplierMovements: (state, {payload}) => {
            state.searchSupplierMovements = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setSelectedSupplier: (state, {payload}) => {
            state.selectedSupplier = payload;
        },

        setSelectedSupplierTemplate: (state, {payload}) => {
            state.selectedSupplierTemplate = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setNewSupplierMovements: (state, {payload}) => {
            state.newSupplierMovements = payload;
        },

        setEditSupplierMovements: (state, {payload}) => {
            state.editSupplierMovements = payload;
        },

        setSelectedSupplierMovementsTemplate: (state, {payload}) => {
            state.selectedSupplierMovementsTemplate = payload;
            state.loading = false;
            state.hasErrors = false;
        },

        setNewPriceList: (state, {payload}) => {
            state.newPriceList = payload;
        },

        setSuppliersSettings: (state, {payload}) => {
            state.suppliersSettings = payload;
            state.categories = payload.categories;
        },

        setSupplierMovementsSearchByBalance: (state, {payload}) => {
            state.supplierMovementsSearchByBalance = payload;
        },

        setSuppliersPayment: (state, {payload}) => {
            state.suppliersPayment = payload;
        },

        setNewSupplierPayment: (state, {payload}) => {
            state.newSupplierPayment = payload;
        },

        resetNewSupplierPayment: (state) => {
            state.newSupplierPayment = state.newSupplierPaymentInitialValue;
        },

        softResetNewSupplierPayment: (state, {payload}) => {
            state.newSupplierPayment = {...state.newSupplierPaymentInitialValue, ...payload};
        },

        setEditSupplierPayment: (state, {payload}) => {
            state.editSupplierPayment = payload;
        },

        // Supplier Isozygio Report
        setSupplierIsozygioReportRequestData: (state, {payload}) => {
            state.supplierIsozygioReportRequestData = payload;
        },
        setSupplierIsozygioReportResponseData: (state, {payload}) => {
            state.supplierIsozygioReportResponseData = payload;
        },
        setSupplierIsozygioReportTotals: (state, {payload}) => {
            state.supplierIsozygioReportTotals = payload;
        },

        setSupplierPaymentsSearch: (state, {payload}) => {
            state.searchSupplierPayments = payload;
        },


        setTotalItemsSPS: (state, {payload}) => {
            state.totalItemsSPS = payload;
        },


        setperPageSPS: (state, {payload}) => {
            state.perPageSPS = payload;
        },

        setSelectedStartDateFrom: (state, {payload}) => {
            state.selectedStartDateFrom = payload;
        },
        setSelectedStartDateTo: (state, {payload}) => {
            state.selectedStartDateTo = payload;
        },
        setSelectedDateBalance: (state, {payload}) => {
            state.selectedDateBalance = payload;
        },
        setSelectedZero: (state, {payload}) => {
            state.selectedZero = payload;
        },
        setSelectedInactive: (state, {payload}) => {
            state.selectedInactive = payload;
        },
        setSelectedZeroBalance: (state, {payload}) => {
            state.selectedZeroBalance = payload;
        },
        setSelectedInactiveBalance: (state, {payload}) => {
            state.selectedInactiveBalance = payload;
        },
        setSelectedFilterBy: (state, {payload}) => {
            state.selectedFilterBy = payload;
        },
        setSearchSupplierCommunicationHistory: (state, {payload}) => {
            state.searchCommunicationHistory = payload;
            state.editSupplier = {...state.editSupplier, communicationHistory: payload};
        },

        resetSearchSupplierCommunicationHistory: (state) => {
            state.searchCommunicationHistory = [];
            state.editSupplier = {...state.editSupplier, communicationHistory: []};
        },

        setTotalItemsSMSS: (state, {payload}) => {
            state.totalItemsSMSS = payload;
        },

        setperPageSMSS: (state, {payload}) => {
            state.perPageSMSS = payload;
        },
        setTotalItemsSSS: (state, {payload}) => {
            state.totalItemsSSS = payload;
        },

        setperPageSSS: (state, {payload}) => {
            state.perPageSSS = payload;
        },

        // Supplier Balance Report
        setSupplierBalanceReportRequestData: (state, {payload}) => {
            state.reportSupplierBalanceRequestData = payload;
        },
        setSupplierBalanceReportResponseData: (state, {payload}) => {
            state.reportSupplierBalanceResponseData = payload;
        },

        // Supplier Movements Report
        setSupplierMovementsReportRequestData: (state, {payload}) => {
            state.supplierMovementsReportRequestData = payload;
        },
        setSupplierMovementsReportResponseData: (state, {payload}) => {
            state.supplierMovementsReportResponseData = payload;
        }
    },
});

export const {
    setSuppliers,
    setNewSupplier,
    resetNewSupplier,
    setSearchSuppliers,
    setSearchSupplierMovements,
    setSelectedSupplier,
    setSelectedSupplierTemplate,
    setSelectedSupplierMovementsTemplate,
    setSuppliersSettings,
    setNewContactPoint,
    setEditContactPoint,
    setNewInstallations,
    resetNewInstallations,
    setNewSupplierMovements,
    setNewCommunicationHistory,
    resetNewCommunicationHistory,
    setEditSupplierMovements,
    setEditSupplier,
    setSuppliersPayment,
    setNewSupplierPayment,
    resetNewSupplierPayment,
    setNewSupplierStatus,
    setEditSupplierStatus,
    setSupplierPaymentsSearch,
    setTotalItemsSPS,
    setperPageSPS,
    setEditSupplierPayment,
    setSearchSupplierCommunicationHistory,
    resetSearchSupplierCommunicationHistory,
    setSupplierMovementsSearchByBalance,
    setSupplierNewCategory,
    resetSupplierNewCategory,
    setTotalItemsSMSS,
    setperPageSMSS,
    setperPageSSS,
    setTotalItemsSSS,
    softResetNewSupplierPayment,

    // Supplier Balance Report
    setSupplierBalanceReportRequestData,
    setSupplierBalanceReportResponseData,

    // Supplier Isozygio Report
    setSupplierIsozygioReportRequestData,
    setSupplierIsozygioReportResponseData,
    setSupplierIsozygioReportTotals,

    // Supplier Movements Report
    setSupplierMovementsReportRequestData,
    setSupplierMovementsReportResponseData,
} = SuppliersSlice.actions;
