import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import Dropdown from "../../../../primer/Dropdown";
import Input from '../../../../common/Input';
import {
  getCountryOptions, getCurrentUser,
  getDefaultOptionByValue,
  getDOYOptions, getGeneratedMaskCodeCLient,
  getMainInstallation,
  getOptionsByData,
  getVatCategoryAbroadOptions,
  getVatCategoryOptions,
  isValidEmail
} from '../../../../../_helpers/commonFunctions';
import {useDispatch, useSelector} from "react-redux";
import {setSaveEditSupplier} from "../../../../../_reducers/activitySlice";
import {setEditSupplier, setEditSupplierStatus, setEditContactPoint} from '../../../../../_reducers/DataPanel/Suppliers/supplierSlice';
import getMyData from '../../../../../_helpers/sharedmydata';
import TextArea from '../../../../common/TextArea';
import EditableTable from '../../../../primer/Editable-Table';
import {
  findObjectFromArrayOfObjects,
  getLabelByValue,
  getObjectFromArrayOfObjects
} from '../../../../../_helpers/helperFunctions';
import {toast} from 'react-toastify';
import Checkbox from '../../../../common/CheckBox';
import {fetchSuppliersSettings} from '../../../../../_apis/api';
import axios from "axios";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import LogTable from "../../../../primer/LogTable";

const EditSupplierGeneral = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation("common");
  const SUPPLIERS_DATA = useSelector( (state) => state.SUPPLIERS_DATA );
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const company = useSelector(state => state.COMPANY_DATA.company)
  const supplierCategories = getObjectFromArrayOfObjects(SUPPLIERS_DATA.suppliersSettings.categories, 'active', 'isActive');
  const [supplierCategoriesOption, setSupplierCategoriesOption] = useState([]);
  const zeroVatReasonOptions = getMyData({type: 'zerovatreason'});
  let vatCategoryList = (SUPPLIERS_DATA.editSupplier.categoryName === "Supplier within E.U." || SUPPLIERS_DATA.editSupplier.categoryName === "Supplier outside E.U." || (SUPPLIERS_DATA.editSupplier.categoryName === "Retail" && SUPPLIERS_DATA.editSupplier.country !== "GR")) ? getVatCategoryAbroadOptions() : getVatCategoryOptions();

  useEffect(() => {
    let retailGroup = ["1"];
    if(retailGroup.includes(SUPPLIERS_DATA.editSupplier.category)) {
      setSupplierCategoriesOption(getOptionsByData(supplierCategories.filter((el) => retailGroup.includes(el._id)), '_id', 'category'));
    } else {
      setSupplierCategoriesOption(getOptionsByData(supplierCategories.filter((el) => !retailGroup.includes(el._id)), '_id', 'category'));
    }
  }, [SUPPLIERS_DATA.editSupplier])

  const isValidCustomVat = (value) => {
    let error;
    if (+value > 100) {
      error = 'Can not be greater than 100.';
    }
    return error;
  }
  const supplierContactPointsColumns = [
    {
      name: t("General.no"),
      field: "no",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.contactName"),
      field: "name",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.mobilePhone"),
      field: "mobile",
      editable:true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.landline"),
      field: "landline",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.otherPhone"),
      field: "otherPhone",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.email"),
      field: "email",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t("General.contactNotes"),
      field: "contactNotes",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
  ]
  useEffect(() => {
    dispatch(fetchSuppliersSettings({
      company:company.id,
      year:company.year.toString(),
      isActive:"active"
    }));
  }, [dispatch]);
  const currentUser = getCurrentUser();
  useEffect( () => {
    console.log(currentUser.lang)
    setTableColumns(supplierContactPointsColumns.slice());
  },[i18n.language])
  const [tableColumns, setTableColumns] = useState(supplierContactPointsColumns.slice())
  const [inActiveSupplierWarning, showInActiveSupplierWarnning] = useState(false);
  const [showErrors, setShowErrors] = useState(false)
  // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.: Select Greece, Accept only Greece, show only Greece
  let countriesList = getCountryOptions("greece");
  if (SUPPLIERS_DATA.editSupplier.categoryName === "Supplier within E.U.") {
    // Update country list for inside EU.
    // Supplier inside E.U., show only countries within E.U. except Greece
    countriesList = getCountryOptions("insideEU");
  } else if (SUPPLIERS_DATA.editSupplier.categoryName === "Supplier outside E.U.") {
    // Update country list for outside EU
    // outside E.U., show only countries outside E.U.
    countriesList = getCountryOptions("outsideEU");
  } else if(SUPPLIERS_DATA.editSupplier.categoryName === "Retail"){
    countriesList = (getCountryOptions('all'));
  } else {
    // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:
    // Select Greece, Accept only Greece, show only Greece
    countriesList = getCountryOptions("greece");
  }
  let disabledFields = false;
  if (SUPPLIERS_DATA.editSupplier.category === undefined || SUPPLIERS_DATA.editSupplier.category === '') {
    disabledFields = true;
  }
  const apiURL2 = process.env.REACT_APP_API_URL2;

  const fetchViesDetails = async (vat, country) => {
    let jsonData = {
      vatNumber: vat,
      country: country
    }
    if(vat === "" || vat === undefined){
      toast.error("Please input a vat Number to check.");
    } else if(country === "" || country === undefined){
      toast.error("Please select a country for VIES check.");
    } else {
      const abort = axios.CancelToken.source();
      const timer = setTimeout(() => abort.cancel(`timeout`), 10000);
      await axios.post(apiURL2 + `/supplier/fetch-vies-details/`, JSON.stringify(jsonData), {headers: {'Content-Type': 'application/json'}})
          .then((res) => {
            if (res.data && res.data.status === "200") {
              if (!ACTIVITY.saveEditSupplier) dispatch(setSaveEditSupplier(true));

              clearTimeout(timer)
              let companyDetails = res.data.data;
              if(companyDetails.valid === true) {
                let editSupplier = Object.assign({}, SUPPLIERS_DATA.editSupplier);
                editSupplier.name = companyDetails?.name !== undefined ? companyDetails?.name : "";
                let address = companyDetails?.address.split(",");
                editSupplier.postalCode = address[2] !== undefined ? address[2].split(" ")[1] : "";
                editSupplier.address =  address[1] !== undefined ? address[1] : "";
                editSupplier.city = address[2] !== undefined ? address[2].split(" ")[2] : "";
                editSupplier.vatCategory = 'Zero VAT';
                editSupplier.vatReason = "14";
                let updatedContactPoints = SUPPLIERS_DATA.editSupplier.contactPoints.slice();
                let newContacts = {
                  name: editSupplier.name,
                  email: editSupplier.email,
                  mobile: editSupplier.phone,
                };
                updatedContactPoints["0"] = {...updatedContactPoints["0"], ...newContacts};
                let defaultInstallation = [
                  {
                    id: 0,
                    no: 0,
                    type: getMainInstallation()?.value,
                    address: editSupplier.address,
                    DOY: editSupplier.DOY,
                    city: editSupplier.city,
                    county: editSupplier.county,
                    vatCategory: editSupplier.vatCategory,
                    vatReason: editSupplier.vatReason,
                    customVat: editSupplier.customVat,
                    postalCode: editSupplier.postalCode
                  },
                ];
                dispatch(setEditSupplier({...editSupplier, contactPoints: updatedContactPoints, installations: defaultInstallation}));
                toast.success(res.data.message);
              } else {
                toast.error("VAT Number with this country was not found on VIES");
              }
            } else {
              console.log(res)
              toast.error(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response?.status === 400) {
              alert({
                message: error.response.data?.Error ? error.response.data?.Error : error?.message === "timeout" ? "Timeout. Please try again later!" : error.code,
                type: 'warning',
              })
            }
          });
    }
  };
  const fetchTaxisNetDetails = async (data) => {
    let jsonData = {
      vatNumber: data
    }
    const abort = axios.CancelToken.source();
    const timer = setTimeout(() => abort.cancel(`timeout`), 10000);
    await axios.post(apiURL2 + `/supplier/fetch-vat-details/`, JSON.stringify(jsonData), { headers: { 'Content-Type': 'application/json'}})
        .then((res) => {
          if (res.data && res.data.status === "200") {
            if (!ACTIVITY.saveEditSupplier) dispatch(setSaveEditSupplier(true));

            clearTimeout(timer)
            let companyDetails = res.data.data;
            let editSupplier = Object.assign({}, SUPPLIERS_DATA.editSupplier);
            editSupplier.name = companyDetails?.name !== undefined ? companyDetails?.name : "";
            editSupplier.smallName = companyDetails?.smallName !== undefined ? companyDetails?.smallName : "";
            editSupplier.activity = companyDetails?.activity !== undefined ? companyDetails?.activity : "";
            editSupplier.postalCode = companyDetails?.tk !== undefined ? companyDetails?.tk : "";
            editSupplier.address =  companyDetails?.address !== undefined ? companyDetails?.address : "";
            editSupplier.DOY = getDefaultOptionByValue(getDOYOptions(), Number(companyDetails?.doyCode), "value", "label", "value").value;
            editSupplier.city = companyDetails?.city !== undefined ? companyDetails?.city : "";
            if(companyDetails?.vatCategory === 'Y'){
              editSupplier.vatCategory = 'Normal VAT';
            }
            let updatedContactPoints = SUPPLIERS_DATA.editSupplier.contactPoints.slice();
            let newContacts = {
              name: editSupplier.name,
              email: editSupplier.email,
              mobile: editSupplier.phone,
            };
            updatedContactPoints["0"] = {
              ...updatedContactPoints["0"],
              ...newContacts,
            };
            let defaultInstallation = [
              {
                id: 0,
                no: 0,
                type: getMainInstallation()?.value,
                address: editSupplier.address,
                DOY: editSupplier.DOY,
                city: editSupplier.city,
                county: editSupplier.county,
                vatCategory: editSupplier.vatCategory,
                vatReason: editSupplier.vatReason,
                customVat: editSupplier.customVat,
                postalCode: editSupplier.postalCode
              },
            ];
            let updatedInstallations = SUPPLIERS_DATA.editSupplier.installations.slice();
            updatedInstallations["0"] = {
              ...updatedInstallations["0"],
              ...defaultInstallation,
            };
            dispatch(setEditSupplier({...editSupplier, contactPoints: updatedContactPoints, installations: updatedInstallations}));
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response?.status);
          if(error.response?.status === 400){
            alert({
              message: error.response.data?.Error ? error.response.data?.Error : error?.message === "timeout" ? "Timeout. Please try again later!" : error.code,
              type: 'warning',
            })
          }
        });
  }
  const handleOnChange = async (e, type="default", ddname = '') => {
    setShowErrors(true);
    // Set if editing the form
    if (!ACTIVITY.saveEditSupplier) {
      dispatch(setSaveEditSupplier(true));
    }
    let name;
    let value;
    if (type === 'dd')  {
      name = ddname;
      value = e.value;
    } else if (type === 'ac') {
      name = ddname;
      value = e.row.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    if(name === 'customVat'){
      let error;
      if (+value > 100) {
        error = 'Can not be greater than 100.';
        toast.error(error);
        value = "";
      }
      value = +String(value).slice(0, 3);
      if (value === 0) {
        value = "";
      }
    }
    // Set settings data
    let editSupplier = Object.assign({}, SUPPLIERS_DATA.editSupplier);
    let editSupplierData = {};
    if (name === 'category') {
      editSupplierData[name] = value;
      editSupplierData.categoryName = getLabelByValue(supplierCategoriesOption, value, 'value');
      const selectedCategory = findObjectFromArrayOfObjects(supplierCategories, value,'_id');
      if (selectedCategory) {
        editSupplierData.categoryMaskCodePrefix = selectedCategory.maskCodePrefix;
        editSupplierData.systemCode = getGeneratedMaskCodeCLient(selectedCategory._id, supplierCategories, selectedCategory.maskCodePrefix); // New With Updated Prefix
        editSupplierData.accounting = selectedCategory.accounting ? selectedCategory.accounting : '';
        editSupplierData.bypassAccounting = selectedCategory.bypassAccounting ? selectedCategory.bypassAccounting : '';
      }
      // If Category: Retail: Add 000000000, do not validate VAT number. Field not mandatory
      if (editSupplierData.categoryName === 'Retail') {
        editSupplierData.vatNumber = "000000000";
      } else {
        editSupplierData.vatNumber = "";
      }
      if (editSupplierData.categoryName === "Supplier within E.U.") {
        // Update country list for inside EU.
        // Supplier inside E.U., show only countries within E.U. except Greece
        countriesList = getCountryOptions("insideEU");
      } else if (editSupplierData.categoryName === "Supplier outside E.U.") {
        // Update country list for outside EU
        // outside E.U., show only countries outside E.U.
        countriesList = getCountryOptions("outsideEU");
      } else if(editSupplierData.categoryName === "Retail"){
        countriesList = getCountryOptions('all');
        editSupplierData.country = "GR";
      } else {
        // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:
        // Select Greece, Accept only Greece, show only Greece
        countriesList = getCountryOptions("greece");
      }
    } else {
      if(name === 'isActive' && value === 'inactive') {
        showInActiveSupplierWarnning(true);
      } else {
        editSupplierData[name] = value;
      }
    }
    vatCategoryList = (SUPPLIERS_DATA.editSupplier.categoryName === "Supplier within E.U." || SUPPLIERS_DATA.editSupplier.categoryName === "Supplier outside E.U." || (SUPPLIERS_DATA.editSupplier.categoryName === "Retail" && SUPPLIERS_DATA.editSupplier.country !== "GR")) ? getVatCategoryAbroadOptions() : getVatCategoryOptions();
    let updatedContactPoints = SUPPLIERS_DATA.editSupplier.contactPoints.slice();
    let newContacts = {
      name: editSupplier.name,
      email: editSupplier.email,
      mobile: editSupplier.phone
    }
    if (e) {
      if (name === "name") {
        newContacts["name"] = value;
      } else if (name === "email") {
        newContacts["email"] = value;
      } else if (name === "phone") {
        newContacts["mobile"] = value;
      } else if (name === "category") {
        newContacts["mobile"] = "";
      }
    }
    updatedContactPoints["0"] = {
      ...updatedContactPoints["0"],
      ...newContacts,
    };
    editSupplier = {...editSupplier, ...editSupplierData}
    // Default Installation
    let defaultInstallation = [{
      id: 0,
      no: 0,
      type: getMainInstallation()?.value,
      address: editSupplier.address,
      DOY: editSupplier.DOY,
      city: editSupplier.city,
      county: editSupplier.county,
      vatCategory: editSupplier.vatCategory,
      vatReason: editSupplier.vatReason,
      customVat: editSupplier.customVat,
      postalCode: editSupplier.postalCode
    }]
    let updatedInstallations = SUPPLIERS_DATA.editSupplier.installations.slice();
    updatedInstallations["0"] = {
      ...updatedInstallations["0"],
      ...defaultInstallation,
    };
    dispatch(setEditSupplier({...editSupplier, contactPoints: updatedContactPoints, installations: updatedInstallations}));
  }

  // It will hide or show the table columns for searchtable.
  const handleColumnShowHide = (field) => {
    let tempTc = tableColumns.slice();
    let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
    const index = tempTc.findIndex((item) => field === item.field);
    if (object.length > 0) {
      tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
      setTableColumns(tempTc);
    }
  }

  const parentMydataActions = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditSupplier) {
        dispatch(setSaveEditSupplier(true));
      }
      let tempContactPoint = Object.assign({},  SUPPLIERS_DATA.newContactPoint);
      if (e.key !== "Enter") {
        tempContactPoint[data.field] = data.value;
        dispatch(setEditContactPoint(tempContactPoint)); // Update redux
        if (data.value !== ''){
          let updatedContactPoint = Object.assign({},  tempContactPoint);
          let oldContactPoints = SUPPLIERS_DATA.editSupplier.contactPoints;
          updatedContactPoint.no = oldContactPoints.length + 1;
          updatedContactPoint.id = oldContactPoints.length + 1;
          dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, contactPoints: [...oldContactPoints, updatedContactPoint]}));
          dispatch(setEditContactPoint(SUPPLIERS_DATA.newContactPointInitialValue)); // Reset new category mydata
        }
      }
      if (e.key === "Enter" && data.value === "add") {
        let updatedContactPoint = Object.assign({},  tempContactPoint);
        let oldContactPoints = SUPPLIERS_DATA.editSupplier.contactPoints;
        updatedContactPoint.no = oldContactPoints.length + 1;
        updatedContactPoint.id = oldContactPoints.length + 1;
        dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, contactPoints: [...oldContactPoints, updatedContactPoint]}));
        dispatch(setEditContactPoint(SUPPLIERS_DATA.newContactPointInitialValue)); // Reset new category mydata
      }
    },
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditSupplier) {
        dispatch(setSaveEditSupplier(true));
      }

      if (data.name && (data.name.length === 0)) {
        toast.error("Contact Name is mandatory field.");
      } else {
        setSaveEditSupplier(true);
        let contactPoints = SUPPLIERS_DATA.editSupplier.contactPoints.slice();
        if (contactPoints.find(item => item.id === id) !== undefined) {
          contactPoints[id-1] = {...contactPoints[id - 1], ...data};
          dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, contactPoints: contactPoints})); // update data
        }
      }
    },
    selected: () => {
      console.log('selected mydata row clicked');
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditSupplier) {
        dispatch(setSaveEditSupplier(true));
      }

      let contactPoints = SUPPLIERS_DATA.editSupplier.contactPoints.slice();
      if (id > 1 && contactPoints.length >= 2) {
        contactPoints.splice(id - 1, 1);
        dispatch(setEditSupplier({...SUPPLIERS_DATA.editSupplier, contactPoints: contactPoints }))
      } else {
        toast.error('First row can not be deleted.');
      }
    }
  }
  const handleConfirmCategory = () => {
    // Set Status Inactive on Yes
    dispatch(setEditSupplierStatus('inactive'));
    showInActiveSupplierWarnning(false);
  }
  return (
      <div className="new-supplier-general product-form position-relative">
        {disabledFields && (
            <div className="overlay_w">{t("Required.selectCategoryFirst")}</div>
        )}
          <Row className="position-relative border-bottom mb-3">
            <Col
                xs="12"
                md="12"
                className="d-flex align-items-center justify-content-end "
            >
              <div className="inline-field d-flex align-items-center mb-3 mr-5">
                <label htmlFor="pib" className="w-100 mt-2 mr-2">{t("General.supplierBalance")}</label>
                <Input
                    name="productInventoryBalance"
                    className="mb-2 w-100"
                    value = { (typeof(SUPPLIERS_DATA.editSupplier.balance) !== 'undefined') ? SUPPLIERS_DATA.editSupplier.balance : 0 }
                    disabled={true}
                />
              </div>
              <div className="inline-field d-flex align-items-center acremd  mb-3">
                <label htmlFor="supplier-active" className="mb-0 mr-2 ">
                  {t("General.activeSupplier")}
                </label>
                <Checkbox
                    className="mb-2"
                    name="isActive"
                    key={Math.random()}
                    id="supplier-active"
                    defaultValue={SUPPLIERS_DATA.editSupplier.isActive}
                    value={SUPPLIERS_DATA.editSupplier.isActive === "active" ? "inactive" : "active"}
                    onChange={(e) => handleOnChange(e)}
                />
              </div>
            </Col>
          </Row>
          {/* First Row */}
          <Row>
            <Col sm="12" md="3" className="showWithOverlay">
              <Dropdown
                  label={t("General.category")}
                  required={true}
                  isCLientCategory={true}
                  options={supplierCategoriesOption}
                  classes="w-100 mb-3"
                  name="category"
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(supplierCategories, SUPPLIERS_DATA.editSupplier.category, "_id", "category", "_id")}
                  onChange={(e) => handleOnChange(e, "dd", "category")}
              />
            </Col>
            <Col sm="12" md="3">
              <Input
                  label={t("General.systemCode")}
                  className="mb-3 w-100"
                  disabled={true}
                  name="systemCode"
                  value={SUPPLIERS_DATA.editSupplier.systemCode}
              />
            </Col>
            <Col sm="12" md="3">
              <Input
                  label={t("General.manualCode")}
                  className="mb-3 w-100"
                  name="manualCode"
                  type="text"
                  value={SUPPLIERS_DATA.editSupplier.manualCode}
                  placeholder="eg. mcc-001"
                  onChange={(e) => handleOnChange(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="4">
              <div className="mb-3">
                <Input
                    label={t("General.name")}
                    required={true}
                    className="w-100"
                    name="name"
                    type="text"
                    value={SUPPLIERS_DATA.editSupplier.name}
                    onChange={(e) => handleOnChange(e)}
                />
                {showErrors && SUPPLIERS_DATA.editSupplier.name === "" && (
                    <div className="text-danger">{t("Required.name")}</div>
                )}
              </div>
            </Col>
            {SUPPLIERS_DATA.editSupplier.categoryName !== "" &&
                SUPPLIERS_DATA.editSupplier.categoryName !== "Retail" &&
                <Col sm="12" md="4">
                  <Input
                      label={t("General.smallName")}
                      className="mb-3 w-100"
                      name="smallName"
                      type="text"
                      value={SUPPLIERS_DATA.editSupplier.smallName}
                      onChange={(e) => handleOnChange(e)}
                  />
                </Col>
            }
            <Col sm="12" md="4">
              <div className="mb-3">
                <Dropdown
                    label={t("General.vatCategory")}
                    classes="w-100"
                    required={true}
                    name="vatCategory"
                    key={Math.random()}
                    defaultValue={getDefaultOptionByValue(vatCategoryList, SUPPLIERS_DATA.editSupplier.vatCategory, "value", "label", "value")}
                    options={vatCategoryList}
                    onChange={(e) => handleOnChange(e, "dd", "vatCategory")}
                />
                {showErrors && SUPPLIERS_DATA.editSupplier.vatCategory === "" && (
                    <div className="text-danger">{t("Required.vatCategory")}</div>
                )}
              </div>
            </Col>
            {(SUPPLIERS_DATA.editSupplier.vatCategory !== "" && SUPPLIERS_DATA.editSupplier.vatCategory === 'Zero VAT') && (
                <Col sm="12" md="4">
                  <div className="mb-3">
                    <Dropdown
                        classes='w-100'
                        label={t("General.0vatReason")}
                        key = {Math.random()}
                        name="vatReason"
                        required={true}
                        options={zeroVatReasonOptions}
                        defaultValue={SUPPLIERS_DATA.editSupplier.vatReason ? { value: SUPPLIERS_DATA.editSupplier.vatReason, label: getLabelByValue(zeroVatReasonOptions, SUPPLIERS_DATA.editSupplier.vatReason,'value'), color: "#76AEB7" } : null}
                        onChange = {(e) => handleOnChange(e, 'dd', 'vatReason')}
                    />
                    {showErrors && SUPPLIERS_DATA.editSupplier.vatCategory === 'Zero VAT' && SUPPLIERS_DATA.editSupplier.vatReason === "" && (
                        <div className="text-danger">{t("Required.0vatReason")}</div>
                    )}
                  </div>
                </Col>
            )}
            { (SUPPLIERS_DATA.editSupplier.vatCategory !== "" && SUPPLIERS_DATA.editSupplier.vatCategory === 'Custom VAT') &&
                <Col xs="12" md="4">
                  <div className="mb-3">
                    <Input
                        name="customVat"
                        type="number"
                        validate={isValidCustomVat}
                        label={t("General.customVat")}
                        charLimit={3}
                        onChange = {(e) => handleOnChange(e)}
                        value={ SUPPLIERS_DATA.editSupplier.customVat }
                    />
                    { (SUPPLIERS_DATA.editSupplier.vatCategory === 'Custom VAT' &&
                            SUPPLIERS_DATA.editSupplier.customVat === "") &&
                        <div className="text-danger">{t("Required.customVat")}</div>
                    }
                  </div>
                </Col>
            }
          </Row>
          <Row>
            {(SUPPLIERS_DATA.editSupplier.categoryName === "Retail") && (
                <Col sm="12" md="4">
                  <Input
                      label={t('General.vatNumber')}
                      className="mb-3 w-100 bg-white"
                      name="vatNumber"
                      type="text"
                      defaultValue={SUPPLIERS_DATA.editSupplier.vatNumber}
                      onChange={(e) => handleOnChange(e)}
                  />
                </Col>
            )}
            {/* Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.: Validate VAT number, use "Get Details" button to get company details. Mandatory field */}
            {(SUPPLIERS_DATA.editSupplier.categoryName === "Freelancer" ||
                SUPPLIERS_DATA.editSupplier.categoryName === "O.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "E.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "E.P.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "I.K.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "A.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === 'Wholesale Others' ||
                SUPPLIERS_DATA.editSupplier.categoryName === "Supplier within E.U." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "Supplier outside E.U.") && (
                <Col sm="12" md="4">
                  <div className="mb-3">
                    <Input
                        label={t('General.vatNumber')}
                        className="w-100 bg-white"
                        name="vatNumber"
                        required={true}
                        charLimit={20}
                        type="text"
                        value={SUPPLIERS_DATA.editSupplier.vatNumber}
                        onChange={(e) => handleOnChange(e)}
                    />
                    {showErrors && SUPPLIERS_DATA.editSupplier.vatNumber === "" && (
                        <div className="text-danger">{t("Required.vatNumber")}</div>
                    )}
                  </div>
                </Col>
            )}
            {/* Show only if category is NOT Retail or Patient */}
            {/* Use "Get Greek Company Details from taxisnet" algorithm */}
            {SUPPLIERS_DATA.editSupplier.categoryName !== "" &&
                SUPPLIERS_DATA.editSupplier.categoryName !== "Retail" &&
                SUPPLIERS_DATA.editSupplier.categoryName !== "Supplier within E.U." &&
                SUPPLIERS_DATA.editSupplier.categoryName !== "Supplier outside E.U." && (
                    <Col sm="12" md="2" className="d-flex align-items-center">
                      <Button
                          onClick={() => fetchTaxisNetDetails(SUPPLIERS_DATA.editSupplier.vatNumber)}
                          size="sm"
                          className="mb-3"
                          type="button"
                      >
                        {t("General.autofillData")}
                      </Button>
                    </Col>
                )}
            {SUPPLIERS_DATA.editSupplier.categoryName === "Supplier within E.U." && (
                <Col sm="12" md="2" className="d-flex align-items-center">
                  <Button
                      onClick={() => fetchViesDetails(SUPPLIERS_DATA.editSupplier.vatNumber, SUPPLIERS_DATA.editSupplier.country)}
                      size="sm"
                      className="mb-3"
                      type="button"
                  >
                    VIES Check
                  </Button>
                </Col>
            )}
            {(SUPPLIERS_DATA.editSupplier.categoryName === "Freelancer" ||
                SUPPLIERS_DATA.editSupplier.categoryName === "O.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "E.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "E.P.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "I.K.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "A.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "Non-Profit Organization" ||
                SUPPLIERS_DATA.editSupplier.categoryName === 'Wholesale Others') && (
                <Col sm="12" md="3">
                  <Dropdown
                      label={t("General.doy")}
                      classes="w-100"
                      required={
                          SUPPLIERS_DATA.editSupplier.categoryName !== "Retail" &&
                          SUPPLIERS_DATA.editSupplier.categoryName !== "Supplier within E.U." &&
                          SUPPLIERS_DATA.editSupplier.categoryName !== "Supplier outside E.U."
                      }
                      searchEnable={true}
                      name="DOY"
                      key={Math.random()}
                      defaultValue={getDefaultOptionByValue(getDOYOptions(), Number(SUPPLIERS_DATA.editSupplier.DOY), "value", "label", "value")}
                      options={getDOYOptions()}
                      onChange={(e) => handleOnChange(e, "dd", "DOY")}
                  />
                  {
                      SUPPLIERS_DATA.editSupplier.categoryName !== "Retail" &&
                      SUPPLIERS_DATA.editSupplier.categoryName !== "Supplier within E.U." &&
                      SUPPLIERS_DATA.editSupplier.categoryName !== "Supplier outside E.U." &&
                      showErrors &&
                      SUPPLIERS_DATA.editSupplier.DOY === "" && (
                          <div className="text-danger">{t("Required.doy")}</div>
                      )}
                </Col>)}
            {(SUPPLIERS_DATA.editSupplier.categoryName === "Freelancer" ||
                SUPPLIERS_DATA.editSupplier.categoryName === "O.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "E.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "E.P.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "I.K.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "A.E." ||
                SUPPLIERS_DATA.editSupplier.categoryName === "Non-Profit Organization" ||
                SUPPLIERS_DATA.editSupplier.categoryName === 'Wholesale Others') && (
                <Col sm="12" md="3">
                  <Input
                      label={t("General.activity")}
                      className="w-100"
                      name="activity"
                      type="text"
                      charLimit={100}
                      required={SUPPLIERS_DATA.editSupplier.categoryName !== "Retail"}
                      value={SUPPLIERS_DATA.editSupplier.activity}
                      onChange={(e) => handleOnChange(e)}
                  />
                  {showErrors && SUPPLIERS_DATA.editSupplier.activity === "" && (
                      <div className="text-danger">{t("Required.activity")}.</div>)}
                </Col>
            )}
          </Row>
          <Row>
            <Col sm="12" md="3">
              {/* Mandatory field EXCEPT if category retail & patient */}
              <Dropdown
                  label={t("General.country")}
                  classes="w-100"
                  required={SUPPLIERS_DATA.editSupplier.categoryName !== "Retail"}
                  name="country"
                  searchEnable={true}
                  key={Math.random()}
                  defaultValue={getDefaultOptionByValue(countriesList, SUPPLIERS_DATA.editSupplier.country, "value", "label", "value")}
                  options={countriesList}
                  onChange={(e) => handleOnChange(e, "dd", "country")}
              />
              {
                  showErrors &&
                  SUPPLIERS_DATA.editSupplier.country === "" && (
                      <div className="text-danger">{t("Required.country")}</div>
                  )}
            </Col>
            <Col sm="12" md="3">
            <div className="mb-3">
                {/* Mandatory field EXCEPT if category retail & patient */}
                <Input
                    label={t("General.address")}
                    className="w-100"
                    name="address"
                    type="text"
                    charLimit={100}
                    required={SUPPLIERS_DATA.editSupplier.categoryName !== "Retail"}
                    value={SUPPLIERS_DATA.editSupplier.address}
                    onChange={(e) => handleOnChange(e)}
                />
                {
                    showErrors && SUPPLIERS_DATA.editSupplier.categoryName !== "Retail" &&
                    SUPPLIERS_DATA.editSupplier.address === "" && (
                        <div className="text-danger">{t("Required.address")}</div>
                    )}
            </div>
            </Col>
            <Col sm="12" md="3">
              <div className="mb-3">
                {/* Mandatory field EXCEPT if category retail & patient */}
                <Input
                    label={t("General.city")}
                    className="w-100"
                    name="city"
                    type="text"
                    charLimit={100}
                    required={SUPPLIERS_DATA.editSupplier.categoryName !== "Retail"}
                    value={SUPPLIERS_DATA.editSupplier.city}
                    onChange={(e) => handleOnChange(e)}
                />
                {
                    showErrors && SUPPLIERS_DATA.editSupplier.categoryName !== "Retail" &&
                    SUPPLIERS_DATA.editSupplier.city === "" && (
                        <div className="text-danger">{t("Required.city")}</div>
                    )}
              </div>
            </Col>
            <Col sm="12" md="3">
              {/* Mandatory field EXCEPT if category retail & patient */}
              <div className="mb-3">
                <Input
                    label={t("General.postalCode")}
                    className="w-100"
                    name="postalCode"
                    type="text"
                    required={SUPPLIERS_DATA.editSupplier.categoryName !== "Retail"}
                    value={SUPPLIERS_DATA.editSupplier.postalCode}
                    onChange={(e) => handleOnChange(e)}
                />
                {
                    showErrors && SUPPLIERS_DATA.editSupplier.categoryName !== "Retail" &&
                    SUPPLIERS_DATA.editSupplier.postalCode === "" && (
                        <div className="text-danger">{t("Required.postalCode")}</div>
                    )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="3">
              <div className="mb-3">
                <Input
                    label={t("General.email")}
                    className="w-100"
                    name="email"
                    type="email"
                    value={SUPPLIERS_DATA.editSupplier.email}
                    placeholder="eg. example@example.com"
                    onChange={(e) => handleOnChange(e)}
                    required={SUPPLIERS_DATA.editSupplier.phone === ""}
                />
                {showErrors &&
                    SUPPLIERS_DATA.editSupplier.email !== "" &&
                    !isValidEmail(SUPPLIERS_DATA.editSupplier.email) && (
                        <div className="text-danger">{t("Required.invalidEmail")}</div>
                    )}

                {showErrors &&
                    SUPPLIERS_DATA.editSupplier.categoryName !== "Patient"  &&
                    SUPPLIERS_DATA.editSupplier.categoryName !== "I.K.E."  &&
                    SUPPLIERS_DATA.editSupplier.categoryName !== "Freelancer"  &&
                    SUPPLIERS_DATA.editSupplier.categoryName !== "O.E."  &&
                    SUPPLIERS_DATA.editSupplier.categoryName !== "E.E."  &&
                    SUPPLIERS_DATA.editSupplier.categoryName !== "A.E."  &&
                    SUPPLIERS_DATA.editSupplier.categoryName !== "Non-Profit Organization"  &&
                    SUPPLIERS_DATA.editSupplier.categoryName !== "E.P.E."  &&
                    SUPPLIERS_DATA.editSupplier.categoryName !== "Wholesale Others"  &&
                    SUPPLIERS_DATA.editSupplier.email === "" &&
                    SUPPLIERS_DATA.editSupplier.phone === "" && (
                        <div className="text-danger">{t("Required.emailOrPhone")}</div>
                    )}
              </div>
            </Col>
            <Col sm="12" md="3">
              <Input
                  label={t("General.phone")}
                  className="mb-3 w-100"
                  name="phone"
                  type="tel"
                  charLimit={20}
                  value={SUPPLIERS_DATA.editSupplier.phone}
                  onChange={(e) => handleOnChange(e)}
                  required={SUPPLIERS_DATA.editSupplier.email === ""}
              />
            </Col>
            <Col sm="12" md="3">
              <Input
                  label={t("General.gemhNo")}
                  className="mb-3 w-100"
                  name="gemhNo"
                  type="text"
                  value={SUPPLIERS_DATA.editSupplier.gemhNo}
                  onChange={(e) => handleOnChange(e)}
              />
            </Col>
            <Col sm="12" md="3">
              <Input
                  label={t("General.webpage")}
                  className="mb-3 w-100"
                  name="webpage"
                  type="url"
                  value={SUPPLIERS_DATA.editSupplier.webpage}
                  placeholder="eg. https://www.example.com/"
                  onChange={(e) => handleOnChange(e)}
              />
            </Col>
          </Row>
        <hr />
        <p className="text-muted">
          <i>{t("General.contactPoints")}</i>
        </p>
        <EditableTable
            tableName="Supplier Contact Points"
            key={Math.random()}
            allowActions={true}
            allowToggleColumns={true}
            toggleColumns={handleColumnShowHide}
            columns={tableColumns}
            data={SUPPLIERS_DATA.editSupplier.contactPoints}
            allowInsertRow={true}
            enableNewRowCheckbox={true}
            onUpdate={parentMydataActions}
        />

        <p>
          <i>{t("General.notes")}</i>
        </p>
        <TextArea
            className="mb-3"
            rows="4"
            name="supplierNotes"
            limit="1000"
            value={SUPPLIERS_DATA.editSupplier.supplierNotes}
            placeholder={t("Placeholders.notes")}
            onChange={(e) => handleOnChange(e)}
        />

        {/* Confirm Category Change */}
        <Modal backdrop={"static"} className="themeModal" show={inActiveSupplierWarning} onHide={() => showInActiveSupplierWarnning(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("General.warning")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("General.warningSupplierInactive")}
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant="outline-primary"
                onClick={() => showInActiveSupplierWarnning(false)}
            >
              {t("General.noOption")}
            </Button>
            <Button variant="primary" onClick={() => handleConfirmCategory()}>
              {t("General.yesOption")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
  )
}

export const SuppliersEditLog = () => {
  const { t } = useTranslation('common');
  const SUPPLIERS_DATA = useSelector((state) => state.SUPPLIERS_DATA);

  return (
      <React.Fragment>
        <div className="text-muted mb-2"><i>{t('Logs.supplierLog')}</i></div>
        <LogTable itemId={SUPPLIERS_DATA.selectedSupplier._id}/>
      </React.Fragment>
  )
}

export default EditSupplierGeneral;
