import React, {forwardRef} from "react";
import "./styles.css";
import {formatBigName, formatInstallation, innerImg} from "./constants";
import {useSelector} from "react-redux";

const Folder = forwardRef(({name, fileCount, locked, hasSubfolders, selected, onFolderLeftClick, onContextMenu, specialCode}, ref) => {
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);

    return (
        <div className={`fileFolderStyle ${selected ? "fileFolderStyleSelected" : ""}`}
             onClick={(e) => onFolderLeftClick(e, name)} style={{userSelect: "none", position: "relative", cursor: "pointer"}}
             onContextMenu={(e) => onContextMenu(e, "folder", name, locked)}
             ref={ref}
        >
            <div style={{position: "relative"}}>
                {fileCount === 0 ? (
                    <img src={innerImg(true, specialCode, locked, hasSubfolders)} alt={"Folder"} style={{height: "56px", width: "56px"}} draggable={false}/>
                ) : (
                    <React.Fragment>
                        <img src={innerImg(false, specialCode, locked, hasSubfolders)} alt={"Folder"} style={{height: "56px", width: "56px"}} draggable={false}/>
                        <div style={{position: "absolute", bottom: "0px", left: "calc(50% - 20px)"}}>{fileCount}</div>
                    </React.Fragment>
                )}
            </div>
            <div className={"mt-1"} style={{wordBreak: "break-all", fontSize: "12px"}}>
                {(specialCode === "1" && !isNaN(name)) ? (
                    <React.Fragment>{formatInstallation(name, companyInstallations)}</React.Fragment>
                ) : (
                    <React.Fragment>{formatBigName(name)}</React.Fragment>
                )}
            </div>
        </div>
    )
});

export default Folder;
