import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, Row, Spinner} from 'react-bootstrap';
import Dropdown from "../../../../primer/Dropdown";
import Input from '../../../../common/Input';
import {
  checkPermission,
  getCountryOptions,
  getDefaultOptionByValue,
  getDOYOptions, getGeneratedMaskCodeCLient,
  getMainInstallation,
  getOptionsByData,
  getVatCategoryAbroadOptions,
  getVatCategoryOptions, isEmpty,
  isValidEmail
} from '../../../../../_helpers/commonFunctions';
import {useDispatch, useSelector} from "react-redux";
import {setSaveEditClient} from "../../../../../_reducers/activitySlice";
import {
  setClientMovementsSearchByBalance,
  setEditClient,
  setEditClientStatus,
  setEditContactPoint
} from '../../../../../_reducers/DataPanel/Clients/clientSlice';
import getMyData from '../../../../../_helpers/sharedmydata';
import TextArea from '../../../../common/TextArea';
import EditableTable from '../../../../primer/Editable-Table';
import {
  findObjectFromArrayOfObjects, getIndexByArrayValue,
  getLabelByValue,
  getObjectFromArrayOfObjects
} from '../../../../../_helpers/helperFunctions';
import {toast} from 'react-toastify';
import Checkbox from '../../../../common/CheckBox';
import {
  fetchClientsSettings,
  fetchInitialClients,
} from '../../../../../_apis/api';
import axios from "axios";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import AnathetousesModal from "../../../../primer/POPUP-COMPONENTS/AnathetousesModal";
import LogTable from "../../../../primer/LogTable";
import {addTab, updateActiveMainTab} from "../../../../../_reducers/TabsSlice";

const EditClientGeneral = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const CLIENTS_DATA = useSelector( (state) => state.CLIENTS_DATA );
  const ACTIVITY = useSelector( (state) => state.ACTIVITY );
  const company = useSelector(state => state.COMPANY_DATA.company)
  const clientCategories = getObjectFromArrayOfObjects(CLIENTS_DATA.clientsSettings.categories, 'active', 'isActive');
  const [clientCategoriesOption, setClientCategoriesOption] = useState([]);
  const zeroVatReasonOptions = getMyData({type: 'zerovatreason'});
  let vatCategoryList = (CLIENTS_DATA.editClient.categoryName === "Client within E.U." || CLIENTS_DATA.editClient.categoryName === "Client outside E.U." || (CLIENTS_DATA.editClient.categoryName === "Retail" && CLIENTS_DATA.editClient.country !== "GR")) ? getVatCategoryAbroadOptions() : getVatCategoryOptions();
  const permissionsData = JSON.parse(localStorage.getItem("permissions"));
  const TABS_DATA = useSelector((state) => state.TABS_REDUCER);

  const [incrKey, setIncrKey] = useState(0);
  useEffect(() => {
    setIncrKey(incrKey + 1);
  }, [CLIENTS_DATA.editClient.contactPoints])

  useEffect(() => {
    let retailGroup = ["1", "8"];
    if(retailGroup.includes(CLIENTS_DATA.editClient.category)) {
      setClientCategoriesOption(getOptionsByData(clientCategories.filter((el) => retailGroup.includes(el._id)), '_id', 'category'));
    } else {
      setClientCategoriesOption(getOptionsByData(clientCategories.filter((el) => !retailGroup.includes(el._id)), '_id', 'category'));
    }
  }, [CLIENTS_DATA.editClient])

  const isValidCustomVat = (value) => {
    let error;
    if (+value > 100) {
      error = 'Can not be greater than 100.';
    }
    return error;
  }
  const clientContactPointsColumns = [
    {
      name: "No",
      field: "no",
      editable: false,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsGeneralEdit.table.contactName'),
      field: "name",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsGeneralEdit.table.mobilePhone'),
      field: "mobile",
      editable:true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsGeneralEdit.table.landLine'),
      field: "landline",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsGeneralEdit.table.otherPhone'),
      field: "otherPhone",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsGeneralEdit.table.email'),
      field: "email",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsGeneralEdit.table.contactNotes'),
      field: "contactNotes",
      editable: true,
      inputType: {
        type: 'text',
      }
    },
    {
      name: t('ClientsGeneralEdit.table.acceptNotifications'),
      field: "acceptsNotifications",
      editable: true,
      inputType: {
        type: "checkbox",
      },
    },
  ]
  useEffect(() => {
    // It will use for validate client name
    dispatch(fetchInitialClients({
      company:company.id,
      year:company.year,
      isActive: 'active'
    }));

    dispatch(fetchClientsSettings({
      company:company.id,
      year:company.year.toString(),
      isActive:"active"
    }))

  }, [dispatch]);
  useEffect( () => {
    setTableColumns(clientContactPointsColumns.slice());
  },[i18n.language])

  const [tableColumns, setTableColumns] = useState(clientContactPointsColumns.slice())
  const [inActiveClientWarning, showInActiveClientWarnning] = useState(false);
  const [showErrors, setShowErrors] = useState(false)
  // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.: Select Greece, Accept only Greece, show only Greece
  let allCountries = getCountryOptions('all');
  let countriesList = getCountryOptions("greece");
  if (CLIENTS_DATA.editClient.categoryName === "Client within E.U.") {
    // Update country list for inside EU.
    // Client inside E.U., show only countries within E.U. except Greece
    countriesList = getCountryOptions("insideEU");
  } else if (CLIENTS_DATA.editClient.categoryName === "Client outside E.U.") {
    // Update country list for outside EU
    // outside E.U., show only countries outside E.U.
    countriesList = getCountryOptions("outsideEU");
  } else if(CLIENTS_DATA.editClient.categoryName === "Retail" || CLIENTS_DATA.editClient.categoryName === "Patient"){
    countriesList = getCountryOptions('all');
  } else {
    // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:
    // Select Greece, Accept only Greece, show only Greece
    countriesList = getCountryOptions("greece");
  }
  let disabledFields = false;
  if (CLIENTS_DATA.editClient.category === undefined || CLIENTS_DATA.editClient.category === '') {
    disabledFields = true;
  }
  const apiURL2 = process.env.REACT_APP_API_URL2;

  const fetchViesDetails = async (vat, country) => {
    let jsonData = {
      vatNumber: vat,
      country: country
    }
    if (vat === "" || vat === undefined) {
      toast.error("Please input a vat Number to check.");
    } else if (country === "" || country === undefined) {
      toast.error("Please select a country for VIES check.");
    } else {
      console.log(jsonData);
      const abort = axios.CancelToken.source();
      const timer = setTimeout(() => abort.cancel(`timeout`), 10000);
      await axios.post(apiURL2 + `/client/fetch-vies-details/`, JSON.stringify(jsonData), {headers: {'Content-Type': 'application/json'}})
          .then((res) => {
            if (res.data && res.data.status === "200") {
              if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

              clearTimeout(timer)
              let companyDetails = res.data.data;
              if (companyDetails.valid === true) {
                let newClient = Object.assign({}, CLIENTS_DATA.editClient);
                newClient.name = companyDetails?.name !== undefined ? companyDetails?.name : "";
                let address = companyDetails?.address.split(",");
                newClient.postalCode = address[2] !== undefined ? address[2].split(" ")[1] : "";
                newClient.address = address[1] !== undefined ? address[1] : "";
                newClient.city = address[2] !== undefined ? address[2].split(" ")[2] : "";
                newClient.vatCategory = 'Zero VAT';
                newClient.vatReason = "14";
                let updatedContactPoints = CLIENTS_DATA.editClient.contactPoints.slice();
                let newContacts = {
                  name: newClient.name,
                  email: newClient.email,
                  mobile: newClient.phone,
                  acceptsNotifications: "active"
                };
                updatedContactPoints["0"] = {
                  ...updatedContactPoints["0"],
                  ...newContacts,
                };
                let defaultInstallation = [
                  {
                    id: 0,
                    no: 0,
                    type: getMainInstallation()?.value,
                    address: newClient.address,
                    DOY: newClient.DOY,
                    city: newClient.city,
                    county: newClient.county,
                    vatCategory: newClient.vatCategory,
                    vatReason: newClient.vatReason,
                    customVat: newClient.customVat,
                    postalCode: newClient.postalCode
                  },
                ];
                dispatch(setEditClient({
                  ...newClient,
                  contactPoints: updatedContactPoints,
                  installations: defaultInstallation
                }));
                toast.success(res.data.message);
              } else {
                toast.error("VAT Number with this country was not found on VIES");
              }
            } else {
              console.log(res)
              toast.error(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response?.status === 400) {
              alert({
                message: error.response.data?.Error ? error.response.data?.Error : error?.message === "timeout" ? "Timeout. Please try again later!" : error.code,
                type: 'warning',
              })
            }
          });
    }
  };

  const fetchTaxisNetDetails = async (data) => {
    let jsonData = {
      vatNumber: data,
      onlyAADE: "true"
    }
    const abort = axios.CancelToken.source();
    const timer = setTimeout(
        () => abort.cancel(`timeout`),
        10000
    )
    await axios.post(apiURL2 + `/client/fetch-vat-details/`, JSON.stringify(jsonData), { headers: { 'Content-Type': 'application/json'}})
        .then((res) => {
          if (res.data && res.data.status === "200") {
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            clearTimeout(timer)
            let companyDetails = res.data.data;
            let editClient = Object.assign({}, CLIENTS_DATA.editClient);
            editClient.name = companyDetails?.name !== undefined ? companyDetails?.name : "";
            editClient.smallName = companyDetails?.smallName !== undefined ? companyDetails?.smallName : "";
            editClient.activity = companyDetails?.activity !== undefined ? companyDetails?.activity : "";
            editClient.postalCode = companyDetails?.tk !== undefined ? companyDetails?.tk : "";
            editClient.address =  companyDetails?.address !== undefined ? companyDetails?.address : "";
            editClient.DOY = getDefaultOptionByValue(getDOYOptions(), Number(companyDetails?.doyCode), "value", "label", "value").value;
            editClient.city = companyDetails?.city !== undefined ? companyDetails?.city : "";
            if(companyDetails?.vatCategory === 'Y'){
              editClient.vatCategory = 'Normal VAT';
            }
            let updatedContactPoints = CLIENTS_DATA.editClient.contactPoints.slice();
            let newContacts = {
              name: editClient.name,
              email: editClient.email,
              mobile: editClient.phone,
              acceptsNotifications: "active"
            };
            updatedContactPoints["0"] = {
              ...updatedContactPoints["0"],
              ...newContacts,
            };
            let defaultInstallation = [
              {
                id: 0,
                no: 0,
                type: getMainInstallation()?.value,
                address: editClient.address,
                DOY: editClient.DOY,
                city: editClient.city,
                county: editClient.county,
                vatCategory: editClient.vatCategory,
                vatReason: editClient.vatReason,
                customVat: editClient.customVat,
                postalCode: editClient.postalCode
              },
            ];
            dispatch(setEditClient({...editClient, contactPoints: updatedContactPoints, installations: defaultInstallation}));
            if (!ACTIVITY.saveEditClient) {
              dispatch(setSaveEditClient(true));
            }
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response?.status === 400){
            alert({
              message: error.response.data?.Error ? error.response.data?.Error : error?.message === "timeout" ? "Timeout. Please try again later!" : error.code,
              type: 'warning',
            })
          }
        });
  }
  const handleOnChange = async (e, type="default", ddname = '') => {
    setShowErrors(true);
    // Set if editing the form
    if (!ACTIVITY.saveEditClient) {
      dispatch(setSaveEditClient(true));
    }
    let name;
    let value;
    if (type === 'dd')  {
      name = ddname;
      value = e.value;
    } else if (type === 'ac') {
      name = ddname;
      value = e.row.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    // 9 numbers only
    if (name === "vatNumber" && CLIENTS_DATA.editClient.categoryName !== "Client within E.U." && CLIENTS_DATA.editClient.categoryName !== "Client outside E.U." ) {
    //  value = +String(value).slice(0, 9);

      // Set Empty if zero
      if (value === 0) {
     //   value = "";
      }
    }
    if(name === 'customVat'){
      let error;
      if (+value > 100) {
        error = 'Can not be greater than 100.';
        toast.error(error);
        value = "";
      }
      value = +String(value).slice(0, 3);
      if (value === 0) {
        value = "";
      }
    }

    // Set settings data
    let editClient = Object.assign({}, CLIENTS_DATA.editClient);
    let editClientData = {};
    if (name === 'category') {
      editClientData[name] = value;
      editClientData.categoryName = getLabelByValue(clientCategoriesOption, value, 'value');
      const selectedCategory = findObjectFromArrayOfObjects(clientCategories, value, '_id');
      if (selectedCategory) {
        editClientData.categoryMaskCodePrefix = selectedCategory.maskCodePrefix;
        editClientData.systemCode = getGeneratedMaskCodeCLient(selectedCategory._id, clientCategories, selectedCategory.maskCodePrefix); // New With Updated Prefix
        editClientData.accounting = selectedCategory.accounting ? selectedCategory.accounting : '';
        editClientData.bypassAccounting = selectedCategory.bypassAccounting ? selectedCategory.bypassAccounting : '';
      }
      // If Category: Retail: Add 999999999, do not validate VAT number. Field not mandatory
      if (editClientData.categoryName === 'Retail' || editClientData.categoryName === 'Patient') {
        editClientData.vatNumber = 999999999;
      } else {
        editClientData.vatNumber = "";
      }
      if (editClientData.categoryName === "Client within E.U.") {
        // Update country list for inside EU.
        // Client inside E.U., show only countries within E.U. except Greece
        countriesList = getCountryOptions("insideEU");
      } else if (editClientData.categoryName === "Client outside E.U.") {
        // Update country list for outside EU
        // outside E.U., show only countries outside E.U.
        countriesList = getCountryOptions("outsideEU");
      } else {
        // Retail, Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.:
        // Select Greece, Accept only Greece, show only Greece
        countriesList = getCountryOptions("greece");
      }
    } else {
      if(name === 'isActive' && value === 'inactive') {
        showInActiveClientWarnning(true);
      } else {
        editClientData[name] = value;
      }
    }
    vatCategoryList = (editClientData.categoryName === "Client within E.U." || editClientData.categoryName === "Client outside E.U." || (editClientData.categoryName === "Retail" && editClientData.country !== "GR")) ? getVatCategoryAbroadOptions() : getVatCategoryOptions();
    let updatedContactPoints = CLIENTS_DATA.editClient.contactPoints.slice();
    let newContacts = {
      name: editClient.name,
      email: editClient.email,
      mobile: editClient.phone,
      acceptsNotifications: "active"
    }
    if (e) {
      if (name === "name") {
        newContacts["name"] = value;
      } else if (name === "email") {
        newContacts["email"] = value;
      } else if (name === "phone") {
        newContacts["mobile"] = value;
      } else if (name === "category") {
        newContacts["mobile"] = "";
      }
    }
    updatedContactPoints["0"] = {
      ...updatedContactPoints["0"],
      ...newContacts,
    }
    editClient = {...editClient, ...editClientData}
    // Default Installation
    let defaultInstallation = [{
      id: 0,
      no: 0,
      type: getMainInstallation()?.value,
      address: editClient.address,
      DOY: editClient.DOY,
      city: editClient.city,
      county: editClient.county,
      vatCategory: editClient.vatCategory,
      vatReason: editClient.vatReason,
      customVat: editClient.customVat,
      postalCode: editClient.postalCode
    }]
    let updatedInstallations = CLIENTS_DATA.editClient.installations.slice();
    updatedInstallations["0"] = {
      ...updatedInstallations["0"],
      ...defaultInstallation,
    };
    dispatch(setEditClient({...editClient, contactPoints: updatedContactPoints, installations: updatedInstallations}));
  }

  // It will hide or show the table columns for searchtable.
  const handleColumnShowHide = (field) => {
    let tempTc = tableColumns.slice();
    let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
    const index = tempTc.findIndex((item) => field === item.field);
    if (object.length > 0) {
      tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
      setTableColumns(tempTc);
    }
  }

  const parentMydataActions = {
    insert: (e, data) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditClient) {
        dispatch(setSaveEditClient(true));
      }
      let tempContactPoint = Object.assign({},  CLIENTS_DATA.newContactPoint);
      if (e.key !== "Enter") {
        tempContactPoint[data.field] = data.value;
        dispatch(setEditContactPoint(tempContactPoint)); // Update redux
        if (data.value !== ''){
          let updatedContactPoint = Object.assign({},  tempContactPoint);
          let oldContactPoints = CLIENTS_DATA.editClient.contactPoints;
          updatedContactPoint.no = oldContactPoints.length + 1;
          updatedContactPoint.id = oldContactPoints.length + 1;
          dispatch(setEditClient({...CLIENTS_DATA.editClient, contactPoints: [...oldContactPoints, updatedContactPoint]}));
          dispatch(setEditContactPoint(CLIENTS_DATA.newContactPointInitialValue)); // Reset new category mydata
        }
      }
      if (e.key === "Enter" && data.value === "add") {
        let updatedContactPoint = Object.assign({},  tempContactPoint);
        let oldContactPoints = CLIENTS_DATA.editClient.contactPoints;
        updatedContactPoint.no = oldContactPoints.length + 1;
        updatedContactPoint.id = oldContactPoints.length + 1;
        dispatch(setEditClient({...CLIENTS_DATA.editClient, contactPoints: [...oldContactPoints, updatedContactPoint]}));
        dispatch(setEditContactPoint(CLIENTS_DATA.newContactPointInitialValue)); // Reset new category mydata
      }
    },
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditClient) {
        dispatch(setSaveEditClient(true));
      }
      if (data.name && (data.name.length === 0)) {
        toast.error("Contact Name is mandatory field.");
      } else {
        setSaveEditClient(true);
        let contactPoints = CLIENTS_DATA.editClient.contactPoints.slice();
        if (contactPoints.find(item => item.id === id) !== undefined) {
          if(id === "0" && data?.acceptsNotifications){
            id = 1;
          }
          contactPoints[id-1] = {...contactPoints[id - 1], ...data};
          dispatch(setEditClient({...CLIENTS_DATA.editClient, contactPoints: contactPoints})); // update data
        }
      }
    },
    selected: () => {
      console.log('selected mydata row clicked');
    },
    delete: (id) => {
      // Set if editing the form
      if (!ACTIVITY.saveEditClient) {
        dispatch(setSaveEditClient(true));
      }

      let contactPoints = CLIENTS_DATA.editClient.contactPoints.slice();
      if (id > 1 && contactPoints.length >= 2) {
        contactPoints.splice(id - 1, 1);
        dispatch(setEditClient({...CLIENTS_DATA.editClient, contactPoints: contactPoints }))
      } else {
        toast.error('First row can not be deleted.');
      }
    }
  }
  const handleConfirmCategory = () => {
    // Set Status Inactive on Yes
    dispatch(setEditClientStatus('inactive'));
    showInActiveClientWarnning(false);
  }

  const [loadingPeppolDetails, setLoadingPeppolDetails] = useState(false);
  const handleGetCompanyInfoPeppol = () => {
    if (!isEmpty(CLIENTS_DATA.editClient.vatNumber) && String(CLIENTS_DATA.editClient.vatNumber).length === 9) {
      setLoadingPeppolDetails(true);
      axios.post(process.env.REACT_APP_API_URL2 + "/client/get-company-info-peppol", {vatNumber: CLIENTS_DATA.editClient.vatNumber}, {
        headers: { 'Content-Type': 'application/json'}
      }).then((res) => {
        setLoadingPeppolDetails(false);
        if (res.data.status === "200") {
          if (res.data.data && res.data.data?.length > 0) {
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));
            dispatch(setEditClient({...CLIENTS_DATA.editClient, peppolPartyIdentification: res.data.data}));
            toast.success("Οι κωδικοί αναθετουσών αρχών συμπληρώθηκαν αυτόματα επιτυχώς.");
          } else {
            toast.info("Δεν βρέθηκαν αναθέτουσες αρχές για αυτό το Α.Φ.Μ.");
          }
        } else {
          toast.error(res.data.message);
        }
      }).catch((error) => {
        setLoadingPeppolDetails(false);
        console.log(error);
        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
      })
    } else {
      toast.error("Παρακαλώ συμπληρώστε Α.Φ.Μ. πρώτα.");
    }
  }

  const handleClickOpenClientMovementsReport = () => {
    const id = "clients-movements";
    if (!TABS_DATA.includes(id)) {
      dispatch(addTab(id));
      dispatch(updateActiveMainTab(TABS_DATA.length));
    } else {
      dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
    }
    dispatch(setClientMovementsSearchByBalance({_id: CLIENTS_DATA.editClient._id, clientName: CLIENTS_DATA.editClient.name}));
  }

  return (
    <div className="new-client-general product-form position-relative">
      {disabledFields && <div className="overlay_w">{t('ClientsGeneralEdit.message')}</div>}
      <Row className="position-relative border-bottom mb-3">
          <Col xs="12" md="12" className="d-flex align-items-center justify-content-end">
            <div className="inline-field d-flex align-items-center mb-3 mr-5">
              <Button size={"sm"} className={"mr-2"} onClick={() => handleClickOpenClientMovementsReport()}>
                Προβολή καρτέλας πελάτη
              </Button>
              <Input
                  name="productInventoryBalance"
                  label={t('ClientsGeneralEdit.clientBalance')}
                  className="mb-2 w-100"
                  value = { (typeof(CLIENTS_DATA.editClient.balance) !== 'undefined') ? CLIENTS_DATA.editClient.balance : 0 }
                  disabled={true}
              />
            </div>
            <div className="inline-field d-flex align-items-center acremd  mb-3">
              <label htmlFor="client-active" className="mb-0 mr-2 " >{t('ClientsGeneralEdit.clientActive')}</label>
              <Checkbox
                className="mb-2"
                name="isActive"
                key = {Math.random()}
                id="client-active"
                defaultValue={CLIENTS_DATA.editClient.isActive}
                value={ CLIENTS_DATA.editClient.isActive === 'active' ? "inactive" : "active" }
                onChange = {(e) => handleOnChange(e) }
              />
            </div>
          </Col>
        </Row>
        {/* First Row */}

        <Row>
          <Col sm='12' md="3" className="showWithOverlay">
            <Dropdown
              label={t('ClientsGeneralEdit.category')}
              required={true}
              options={clientCategoriesOption}
              isCLientCategory={true}
              classes="w-100 mb-3"
              name="category"
              key={ Math.random() }
              defaultValue={getDefaultOptionByValue(clientCategories, CLIENTS_DATA.editClient.category, '_id', 'category', '_id')}
              onChange = {(e) => handleOnChange(e, 'dd', 'category') }
            />
          </Col>
          <Col sm='12' md="3">
            <Input
              label={t('ClientsGeneralEdit.systemCode.systemCode')}
              className="mb-3 w-100"
              disabled={true}
              key={Math.random()}
              name={t('ClientsGeneralEdit.systemCode.placeholder')}
              defaultValue={CLIENTS_DATA.editClient.systemCode}
              placeholder="eg. sc-***"
            />
          </Col>
          <Col sm='12' md="3">
            <Input
              label={t('ClientsGeneralEdit.manualCode.manualCode')}
              className="mb-3 w-100"
              name="manualCode"
              type="text"
              value={CLIENTS_DATA.editClient.manualCode}
              placeholder={t('ClientsGeneralEdit.manualCode.placeholder')}
              onChange = {(e) => handleOnChange(e) }
            />
          </Col>
          <Col sm='12' md="3" className="d-flex align-items-center justify-content-end">
            <Button disabled className="disabled" type='button'>{t('ClientsGeneralEdit.files')}</Button>
          </Col>
        </Row>
        <Row>
          <Col sm='12' md="4">
          <div className="mb-3">
            <Input
                label={t('ClientsGeneralEdit.name.name')}
                required = {true}
                className="w-100"
                name="name"
                type="text"
                value={CLIENTS_DATA.editClient.name}
                placeholder={t('ClientsGeneralEdit.name.placeholder')}
                onChange = {(e) => handleOnChange(e) }
              />
              {showErrors && CLIENTS_DATA.editClient.name === '' && <div className="text-danger">{t('ClientsGeneralEdit.name.required')}</div> }
            </div>
          </Col>
          {CLIENTS_DATA.editClient.categoryName !== "" &&
              CLIENTS_DATA.editClient.categoryName !== "Retail" &&
              CLIENTS_DATA.editClient.categoryName !== "Patient" &&
          <Col sm='12' md="4">
            <Input
              label={t('ClientsGeneralEdit.smallName.smallName')}
              className="mb-3 w-100"
              name="smallName"
              type="text"
              value={CLIENTS_DATA.editClient.smallName}
              placeholder={t('ClientsGeneralEdit.smallName.placeholder')}
              onChange = {(e) => handleOnChange(e) }
            />
          </Col>
          }
          <Col sm='12' md="4">
            <div className='mb-3'>
              <Dropdown
                label={t('ClientsGeneralEdit.vatCategory.vatCategory')}
                classes="w-100"
                required={true}
                name="vatCategory"
                key = {Math.random()}
                defaultValue={getDefaultOptionByValue(vatCategoryList, CLIENTS_DATA.editClient.vatCategory, 'value', 'label', 'value')}
                options={vatCategoryList}
                onChange = {(e) => handleOnChange(e, 'dd', 'vatCategory') }
              />
              {showErrors && CLIENTS_DATA.editClient.vatCategory === '' && <div className="text-danger">{t('ClientsGeneralEdit.vatCategory.required')}</div> }
            </div>
            </Col>
          {(CLIENTS_DATA.editClient.vatCategory !== "" && CLIENTS_DATA.editClient.vatCategory === 'Zero VAT') && (
              <Col sm="12" md="4">
                <div className="mb-3">
                  <Dropdown
                      classes='w-100'
                      label={t('ClientsGeneralEdit.0VatReason.0VatReason')}
                      key = {Math.random()}
                      name="vatReason"
                      required={true}
                      options={zeroVatReasonOptions}
                      defaultValue={CLIENTS_DATA.editClient.vatReason ? { value: CLIENTS_DATA.editClient.vatReason, label: getLabelByValue(zeroVatReasonOptions, CLIENTS_DATA.editClient.vatReason,'value'), color: "#76AEB7" } : null}
                      onChange = {(e) => handleOnChange(e, 'dd', 'vatReason')}
                  />
                  {showErrors && CLIENTS_DATA.editClient.vatCategory === 'Zero VAT' && CLIENTS_DATA.editClient.vatReason === "" && (
                      <div className="text-danger">{t('ClientsGeneralEdit.vatCategory.required')}.</div>
                  )}
                </div>
              </Col>
          )}
          { (CLIENTS_DATA.editClient.vatCategory !== "" && CLIENTS_DATA.editClient.vatCategory === 'Custom VAT') &&
              <Col xs="12" md="4">
                <div className="mb-3">
                  <Input
                      name="customVat"
                      type="number"
                      validate={isValidCustomVat}
                      placeholder={t('ClientsGeneralEdit.customVat%.placeholder')}
                      label={t('ClientsGeneralEdit.customVat%.customVat%')}
                      charLimit={3}
                      onChange = {(e) => handleOnChange(e)}
                      value={ CLIENTS_DATA.editClient.customVat }
                  />
                  { (CLIENTS_DATA.editClient.vatCategory === 'Custom VAT' &&
                          CLIENTS_DATA.editClient.customVat === "") &&
                      <div className="text-danger">{t('ClientsGeneralEdit.customVat%.required')}</div>
                  }
                </div>
              </Col>
          }
        </Row>
        <Row>
          {/* Retail: Add 999999999, do not validate VAT number. Field not mandatory */}
          {/* Client inside E.U.,outside E.U., Do not validate VAT, do not enter anything. Not mandatory field */}
          { (CLIENTS_DATA.editClient.categoryName === 'Retail' || CLIENTS_DATA.editClient.categoryName === 'Patient') &&
            <Col sm='12' md="4">
                <Input
                label={t("General.vatNumber")}
                className="mb-3 w-100 bg-white"
                name="vatNumber"
                type="number"
                value={CLIENTS_DATA.editClient.vatNumber}
                onChange = {(e) => handleOnChange(e) }
              />
            </Col>
          }
           {/* Freelancer, O.E., E.E., E.P.E., I.K.E., A.E.: Validate VAT number, use "Get Details" button to get company details. Mandatory field */}
           { (
            CLIENTS_DATA.editClient.categoryName === 'Freelancer' ||
            CLIENTS_DATA.editClient.categoryName === 'O.E.' ||
            CLIENTS_DATA.editClient.categoryName === 'E.E.' ||
            CLIENTS_DATA.editClient.categoryName === 'E.P.E.' ||
            CLIENTS_DATA.editClient.categoryName === 'I.K.E.' ||
               CLIENTS_DATA.editClient.categoryName === "Non-Profit Organization" ||
            CLIENTS_DATA.editClient.categoryName === 'A.E.' ||
               CLIENTS_DATA.editClient.categoryName === 'Wholesale Others' ||
           CLIENTS_DATA.editClient.categoryName === "Client within E.U." ||
           CLIENTS_DATA.editClient.categoryName === "Client outside E.U.") && (
            <Col sm='12' md="4">
              <div className='mb-3'>
                <Input
                  label={t('ClientsGeneralEdit.vatNumber.vatNumber')}
                  className="w-100 bg-white"
                  name="vatNumber"
                  required={true}
                  charLimit={15}
                  type={CLIENTS_DATA.editClient.categoryName === 'Wholesale Others' ||
                      CLIENTS_DATA.editClient.categoryName === "Client within E.U." ||
                      CLIENTS_DATA.editClient.categoryName === "Client outside E.U." ? "text" : "number"}
                  value={CLIENTS_DATA.editClient.vatNumber}
                  placeholder={t('ClientsGeneralEdit.vatNumber.placeholder')}
                  onChange = {(e) => handleOnChange(e) }
                />
                {showErrors && CLIENTS_DATA.editClient.vatNumber === '' && <div className="text-danger">{t('ClientsGeneralEdit.vatNumber.required')}</div> }
              </div>
            </Col>
           )}

          {/* Show only if category is NOT Retail or Patient */}
          {/* Use "Get Greek Company Details from taxisnet" algorithm */}
          { (CLIENTS_DATA.editClient.categoryName !== '' &&
            CLIENTS_DATA.editClient.categoryName !== 'Retail' &&
            CLIENTS_DATA.editClient.categoryName !== 'Patient' &&
              CLIENTS_DATA.editClient.categoryName !== "Client within E.U." &&
              CLIENTS_DATA.editClient.categoryName !== "Client outside E.U.") && (
            <Col sm='12' md="2" className="d-flex align-items-center">
              <Button onClick={() => fetchTaxisNetDetails(CLIENTS_DATA.editClient.vatNumber)} size="sm" className="mb-3" type='button'>{t('ClientsGeneralEdit.getDetails')}</Button>
            </Col>
              )}
          {CLIENTS_DATA.editClient.categoryName === "Client within E.U." && (
              <Col sm="12" md="2" className="d-flex align-items-center">
                <Button
                    onClick={() => fetchViesDetails(CLIENTS_DATA.editClient.vatNumber, CLIENTS_DATA.editClient.country)}
                    size="sm"
                    className="mb-3"
                    type="button"
                >
                  VIES CHECK
                </Button>
              </Col>
          )}
          {(CLIENTS_DATA.editClient.categoryName === "Freelancer" ||
              CLIENTS_DATA.editClient.categoryName === "O.E." ||
              CLIENTS_DATA.editClient.categoryName === "E.E." ||
              CLIENTS_DATA.editClient.categoryName === "E.P.E." ||
              CLIENTS_DATA.editClient.categoryName === "I.K.E." ||
              CLIENTS_DATA.editClient.categoryName === "A.E." ||
              CLIENTS_DATA.editClient.categoryName === "Non-Profit Organization" ||
              CLIENTS_DATA.editClient.categoryName === 'Wholesale Others') && (
          <Col sm='12' md="3">
            <Dropdown
              label={t('ClientsGeneralEdit.doy.doy')}
              classes="w-100"
              required={
                  (
                      CLIENTS_DATA.editClient.categoryName !== 'Retail' &&
                      CLIENTS_DATA.editClient.categoryName !== 'Patient' &&
                      CLIENTS_DATA.editClient.categoryName !== 'Client within E.U.' &&
                      CLIENTS_DATA.editClient.categoryName !== 'Client outside E.U.'
                  )
              }
              searchEnable = {true}
              name="DOY"
              key = {Math.random()}
              defaultValue={getDefaultOptionByValue(getDOYOptions(), Number(CLIENTS_DATA.editClient.DOY), 'value', 'label', 'value')}
              options={getDOYOptions()}
              onChange = {(e) => handleOnChange(e, 'dd', 'DOY') }
            />
            { (
              CLIENTS_DATA.editClient.categoryName !== 'Retail' &&
              CLIENTS_DATA.editClient.categoryName !== 'Patient' &&
              CLIENTS_DATA.editClient.categoryName !== 'Client within E.U.' &&
              CLIENTS_DATA.editClient.categoryName !== 'Client outside E.U.'
              ) &&
              showErrors &&
              CLIENTS_DATA.editClient.DOY === '' &&
              <div className="text-danger">{t('ClientsGeneralEdit.doy.required')}</div>
            }
          </Col> )}
          {(CLIENTS_DATA.editClient.categoryName === "Freelancer" ||
              CLIENTS_DATA.editClient.categoryName === "O.E." ||
              CLIENTS_DATA.editClient.categoryName === "E.E." ||
              CLIENTS_DATA.editClient.categoryName === "E.P.E." ||
              CLIENTS_DATA.editClient.categoryName === "I.K.E." ||
              CLIENTS_DATA.editClient.categoryName === "A.E." ||
              CLIENTS_DATA.editClient.categoryName === "Non-Profit Organization" ||
              CLIENTS_DATA.editClient.categoryName === 'Wholesale Others') && (
          <Col sm='12' md="3">
            <Input
                label={t('ClientsGeneralEdit.activity.activity')}
                className="w-100"
                name="activity"
                type="text"
                charLimit={1000}
                required={
                    CLIENTS_DATA.editClient.categoryName !== "Retail" &&
                    CLIENTS_DATA.editClient.categoryName !== "Patient"
                }
                value={CLIENTS_DATA.editClient.activity}
                placeholder={t('ClientsGeneralEdit.activity.placeholder')}
                onChange={(e) => handleOnChange(e)}
            />
            {CLIENTS_DATA.editClient.categoryName !== "Retail" &&
                CLIENTS_DATA.editClient.categoryName !== "Patient" &&
                showErrors &&
                CLIENTS_DATA.editClient.activity === "" && (
                    <div className="text-danger">{t('ClientsGeneralEdit.activity.required')}</div>
                )}
          </Col>
          )}
          {/* show only if Category is "Patient" */}
          { (CLIENTS_DATA.editClient.categoryName === 'Patient') &&
            <Col sm='12' md="3">
              <div className='mb-3'>
                <Input
                  label={t('ClientsGeneralEdit.amka.amka')}
                  className="mb-3 w-100"
                  name="amka"
                  type="text"
                  charLimit={30}
                  value={CLIENTS_DATA.editClient.amka}
                  placeholder="Enter AMKA"
                  onChange = {(e) => handleOnChange(e) }
                />
                {showErrors &&
                    CLIENTS_DATA.editClient.categoryName === "Patient"  &&
                    CLIENTS_DATA.editClient.email === "" &&
                    CLIENTS_DATA.editClient.amka === "" &&
                    CLIENTS_DATA.editClient.phone === "" && (
                        <div className="text-danger">{t('ClientsGeneralEdit.amka.required')}</div>
                    )}
              </div>
            </Col>
          }
        </Row>
        <Row>
          <Col sm='12' md="3">
            {/* Mandatory field EXCEPT if category retail & patient */}
            <Dropdown
              label={t('ClientsGeneralEdit.country.country')}
              classes="w-100"
              required={(CLIENTS_DATA.editClient.categoryName !== 'Retail' && CLIENTS_DATA.editClient.categoryName !== 'Patient')}
              name="country"
              searchEnable = {true}
              key = {Math.random()}
              defaultValue={getDefaultOptionByValue(allCountries, CLIENTS_DATA.editClient.country, 'value', 'label', 'value')}
              options={countriesList}
              onChange = {(e) => handleOnChange(e, 'dd', 'country') }
            />
            { (CLIENTS_DATA.editClient.categoryName !== 'Retail' &&
              CLIENTS_DATA.editClient.categoryName !== 'Patient') &&
              showErrors &&
              CLIENTS_DATA.editClient.country === '' &&
              <div className="text-danger">{t('ClientsGeneralEdit.country.required')}</div>
            }
          </Col>
          <Col sm='12' md="3">
            <div className='mb-3'>
              {/* Mandatory field EXCEPT if category retail & patient */}
              <Input
                label={t('ClientsGeneralEdit.address.address')}
                className="w-100"
                name="address"
                type="text"
                charLimit={100}
                required={(CLIENTS_DATA.editClient.categoryName !== 'Retail')}
                value={CLIENTS_DATA.editClient.address}
                placeholder={t('ClientsGeneralEdit.address.placeholder')}
                onChange = {(e) => handleOnChange(e) }
              />
              { (CLIENTS_DATA.editClient.categoryName !== 'Retail') &&
                showErrors &&
                CLIENTS_DATA.editClient.address === '' &&
                <div className="text-danger">{t('ClientsGeneralEdit.address.required')}</div>
              }
            </div>
          </Col>
          <Col sm='12' md="3">
            <div className='mb-3'>
              {/* Mandatory field EXCEPT if category retail & patient */}
              <Input
                  label={t('ClientsGeneralEdit.city.city')}
                  className="w-100"
                  name="city"
                  type="text"
                  charLimit={100}
                  required={
                      CLIENTS_DATA.editClient.categoryName !== "Retail" &&
                      CLIENTS_DATA.editClient.categoryName !== "Patient"
                  }
                  value={CLIENTS_DATA.editClient.city}
                  placeholder={t('ClientsGeneralEdit.city.placeholder')}
                  onChange={(e) => handleOnChange(e)}
              />
              {CLIENTS_DATA.editClient.categoryName !== "Retail" &&
                  CLIENTS_DATA.editClient.categoryName !== "Patient" &&
                  showErrors &&
                  CLIENTS_DATA.editClient.city === "" && (
                      <div className="text-danger">{t('ClientsGeneralEdit.city.required')}</div>
                  )}
            </div>
          </Col>
          <Col sm='12' md="3">
            {/* Mandatory field EXCEPT if category retail & patient */}
            <div className='mb-3'>
              <Input
                label={t('ClientsGeneralEdit.postalCode.postalCode')}
                className="w-100"
                name="postalCode"
                type="text"
                required={(CLIENTS_DATA.editClient.categoryName !== 'Retail' && CLIENTS_DATA.editClient.categoryName !== 'Patient')}
                value={CLIENTS_DATA.editClient.postalCode}
                placeholder={t('ClientsGeneralEdit.postalCode.placeholder')}
                onChange = {(e) => handleOnChange(e) }
              />
              { (CLIENTS_DATA.editClient.categoryName !== 'Retail' &&
                CLIENTS_DATA.editClient.categoryName !== 'Patient') &&
                showErrors &&
                CLIENTS_DATA.editClient.postalCode === '' &&
                <div className="text-danger">{t('ClientsGeneralEdit.postalCode.required')}</div>
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm='12' md="3">
            <div className='mb-3'>
              <Input
                label={t('ClientsGeneralEdit.email.email')}
                className="w-100"
                name="email"
                type="email"
                value={CLIENTS_DATA.editClient.email}
                placeholder={t('ClientsGeneralEdit.email.placeholder')}
                onChange = {(e) => handleOnChange(e) }
                required = {CLIENTS_DATA.editClient.phone === ''}
              />
              { showErrors &&
                CLIENTS_DATA.editClient.email !== '' && !isValidEmail(CLIENTS_DATA.editClient.email) &&
                <div className="text-danger">{t('ClientsGeneralEdit.email.error')}</div>
              }

              {showErrors &&
                  CLIENTS_DATA.editClient.categoryName !== "Patient"  &&
                  CLIENTS_DATA.editClient.categoryName !== "I.K.E."  &&
                  CLIENTS_DATA.editClient.categoryName !== "Freelancer"  &&
                  CLIENTS_DATA.editClient.categoryName !== "O.E."  &&
                  CLIENTS_DATA.editClient.categoryName !== "E.E."  &&
                  CLIENTS_DATA.editClient.categoryName !== "A.E."  &&
                  CLIENTS_DATA.editClient.categoryName !== "Non-Profit Organization"  &&
                  CLIENTS_DATA.editClient.categoryName !== "E.P.E."  &&
                  CLIENTS_DATA.editClient.categoryName !== "Wholesale Others"  &&
                  CLIENTS_DATA.editClient.email === "" &&
                  CLIENTS_DATA.editClient.phone === "" && (
                      <div className="text-danger">{t('ClientsNewGeneral.email.required')}</div>
                  )}
              {showErrors &&
                  CLIENTS_DATA.editClient.categoryName === "Patient"  &&
                  CLIENTS_DATA.editClient.email === "" &&
                  CLIENTS_DATA.editClient.amka === "" &&
                  CLIENTS_DATA.editClient.phone === "" && (
                      <div className="text-danger">{t('ClientsGeneralEdit.email.required')}</div>
                  )}
            </div>
          </Col>
          <Col sm='12' md="3">
            <Input
              label={t('ClientsGeneralEdit.phone.phone')}
              className="mb-3 w-100"
              name="phone"
              type="tel"
              charLimit={20}
              value={CLIENTS_DATA.editClient.phone}
              placeholder={t('ClientsGeneralEdit.phone.placeholder')}
              onChange = {(e) => handleOnChange(e) }
              required = {CLIENTS_DATA.editClient.email === ''}
            />
          </Col>
          <Col sm='12' md="3">
           <Input
              label={t('ClientsGeneralEdit.gemh.gemh')}
              className="mb-3 w-100"
              name="gemhNo"
              type="text"
              value={CLIENTS_DATA.editClient.gemhNo}
              placeholder={t('ClientsGeneralEdit.phone.placeholder')}
              onChange = {(e) => handleOnChange(e) }
            />
          </Col>
          <Col sm='12' md="3">
            <Input
              label={t('ClientsGeneralEdit.webpage.webpage')}
              className="mb-3 w-100"
              name="webpage"
              type="url"
              value={CLIENTS_DATA.editClient.webpage}
              placeholder={t('ClientsGeneralEdit.webpage.placeholder')}
              onChange = {(e) => handleOnChange(e) }
            />
          </Col>
        </Row>
        {checkPermission("custom-b2g", permissionsData) && (
        <Row>
          <Col md={5}>
            <div className={"text-muted mb-1"}><i>Πληροφορίες για Δημόσιες Συμβάσεις</i></div>
            <Button size={"sm"} className={"mr-3"} onClick={() => handleGetCompanyInfoPeppol()} disabled={loadingPeppolDetails}>
              Ανάκτηση Αναθετουσών από Α.Φ.Μ.
              {loadingPeppolDetails && <Spinner className={"ml-3"} size={"sm"} animation="border" variant="dark" />}
            </Button>
            <AnathetousesModal data={CLIENTS_DATA.editClient} />
          </Col>
        </Row>
        )}
      <hr/>
      <p className="text-muted"><i>{t('ClientsGeneralEdit.table.title')}</i></p>
      <EditableTable
        tableName="Edit Client Contact Points"
        key={"Edit Client Contact Points" + incrKey}
        allowActions={true}
        allowToggleColumns = { true }
        toggleColumns = {handleColumnShowHide}
        columns={tableColumns}
        data={ CLIENTS_DATA.editClient.contactPoints }
        allowInsertRow={true}
        enableNewRowCheckbox = {true}
        onUpdate={parentMydataActions}
      />

      <p><i>{t('ClientsGeneralEdit.clientNotes.clientNotes')}</i></p>
      <TextArea
         className="mb-3"
         rows="4"
         placeholder={t('ClientsGeneralEdit.clientNotes.placeholder')}
         name="clientNotes"
         value={CLIENTS_DATA.editClient.clientNotes}
         onChange = {(e) => handleOnChange(e) }
       />

      {/* Confirm Category Change */}
      <Modal backdrop={"static"} className="themeModal" show={inActiveClientWarning}>
        <Modal.Header closeButton>
          <Modal.Title>Warning!!</Modal.Title>
        </Modal.Header>
          <Modal.Body>
            Do you want to make all client connections inactive (automatic sales, quick retail settings)?
          </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={ () => showInActiveClientWarnning(false) }>No</Button>
          <Button variant="primary" onClick={ () => handleConfirmCategory() } >Yes</Button>
        </Modal.Footer>
      </Modal>

      { process.env.REACT_APP_DEBUG_MODE === 'true' && <pre>{JSON.stringify(CLIENTS_DATA.editClient, null, 2)}</pre> }
    </div>
  )
}

export const ClientsEditLog = () => {
  const { t } = useTranslation('common');
  const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);

  return (
      <React.Fragment>
        <div className="text-muted mb-2"><i>{t('Logs.clientLog')}</i></div>
        <LogTable itemId={CLIENTS_DATA.selectedClient._id}/>
      </React.Fragment>
  )
}

export default EditClientGeneral;
