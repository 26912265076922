import React, {useEffect, useRef, useState} from "react";
import Folder from "./Folder";
import File from "./File";
import ContextMenu from "./ContextMenu";
import {toast} from "react-toastify";
import {convertToBase64} from "./constants";

const FileBrowser = ({dirData, hasCopiedFile, onFileLeftClick, onFolderLeftClick, onFilesDrop, onNewFolder, onFileDelete, onFolderDelete,
                         onFileRename, onFolderRename, onFileDownload, onFileCopy, onFilePaste, onFileHistory, onFileDoubleClick, onPDFToImages,
                         onImagesToPDF, onMultipleDownloads, onPDFConcat, onMultipleDelete, onPDFToPDFs,
                         onEmronDelete, onEmronMultipleDelete, onEmronUnlock, specialCode, canUpload}) => {
    const fileBrowserRef = useRef();
    const [isDraggingFile, setIsDraggingFile] = useState(false);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuX, setContextMenuX] = useState(0);
    const [contextMenuY, setContextMenuY] = useState(0);
    const [contextMenuFolderLocker, setContextMenuFolderLocked] = useState(false);

    const [someModalOpen, setSomeModalOpen] = useState(false);

    const permissionsData = JSON.parse(localStorage.getItem("user"))?.permissions;
    const canUnlock = permissionsData.some((el) => el.permissionName === "custom-logistirio");

    const [fileSelections, setFileSelections] = useState([]); // For multiple selection when holding control
    const [folderSelections, setFolderSelections] = useState([]); // For multiple selection when holding control

    const [rectOriginX, setRectOriginX] = useState(0);
    const [rectOriginY, setRectOriginY] = useState(0);
    const [rectTargetX, setRectTargetX] = useState(0);
    const [rectTargetY, setRectTargetY] = useState(0);
    const [holding, setHolding] = useState(false);

    const folderRefs = useRef({});
    const fileRefs = useRef({});

    useEffect(() => {
        handleDeselections();
    }, [dirData])

    const fileBrowserStyle = {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        gap: "20px 20px",
        minHeight: "50vh",
        maxHeight: "60vh",

        position: "relative",
        padding: "10px",
        borderTopLeftRadius: "18px",
        borderBottomLeftRadius: "18px",
        border: (isDraggingFile && canUpload) ? "2px dashed lightgray" : "2px solid lightgray",
        overflowY: "auto",
        width: "100%",
        backgroundColor: (isDraggingFile && canUpload) ? "#ebebeb" : "",
        alignItems: "flex-start",
        alignContent: "flex-start",
    }

    const onDragOver = (e) => {
        if (!someModalOpen && canUpload && !holding) {
            e.preventDefault();
            e.stopPropagation();
            if (!isDraggingFile) setIsDraggingFile(true);
        }
    }

    const onDragEnter = (e) => {
        if (!someModalOpen && canUpload && !holding) {
            e.preventDefault();
            e.stopPropagation();
            if (!isDraggingFile) setIsDraggingFile(true);
        }
    }

    const onDragLeave = (e) => {
        if (!someModalOpen && canUpload && !holding) {
            e.preventDefault();
            e.stopPropagation();
            if (isDraggingFile) setIsDraggingFile(false);
        }
    }

    const onDrop = async (e) => {
        if (!someModalOpen && canUpload && !holding) {
            e.preventDefault();
            e.stopPropagation();
            setIsDraggingFile(false);
            const files = e.dataTransfer.files;
            if (files.length) {
                for (let file of files) {
                    if (file.size % 4096 === 0) {
                        return toast.error("Δεν είναι δυνατή η μεταφορά φακέλου.");
                    } else if (file.type === "") {
                        return toast.error("Δεν είναι δυνατή η μεταφορά άμορφο αρχείου.");
                    }
                }
                const arr = [];
                for (let file of files) {
                    const base64Encoded = await convertToBase64(file);
                    arr.push({
                        fileName: file.name,
                        base64Encoded: base64Encoded,
                    })
                }
                onFilesDrop(arr);
            }
        }
    }

    const onContextMenu = (e, type, name = "", locked = false) => {
        // type: file, folder or parent
        if (!someModalOpen && !holding) {
            e.stopPropagation();
            e.preventDefault();
            if (specialCode === "1" && type !== "file" && !locked && !canUnlock) return;

            if (type === "file") {
                if (!fileSelections.includes(name)) {
                    setFileSelections([name]);
                    setFolderSelections([]);
                }
            } else if (type === "folder") {
                if (!folderSelections.includes(name)) {
                    setFileSelections([]);
                    setFolderSelections([name]);
                }
            }

            const bounds = fileBrowserRef.current?.getBoundingClientRect();
            setShowContextMenu(true);

            let initX = e.clientX - bounds.left;
            if (initX + 120 > bounds.width) initX -= 120;
            setContextMenuX(initX);

            let initY = e.clientY - bounds.top;
            if (initY + 50 > bounds.height) initY -= 50;
            initY += fileBrowserRef.current.scrollTop;
            setContextMenuY(initY);

            setContextMenuFolderLocked(locked);
        }
    }

    const handleCloseContextMenu = () => {
        if (showContextMenu) setShowContextMenu(false);
    }

    const handleCloseAll = () => {
        handleCloseContextMenu();
        if (holding) setHolding(false);
    }

    const handleMouseOver = () => {
        if (isDraggingFile && !holding) setIsDraggingFile(false);
    }

    const handleFileLeftClick = (e, name) => {
        if (!holding) {
            if (e.ctrlKey) {
                let arr = [...fileSelections];
                if (!fileSelections.includes(name)) {
                    arr.push(name);
                } else {
                    arr = arr.filter((el) => el !== name);
                }
                setFileSelections(arr);
            } else {
                setFileSelections([name]);
                setFolderSelections([]);
                onFileLeftClick(name);
            }
        }
    }

    const handleFolderLeftClick = (e, name) => {
        if (!holding) {
            if (e.ctrlKey) {
                let arr = [...folderSelections];
                if (!folderSelections.includes(name)) {
                    arr.push(name);
                } else {
                    arr = arr.filter((el) => el !== name);
                }
                setFolderSelections(arr);
            } else {
                setFileSelections([]);
                setFolderSelections([name]);
                onFolderLeftClick(name);
            }
        }
    }

    const handleDeselections = () => {
        setFileSelections([]);
        setFolderSelections([]);
        folderRefs.current = {};
        fileRefs.current = {};
    }

    const onMouseDown = (e) => {
        if (e?.target === fileBrowserRef.current && e.buttons === 1 && !holding) {
            e.stopPropagation();
            e.preventDefault();
            setHolding(true);

            const bounds = fileBrowserRef.current?.getBoundingClientRect();
            setRectOriginX(e.clientX - bounds.left);
            setRectOriginY(e.clientY - bounds.top);
            setRectTargetX(e.clientX - bounds.left);
            setRectTargetY(e.clientY - bounds.top);
        }
    }

    const onMouseMove = (e) => {
        if (holding) {
            e.stopPropagation();
            e.preventDefault();
            const bounds = fileBrowserRef.current?.getBoundingClientRect();
            setRectTargetX(e.clientX - bounds.left);
            setRectTargetY(e.clientY - bounds.top);
        }
    }

    const onMouseUp = () => {
        if (holding) {
            setHolding(false);
            setRectOriginX(0);
            setRectOriginY(0);
            setRectTargetX(0);
            setRectTargetY(0);

            let tmpFolders = [];
            let tmpFiles = [];

            Object.values(folderRefs.current).forEach(({ ref, item }) => {
                const rect = {
                    left: ref.offsetLeft,
                    top: ref.offsetTop,
                    right: ref.offsetLeft + ref.offsetWidth,
                    bottom: ref.offsetTop + ref.offsetHeight,
                }
                if (isIntersecting(rect, rectOriginX, rectOriginY, rectTargetX, rectTargetY)) {
                    tmpFolders.push(item.name);
                }
            })

            Object.values(fileRefs.current).forEach(({ ref, item }) => {
                const rect = {
                    left: ref.offsetLeft,
                    top: ref.offsetTop,
                    right: ref.offsetLeft + ref.offsetWidth,
                    bottom: ref.offsetTop + ref.offsetHeight,
                }
                if (isIntersecting(rect, rectOriginX, rectOriginY, rectTargetX, rectTargetY)) {
                    tmpFiles.push(item.name);
                }
            })

            setFolderSelections(tmpFolders);
            setFileSelections(tmpFiles);
        }
    }

    const isIntersecting = (rect, originX, originY, targetX, targetY) => {
        const left = Math.min(originX, targetX);
        const right = Math.max(originX, targetX);
        const top = Math.min(originY, targetY);
        const bottom = Math.max(originY, targetY);

        return (
            rect.left < right &&
            rect.right > left &&
            rect.top < bottom &&
            rect.bottom > top
        )
    }

    return (
        <React.Fragment>
            <div
                 onDragEnter={(e) => onDragEnter(e)}
                 onDragLeave={(e) => onDragLeave(e)}
                 onDragOver={(e) => onDragOver(e)}
                 onDrop={(e) => onDrop(e)}

                 onMouseOver={(e) => handleMouseOver(e)}
                 onMouseLeave={() => handleCloseAll()}
                 onMouseDown={(e) => onMouseDown(e)}
                 onMouseMove={(e) => onMouseMove(e)}
                 onMouseUp={(e) => onMouseUp(e)}

                 onClick={() => handleCloseContextMenu()}
                 onContextMenu={(e) => onContextMenu(e, "parent")}
            >
                <div style={fileBrowserStyle} ref={fileBrowserRef}>
                    <ContextMenu
                        hasCopiedFile={hasCopiedFile}
                        show={showContextMenu}
                        posX={contextMenuX}
                        posY={contextMenuY}
                        fileSelections={fileSelections}
                        folderSelections={folderSelections}
                        locked={contextMenuFolderLocker}

                        onNewFolder={onNewFolder}
                        onFileDelete={onFileDelete}
                        onFolderDelete={onFolderDelete}
                        onFileRename={onFileRename}
                        onFolderRename={onFolderRename}
                        onFileDownload={onFileDownload}
                        onFileCopy={onFileCopy}
                        onFilePaste={onFilePaste}
                        onFileHistory={onFileHistory}
                        onPDFToImages={onPDFToImages}
                        onImagesToPDF={onImagesToPDF}
                        onMultipleDownloads={onMultipleDownloads}
                        onPDFConcat={onPDFConcat}
                        onMultipleDelete={onMultipleDelete}
                        onPDFToPDFs={onPDFToPDFs}

                        setSomeModalOpen={setSomeModalOpen}

                        onEmronDelete={onEmronDelete}
                        onEmronMultipleDelete={onEmronMultipleDelete}
                        onEmronUnlock={onEmronUnlock}

                        specialCode={specialCode}
                    />
                    {holding && (
                        <div style={{
                            position: "absolute",
                            left: rectOriginX < rectTargetX ? rectOriginX : rectTargetX,
                            top: rectOriginY < rectTargetY ? rectOriginY : rectTargetY,
                            width: Math.abs(rectOriginX - rectTargetX),
                            height: Math.abs(rectOriginY - rectTargetY),
                            backgroundColor: "lightblue",
                            border: "2px solid blue",
                            zIndex: "1000",
                            opacity: "0.3",
                        }}>
                        </div>
                    )}
                    {dirData.length > 0 && dirData.map((item, idx) => {
                        if (item.folder === true) {
                            return (
                                <Folder
                                    ref={(el) => {
                                        if (el) folderRefs.current[item.name] = { ref: el, item };
                                    }}
                                    key={`folder-${idx}`}
                                    name={item.name}
                                    fileCount={item.fileCount}
                                    locked={item.locked}
                                    hasSubfolders={item.hasSubfolders}
                                    onContextMenu={onContextMenu}
                                    onFolderLeftClick={handleFolderLeftClick}
                                    selected={folderSelections.includes(item.name)}
                                    specialCode={specialCode}
                                />
                            )
                        } else {
                            return (
                                <File
                                    ref={(el) => {
                                        if (el) fileRefs.current[item.name] = { ref: el, item };
                                    }}
                                    key={`file-${idx}`}
                                    name={item.name}
                                    onContextMenu={onContextMenu}
                                    onFileLeftClick={handleFileLeftClick}
                                    onFileDoubleClick={onFileDoubleClick}
                                    selected={fileSelections.includes(item.name)}
                                />
                            )
                        }
                    })}
                </div>
            </div>
        </React.Fragment>
    )
}

export default FileBrowser;
