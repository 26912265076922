import React from "react";
import {Badge} from "react-bootstrap";
import {formatInstallation} from "./Components/constants";
import {useSelector} from "react-redux";

const EmronCloudTotals = ({emronTotals}) => {
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const invalidTotals = !emronTotals || !Object.keys(emronTotals).length;

    const containerStyle = {
        flex: "1",
        borderRadius: "8px",
        border: "1px solid lightgray",
        overflow: "hidden",
    }

    const headerStyle = {
        fontWeight: "600",
        textAlign: "center",
        paddingTop: "5px",
        paddingBottom: "5px",
    }

    const lineStyle = {
        position: "relative",
        borderTop: "1px solid lightgray",
        paddingTop: "3px",
        paddingBottom: "3px",
        paddingLeft: "5px",
    }

    const getLineStyle = (data) => {
        const attention = Number(String(data).split("|")[0]) > 0;

        return {
            ...lineStyle,
            backgroundColor: attention ? "#fceca2" : "",
        }
    }

    const renderTotal = (data) => {
        const split = String(data).split("|");
        const ataks = split[0];
        const taks = split[1];
        const kat = split[2];
        return (
            <React.Fragment>
                <div style={{textAlign: "right", position: "absolute", right: "2px", top: "1px"}}>
                    <Badge bg={"danger"} style={{padding: "5px"}} title={"Αταξινόμητα"}>{ataks}</Badge>
                    {" / "}
                    <Badge bg={"warning"} style={{padding: "5px"}} title={"Ταξινομημένα"}>{taks}</Badge>
                    {" / "}
                    <Badge bg={"success"} style={{padding: "5px"}} title={"Καταχωρημένα"}>{kat}</Badge>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {!invalidTotals && (
                <div style={{display: "flex", width: "100%", gap: "5px", overflow: "none"}} className={"mb-3"}>
                    <div style={containerStyle}>
                        <div style={headerStyle}>
                            ΜΗΝΕΣ
                        </div>
                        {Object.keys(emronTotals.months).map((month, idx) => (
                            <div style={getLineStyle(emronTotals.months[month])} key={`totals-month-${idx}`}>
                                <div style={{width: "70%", wordBreak: "break-all"}}>
                                    {month}
                                </div>
                                {renderTotal(emronTotals.months[month])}
                            </div>
                        ))}
                    </div>
                    <div style={containerStyle}>
                        <div style={headerStyle}>
                            ΕΓΚΑΤΑΣΤΑΣΕΙΣ
                        </div>
                        {Object.keys(emronTotals.installations).map((instId, idx) => (
                            <div style={getLineStyle(emronTotals.installations[instId])} key={`totals-installations-${idx}`}>
                                <div style={{width: "70%", wordBreak: "break-word"}}>
                                    {formatInstallation(instId, companyInstallations)}
                                </div>
                                {renderTotal(emronTotals.installations[instId])}
                            </div>
                        ))}
                    </div>
                    <div style={containerStyle}>
                        <div style={headerStyle}>
                            ΥΠΟΚΑΤΗΓΟΡΙΕΣ
                        </div>
                        {Object.keys(emronTotals.subCategories).map((subCat, idx) => (
                            <div style={getLineStyle(emronTotals.subCategories[subCat])} key={`totals-subCategories-${idx}`}>
                                <div style={{width: "70%", wordBreak: "break-word"}}>
                                    {subCat}
                                </div>
                                {renderTotal(emronTotals.subCategories[subCat])}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default EmronCloudTotals
