import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

let date = new Date();
const currentTime = date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0');

const newEmployeeInitialValue = {
    isActive: "active",
    isFromHyper: "false",
    firstName: "",
    lastName: "",
    fatherFirstName: "",
    fatherLastName: "",
    motherFirstName: "",
    motherLastName: "",
    phone: "",
    sex: "",
    birthDate: "",
    vatNumber: "",
    amka: "",
    amika: "",
    employeeCategory: "",
    employeeType: "",
    specialty: "",
    installation: "",
    employmentDate: "",
    salary: 0,
    resignationDate: "",
    resignationCause: "",
    notes: "",
    email: "",
    ama: "",

    workingDaysPerWeek: "",
    workingHoursPerWeek: "",
    symbatikesWorkingHoursPerWeek: "",
    flexibleAttendanceMinutes: "",
    workingExperienceYears: "",
    firstYearPermits: "",
    breakMinutes: "",
    breakMinutesWhen: "in",
    autoSendErganiCard: false,
    erganiCard: false,
    kathestos: "full-time",
}

const newAccountingAccountInitialValue = {
    isActive: "active",
    category: "",
    categoryName: "",
    code: "",
    name: "",
    balance: 0,
    alternativeName: "",
    transferable: "active",
    movable: "active",
    vat: "",
    vatreason: "",
    isNetValue: "false",
    subAccount: "",
    notes: "",
    taxType: "",
    withHoldTax: "",
    otherTax: "",
    fee: "",
    stamp: "",
    mydata: [],
    clientCategories: [],
    supplierCategories: [],
    employeeCategories: []
}

const newAccountingRecordInitialValue = {
    category: "",
    categoryName: "",
    myDataType: "",
    subCategory: "",
    subCategoryName: "",
    documentType: "",
    documentTypeName: "",
    recordType: "normal",
    date: null,
    time: currentTime,
    installation: "",
    preNumber: "",
    number: "",
    reason: "",
    paymentType: "",
    paymentTypeName: "",
    myDataPaymentId: "",
    bankAccount: "",
    bankAccountName: "",
    accounts: [],
    totalCredit: 0,
    totalDebit: 0,
    notes: "",
    myDataError: "",
    mark: "",
    uid: "",
    myDataStatus: "not_sent"
}

const accountingSettingsInitialValue = {
    categories: [
        {
            name: "",
            subCategories: [{
                name: "",
                accountingRecords: [],
                documentType: "",
                installation: "",
                reason: ""
            }]
        }
    ]
}

export const newAccountingAccountCategoryInitialValue = {
    category: "",
    mydata: [],
    subAccount: "",
    vat: "",
    vatreason: "",
    isNetValue: "false",
    taxType: "",
    withHoldTax: "",
    otherTax: "",
    fee: "",
    stamp: "",
    isActive: "active"

}

const newAccountingRecordCategoryInitialValue = {
    name: "",
    subCategories: []
}

const newAccountingRecordSubCategoryInitialValue = {
    name: "",
    documentType: "",
    reason: "",
    recordType: "normal",
    accounts: []
}

const newAccountInstanceInitialValue = {
    no: 1,
    id: 1,
    code: "",
    name: "",
    subAccountType: "",
    isNetValue: "false",
    vat: "",
    taxType: "",
    withHoldTax: "",
    otherTax: "",
    fee: "",
    stamp: "",
    vatReason: "",
    subAccountCategories: [],
    subAccountData: [],
    classificationType: "",
    classificationCategory: "",
    mydata: [],
    subAccount: "",
    subAccountId: "",
    credit: "",
    debit: ""
}

const initialState = {
    newEmployeeInitialValue: newEmployeeInitialValue,
    newAccountingAccountInitialValue: newAccountingAccountInitialValue,
    newEmployee: newEmployeeInitialValue,
    editEmployee: newEmployeeInitialValue,
    newAccountingAccount: newAccountingAccountInitialValue,
    editAccountingAccount: newAccountingAccountInitialValue,
    accountingSettings: accountingSettingsInitialValue,
    searchEmployees: [],
    totalItemsES: 0,
    perPageES: 0,
    searchAccountingAccounts: [],
    totalItemsAAS: 0,
    perPageAAS: 0,
    accountingCategories: [],
    newAccountingRecordCategory: newAccountingRecordCategoryInitialValue,
    newAccountingRecordSubCategory: newAccountingRecordSubCategoryInitialValue,
    newAccountInstance: newAccountInstanceInitialValue,
    accountingRecordCategories: [],
    accountingRecordSubcategories: [],
    accountingAdvancedTypes: [],
    newAccountingRecord: newAccountingRecordInitialValue,
    editAccountingRecord: newAccountingRecordInitialValue,
    searchAccountingRecords: [],
    totalItemsAR: 0,
    perPageAR: 0,

    paymentTypes: [],

    // Isozygio report
    isozygioRequestData: {
        degree: 4,
        date_from: "",
        date_to: "",
        installation: "",
        countClosure: true,
        analysis: [],
    },
    isozygioResponseData: {},
    isozygioDisplayData: {
        fontSize: 14,
        degreeTotals: [],
    },

    // Accounts report
    accountsReportRequestData: {
        code_prefix: "",
        analysis: [],
        date_from: "",
        date_to: "",
        installation: "",
        subAccount: "",
        subAccountInput: "",
    },
    accountsReportResponseData: {},
    accountsReportDisplayData: {
        fontSize: 14,
    },

    // Accounts Calendar Report
    calendarReportRequestData: {
        code_prefix: "",
        date_from: "",
        date_to: "",
        installation: "",
        subAccount: "",
        subAccountInput: "",
    },
    calendarReportResponseData: [],
    calendarReportDisplayData: {
        fontSize: 14,
    },

    // Accounting General Calendar Report
    generalCalendarReportRequestData: {
        page: 1,
        code_prefix: "",
        date_from: "",
        date_to: "",
        installation: "",
        subAccount: "",
        subAccountInput: "",
    },
    generalCalendarReportResponseData: {
        data: [],
        totalPages: 0,
    },
    generalCalendarReportDisplayData: {
        fontSize: 14,
    },

    // Accounting Bridge
    accountingBridgeFilters: {
        section: "",
        date_from: moment().startOf("month").format("DD/MM/YYYY"),
        date_to: moment().endOf("month").format("DD/MM/YYYY"),
        showUnbridgable: false,
    },
    accountingBridgeReportData: [],

    // Accounting Year Report
    accountingYearReportRequestData: {
        firstLoad: true,
        forCompany: [],
    },
    accountingYearReportResponseData: {
        data: [],
        columns: [],
    }
}

export const AccountingSlice = createSlice({
    name: "accounting",
    initialState,
    reducers: {
        setNewEmployee: (state, {payload}) => {
            state.newEmployee = payload;
        },
        setEditEmployee: (state, {payload}) => {
            state.editEmployee = payload;
        },
        setNewAccountingAccount: (state, {payload}) => {
            state.newAccountingAccount = payload;
        },
        setEditAccountingAccount: (state, {payload}) => {
            state.editAccountingAccount = payload;
        },
        setSearchEmployees: (state, {payload}) => {
            state.searchEmployees = payload;
        },
        setSearchAccountingAccounts: (state, {payload}) => {
            state.searchAccountingAccounts = payload;
        },
        setTotalItemsES: (state, {payload}) => {
            state.totalItemsES = payload;
        },
        setperPageES: (state, {payload}) => {
            state.perPageES = payload;
        },
        setTotalItemsAAS: (state, {payload}) => {
            state.totalItemsAAS = payload;
        },
        setperPageAAS: (state, {payload}) => {
            state.perPageAAS = payload;
        },
        resetNewEmployee: (state) => {
            state.newEmployee = state.newEmployeeInitialValue;
        },
        resetNewAccountingAccount: (state) => {
            state.newAccountingAccount = state.newAccountingAccountInitialValue;
        },
        setAccountingSettings: (state, {payload}) => {
            state.accountingCategories = payload;
        },
        setNewAccountingRecordCategory: (state, {payload}) => {
            state.newAccountingRecordCategory = payload;
        },
        resetNewAccountingRecordCategory: (state) => {
            state.newAccountingRecordCategory = newAccountingRecordCategoryInitialValue;
        },
        setNewAccountingRecordSubCategory: (state, {payload}) => {
            state.newAccountingRecordSubCategory = payload;
        },
        resetNewAccountingRecordSubCategory: (state) => {
            state.newAccountingRecordSubCategory = newAccountingRecordSubCategoryInitialValue;
        },
        setAccountingRecordCategories: (state, {payload}) => {
            state.accountingRecordCategories = payload;
        },
        setAccountingRecordSubcategories: (state, {payload}) => {
            state.accountingRecordSubcategories = payload;
        },
        setAccountingAdvancedTypes: (state, {payload}) => {
            state.accountingAdvancedTypes = payload;
        },
        setNewAccountInstance: (state, {payload}) => {
            state.newAccountInstance = payload;
        },
        resetNewAccountingInstance: (state) => {
            state.newAccountInstance = newAccountInstanceInitialValue;
        },
        setNewAccountingRecord: (state, {payload}) => {
            state.newAccountingRecord = payload;
        },
        resetNewAccountingRecord: (state) => {
            state.newAccountingRecord = newAccountingRecordInitialValue;
        },
        softResetAccountingRecord: (state) => {
            state.newAccountingRecord.reason = "";
        },
        setEditAccountingRecord: (state, {payload}) => {
            state.editAccountingRecord = payload;
        },
        setTotalItemsAR: (state, {payload}) => {
            state.totalItemsAR = payload;
        },
        setperPageAR: (state, {payload}) => {
            state.perPageAR = payload;
        },
        setSearchAccountingRecords: (state, {payload}) => {
            state.searchAccountingRecords = payload;
        },
        setAccountingPaymentTypes: (state, {payload}) => {
            state.paymentTypes = payload;
        },

        // Isozygio Report
        setIsozygioRequestData: (state, {payload}) => {
            state.isozygioRequestData = payload;
        },
        setIsozygioDisplayData: (state, {payload}) => {
            state.isozygioDisplayData = payload;
        },
        setIsozygioResponseData: (state, {payload}) => {
            state.isozygioResponseData = payload;
        },

        // Accounting Accounts Report
        setAccountsReportRequestData: (state, {payload}) => {
            state.accountsReportRequestData = payload;
        },
        setAccountsReportDisplayData: (state, {payload}) => {
            state.accountsReportDisplayData = payload;
        },
        setAccountsReportResponseData: (state, {payload}) => {
            state.accountsReportResponseData = payload;
        },

        // Accounts Calendar Report
        setCalendarReportRequestData: (state, {payload}) => {
            state.calendarReportRequestData = payload;
        },
        setCalendarReportDisplayData: (state, {payload}) => {
            state.calendarReportDisplayData = payload;
        },
        setCalendarReportResponseData: (state, {payload}) => {
            state.calendarReportResponseData = payload;
        },

        // Accounts General Calendar Report
        setGeneralCalendarReportRequestData: (state, {payload}) => {
            state.generalCalendarReportRequestData = payload;
        },
        setGeneralCalendarReportDisplayData: (state, {payload}) => {
            state.generalCalendarReportDisplayData = payload;
        },
        setGeneralCalendarReportResponseData: (state, {payload}) => {
            state.generalCalendarReportResponseData = payload;
        },

        // Accounting Bridge
        setAccountingBridgeFilters: (state, {payload}) => {
            state.accountingBridgeFilters = payload;
        },
        setAccountingBridgeReportData: (state, {payload}) => {
            state.accountingBridgeReportData = payload;
        },

        // Accounting Year Report
        setAccountingYearReportRequestData: (state, {payload}) => {
            state.accountingYearReportRequestData = payload;
        },
        setAccountingYearReportResponseData: (state, {payload}) => {
            state.accountingYearReportResponseData = payload;
        }
    }
});

export const {
    setNewEmployee,
    setEditEmployee,
    setNewAccountingAccount,
    setEditAccountingAccount,
    setSearchEmployees,
    setSearchAccountingAccounts,
    setTotalItemsES,
    setperPageES,
    setTotalItemsAAS,
    setperPageAAS,
    resetNewEmployee,
    setAccountingSettings,
    resetNewAccountingAccount,
    setNewAccountingRecordCategory,
    resetNewAccountingRecordCategory,
    setAccountingRecordCategories,
    setNewAccountingRecordSubCategory,
    resetNewAccountingRecordSubCategory,
    setAccountingAdvancedTypes,
    setNewAccountInstance,
    resetNewAccountingInstance,
    setNewAccountingRecord,
    resetNewAccountingRecord,
    softResetAccountingRecord,
    setEditAccountingRecord,
    setperPageAR,
    setTotalItemsAR,
    setSearchAccountingRecords,
    setAccountingRecordSubcategories,
    setAccountingPaymentTypes,

    setIsozygioRequestData,
    setIsozygioDisplayData,
    setIsozygioResponseData,

    setAccountsReportRequestData,
    setAccountsReportDisplayData,
    setAccountsReportResponseData,

    setCalendarReportRequestData,
    setCalendarReportDisplayData,
    setCalendarReportResponseData,

    setGeneralCalendarReportRequestData,
    setGeneralCalendarReportDisplayData,
    setGeneralCalendarReportResponseData,

    setAccountingBridgeFilters,
    setAccountingBridgeReportData,

    setAccountingYearReportRequestData,
    setAccountingYearReportResponseData,
} = AccountingSlice.actions;
