import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getOptionsByData, getSubaccountOptions} from "../../../../_helpers/commonFunctions";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import SearchTable from "../../../primer/Search-Table";
import {classicStyleBelowNavbar} from "../Statics";

const SearchAccountingAccount = () => {
    const [t] = useTranslation('common');
    const subAccountOptions = getSubaccountOptions();
    const ACCOUNTING_CATEGORIES = useSelector((state) => state.ACCOUNTING_DATA.accountingCategories);
    let accountCategories = getOptionsByData(ACCOUNTING_CATEGORIES, "_id", "category");
    accountCategories = [{value: "", label: t("Null"), color: "#76AEB7"}, ...accountCategories];

    const movableOptions = [
        {label: t("General.empty"), value: ""},
        {label: t("General.yesOption"), value: "yes"},
        {label: t("General.noOption"), value: "no"}
    ]
    const transferableOptions = [
        {label: t("General.empty"), value: ""},
        {label: t("General.yesOption"), value: "yes"},
        {label: t("General.noOption"), value: "no"}
    ]
    const logisticOptions = [
        {label: t("General.empty"), value: ""},
        {label: t("General.isNetValue"), value: "isNetValue"},
        {label: t("AccountingAccountNew.isVat"), value: "vat"},
        {label: t("General.isTax"), value: "taxType"},
    ]

    const searchEmployeeFilters = {
        filters: [
            {
                name: "name",
                label: t("AccountingAccountSearch.filters.name"),
                type: "input",
            },
            {
                name: "code",
                label: t("AccountingAccountSearch.filters.code"),
                type: "input",
            },
            {
                name: "alternativeName",
                label: t("AccountingAccountSearch.filters.alternativeName"),
                type: "input"
            },
            {
                name: "category",
                label: t("AccountingAccountSearch.filters.category"),
                type: "multiselect",
                options: accountCategories
            },
            {
                name: "subAccount",
                label: t("AccountingAccountSearch.filters.subAccount"),
                type: "multiselect",
                options: subAccountOptions
            },
            {
                name: "movable",
                label: t("AccountingAccountSearch.columns.movable"),
                type: "dropdown",
                options: movableOptions,
            },
            {
                name: "transferable",
                label: t("AccountingAccountSearch.columns.transferable"),
                type: "dropdown",
                options: transferableOptions,
            },
            {
                name: "logisticOptions",
                label: t("General.accountingAccountType"),
                type: "dropdown",
                options: logisticOptions,
            },
            {
                name: "isActive",
                label: t("AccountingAccountSearch.filters.isActive"),
                type: "checkbox",
            },
        ]
    }

    const scColumns = [
        {
            name: t('AccountingAccountSearch.columns.code'),
            field: "code",
            width: "10%",
            hiddenColumn: false,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('AccountingAccountSearch.columns.name'),
            field: "name",
            width: "30%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('AccountingAccountSearch.columns.alternativeName'),
            field: "alternativeName",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('AccountingAccountSearch.columns.category'),
            field: "accountCategory",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Υπολογαριασμός",
            field: "subAccount",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('AccountingAccountSearch.columns.transferable'),
            field: "transferable",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('AccountingAccountSearch.columns.movable'),
            field: "movable",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
    ]

    const [tableColumns, setTableColumns] = useState(scColumns.slice());

    // It will hide or show the table columns for search table.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }

    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Accounting Account Search"
                key={"Accounting Account Search"}
                hideYearFilter={true}
                allowPagination={true}
                allowToggleColumns={true}
                toggleColumns={handleColumnShowHide}
                allowPrintResults={true}
                columns={tableColumns}
                data={[]}
                filtersConfig={searchEmployeeFilters}
            />
        </div>
    )
}

export default SearchAccountingAccount;
