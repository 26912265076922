import {createSlice} from "@reduxjs/toolkit";

const initFilePreviewObj = {
    name: "",
    path: "",
    data: null,
    size: 0, // In MB
}

const initialState = {
    currentPath: "/",
    currentPathLocked: false,
    dirData: [],
    previewFile: initFilePreviewObj,
    extraData: {
        spaceUsed: 0,
        cloudSpace: 0,
        cloudMaximumFileSize: 0,
        specialCode: "0",
    },
    copyFilePath: "",
    copyFileCut: false,

    autoPreview: "", // File to auto open preview on window load.
    autoDir: "", // Dir to auto open
    searchKeyword: "",

    specialCodeWas1: false,

    // Tree View
    openPaths: [],
    treeData: [{
        item: "/",
        displayName: "Αρχική",
        folder: true,
        fileCount: 0,
        hasSubfolders: true,
        content: [],
    }],

    // Emron functionalities
    disableCloudPage: false,
    disableEmronRegisterPage: false,

    saleMarkFromCloud: "",
    buyMarkFromCloud: "",
}

export const CloudSlice = createSlice({
    name: "cloud-slice",
    initialState,
    reducers: {
        setCurrentPath: (state, {payload}) => {
            state.currentPath = payload;
        },
        setCurrentPathLocked: (state, {payload}) => {
            state.currentPathLocked = payload;
        },
        setDirData: (state, {payload}) => {
            state.dirData = payload;
        },
        setPreviewFile: (state, {payload}) => {
            if (state.previewFile.data) URL.revokeObjectURL(state.previewFile.data); // Clear last blob preview from browser memory
            state.previewFile = payload;
        },
        resetPreviewFile: (state) => {
            if (state.previewFile.data) URL.revokeObjectURL(state.previewFile.data); // Clear last blob preview from browser memory
            state.previewFile = {...initFilePreviewObj};
        },
        setExtraData: (state, {payload}) => {
            state.extraData = payload;
            if (payload?.specialCode === "1" && !state.specialCodeWas1) state.specialCodeWas1 = true;
        },
        setCopyFilePath: (state, {payload}) => {
            state.copyFilePath = payload;
        },
        setCopyFileCut: (state, {payload}) => {
            state.copyFileCut = payload;
        },
        setAutoPreview: (state, {payload}) => {
            state.autoPreview = payload;
        },
        setSearchKeyword: (state, {payload}) => {
            state.searchKeyword = payload;
        },
        setAutoDir: (state, {payload}) => {
            state.autoDir = payload;
        },

        // Tree View
        setOpenPaths: (state, {payload}) => {
            state.openPaths = payload;
        },
        setTreeData: (state, {payload}) => {
            state.treeData = payload;
        },

        // Emron functionalities
        setDisableCloudPage: (state, {payload}) => {
            state.disableCloudPage = payload;
        },
        setDisableEmronRegisterPage: (state, {payload}) => {
            state.disableEmronRegisterPage = payload;
        },

        setSaleMarkFromCloud: (state, {payload}) => {
            state.saleMarkFromCloud = payload;
        },
        setBuyMarkFromCloud: (state, {payload}) => {
            state.buyMarkFromCloud = payload;
        }
    }
})

export const {
    setCurrentPath,
    setCurrentPathLocked,
    setDirData,
    setPreviewFile,
    resetPreviewFile,
    setExtraData,
    setCopyFilePath,
    setCopyFileCut,
    setAutoPreview,
    setSearchKeyword,
    setAutoDir,

    // Tree View
    setOpenPaths,
    setTreeData,

    // Emron functionalities
    setDisableCloudPage,
    setDisableEmronRegisterPage,

    setSaleMarkFromCloud,
    setBuyMarkFromCloud,
} = CloudSlice.actions;
