import React, {useEffect, useState} from "react";
import {checkPermission, clearEmptyValuesFromObject, getKeyByName} from "../../../_helpers/commonFunctions";
import {
    deleteObjectFromArrayOfObjects,
    getIndexByArrayValue,
    getObjectFromArrayOfObjects
} from "../../../_helpers/helperFunctions";
import TableBodyRow from "./TableBodyRow";
import TableFilterRow from "./TableFilterDataRow";
import TableInsertDataRow from "./TableInsertDataRow";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import axios from "axios";
import {addTab, updateActiveMainLink, updateActiveMainTab} from "../../../_reducers/TabsSlice";
import {
    setClientMovementsSearchByBalance,
    setEditClient, setEditClientMovements, setEditClientPayment,
    setSelectedClient, setSelectedClientMovement
} from "../../../_reducers/DataPanel/Clients/clientSlice";
import {
    setEditPriceList,
    setEditProductMovements,
    setSelectedEditProduct
} from "../../../_reducers/DataPanel/Products/productsSlice";
import {setEditPage} from "../../../_reducers/activitySlice";
import {setEditAdvancedType, setEditBasicType} from "../../../_reducers/settingsSlice";
import {
    setEditSupplier, setEditSupplierMovements,
    setEditSupplierPayment,
    setSelectedSupplier, setSupplierMovementsSearchByBalance
} from "../../../_reducers/DataPanel/Suppliers/supplierSlice";
import {
    setEditAccountingAccount,
    setEditAccountingRecord, setEditEmployee
} from "../../../_reducers/DataPanel/Accounting/accountingSlice";
import {setEditBankAccount, setEditCashRegisters, setEditPOS} from "../../../_reducers/DataPanel/Cash/cashSlice";
import {setEditSales} from "../../../_reducers/DataPanel/Sales/salesSlice";
import {setEditBuys} from "../../../_reducers/DataPanel/Buys/buysSlice";
import {setEditWorkMovement, setEditWorkPermit} from "../../../_reducers/DataPanel/Payroll/payrollSlice";

const TableBody = (props) => {
    const [t] = useTranslation('common');
    let {
        data,
        columns,
        allowInsertRow,
        allowInsertRowFromTop,
        selectedRow,
        tableName,
        allowFilterTable,
        checkedRows,
        quickEditCustomColumns,
        pRequestData,
        reqName,
        selectedData,
        setSelectedData,
        emronPaymentsMonth,
    } = props;
    const [bodyData, setBodyData] = useState(data);
    const [filters, setFilters] = useState({});
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const SETTINGS = useSelector(state => state.SETTINGS);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const dispatch = useDispatch();

    useEffect(() => {
        setBodyData(data);
    }, [data])


    // This is used on Table Filter Cell
    const filterFieldValue = (value, field) => {
        // Add the value ot new filter
        let filter = {[field]: value}
        let newFilters = {...filters, ...filter};
        setFilters(newFilters);

        // Filter Data
        let filteredData = filterTableBodyData(data, newFilters);
        if (Object.keys(newFilters).length <= 0) {
            setBodyData(data);
        } else {
            setBodyData(filteredData);
        }
    }

    const filterTableBodyData = (data, filter) => {

        data = deleteObjectFromArrayOfObjects(data, null, '_id') // remove if there is any null value
        filter = clearEmptyValuesFromObject(filter); // clear empty fields

        return data.filter(function (item) {
            let returnType = true;
            for (const key in filter) {
                if (key) {
                    let itemVal = item[key]?.toString().toLowerCase().trim();
                    let filterVal = filter[key]?.toString().toLowerCase().trim();

                    if (key === 'isActive') {
                        // Exact Match Case
                        if (item[key] === undefined || (itemVal !== filterVal)) {
                            returnType = false;
                        }
                    } else {
                        // Normal Case for input
                        if (
                            item[key] === undefined ||
                            (
                                itemVal !== filterVal &&
                                // itemVal !== "" && // Fix for empty values on filter
                                !itemVal?.includes(filterVal) &&
                                !itemVal?.startsWith(filterVal) &&
                                !itemVal?.endsWith(filterVal)
                            )
                        ) {
                            returnType = false;
                        }
                    }
                }
            }
            return returnType;
        });
    }

    const selectRow = (e, data) => {
        // It will select the row on single click
        if (tableName === 'companyModal') {
            if ((data._id !== selectedRow) && (e.detail === 1)) {
                setSelectedData({...selectedData, ...{_id: data._id}});
                props.onRowSelect.getRow(data);
                console.log({...selectedData, ...{_id: data._id}})
            }
        } else if (tableName === 'Product Variants') {
            if (e.detail === 2) {
                props.onRowSelect.getRow(data);
            }
        } else {
            if ((data._id !== selectedRow) && (e.detail === 1)) {
                props.onRowSelect.getRow(data);
            }
        }


    }

    const handleRowUpdate = (e, data, id, rowIndex) => {
        props.update && props.update(e, data, id, rowIndex);
    }

    const handleRowDoubleClick = (e, row) => {
        // It will handle the edit redirect on double click
        if (props.editRedirectAllowed) {
            openEditPage(e, row);
        } else if (props.onDoubleClick) {
            props.onDoubleClick(e, row);
        }
    }

    const handleCompanyDoubleClick = (e, row) => {
        if (props?.handleDoubleClick && (e.detail === 2)) {
            props.handleDoubleClick();
        }
    }

    const openEditPage = (e, row) => {
        const permissionsData = JSON.parse(localStorage.getItem("permissions"));
        // It will edit product
        if (tableName && getKeyByName(tableName) === "search_products") {
            if (checkPermission("products-products/edit-read", permissionsData)) {
                const id = "edit-product";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                sessionStorage.setItem("editProductData", JSON.stringify(row));
                dispatch(setSelectedEditProduct(row)); // set selected product
                dispatch(setEditPage(true)); // set product edit true
            } else {
                toast.error("You don't have permission to edit a product.");
            }
        } else if (tableName && tableName === "Client Balance Report") {
            const id = "clients-movements";
            if (!TABS_DATA.includes(id)) {
                dispatch(addTab(id));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            } else {
                dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
            }
            dispatch(setClientMovementsSearchByBalance(row)); // { _id: ... }
        } else if (tableName === "Client Isozygio Report") {
            const id = "clients-movements";
            if (!TABS_DATA.includes(id)) {
                dispatch(addTab(id));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            } else {
                dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
            }
            dispatch(setClientMovementsSearchByBalance({_id: row._id, clientName: row.name}));
        } else if (tableName && tableName === "Supplier Balance Report") {
            const id = "suppliers-movements";
            if (!TABS_DATA.includes(id)) {
                dispatch(addTab(id));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            } else {
                dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
            }
            dispatch(setSupplierMovementsSearchByBalance(row));
        } else if (tableName === "Supplier Isozygio Report") {
            const id = "suppliers-movements";
            if (!TABS_DATA.includes(id)) {
                dispatch(addTab(id));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            } else {
                dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
            }
            dispatch(setSupplierMovementsSearchByBalance({_id: row._id, supplierName: row.name}));
        } else if (tableName && getKeyByName(tableName) === "search_product_movements") {
            if (checkPermission("products-productsmovements/edit-read", permissionsData)) {
                const id = "edit-product-movements";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                sessionStorage.setItem("editProductMovementsData", JSON.stringify(row));
                dispatch(setEditProductMovements(row)); // set selected product
            } else {
                toast.error("You do not have permission to edit a product movement.");
            }
        } else if (tableName && getKeyByName(tableName) === "search_product_price_list") {
            if (checkPermission("products-pricelist/edit-read", permissionsData)) {
                const id = "edit-price-list";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                sessionStorage.setItem("editProductPriceListData", JSON.stringify(row));
                dispatch(setEditPriceList(row)); // set selected product
            } else {
                toast.error("You do not have permission to edit a price list.");
            }
        } else if (tableName && getKeyByName(tableName) === "client_search") {
            if (checkPermission("clients-clients/edit-read", permissionsData)) {
                const id = "edit-client";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setSelectedClient(row)); // set selected
                dispatch(setEditClient(row)); // set selected
                sessionStorage.setItem("editClientsLog", row);
            } else {
                toast.error("You do not have permission to edit a client.");
            }
        } else if (tableName && tableName === "Employees Search") {
            const id = "employee-edit";
            if (!TABS_DATA.includes(id)) {
                dispatch(addTab(id));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            } else {
                dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
            }
            dispatch(setEditEmployee(row)); // set selected
        } else if (tableName && tableName === "Accounting Account Search") {
            const id = "accounting-account-edit";
            if (!TABS_DATA.includes(id)) {
                dispatch(addTab(id));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            } else {
                dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
            }
            dispatch(setEditAccountingAccount(row)); // set selected
        } else if (tableName && tableName === "Accounting Record Search") {
            const id = "accounting-record-edit";
            if (!TABS_DATA.includes(id)) {
                dispatch(addTab(id));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            } else {
                dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
            }
            dispatch(setEditAccountingRecord(row)); // set selected
        } else if (tableName && tableName === "Emron Payments Report") {
            const id = "edit-client";
            axios.get(process.env.REACT_APP_API_URL2 + `/client/get-client-id-by/${row?._id}`)
                .then((res) => {
                    if (res.data && res.data.status === "200") {
                        let clientData = res.data.data;
                        dispatch(setEditClient(clientData)); // set selected
                        const idx = TABS_DATA.findIndex((item) => "edit-client" === item);
                        if (idx === -1) {
                            dispatch(addTab(id));
                            dispatch(updateActiveMainTab(TABS_DATA.length));
                        } else {
                            dispatch(updateActiveMainTab(idx));
                        }
                        dispatch(updateActiveMainLink(5));
                    } else {
                        toast.error("Client not found!");
                    }
                })
                .catch((error) => {
                    toast.error("Client not found!");
                    console.log(error);
                })
        } else if (tableName && getKeyByName(tableName) === "supplier_search") {
            if (checkPermission("suppliers-suppliers/edit-read", permissionsData)) {
                const id = "edit-supplier";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setSelectedSupplier(row)); // set selected
                dispatch(setEditSupplier(row)); // set selected
                sessionStorage.setItem("editSuppliersLog", row);
            } else {
                toast.error("You do not have permission to edit a supplier.");
            }
        } else if (tableName && getKeyByName(tableName) === "client_movements_search") {
            if (checkPermission("clients-clientmovements/edit-read", permissionsData)) {
                const id = "edit-client-movements";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setSelectedClientMovement(row)); // set selected
                dispatch(setEditClientMovements(row)); // set selected
                sessionStorage.setItem("editClientMovementsLog", row);
            } else {
                toast.error("You do not have permission to edit a client movement.");
            }
        } else if (tableName && getKeyByName(tableName) === "supplier_movements_search") {
            if (checkPermission("suppliers-suppliermovements/edit-read", permissionsData)) {
                const id = "edit-supplier-movements";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setEditSupplierMovements(row)); // set selected
            } else {
                toast.error("You do not have permission to edit a supplier movement.");
            }
        } else if (tableName && getKeyByName(tableName) === "basic_types_search") {
            if (checkPermission("settings-documenttype/basictypesedit-read", permissionsData)) {
                const id = "edit-basic-types";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setEditBasicType(row)); // set selected
                sessionStorage.setItem("editBasicTypeData", JSON.stringify(row));
            } else {
                toast.error("You do not have permission to edit a basic type.");
            }
        } else if (tableName && getKeyByName(tableName) === "advanced_types_search") {
            if (checkPermission("settings-documenttype/advancedtypesedit-read", permissionsData)) {
                const id = "edit-advanced-types";
                if (row && row.name) {
                    if (!TABS_DATA.includes(id)) {
                        dispatch(addTab(id));
                        dispatch(updateActiveMainTab(TABS_DATA.length));
                    } else {
                        dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                    }
                    if (row.section === "Sales") {
                        let advancedTypeSalesData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesSales, row.name, "name")["0"];
                        if (advancedTypeSalesData !== undefined) {
                            dispatch(setEditAdvancedType(advancedTypeSalesData)); // set selected
                            sessionStorage.setItem("editAdvancedTypeData", JSON.stringify(advancedTypeSalesData));
                        }
                    } else if (row.section === "Buys") {
                        let advancedTypeBuysData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesBuys, row.name, "name")["0"];
                        if (advancedTypeBuysData !== undefined) {
                            dispatch(setEditAdvancedType(advancedTypeBuysData)); // set selected
                            sessionStorage.setItem("editAdvancedTypeData", JSON.stringify(advancedTypeBuysData));
                        }
                    } else {
                        let advancedTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypes, row.name, "name")["0"];
                        if (advancedTypeData !== undefined) {
                            dispatch(setEditAdvancedType(advancedTypeData)); // set selected
                            sessionStorage.setItem("editAdvancedTypeData", JSON.stringify(advancedTypeData));
                        }
                    }
                }
            } else {
                toast.error("You do not have permission to edit an advance type.");
            }
        } else if (tableName && getKeyByName(tableName) === "cash_registers_search") {
            if (checkPermission("cash-cashregisters/edit-read", permissionsData)) {
                const id = "edit-cash-registers";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setEditCashRegisters(row)); // set selected
                sessionStorage.setItem("editCashRegistersData", JSON.stringify(row));
            } else {
                toast.error("You do not have permission to edit a cash register.");
            }
        } else if (tableName && getKeyByName(tableName) === "pos_search") {
            if (checkPermission("cash-pos/edit-read", permissionsData)) {
                const id = "edit-pos";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setEditPOS(row)); // set selected
                sessionStorage.setItem("editPOSData", JSON.stringify(row));
            } else {
                toast.error("You do not have permission to edit a POS.");
            }
        } else if (tableName && getKeyByName(tableName) === "bank_account_search") {
            if (checkPermission("cash-bankaccounts/edit-read", permissionsData)) {
                const id = "edit-bank-account";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setEditBankAccount(row)); // set selected
                sessionStorage.setItem("editBankAccountData", JSON.stringify(row));
            } else {
                toast.error("You do not have permission to edit a bank account.");
            }
        } else if (tableName && getKeyByName(tableName) === "sales_search") {
            if (checkPermission("sales-sales/edit-read", permissionsData)) {
                let id = "view-sales";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setEditSales(row));
            } else {
                toast.error("You do not have permission to view a sale.");
            }
        } else if (tableName && getKeyByName(tableName) === "buys_search") {
            if (checkPermission("buys-buy/edit-read", permissionsData)) {
                /*
                if(row?.myDataDestination === "provider" || row?.myDataStatus === "sent" || !isEmpty(row?.mark)) { // Open view buy
                  let id = "view-buys";
                  if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                  } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                  }
                } else { // Open edit buy
                 */
                let id = "edit-buys";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setEditBuys(row));
            } else {
                toast.error("You do not have permission to edit a buy.");
            }
        } else if (tableName && getKeyByName(tableName) === getKeyByName("Client Payments Search")) {
            if (checkPermission("sales-clientpayments/edit-read", permissionsData)) {
                const id = "edit-client-payments";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setEditClientPayment(row)); // set selected
                sessionStorage.setItem("editClientPaymentsData", JSON.stringify(row));
            } else {
                toast.error("You do not have permission to edit a client payment.");
            }
        } else if (tableName && getKeyByName(tableName) === getKeyByName("Supplier Payments Search")) {
            if (checkPermission("buys-supplierpayments/edit-read", permissionsData)) {
                const id = "edit-supplier-payments";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                if (tableName === "Supplier Payments Report") {
                    const [day, month, year] = row.date.split('/').map(Number);
                    row.date = new Date(year, month - 1, day);
                }
                dispatch(setEditSupplierPayment(row)); // set selected
                sessionStorage.setItem("editSupplierPaymentsData", JSON.stringify(row));
            } else {
                toast.error("You do not have permission to edit a supplier payment.");
            }
        } else if (tableName && tableName === "Work Movements Search") {
            if (checkPermission("payroll-workmovements/edit-read", permissionsData)) {
                const id = "edit-work-movement";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setEditWorkMovement(row));
            } else {
                toast.error("You do not have permission to edit a work movement.");
            }
        } else if (tableName && tableName === "Work Permits Search") {
            if (checkPermission("payroll-workpermits/edit-read", permissionsData)) {
                const id = "edit-work-permit";
                if (!TABS_DATA.includes(id)) {
                    dispatch(addTab(id));
                    dispatch(updateActiveMainTab(TABS_DATA.length));
                } else {
                    dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                }
                dispatch(setEditWorkPermit(row));
            } else {
                toast.error("You do not have permission to edit a work permit.");
            }
        } else if (tableName && tableName === "Primer Payments Report") {
            if (checkPermission("sales-sales/edit-read", permissionsData)) {
                if (row && row?.invoice_mark) {
                    axios.get(process.env.REACT_APP_API_URL2 + "/sale/get-sale-by-mark", {
                        headers: {"Content-Type": "application/json"},
                        params: {
                            mark: row?.invoice_mark,
                            company: company.id,
                        }
                    }).then((res) => {
                        if (res.data.status === "200") {
                            dispatch(setEditSales(res.data.data));
                            if (!TABS_DATA.includes("view-sales")) {
                                dispatch(addTab("view-sales"));
                                dispatch(updateActiveMainTab(TABS_DATA.length));
                            } else {
                                dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, "view-sales")));
                            }
                        } else {
                            toast.error("Η πώληση δεν βρέθηκε, παρακαλώ δοκιμάστε αργότερα.");
                        }
                    }).catch((err) => {
                        console.log(err);
                        toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
                    })
                } else {
                    toast.error("Το παραστατικό δεν έχει εκδοθεί ακόμα.");
                }
            }
        } else if (tableName && tableName === "Sales Balance Sheet") {
            if (checkPermission("sales-sales/edit-read", permissionsData)) {
                axios.get(`${process.env.REACT_APP_API_URL2}/company/get-document-by-id`, {
                    headers: {"Content-Type": "application/json"},
                    params: {
                        company: company.id,
                        section: "Sales",
                        docId: row._id
                    }
                }).then((res) => {
                    if (res.data.status === "200") {
                        let id = "view-sales";
                        dispatch(setEditSales(res.data.data));
                        if (!TABS_DATA.includes(id)) {
                            dispatch(addTab(id));
                            dispatch(updateActiveMainTab(TABS_DATA.length));
                        } else {
                            dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                        }
                    } else {
                        toast.error("Σφάλμα κατά το άνοιγμα.");
                    }
                }).catch((err) => {
                    console.log(err);
                    toast.error("Σφάλμα κατά το άνοιγμα.");
                })
            }
        } else if (tableName && tableName === "Client Payments Report") {
            if (checkPermission("sales-clientpayments/edit-read", permissionsData)) {
                axios.get(`${process.env.REACT_APP_API_URL2}/company/get-document-by-id`, {
                    headers: {"Content-Type": "application/json"},
                    params: {
                        company: company.id,
                        section: "Client Payments",
                        docId: row._id
                    }
                }).then((res) => {
                    if (res.data.status === "200") {
                        const id = "edit-client-payments";
                        dispatch(setEditClientPayment(res.data.data));
                        if (!TABS_DATA.includes(id)) {
                            dispatch(addTab(id));
                            dispatch(updateActiveMainTab(TABS_DATA.length));
                        } else {
                            dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                        }
                    } else {
                        toast.error("Σφάλμα κατά το άνοιγμα.");
                    }
                }).catch((err) => {
                    console.log(err);
                    toast.error("Σφάλμα κατά το άνοιγμα.");
                })
            }
        } else if (tableName && tableName === "Buys Balance Sheet") {
            if (checkPermission("buys-buy/edit-read", permissionsData)) {
                axios.get(`${process.env.REACT_APP_API_URL2}/company/get-document-by-id`, {
                    headers: {"Content-Type": "application/json"},
                    params: {
                        company: company.id,
                        section: "Buys",
                        docId: row._id
                    }
                }).then((res) => {
                    if (res.data.status === "200") {
                        let buyDoc = res.data.data;
                        dispatch(setEditBuys(buyDoc)); // set selected
                        /*
                        if(buyDoc?.myDataDestination === "provider" || buyDoc?.myDataStatus === "sent" || !isEmpty(buyDoc?.mark)) { // Open view buy
                          let id = "view-buys";
                          if (!TABS_DATA.includes(id)) {
                            dispatch(addTab(id));
                            dispatch(updateActiveMainTab(TABS_DATA.length));
                          } else {
                            dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                          }
                        } else { // Open edit buy
                         */
                        let id = "edit-buys";
                        if (!TABS_DATA.includes(id)) {
                            dispatch(addTab(id));
                            dispatch(updateActiveMainTab(TABS_DATA.length));
                        } else {
                            dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                        }
                    } else {
                        toast.error("Σφάλμα κατά το άνοιγμα.");
                    }
                }).catch((err) => {
                    console.log(err);
                    toast.error("Σφάλμα κατά το άνοιγμα.");
                })
            }
        } else if (tableName && tableName === "Supplier Payments Report") {
            if (checkPermission("buys-supplierpayments/edit-read", permissionsData)) {
                axios.get(`${process.env.REACT_APP_API_URL2}/company/get-document-by-id`, {
                    headers: {"Content-Type": "application/json"},
                    params: {
                        company: company.id,
                        section: "Supplier Payments",
                        docId: row._id
                    }
                }).then((res) => {
                    if (res.data.status === "200") {
                        const id = "edit-supplier-payments";
                        dispatch(setEditSupplierPayment(res.data.data));
                        if (!TABS_DATA.includes(id)) {
                            dispatch(addTab(id));
                            dispatch(updateActiveMainTab(TABS_DATA.length));
                        } else {
                            dispatch(updateActiveMainTab(getIndexByArrayValue(TABS_DATA, id)));
                        }
                    } else {
                        toast.error("Σφάλμα κατά το άνοιγμα.");
                    }
                }).catch((err) => {
                    console.log(err);
                    toast.error("Σφάλμα κατά το άνοιγμα.");
                })
            }
        }
    }

    let hideFirstRow = false;

    if (tableName && ((tableName === "Client Contact Points" || tableName === "Edit Client Contact Points") || (tableName === "Supplier Contact Points" || tableName === "Edit Supplier Contact Points"))) {
        if (data && data.length === 1) {
            if (data['0'].name === '' && data['0'].mobile === '' && data['0'].email === '') {
                hideFirstRow = true;
            }
        }
    }

    let tableDataLength = data && data?.length ? data?.length : 0;

    return (
        <tbody>

        {/* Table Filter Row */}
        {
            allowFilterTable && (data?.length > 0) ? <tr className="table-filter-row">
                <TableFilterRow
                    {...props}
                    tableName={tableName}
                    filters={filters}
                    filterFieldValue={filterFieldValue}
                    columns={columns}
                />
            </tr> : null
        }
        {/* Table Insert Row */}
        {
            allowInsertRowFromTop ? <tr className="insert-new-row">
                <TableInsertDataRow tableName={tableName} {...props} columns={columns} pRequestData={pRequestData}
                                    reqName={reqName}/>
            </tr> : null
        }

        {/* Table Data */}
        {
            bodyData && bodyData.length > 0 && Array.isArray(bodyData)
                ?
                bodyData && bodyData.map((row, rowi) => {
                    if (hideFirstRow) {
                        return <tr onDoubleClick={(e) => handleRowDoubleClick(e, row)}
                                   className={selectedRow && row && (row?._id === selectedRow || row?.id === selectedRow) ? 'active-row d-none' : 'inactive-row d-none'}
                                   onClick={(e) => selectRow(e, row)} key={rowi}>
                            <TableBodyRow quickEditFields={quickEditCustomColumns} checkedRows={checkedRows} rowIndex={rowi}
                                          dataLength={tableDataLength} tableName={tableName} updateRowData={handleRowUpdate}
                                          columns={columns} data={row} pRequestData={pRequestData} reqName={reqName}/>
                        </tr>
                    } else {
                        if (tableName === 'companyModal') {
                            return <tr onDoubleClick={(e) => handleCompanyDoubleClick(e, row)}
                                       className={selectedRow && row && (row?._id === selectedRow || row?._id === selectedRow) ? 'active-row' : 'inactive-row'}
                                       onClick={(e) => selectRow(e, row)} key={rowi}>
                                <TableBodyRow rowIndex={rowi} dataLength={tableDataLength} tableName={tableName}
                                              columns={columns} data={row}/>
                            </tr>
                        } else if (tableName === "Client Subscriptions Report") {
                            return <tr style={{backgroundColor: row.status === "Ενεργή" ? "" : "#fa6666"}}
                                       onDoubleClick={(e) => handleRowDoubleClick(e, row)}
                                       className={selectedRow && row && (row?._id === selectedRow || row?.id === selectedRow) ? 'active-row' : 'inactive-row'}
                                       onClick={(e) => selectRow(e, row)} key={rowi}>
                                <TableBodyRow quickEditFields={quickEditCustomColumns}
                                              emronPaymentsMonth={emronPaymentsMonth} checkedRows={checkedRows}
                                              rowIndex={rowi} dataLength={tableDataLength} tableName={tableName}
                                              updateRowData={handleRowUpdate} columns={columns} data={row}
                                              pRequestData={pRequestData} reqName={reqName}/>
                            </tr>
                        } else {
                            return <tr onDoubleClick={(e) => handleRowDoubleClick(e, row)}
                                       className={selectedRow && row && (row?._id === selectedRow || row?.id === selectedRow) ? 'active-row' : 'inactive-row'}
                                       onClick={(e) => selectRow(e, row)} key={rowi}>
                                <TableBodyRow quickEditFields={quickEditCustomColumns}
                                              emronPaymentsMonth={emronPaymentsMonth} checkedRows={checkedRows}
                                              rowIndex={rowi} dataLength={tableDataLength} tableName={tableName}
                                              updateRowData={handleRowUpdate} columns={columns} data={row}
                                              pRequestData={pRequestData} reqName={reqName}/>
                            </tr>
                        }

                    }
                })
                :
                (!allowInsertRow && !allowInsertRowFromTop) && <tr>
                    <td colSpan={columns.length + 1}><span
                        className="tdelement text-center">{t('dateFilterColumn.noRecordsFound')}</span></td>
                </tr>
        }

        {/* Table Insert Row */}
        {allowInsertRow && (
            <tr className="insert-new-row">
                <TableInsertDataRow tableName={tableName} {...props} columns={columns} pRequestData={pRequestData}
                                    reqName={reqName}/>
            </tr>
        )}
        </tbody>
    )
}

export default TableBody;
