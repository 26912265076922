// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primerPaymentAdminNotes:hover {
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
}`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Clients/styles.css"],"names":[],"mappings":"AAAA;IACI,yCAAiC;YAAjC,iCAAiC;AACrC","sourcesContent":[".primerPaymentAdminNotes:hover {\r\n    text-decoration: underline dotted;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
