import React, {useEffect} from "react";
import "./styles.css";
import {formatInstallation, formatPath, innerImg} from "../Components/constants";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setAutoDir, setOpenPaths, setTreeData} from "../../../../../_reducers/cloudSlice";

const TreeView = () => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const companyInstallations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const openPaths = useSelector((state) => state.CLOUD.openPaths);
    const treeData = useSelector((state) => state.CLOUD.treeData);
    const specialCodeWas1 = useSelector((state) => state.CLOUD.specialCodeWas1);

    useEffect(() => {
        if (treeData[0]?.content?.length === 0) handleTogglePath("/");
    }, [])

    const dir = async (path) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
                action: "dir",
                currentPath: path,
                company: company.id,
                year: company.year,
            }, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (res.data.status === "200") {
                let reformattedArr = [];
                for (let d of res.data.data) {
                    if (d.folder) {
                        let prepObj = {
                            item: d.name,
                            content: [],
                            folder: true,
                            fileCount: d.fileCount,
                            hasSubfolders: d.hasSubfolders,
                            locked: d.locked,
                        }
                        if (specialCodeWas1 && !isNaN(prepObj.item)) prepObj.displayName = formatInstallation(prepObj.item, companyInstallations);
                        reformattedArr.push(prepObj);
                    }
                }
                return reformattedArr;
            }
        } catch (err) {
            console.log(err);
            return null;
        }
        return null;
    }

    const addItemsByPath = (dummyData, path, newItems) => {
        const updateContent = (items, pathSegments) => {
            return items.map(item => {
                if (item.folder && item.item === pathSegments[0]) {
                    if (pathSegments.length === 1) {
                        // Target folder found, add new items to content
                        return {...item, content: newItems}
                    }
                    // Continue searching deeper into the nested structure
                    return {...item, content: updateContent(item.content || [], pathSegments.slice(1))}
                }
                // Return unmodified item
                return { ...item };
            });
        }

        const pathSegments = ["/", ...path.split('/').filter(Boolean)]; // Split path into segments
        return updateContent(dummyData, pathSegments);
    }

    const handleTogglePath = (path) => {
        if (openPaths.includes(path)) { // Close
            dispatch(setOpenPaths(openPaths.filter((el) => !String(el).startsWith(path))));
        } else { // Open and dir
            dir(path).then((newItems) => {
                if (newItems) {
                    if (path === "/") {
                        dispatch(setTreeData([{
                            item: "/",
                            displayName: "Αρχική",
                            folder: true,
                            fileCount: 0,
                            hasSubfolders: true,
                            content: newItems,
                        }]));
                    } else {
                        dispatch(setTreeData(addItemsByPath(structuredClone(treeData), path, newItems)));
                    }
                    dispatch(setOpenPaths([...openPaths, path]));
                }
            })
        }
    }

    const handleFolderClick = (path) => {
        dispatch(setAutoDir(path));
    }

    const handleFileClick = (path) => {
        // We show only folders for now
    }

    const renderList = (arr, depth = 0, parentPath = "") => {
        let compArr = [];
        for (let b of arr) {
            const paddingLeft = 5 + depth * 10;
            const curPath = formatPath(parentPath + "/" + b.item);
            const isOpen = openPaths.includes(curPath);
            compArr.push(
                <li className={"tree-view-item"} style={{paddingLeft: `${paddingLeft}px`}} key={`${b.item}-${depth}`} onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        if (b.folder) {
                            handleFolderClick(curPath);
                        } else {
                            handleFileClick(curPath);
                        }
                    }
                }}>
                    {b.folder ? (
                        <React.Fragment>
                            {b.hasSubfolders ? (
                                <span className={"pl-2 pr-2 tree-view-symbol"} onClick={() => handleTogglePath(curPath)}>{isOpen ? "-" : "+"}</span>
                            ) : (
                                <span className={"pl-3"}>{" "}</span>
                            )}
                            {"  "}<img src={innerImg(b.fileCount === 0, specialCodeWas1 ? "1" : "0", b.locked, b.hasSubfolders)} className={"mr-1"} alt={"Mini Folder"}
                                       style={{width: "20px", height: "20px"}} />{b.displayName ? b.displayName : b.item}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <img src={"assets/images/cloud/mini_file.png"} className={"mr-1"} alt={"Mini File"} />{b.item}
                        </React.Fragment>
                    )}
                </li>
            )
            if (b.folder && isOpen) {
                compArr = [...compArr, ...renderList(b.content, depth + 1, curPath)]
            }
        }
        return compArr;
    }

    return (
        <div style={{width: "100%", padding: "5px", userSelect: "none"}} className={"mt-1"}>
            <ul className={"ul-tree-view"}>
                {renderList(treeData)}
            </ul>
        </div>
    )
}

export default TreeView;

/*
const dummyData = [
    {
        item: "/",
        displayName: "Home",
        folder: true,
        content: [
            {
                item: "parent",
                folder: true,
                content: [
                    {
                        item: "child",
                        folder: true,
                        content: [
                            {
                                item: "file1",
                                folder: false,
                                content: "gkionis 1",
                            },
                            {
                                item: "file2",
                                folder: false,
                                content: "gkionis 1",
                            },
                            {
                                item: "file3",
                                folder: false,
                                content: "gkionis 1",
                            }
                        ]
                    }
                ]
            }
        ]
    },
]
 */