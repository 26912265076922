import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {mobileSidebarToggle} from "../../../_reducers/MobileSidebar";
import {activePath} from "../../../_reducers/Navbar";
import {
    checkPermissionMasterParentStartingTree, getCurrentUser
} from "../../../_helpers/commonFunctions";
import {addTab, updateActiveMainTab} from "../../../_reducers/TabsSlice";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "@mui/material";
import {handleLogOut} from "../../../_apis/api";
import {Dropdown, DropdownButton} from "react-bootstrap";

const Navbar = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation("common");
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const currentUser = getCurrentUser();
    const isMobileScreen = useMediaQuery("(max-width: 980px)");
    const isEmployee = permissionsData?.find((el) => el.permissionName === "custom-employee");
    const isEmronConnectFileERP = useSelector((state) => state.ACTIVITY.isEmronConnectFileERP);

    const handleClick = (e) => {
        if (e === "Start") {
            const findStart = TABS_DATA.findIndex((item) => "start" === item);
            if (findStart === -1) {
                dispatch(addTab("start"));
                dispatch(updateActiveMainTab(TABS_DATA.length));
            } else {
                dispatch(updateActiveMainTab(findStart));
            }
        }
        dispatch(activePath(e === "Start" ? "" : e));
        dispatch(mobileSidebarToggle());
    }

    const handleOpenUserManagement = () => {
        const idx = TABS_DATA.findIndex((item) => "user-settings" === item);
        if (idx === -1) {
            dispatch(addTab("user-settings"));
            dispatch(updateActiveMainTab(TABS_DATA.length));
        } else {
            dispatch(updateActiveMainTab(idx));
        }
    }

    return (
        <div className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row mainNavbar" style={{pointerEvents: isEmronConnectFileERP ? "none" : ""}}>
            {/* ___________________________LOGO____________________________ */}
            <div className="text-center navbar-brand-wrapper d-flex justify-content-between align-items-center"
                 style={{width: isMobileScreen ? "100%" : "", height: "70px"}}>
                {!isEmployee && (
                    <button
                        onClick={() => dispatch(mobileSidebarToggle())}
                        className="navbar-toggler navbar-toggler-left toggleSidebarBtn outline-danger"
                        type="button"
                        data-toggle="offcanvas"
                        hidden={!isMobileScreen}
                    >
                        <span className="mdi mdi-menu"></span>
                    </button>
                )}
                <div style={{cursor: "pointer", maxHeight: "70px"}} className="p-2 mx-auto">
                    <img
                        alt="Primer Logo"
                        style={{maxHeight: "55px"}}
                        src={"assets/images/prrimer-logo.png"}
                    />
                </div>
                {isMobileScreen ? (
                    <div className={"mr-1"}>
                        <DropdownButton id="dropdown-basic-button" style={{padding: "4px"}} title={
                            <img
                                src={currentUser?.avatar ? currentUser?.avatar : "assets/images/faces/face16.jpg"}
                                alt="userAvatar"
                                style={{maxHeight: "30px", borderRadius: "50%"}}
                            />
                        }>
                            <Dropdown.Item onClick={() => handleOpenUserManagement()} className={"pt-2 pb-2"}>Ρυθμίσεις Χρήστη</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(handleLogOut())} className={"pt-2 pb-2"}>Αποσύνδεση</Dropdown.Item>
                        </DropdownButton>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>


            {!isMobileScreen && (
                <React.Fragment>
                    {/* _______________________NAVBAR MENU_____________________*/}
                    <div className="navbar-menu-wrapper d-flex align-items-stretch">
                        {/* __________________NAVLINKS_________________ */}
                        <div className="align-self-center mx-auto">
                            <div className="center-navbar-menu">
                                <ul className="nav nav-pills" role="tablist">
                                    <li className="nav-item cen-menu-active" style={{textAlign: "center"}}
                                        hidden={permissionsData?.find((el) => ["custom-epay", "custom-peiraios", "custom-daras", "custom-employee", "custom-cardlink"].includes(el.permissionName))}>
                                        <a
                                            onClick={() => handleClick("Start")}
                                            className="nav-link active"
                                            data-toggle="pill"
                                            href={"#pills-start"}
                                        >
                                            <img
                                                src={"assets/images/side-menu/house-wh.png"}
                                                className="img-fluid mr-1 image-hover pt-1"
                                                style={{width: "22px"}}
                                                alt="Start"
                                            />
                                            <img
                                                src={"assets/images/side-menu/house-dark.png"}
                                                className="img-fluid mr-1 image-without-hover pt-1"
                                                style={{width: "22px"}}
                                                alt="Start1"
                                            />
                                            {t('NavBar.start')}
                                        </a>
                                    </li>
                                    {checkPermissionMasterParentStartingTree("products", permissionsData) === true && (
                                        <li className="nav-item cen-menu-active" style={{textAlign: "center"}}>
                                            <a
                                                onClick={() => handleClick("Products")}
                                                className="nav-link"
                                                data-toggle="pill"
                                                href={"#pills-Products"}
                                            >
                                                <img
                                                    onClick={() => handleClick("Products")}
                                                    src={"assets/images/side-menu/Products-white.png"}
                                                    className="img-fluid mr-1 image-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start"
                                                />
                                                <img
                                                    onClick={() => handleClick("Products")}
                                                    src={"assets/images/side-menu/Products-dark.png"}
                                                    className="img-fluid mr-1 image-without-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start1"
                                                />
                                                {t('NavBar.products')}
                                            </a>
                                        </li>)}
                                    {checkPermissionMasterParentStartingTree("sales", permissionsData) === true && (
                                        <li className="nav-item cen-menu-active" style={{textAlign: "center"}}>
                                            <a
                                                onClick={() => handleClick("Sales")}
                                                className="nav-link"
                                                data-toggle="pill"
                                                href={"#pills-Sales"}
                                            >
                                                <img
                                                    onClick={() => handleClick("Sales")}
                                                    src={"assets/images/side-menu/Sales-wh.png"}
                                                    className="img-fluid mr-1 image-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start"
                                                />
                                                <img
                                                    onClick={() => handleClick("Sales")}
                                                    src={"assets/images/side-menu/Sales-dark.png"}
                                                    className="img-fluid mr-1 image-without-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start1"
                                                />
                                                {t('NavBar.sales')}
                                            </a>
                                        </li>)}
                                    {checkPermissionMasterParentStartingTree("buys", permissionsData) === true && (
                                        <li className="nav-item cen-menu-active" style={{textAlign: "center"}}>
                                            <a
                                                onClick={() => handleClick("Buys")}
                                                className="nav-link"
                                                data-toggle="pill"
                                                href={"#pills-Buys"}
                                            >
                                                <img
                                                    onClick={() => handleClick("Buys")}
                                                    src={"assets/images/side-menu/Buys-wh.png"}
                                                    className="img-fluid mr-1 image-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start"
                                                />
                                                <img
                                                    onClick={() => handleClick("Buys")}
                                                    src={"assets/images/side-menu/Buys-dark.png"}
                                                    className="img-fluid mr-1 image-without-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start1"
                                                />
                                                {t('NavBar.buys')}
                                            </a>
                                        </li>)}
                                    {checkPermissionMasterParentStartingTree("clients", permissionsData) === true && (
                                        <li className="nav-item cen-menu-active" style={{textAlign: "center"}}>
                                            <a
                                                onClick={() => handleClick("Clients")}
                                                className="nav-link"
                                                data-toggle="pill"
                                                href={"#pills-Clients"}
                                            >
                                                <img
                                                    onClick={() => handleClick("Clients")}
                                                    src={"assets/images/side-menu/Clients-wh.png"}
                                                    className="img-fluid mr-1 image-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start"
                                                />
                                                <img
                                                    onClick={() => handleClick("Clients")}
                                                    src={"assets/images/side-menu/Clients-dark.png"}
                                                    className="img-fluid mr-1 image-without-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start1"
                                                />
                                                {t('NavBar.clients')}
                                            </a>
                                        </li>)}
                                    {checkPermissionMasterParentStartingTree("suppliers", permissionsData) === true && (
                                        <li className="nav-item cen-menu-active" style={{textAlign: "center"}}>
                                            <a
                                                onClick={() => handleClick("Suppliers")}
                                                className="nav-link"
                                                data-toggle="pill"
                                                href={"#pills-Suppliers"}
                                            >
                                                <img
                                                    onClick={() => handleClick("Suppliers")}
                                                    src={"assets/images/side-menu/Supplier-wh.png"}
                                                    className="img-fluid mr-1 image-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start"
                                                />
                                                <img
                                                    onClick={() => handleClick("Suppliers")}
                                                    src={"assets/images/side-menu/Supplier-dark.png"}
                                                    className="img-fluid mr-1 image-without-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start1"
                                                />
                                                {t('NavBar.suppliers')}
                                            </a>
                                        </li>)}
                                    {checkPermissionMasterParentStartingTree("cash", permissionsData) === true && (
                                        <li className="nav-item cen-menu-active" style={{textAlign: "center"}}>
                                            <a
                                                onClick={() => handleClick("Cash Registers - POS - Bank Accounts")}
                                                className="nav-link"
                                                data-toggle="pill"
                                                href="/"
                                            >
                                                <img
                                                    src={"assets/images/side-menu/cash-wh.png"}
                                                    className="img-fluid mr-1 image-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start"
                                                />
                                                <img
                                                    src={"assets/images/side-menu/cash-dark.png"}
                                                    className="img-fluid mr-1 image-without-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start1"
                                                />
                                                {t('NavBar.cash.cash')}
                                            </a>
                                        </li>)}
                                    {checkPermissionMasterParentStartingTree("accounting", permissionsData) === true && (
                                        <li
                                            onClick={() => handleClick("Accounting")}
                                            className="nav-item cen-menu-active"
                                            style={{textAlign: "center"}}
                                        >
                                            <a
                                                className="nav-link"
                                                data-toggle="pill"
                                                href={"#pills-Accounting"}
                                            >
                                                <img
                                                    onClick={() => handleClick("Accounting")}
                                                    src={"assets/images/side-menu/accounting-white.png"}
                                                    className="img-fluid mr-1 image-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start"
                                                />
                                                <img
                                                    onClick={() => handleClick("Accounting")}
                                                    src={"assets/images/side-menu/accounting.png"}
                                                    className="img-fluid mr-1 image-without-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start1"
                                                />
                                                {t('NavBar.accounting')}
                                            </a>
                                        </li>)}
                                    {checkPermissionMasterParentStartingTree("payroll", permissionsData) === true && (
                                        <li
                                            onClick={() => handleClick("Payroll")}
                                            className="nav-item cen-menu-active"
                                            style={{textAlign: "center"}}
                                        >
                                            <a
                                                className="nav-link"
                                                data-toggle="pill"
                                                href={"#pills-Payroll"}
                                            >
                                                <img
                                                    onClick={() => handleClick("Payroll")}
                                                    src={"assets/images/side-menu/Payroll-wh.png"}
                                                    className="img-fluid mr-1 image-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start"
                                                />
                                                <img
                                                    onClick={() => handleClick("Payroll")}
                                                    src={"assets/images/side-menu/Payroll-dark.png"}
                                                    className="img-fluid mr-1 image-without-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start1"
                                                />
                                                {t('NavBar.payroll')}
                                            </a>
                                        </li>)}
                                    {checkPermissionMasterParentStartingTree("settings", permissionsData) === true && (
                                        <li
                                            onClick={() => handleClick("Settings")}
                                            className="nav-item cen-menu-active"
                                            style={{textAlign: "center"}}
                                        >
                                            <a
                                                className="nav-link"
                                                data-toggle="pill"
                                                href={"#pills-Settings"}
                                            >
                                                <img
                                                    onClick={() => handleClick("Settings")}
                                                    src={"assets/images/side-menu/settings-wh.png"}
                                                    className="img-fluid mr-1 image-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start"
                                                />
                                                <img
                                                    onClick={() => handleClick("Settings")}
                                                    src={"assets/images/side-menu/settings-dark.png"}
                                                    className="img-fluid mr-1 image-without-hover pt-1"
                                                    style={{width: "22px"}}
                                                    alt="Start1"
                                                />
                                                {t('NavBar.settings')}
                                            </a>
                                        </li>)}
                                </ul>
                            </div>
                        </div>
                        {/* ___________________PROFILE_LINKS_____________ */}
                        <ul className="navbar-nav navbar-nav-right profileLinksMain">
                            <li className="nav-item nav-profile dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    id="profileDropdown"
                                    href="/"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <div className="nav-profile-img">
                                        <img
                                            src={currentUser?.avatar ? currentUser?.avatar : "assets/images/faces/face16.jpg"}
                                            alt="userAvatar"/>
                                        <span className="availability-status online"></span>
                                    </div>
                                    <div className="nav-profile-text">
                                        <p className="mb-1 text-black"><span
                                            className="font-weight-bold mb-2">{currentUser?.lastName ? currentUser?.firstName + " " + currentUser?.lastName : ""}</span>
                                            <br/>
                                            <span
                                                className="text-secondary text-small">{currentUser?.active ? currentUser?.active : "Active"}<i
                                                className="fa fa-angle-down" aria-hidden="true"></i></span>
                                        </p>
                                    </div>
                                </a>
                                <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
                                    <div onClick={() => handleOpenUserManagement()} className="dropdown-item">
                                        Ρυθμίσεις χρήστη
                                    </div>
                                    <div className="dropdown-divider"></div>
                                    <div onClick={() => dispatch(handleLogOut())} className="dropdown-item">
                                        <i className="mdi mdi-logout mr-1 text-primary"></i> {t('NavBar.signOut')}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default Navbar;
