// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ul-tree-view {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid lightgray;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
    background-color: #fff;
}

.tree-view-item {
    cursor: pointer;
    display: block;
    position: relative;
    transition: background-color 100ms linear;
    font-size: 14px;
}

.tree-view-item:hover {
    background-color: lightgray;
}

.tree-view-symbol {
    border-radius: 2px;
    font-weight: 600;
    cursor: auto;
    transition: all 100ms linear;
}

.tree-view-symbol:hover {
    background-color: var(--theme-color-main);
    color: white;
}

`, "",{"version":3,"sources":["webpack://./src/_components/layouts/data-panel/Cloud/TreeView/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,2BAA2B;IAC3B,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,yCAAyC;IACzC,eAAe;AACnB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,yCAAyC;IACzC,YAAY;AAChB","sourcesContent":[".ul-tree-view {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-radius: 8px;\r\n    border: 1px solid lightgray;\r\n    padding: 0;\r\n    overflow: hidden;\r\n    overflow-y: auto;\r\n    background-color: #fff;\r\n}\r\n\r\n.tree-view-item {\r\n    cursor: pointer;\r\n    display: block;\r\n    position: relative;\r\n    transition: background-color 100ms linear;\r\n    font-size: 14px;\r\n}\r\n\r\n.tree-view-item:hover {\r\n    background-color: lightgray;\r\n}\r\n\r\n.tree-view-symbol {\r\n    border-radius: 2px;\r\n    font-weight: 600;\r\n    cursor: auto;\r\n    transition: all 100ms linear;\r\n}\r\n\r\n.tree-view-symbol:hover {\r\n    background-color: var(--theme-color-main);\r\n    color: white;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
