// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buysModalBig {
  max-width: none !important;
  width: 95%;
}`, "",{"version":3,"sources":["webpack://./src/_components/primer/POPUP-COMPONENTS/NewBuyModal/mydata-report.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,UAAU;AACZ","sourcesContent":[".buysModalBig {\r\n  max-width: none !important;\r\n  width: 95%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
