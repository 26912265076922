import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {bridgedSections, getOptionsByData, getSubaccountOptions} from "../../../../_helpers/commonFunctions";
import {useDispatch, useSelector} from "react-redux";
import {getObjectFromArrayOfObjects} from "../../../../_helpers/helperFunctions";
import SearchTable from "../../../primer/Search-Table";
import {fetchAccountingAdvancedTypes, fetchAccountingRecordCategories} from "../../../../_apis/api";
import {classicStyleBelowNavbar} from "../Statics";

export const SearchAccountingRecord = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const subAccountOptions = getSubaccountOptions();
    const ACCOUNTING_DATA = useSelector( (state) => state.ACCOUNTING_DATA.accountingRecordCategories );
    const ACCOUNTING_DATA_TYPES = useSelector( (state) => state.ACCOUNTING_DATA.accountingAdvancedTypes );
    const company = useSelector((state) => state.COMPANY_DATA.company);
    let documentTypesOptions = useMemo(() => getOptionsByData(ACCOUNTING_DATA_TYPES, "_id", "name"), [ACCOUNTING_DATA_TYPES]);
    let accountCategories = useMemo(() => getOptionsByData(ACCOUNTING_DATA, "_id", "name"), [ACCOUNTING_DATA]);
    const [subCategories, setSubCategories] = useState([]);
    accountCategories = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...accountCategories];
    documentTypesOptions = [{ value: "", label: t("Null"), color: "#76AEB7" }, ...documentTypesOptions];
    const searchAccountingRecordFilters = {
        filters: [
            {
                name: "category",
                label: t("General.articleCategory"),
                type: "multiselect",
                options: accountCategories
            },
            {
                name: "subCategory",
                label: t("General.articleSubcategory"),
                type: "multiselect",
                options: subCategories
            },
            {
                name: "subAccount",
                label: t("AccountingAccountSearch.filters.subAccount"),
                type: "multiselect",
                options: subAccountOptions
            },
            {
                name: "documentType",
                label: t('SalesSearch.table2.documentType'),
                type: "multiselect",
                options: documentTypesOptions
            },
            {
                name: "subAccountName",
                label: "Υπολογαριασμός",
                type: "input",
                mdWidth: 4,
            },
            {
                name: "totalSum_from",
                label: t('SalesSearch.table2.totalSumFrom'),
                type: "input",
                mdWidth: 2,
            },
            {
                name: "totalSum_to",
                label: t('SalesSearch.table2.totalSumTo'),
                type: "input",
                mdWidth: 2,
            },
            {
                name: "preNumber_from",
                label: t('SalesSearch.table2.preNumberFrom'),
                type: "input",
                mdWidth: 2,
            },
            {
                name: "preNumber_to",
                label: t('SalesSearch.table2.preNumberTo'),
                type: "input",
                mdWidth: 2,
            },
            {
                name: "number_from",
                label: t('SalesSearch.table2.numberFrom'),
                type: "input",
                mdWidth: 2,
            },
            {
                name: "number_to",
                label: t('SalesSearch.table2.numberTo'),
                type: "input",
                mdWidth: 2,
            },
            {
                name: "documentCreator",
                label: t("General.articleCreator"),
                type: "dropdown",
                options: [{value: "", label: "Κενό (Όλα)"}, {value: "bridge", label: "Από λογιστική γέφυρα"}, {value: "manual", label: "Από χειροκίνητη εισαγωγή"}],
                mdWidth: 2,
            },
            {
                name: "bridgedSection",
                label: "Κατηγορία Εμπορικού",
                type: "multiselect",
                options: bridgedSections.filter((el) => el.value !== ""),
                mdWidth: 3,
            }
        ]
    };
    const scColumns = [
        {
            name: t("General.date"),
            field: "date",
            width: "10%",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.invoiceType"),
            field: "documentTypeName",
            hiddenColumn: false,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Υπολογαριασμοί",
            field: "subAccounts",
            hiddenColumn: false,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.category"),
            field: "categoryName",
            width: "10%",
            hiddenColumn: false,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.subCategory"),
            field: "subCategoryName",
            width: "10%",
            hiddenColumn: false,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.installation"),
            field: "installation",
            hiddenColumn: false,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.numbering"),
            field: "preNumberAndNumber",
            width: "100px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.totalCredit"),
            field: "totalCredit",
            editable: true,
            disabled: true,
            inputType: {
                type: 'accounting',
                config: {
                    suffix: "€",
                }
            }
        },
        {
            name: t("General.myDataStatus"),
            field: "myDataStatus",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('SalesSearch.table.myDataStatus'),
            field: "myDataStatus",
            width: "100px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Κατηγορία Εμπορικού",
            field: "bridgedSection",
            hiddenColumn: true,
            editable: false,
            inputType: {
                type: 'text',
            }
        },
    ]
    useEffect( () => {
        dispatch(fetchAccountingRecordCategories(company.id, setSubCategories));
        dispatch(fetchAccountingAdvancedTypes(company.id));
    },[dispatch])

    const [tableColumns, setTableColumns] = useState(scColumns.slice());

    // It will hide or show the table columns for searchtable.
    const handleColumnShowHide = (field) => {
        let tempTc = tableColumns.slice();
        let object = getObjectFromArrayOfObjects(tempTc, field, 'field')
        const index = tempTc.findIndex((item) => field === item.field);
        if (object.length > 0) {
            tempTc[index].hiddenColumn = !object['0'].hiddenColumn;
            setTableColumns(tempTc);
        }
    }
    return (
        <div style={classicStyleBelowNavbar}>
            <SearchTable
                tableName="Accounting Record Search"
                key={"Accounting Record Search"}
                hideYearFilter={false}
                allowPagination={true}
                allowToggleColumns={true}
                sendSelectedToMyData={true}
                toggleColumns={handleColumnShowHide}
                allowPrintResults={true}
                columns={tableColumns}
                data={[]}
                filtersConfig={searchAccountingRecordFilters}
            />
        </div>
    )
}
