import React, {useEffect, useState} from "react";
import {
    documentTypeMapping,
    getMimeType,
    getParentPath,
    popExtension
} from "./Components/constants";
import {getDocumentLabel, isEmpty} from "../../../../_helpers/commonFunctions";
import {Badge, Button, Col, Row, Spinner} from "react-bootstrap";
import {toast} from "react-toastify";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {
    setDisableEmronRegisterPage
} from "../../../../_reducers/cloudSlice";
import "./EmronRegisterStyle.css";
import BetterCheckBox from "../../../common/BetterCheckBox";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import "./../../../primer/POPUP-COMPONENTS/modalWidths.css";
import {useTranslation} from "react-i18next";
import DateBox from "../../../primer/DateBox";
import DateHelper from "../../../primer/DateHelper/DateHelper";
import FilePreviewerContainer from "./Components/FilePreviewerContainer";
import pdfToText from "react-pdftotext";
import Tesseract from 'tesseract.js';
import Input from "../../../common/Input";

const EmronRegisterFile = ({fileData, ssKey}) => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState(fileData.name);
    const [previewData, setPreviewData] = useState(fileData.data);
    const [currentPath, setCurrentPath] = useState(fileData.currentPath);
    const [fakeCurrentPath, setFakeCurrentPath] = useState(null);
    const [dirData, setDirData] = useState(fileData.dirData);
    const [moveToNextOne, setMoveToNextOne] = useState(true);
    const [openCorrelationButtons, setOpenCorrelationButtons] = useState(false);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const disableEmronRegisterPage = useSelector((state) => state.CLOUD.disableEmronRegisterPage);

    const [showCorrelationModal, setShowCorrelationModal] = useState(false);
    const [section, setSection] = useState("");
    const [pageChanged, setPageChanged] = useState(false);
    const [page, setPage] = useState(1);
    const [requestData, setRequestData] = useState({
        dateFrom: moment(String(company.year), "YYYY").startOf("year").format("DD/MM/YYYY"),
        dateTo: moment(String(company.year), "YYYY").endOf("year").format("DD/MM/YYYY"),
    })
    const [viewData, setViewData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const alreadyCorrelatedDocuments = fileData.documentHooks;
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const [scanForMark, setScanForMark] = useState(true);
    const [invoiceMark, setInvoiceMark] = useState("");
    const [autoCorrModal, setAutoCorrModal] = useState(false);

    useEffect(() => {
        if (scanForMark) {
            setScanForMark(false);
            setInvoiceMark("");
            if (String(fileName).toLowerCase().endsWith(".pdf") || String(fileName).toLowerCase().endsWith(".doc") || String(fileName).toLowerCase().endsWith(".docx")) {
                fetch(previewData).then((r) => r.blob()).then((arrayBuffer) => {
                    pdfToText(arrayBuffer).then((data) => {
                        const mark = String(data).match(/\b4\d{14}\b/);
                        if (mark) setInvoiceMark(mark[0]);
                    })
                })
            } else if (String(fileName).toLowerCase().endsWith(".png") || String(fileName).toLowerCase().endsWith(".jpg")) {
                fetch(previewData).then((r) => r.blob()).then((arrayBuffer) => {
                    Tesseract.recognize(arrayBuffer, 'eng+ell').then((res) => {
                        const mark = String(res.data?.text).match(/\b4\d{14}\b/);
                        if (mark) setInvoiceMark(mark[0]);
                    })
                })
            }
        }
    }, [scanForMark])

    useEffect(() => {
        if (!showCorrelationModal) {
            setPage(1);
            setViewData([]);
            setTotalPages(0);
            setSelectedDocuments([]);
            setSection("");
        }
    }, [showCorrelationModal])

    useEffect(() => {
        if (!autoCorrModal) {
            setSelectedDocuments([]);
        }
    }, [autoCorrModal])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchViewData(true);
        }
    }, [pageChanged])

    useEffect(() => {
        setFakeCurrentPath(null);
    }, [currentPath])

    const handleOpenNewERPTabTo = async (tabName) => {
        let extraData = {};
        if (tabName === "buys-new" && !isEmpty(invoiceMark)) {
            tabName = "buys-mydata-report";
            extraData.section = "Buys";
            extraData.mark = invoiceMark;
            try {
                const markResponse = await axios.get(`${process.env.REACT_APP_API_URL2}/buy/get-buy-by-mark`, {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        company: company.id,
                        mark: invoiceMark,
                    }
                });
                if (markResponse.data.status === "200") {
                    const rowData = markResponse.data.data;
                    setSelectedDocuments([...selectedDocuments, {_id: rowData._id, section: "Buys", label: getDocumentLabel(rowData)}]);
                    setAutoCorrModal(true);
                    return;
                }
            } catch (err) {
                console.log(err);
                return toast.error("Σφάλμα κατά την αποστολή αιτήματος. Δοκιμάστε αργότερα.");
            }
        } else if (tabName === "sales-new" && !isEmpty(invoiceMark)) {
            tabName = "mydata-report";
            extraData.section = "Sales";
            extraData.mark = invoiceMark;
            try {
                const markResponse = await axios.get(`${process.env.REACT_APP_API_URL2}/sale/get-sale-by-mark`, {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        company: company.id,
                        mark: invoiceMark,
                    }
                });
                if (markResponse.data.status === "200") {
                    const rowData = markResponse.data.data;
                    setSelectedDocuments([...selectedDocuments, {_id: rowData._id, section: "Sales", label: getDocumentLabel(rowData)}]);
                    setAutoCorrModal(true);
                    return;
                }
            } catch (err) {
                console.log(err);
                return toast.error("Σφάλμα κατά την αποστολή αιτήματος. Δοκιμάστε αργότερα.");
            }
        }
        const newURL = new URL(window.location.href);
        newURL.searchParams.set("ssKey", ssKey);
        newURL.searchParams.set("forceNewTab", tabName);
        if (Object.keys(extraData).length > 0) newURL.searchParams.set("extraData", JSON.stringify(extraData));

        const clientWidth = window.innerWidth;
        const clientHeight = window.innerHeight;
        const popUpWindow = window.open(newURL.toString(), "_blank", `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${clientWidth},height=${clientHeight}`);
        if (popUpWindow) {
            dispatch(setDisableEmronRegisterPage(true));
            const monitorPopup = setInterval(async () => {
                if (popUpWindow.closed) {
                    clearInterval(monitorPopup);
                    dispatch(setDisableEmronRegisterPage(false));

                    const status = localStorage.getItem(`${ssKey}-status`);
                    // status is 200|_id|label if successful
                    if (status && status.startsWith("200")) {
                        const split = String(status).split("|");
                        const oId = split[1];
                        const label = split[2];
                        const section = documentTypeMapping[tabName];

                        await attempFileRegister(section, [{_id: oId, section: section, label: label}]);
                        localStorage.removeItem(`${ssKey}-status`);
                    } else {
                        toast.warning("Η καταχώρηση δεν πραγματοποιηθήκε.");
                    }
                }
            }, 500);
        } else {
            console.error("Failed to open popup window.");
        }
    }

    const disabledPageStyle = disableEmronRegisterPage ? {
        opacity: "0.3",
        pointerEvents: "none",
        userSelect: "none",
    } : {};

    const handleArrowClick = (direction) => {
        setLoading(true);
        const parentPath = getParentPath(currentPath);
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "traverse",
            direction: direction,
            currentPath: parentPath,
            currentFileName: fileName,
            company: company.id,
            year: company.year,
        }, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                // Create blob
                const binaryString = atob(res.data.data);
                const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                const blob = new Blob([byteArray], { type: getMimeType(res.data.fileName) });
                let blobUrl = URL.createObjectURL(blob);
                if (popExtension(res.data.fileName) === "pdf") {
                    blobUrl += "#navpanes=0&view=Fit";
                }
                setFileName(res.data.fileName);
                setPreviewData(blobUrl);
                setCurrentPath(res.data.currentPath);
                setDirData(res.data.dirData);
                setScanForMark(true);
            } else {
                if (res.data.specialCode !== "1") {
                    toast.error(res.data.message);
                }
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleCorrelate = (section) => {
        setPage(1);
        setShowCorrelationModal(true);
        setPageChanged(true);
        setSection(section);
    }

    const fetchViewData = (fromPagination = true) => {
        const toReq = {
            company: company.id,
            year: company.year,
            section: section,
            page: page,
            ...requestData,
        }
        if (!fromPagination) {
            setPage(1);
            toReq.page = 1;
        }
        axios.get(`${process.env.REACT_APP_API_URL2}/cloud/fetch-doc-cor`, {
            headers: { "Content-Type": "application/json" },
            params: toReq
        }).then((res) => {
            if (res.data.status === "200") {
                setViewData(res.data.data);
                setTotalPages(res.data.totalPages);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const attempFileRegister = async (section, documentHooks, isCorrelation = false) => {
        axios.post(`${process.env.REACT_APP_API_URL2}/cloud/action`, {
            action: "emronRegister",
            currentPath: fakeCurrentPath ? fakeCurrentPath : currentPath,
            documentHooks: documentHooks,
            moveToNextOne: moveToNextOne,
            company: company.id,
            year: company.year,
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                isCorrelation ? toast.success("Επιτυχής συσχέτιση.") : toast.success("Επιτυχής καταχώρηση.");
                if (res.data?.nextOne) {
                    // Create blob
                    const binaryString = atob(res.data.nextOne.data);
                    const byteArray = new Uint8Array(binaryString.split('').map((char) => char.charCodeAt(0)));
                    const blob = new Blob([byteArray], { type: getMimeType(res.data.nextOne.name) });
                    let blobUrl = URL.createObjectURL(blob);
                    if (popExtension(res.data.nextOne.name) === "pdf") {
                        blobUrl += "#navpanes=0&view=Fit";
                    }
                    setFileName(res.data.nextOne.name);
                    setPreviewData(blobUrl);
                    setCurrentPath(res.data.nextOne.currentPath);
                    setDirData(res.data.nextOne.dirData);
                    setScanForMark(true);
                } else if (res.data?.fakeCurrentPath) {
                    setFakeCurrentPath(res.data?.fakeCurrentPath);
                } else {
                    window.close();
                }
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const saveDocumentCorrelations = async () => {
        setLoading(true);
        await attempFileRegister(section, selectedDocuments, true);
        if (showCorrelationModal) setShowCorrelationModal(false);
        if (autoCorrModal) setAutoCorrModal(false);
    }

    const isSelected = (docId) => {
        return selectedDocuments.some((el) => el._id === docId && el.section === section);
    }

    const isCorrelated = (docId) => {
        return alreadyCorrelatedDocuments.some((el) => el._id === docId && el.section === section);
    }

    const handleSelect = (row) => {
        setSelectedDocuments([...selectedDocuments, {_id: row._id, section: section, label: getDocumentLabel(row)}]);
    }

    const handleDeselect = (docId) => {
        setSelectedDocuments(selectedDocuments.filter((el) => el._id !== docId));
    }

    const showLeftArrow = dirData.findIndex((el) => el.name === fileName) - 1 >= 0 && !loading;
    const showRightArrow = dirData.findIndex((el) => el.name === fileName) + 1 < dirData.length && !loading;

    return (
        <React.Fragment>
            {showLeftArrow && (
                <div className={"arrowboxLeft"} onClick={() => handleArrowClick("left")}>
                    <img src={"assets/images/cloud/left-arrow.png"} alt={"Left arrow"}
                         style={{height: "32px", width: "32px", paddingLeft: "16px", paddingTop: "16px"}}/>
                </div>
            )}
            {showRightArrow && (
                <div className={"arrowboxRight"} onClick={() => handleArrowClick("right")}>
                    <img src={"assets/images/cloud/right-arrow.png"} alt={"right-arrow"}
                         style={{height: "32px", width: "32px", paddingLeft: "16px", paddingTop: "16px"}}/>
                </div>
            )}
            <div style={{
                height: "100%",
                width: "100%",
                position: "fixed",
                margin: "0",
                padding: "0",
            }}>
                <div style={{height: "calc(100vh - 75px)", position: "relative", overflowY: "auto"}}>
                    <FilePreviewerContainer
                        fileName={fileName}
                        previewData={previewData}
                        containerHeight={"100%"}
                    />
                    <div style={{
                        position: "fixed",
                        maxWidth: "400px",
                        right: "0px",
                        bottom: "75px",
                        backgroundColor: "#585858",
                        display: "flex",
                        justifyContent: "space-between", // Adjust spacing between children
                        flexDirection: "column",
                    }}>
                        {!isEmpty(invoiceMark) && (
                            <div style={{color: "white", padding: "4px", textAlign: "center"}}>Εντοπισμένο MARK: {invoiceMark}</div>
                        )}
                        <div style={{
                            flex: "1",
                            display: "flex",
                            justifyContent: "left", // Align content horizontally to the end
                        }}>
                            <BetterCheckBox
                                name={"moveToNextOne"}
                                className={"pl-2 pt-2 pr-2 customCheckbox"}
                                checked={moveToNextOne}
                                disabled={!isEmpty(fakeCurrentPath) || disableEmronRegisterPage}
                                text={<span
                                    style={{color: "white"}}>Φόρτωση επόμενου αρχείου κατά την καταχώρηση</span>}
                                onChange={(e) => setMoveToNextOne(e.target.checked)}
                            />
                        </div>
                        <div style={{
                            flex: "1",
                            display: "flex",
                            justifyContent: "left", // Align content horizontally to the end
                        }}>
                            <BetterCheckBox
                                name={"openCorrelationButtons"}
                                className={"pl-2 pr-2 customCheckbox"}
                                checked={openCorrelationButtons}
                                text={<span style={{color: "white"}}>Συσχέτιση ήδη υπάρχον εγγράφου</span>}
                                disabled={disableEmronRegisterPage}
                                onChange={(e) => setOpenCorrelationButtons(e.target.checked)}
                            />
                        </div>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    gap: "5px",
                    bottom: "0",
                    left: "0",
                    width: "100%",
                    position: "sticky",
                    backgroundColor: "#585858",
                    height: "75px",
                    padding: "8px",
                    ...disabledPageStyle
                }}>
                    {openCorrelationButtons ? (
                        <React.Fragment>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Sales")}>Συσχέτιση Πώλησης</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Buys")}>Συσχέτιση Αγοράς</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Client Payments")}>Συσχέτιση Είσπραξης</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Supplier Payments")}>Συσχέτιση Πληρωμής</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Accounting Records")}>Συσχέτιση Λογιστικής εγγραφής</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Product Movements")}>Συσχέτιση Κίνησης Ειδών</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Client Movements")}>Συσχέτιση Κίνησης Πελατών</div>
                            <div style={{flex: "1"}} className={"correlateButton"} onClick={() => handleCorrelate("Supplier Movements")}>Συσχέτιση Κίνησης Προμηθευτών</div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                        <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("sales-new")}>Πώληση</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("buys-new")}>Αγορά</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("new-client-payments")}>Είσπραξη</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("new-supplier-payments")}>Πληρωμή</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("accounting-record-new")}>Λογιστική εγγραφή</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("product-movements-new")}>Κίνηση ειδών</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("new-client-movements")}>Κίνηση πελατών</Button>
                            <Button size={"sm"} style={{flex: "1"}}
                                    onClick={() => handleOpenNewERPTabTo("new-supplier-movements")}>Κίνηση προμηθευτών</Button>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <Modal backdrop={"static"} show={showCorrelationModal} backdrop={"static"} dialogClassName={"modal65PercentWidth"}>
                <Modal.Header>
                    <Modal.Title>Συσχέτιση Εγγράφου</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"text-muted"}><i>Φίλτρα</i></div>
                    <Row>
                        <Col md={3}>
                            <DateHelper
                                dateFromAndToFunction={(from, to) => setRequestData({...requestData, dateFrom: from, dateTo: to})}
                            />
                        </Col>
                        <Col md={2}>
                            <DateBox
                                name={"dateFrom"}
                                label={"Ημερομηνία από"}
                                selected={moment(requestData.dateFrom, "DD/MM/YYYY").isValid() ? moment(requestData.dateFrom, "DD/MM/YYYY").toDate() : null}
                                onChange={(e) => {
                                    if (moment(e).isValid()) {
                                        setRequestData({...requestData, dateFrom: moment(e).format("DD/MM/YYYY")});
                                    } else {
                                        setRequestData({...requestData, dateFrom: moment().format("DD/MM/YYYY")});
                                    }
                                }}
                            />
                        </Col>
                        <Col md={2}>
                            <DateBox
                                name={"dateTo"}
                                label={"Ημερομηνία έως"}
                                selected={moment(requestData.dateTo, "DD/MM/YYYY").isValid() ? moment(requestData.dateTo, "DD/MM/YYYY").toDate() : null}
                                onChange={(e) => {
                                    if (moment(e).isValid()) {
                                        setRequestData({...requestData, dateTo: moment(e).format("DD/MM/YYYY")});
                                    } else {
                                        setRequestData({...requestData, dateTo: moment().format("DD/MM/YYYY")});
                                    }
                                }}
                            />
                        </Col>
                        <Col md={5}>
                            <Input
                                name={"generalFilter"}
                                label={"Φίλτρο"}
                                value={requestData.generalFilter}
                                onChange={(e) => setRequestData({...requestData, generalFilter: e.target.value})}
                            />
                        </Col>
                    </Row>
                    <Row className={"d-flex justify-content-end"}>
                        <Col md={3} className={"d-flex justify-content-end"}>
                            <Button style={{marginTop: "25px"}} onClick={() => fetchViewData(false)}>Εφαρμογή φίλτρων</Button>
                        </Col>
                    </Row>
                    {totalPages > 0 && (
                        <React.Fragment>
                            <div className="d-flex justify-content-end mb-2 mt-2">
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => {
                                        setPage(1);
                                        setPageChanged(true);
                                    }}
                                    disabled={page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.first')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => {
                                        setPage(page - 1);
                                        setPageChanged(true);
                                    }}
                                    disabled={page === 1 || loading}
                                >
                                    {t('SalesBalanceSheet.previous')}
                                </Button>
                                <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1" bg="primary">{t('SalesBalanceSheet.page')} {page} / {totalPages}</Badge>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => {
                                        setPage(page + 1);
                                        setPageChanged(true);
                                    }}
                                    disabled={page === totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.next')}
                                </Button>
                                <Button
                                    size={"sm"}
                                    className={"mr-1"}
                                    onClick={() => {
                                        setPage(totalPages);
                                        setPageChanged(true);
                                    }}
                                    disabled={page === totalPages || loading}
                                >
                                    {t('SalesBalanceSheet.last')}
                                </Button>
                            </div>
                            {viewData.length > 0 ? (
                                <table className={"simpleClassicTable"} style={{wordBreak: "break-all"}}>
                                    <colgroup>
                                        <col span={1} style={{width: "20%"}}></col>
                                        <col span={1} style={{width: "35%"}}></col>
                                        <col span={1} style={{width: "25%"}}></col>
                                        <col span={1} style={{width: "20%"}}></col>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Στοιχεία εγγράφου</th>
                                        <th>Ποσό</th>
                                        <th>Επιλογή</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {viewData.map((row, idx) => (
                                        <tr key={`ertable-${idx}`}
                                            style={{backgroundColor: isSelected(row._id) || isCorrelated(row._id) ? "lightgray" : ""}}>
                                            <td>{moment(row["date"]).format("DD/MM/YYYY")}</td>
                                            <td>{getDocumentLabel(row)}</td>
                                            <td>{row["paymentAmount"] || row["sum"] || row["amount"]}</td>
                                            <td>{isCorrelated(row._id) ? (
                                                <Badge bg={"dark"}>Ήδη συσχετισμένο</Badge>
                                            ) : isSelected(row._id) ? (
                                                <Badge bg={"danger"} style={{cursor: "pointer"}}
                                                       onClick={() => handleDeselect(row._id)}>Αποεπιλογή</Badge>
                                            ) : (
                                                <Badge bg={"primary"} style={{cursor: "pointer"}}
                                                       onClick={() => handleSelect(row)}>Επιλογή</Badge>
                                            )}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ) : (
                                <span>Δεν βρέθηκαν δεδομένα</span>
                            )}
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowCorrelationModal(false)} disabled={loading}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => saveDocumentCorrelations()}
                            disabled={selectedDocuments.length === 0 || loading}>
                        Συσχέτιση επιλεγμένων {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={autoCorrModal} onHide={() => setAutoCorrModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Αυτόματη συσχέτιση</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Βρέθηκε ήδη παραστατικό προς συσχέτιση με αποτέλεσμα να μην είναι δυνατή η δημιουργία διπλότυπης εγγραφής.
                    Επιθυμείτε αυτόματη καταχώρηση και συσχέτιση;
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setAutoCorrModal(false)} disabled={loading}>
                        Όχι, κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => saveDocumentCorrelations()} disabled={loading}>
                        Ναι, να γίνει {loading && <Spinner className={"ml-2"} animation={"border"} variant={"dark"} />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default EmronRegisterFile;
