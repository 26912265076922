import React, {useEffect, useState} from 'react';
import {classicStyleBelowNavbar} from "../../Statics";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";
import moment from "moment";
import {isEmpty} from "../../../../../_helpers/commonFunctions";
import {
    setLegacyWorkingProgram,
    setWorkingProgram, setWorkingProgramExtraData,
    setWorkingProgramNeedsSave,
    setWorkingProgramOnEmployee, setWorkingProgramOnlyView
} from "../../../../../_reducers/DataPanel/Payroll/payrollSlice";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import {colorMapping, dayTranslate, decimalToHourMinutesFormat, getWorkType} from "./constants";
import {addTab, updateActiveMainTab} from "../../../../../_reducers/TabsSlice";
import EmployeeInfoModal from "./employee-info-modal";
import "./otherStyles.css";
import {Popover} from "antd";

const WorkingProgram = () => {
    const employeeRowHeight = 100;
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const workingProgram = useSelector((state) => state.PAYROLL.workingProgram);
    const workingProgramExtraData = useSelector((state) => state.PAYROLL.workingProgramExtraData);
    const workingProgramNeedsSave = useSelector((state) => state.PAYROLL.workingProgramNeedsSave);
    const workingProgramOnlyView = useSelector((state) => state.PAYROLL.workingProgramOnlyView);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);

    const [tableColumns, setTableColumns] = useState([]);

    const [changeWeekModal, setChangeWeekModal] = useState(false);
    const [changeWeekAction, setChangeWeekAction] = useState(""); // previous, next

    const [loadingERPSave, setLoadingERPSave] = useState(false);
    const [loadingErganiSave, setLoadingErganiSave] = useState(false);
    const [loadingWeekChange, setLoadingWeekChange] = useState(false);

    const [updatedAtModal, setUpdatedAtModal] = useState(false);

    const [weekChanged, setWeekChanged] = useState(false);

    const todayBorderStyle = "3px solid #68a0fc";

    // Employee Modal
    const [showEmployeeInfoModal, setShowEmployeeInfoModal] = useState(false);
    const [employeeInfoModalId, setEmployeeInfoModalId] = useState("");

    useEffect(() => {
        if (!workingProgram) { // Is null need to fetch, even a default
            fetchWorkingProgram();
        } else {
            fetchWorkingProgramStatus();
        }
    }, [])

    useEffect(() => {
        if (workingProgram) fetchWorkingProgramStatus();
    }, [workingProgram?.updated_at])

    useEffect(() => {
        if (workingProgram) {
            let tc = [{
                date: "",
                columnDisplay: "Εργαζόμενοι"
            }];
            const from = moment(workingProgram.dateFrom, "DD/MM/YYYY", true);
            const to = moment(workingProgram.dateTo, "DD/MM/YYYY", true);
            const diff = Number(to.diff(from, "days")) + 1;
            for (let i = 0; i < diff; i++) {
                let curMomentFormat = from.format("DD/MM/YYYY");
                let curMomentDay = from.format("dddd");

                tc.push({
                    date: curMomentFormat,
                    columnDisplay: <React.Fragment>
                        {dayTranslate[curMomentDay]}{moment().format("DD/MM/YYYY") === curMomentFormat && " (Σήμερα)"}<br/>{curMomentFormat}
                    </React.Fragment>,
                })
                from.add(1, "days");
            }
            setTableColumns(tc);
        }
    }, [workingProgram])

    useEffect(() => {
        if (weekChanged) {
            setWeekChanged(false);
            fetchWorkingProgram();
        }
    }, [weekChanged])

    useEffect(() => {
        if (!changeWeekModal) {
            setChangeWeekAction("");
        }
    }, [changeWeekModal])

    const fetchWorkingProgram = (specialCode = null) => {
        if (updatedAtModal) setUpdatedAtModal(false);

        let params = { company: company.id }
        if (isEmpty(workingProgram?.dateFrom) && isEmpty(workingProgram?.dateTo)) {
            params.dateFrom = moment().startOf("week").format("DD/MM/YYYY");
            params.dateTo = moment().endOf("week").format("DD/MM/YYYY");
        } else {
            params.dateFrom = workingProgram?.dateFrom;
            params.dateTo = workingProgram?.dateTo;
        }
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/fetch-working-program`, {
            headers: { "Content-Type": "application/json" },
            params: params
        }).then((res) => {
            if (res.data.status === "200") {
                dispatch(setWorkingProgram(res.data.data));
                dispatch(setWorkingProgramExtraData(res.data.extraData));
                dispatch(setLegacyWorkingProgram(res.data.data));
                dispatch(setWorkingProgramNeedsSave(false));
                if (specialCode === "0") toast.success("Επιτυχής φόρτωση του νέου προγράμματος.");
                setLoadingWeekChange(false);
            } else {
                toast.error(res.data.message);
                setLoadingWeekChange(false);
            }
        }).catch((err) => {
            setLoadingWeekChange(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const fetchWorkingProgramStatus = () => {
        let params = { company: company.id }
        if (!isEmpty(workingProgram.dateFrom) && !isEmpty(workingProgram.dateTo)) {
            params.dateFrom = workingProgram.dateFrom;
            params.dateTo = workingProgram.dateTo;
            params.updated_at = workingProgram.updated_at;
            params._id = workingProgram?._id;
        }
        axios.get(`${process.env.REACT_APP_API_URL2}/payroll/fetch-working-program-status`, {
            headers: { "Content-Type": "application/json" },
            params: params
        }).then((res) => {
            if (res.data.status === "200") {
                if (workingProgramOnlyView) dispatch(setWorkingProgramOnlyView(false));
            } else if (res.data.status === "400") {
                if (res.data?.specialCode === "0") {
                    setUpdatedAtModal(true);
                } else if (res.data?.specialCode === "1") {
                    if (!workingProgramOnlyView) dispatch(setWorkingProgramOnlyView(true));
                }
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const renderEmployeeCell = (emp, day) => {
        const findMatchingDay = emp?.dailyCalendar?.filter((el) => el.day === day);

        if (findMatchingDay?.length > 0) {
            let maxHeight = employeeRowHeight;
            if (findMatchingDay.length > 1) maxHeight = 150;
            const height = maxHeight / findMatchingDay.length;
            let dayComponents = [];
            for (let dc of findMatchingDay) {
                dayComponents.push(
                    <div style={{position: "relative", height: `${height}px`, backgroundColor: colorMapping[dc.workType], margin: "4px", padding: "4px", borderRadius: "8px"}}>
                        {getWorkType(dc)}<br/>
                        {dc.allDay ? (
                            <React.Fragment>
                                Όλη μέρα<br/>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {decimalToHourMinutesFormat(dc.hourFrom)} - {decimalToHourMinutesFormat(dc.hourTo)}<br/>
                            </React.Fragment>
                        )}
                        {(dc?.metaballomeno === true && dc?.sentToErgani !== true) && (
                            <React.Fragment>
                                <i>Θα σταλεί μεταβαλλόμενο</i><br/>
                            </React.Fragment>
                        )}
                        {(dc?.metaballomeno === true && dc?.sentToErgani === true) && (
                            <React.Fragment>
                                <i>Στάλθηκε μεταβαλλόμενο</i><br/>
                            </React.Fragment>
                        )}
                        {(dc?.apologistiko === true && dc?.sentToErgani !== true) && (
                            <React.Fragment>
                                <i>Θα σταλεί απολογιστικό</i><br/>
                            </React.Fragment>
                        )}
                        {(dc?.apologistiko === true && dc?.sentToErgani === true) && (
                            <React.Fragment>
                                <i>Στάλθηκε απολογιστικό</i><br/>
                            </React.Fragment>
                        )}
                        Εγκατάσταση: {dc.installationMasterId}
                    </div>
                )
            }
            if (workingProgramExtraData.erganiCards) {
                if (workingProgramExtraData.erganiCards[emp.employeeId]) {
                    if (workingProgramExtraData.erganiCards[emp.employeeId][String(day)]) {
                        const cardHits = workingProgramExtraData.erganiCards[emp.employeeId][String(day)];
                        let displayValues = [];
                        for (let hit of cardHits) {
                            const values = String(hit).split("|");
                            displayValues.push(`${values[0] === "arrive" ? "Άφιξη" : "Αναχώρηση"} ${values[1]}`);
                        }
                        displayValues = displayValues.join(", ");
                        dayComponents.push(
                            <div style={{position: "relative", width: "calc(100% - 8px)", margin: "4px", borderRadius: "4px", backgroundColor: "lightgray", color: "black", paddingTop: "3px", paddingBottom: "3px"}}>
                                {displayValues}
                            </div>
                        )
                    }
                }
            }
            return dayComponents;
        } else {
            return (
                <React.Fragment>

                </React.Fragment>
            )
        }
    }

    const handleOpenEmployeeWorkingProgram = (emp) => {
        dispatch(setWorkingProgramOnEmployee(emp));
        const findWorkingProgram = TABS_DATA.findIndex((item) => "working-program-employee" === item);
        if (findWorkingProgram === -1) {
            dispatch(addTab("working-program-employee"));
            dispatch(updateActiveMainTab(TABS_DATA.length));
        } else {
            dispatch(updateActiveMainTab(findWorkingProgram));
        }
    }

    const renderEmployeeRow = (emp, lastRow = false) => {
        if (emp) {
            const sId = String(emp.employeeIdentifier).split("|");
            const firstName = sId[0];
            const lastName = sId[1];
            const vatNumber = sId[2];
            return (
                <tr style={{height: `${employeeRowHeight}px`, position: "relative", cursor: "pointer"}} className={"employeeHover"} onClick={() => handleOpenEmployeeWorkingProgram(emp)}>
                    <td style={{position: "relative", height: "100%", width: "100%"}}>
                        {firstName} {lastName}<br/>(ΑΦΜ: {vatNumber})
                        {emp?.persistent === "true" && (
                            <span><br/>(Σταθερό πρόγραμμα)</span>
                        )}
                        <div className={"employeeHoverText"}><br/><i>Προβολή/Επεξεργασία προγράμματος</i></div>
                        <div style={{position: "absolute", bottom: "0px", right: "2px", zIndex: "5", height: "25px", width: "25px"}} onClick={(e) => {
                            e.stopPropagation();
                            setEmployeeInfoModalId(emp.employeeId);
                            setShowEmployeeInfoModal(true);
                        }}>
                            <i className="fa-regular fa-address-card fa-xl" title={"Προβολή πληροφοριών εργαζομένου"}></i>
                        </div>
                    </td>
                    {tableColumns.toSpliced(0, 1).map((col, colIdx) => {
                        if (moment().format("DD/MM/YYYY") === col.date) {
                            return (
                                <td style={{borderLeft: todayBorderStyle, borderRight: todayBorderStyle, borderBottom: lastRow ? todayBorderStyle : ""}}>{renderEmployeeCell(emp, colIdx)}</td>
                            )
                        } else {
                            return (
                                <td>{renderEmployeeCell(emp, colIdx)}</td>
                            )
                        }
                    })}
                </tr>
            )
        } else {
            return <React.Fragment></React.Fragment>
        }
    }

    const handleERPSave = () => {
        setLoadingERPSave(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/save-working-program`, {
            ...workingProgram,
            company: company.id,
        }, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            setLoadingERPSave(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής αποθήκευση!");
                dispatch(setWorkingProgram(res.data.data));
                dispatch(setWorkingProgramNeedsSave(false));
                dispatch(setLegacyWorkingProgram(res.data.data));
            } else {
                if (res.data?.specialCode === "0") {
                    setUpdatedAtModal(true);
                } else {
                    toast.error(res.data.message);
                }
            }
        }).catch((err) => {
            setLoadingERPSave(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleErganiSave = () => {
        setLoadingErganiSave(true);
        axios.post(`${process.env.REACT_APP_API_URL2}/payroll/send-working-program-ergani`, {
            dateFrom: workingProgram.dateFrom,
            dateTo: workingProgram.dateTo,
            company: company.id,
        }).then((res) => {
            setLoadingErganiSave(false);
            if (res.data.status === "200") {
                toast.success("Επιτυχής αποστολή!");
                dispatch(setWorkingProgram(res.data.data));
                dispatch(setLegacyWorkingProgram(res.data.data));
            } else if (res.data.status === "300") {
                toast.info(res.data.message);
                dispatch(setWorkingProgram(res.data.data));
                dispatch(setLegacyWorkingProgram(res.data.data));
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoadingErganiSave(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const handleChangeWeekButton = (action) => {
        if (!workingProgramNeedsSave) {
            handleConfirmWeekChange(action);
        } else {
            setChangeWeekModal(true);
            setChangeWeekAction(action);
        }
    }

    const handleConfirmWeekChange = (forceAction = "") => {
        setChangeWeekModal(false);
        if (changeWeekAction === "next" || forceAction === "next") {
            handleButtonNextWeek();
        } else if (changeWeekAction === "previous" || forceAction === "previous") {
            handleButtonPreviousWeek();
        }
        setChangeWeekAction("");
    }

    const handleButtonNextWeek = () => {
        let dateFrom = moment(workingProgram.dateTo, "DD/MM/YYYY", true).add(2, "day").startOf("week").format("DD/MM/YYYY");
        let dateTo = moment(workingProgram.dateTo, "DD/MM/YYYY", true).add(2, "day").endOf("week").format("DD/MM/YYYY");
        dispatch(setWorkingProgram({...workingProgram, dateFrom: dateFrom, dateTo: dateTo}));
        dispatch(setWorkingProgramNeedsSave(false));
        setWeekChanged(true);
        setLoadingWeekChange(true);
    }

    const handleButtonPreviousWeek = () => {
        let dateFrom = moment(workingProgram.dateFrom, "DD/MM/YYYY", true).subtract(2, "day").startOf("week").format("DD/MM/YYYY");
        let dateTo = moment(workingProgram.dateFrom, "DD/MM/YYYY", true).subtract(2, "day").endOf("week").format("DD/MM/YYYY");
        dispatch(setWorkingProgram({...workingProgram, dateFrom: dateFrom, dateTo: dateTo}));
        dispatch(setWorkingProgramNeedsSave(false));
        setWeekChanged(true);
        setLoadingWeekChange(true);
    }

    const workingProgramEmpty = workingProgram?.employees?.length === 0;

    return (
        <div style={classicStyleBelowNavbar}>
            {workingProgram && (
                <React.Fragment>
                    <Row className={"d-flex justify-content-center mb-2"} style={{position: "sticky", top: "0", zIndex: "202", backgroundColor: "white"}}>
                        <Col md={2} style={{textAlign: "right"}} className={"mt-2"}>
                            <Button size={"sm"} onClick={() => handleChangeWeekButton("previous")} disabled={loadingWeekChange}>
                                {"<<"} Προηγούμενη Εβδομάδα
                            </Button>
                        </Col>
                        <Col md={5} style={{textAlign: "center"}}>
                            <div style={{fontWeight: "600", fontSize: "16px"}} className={"mt-1"}>Εβδομαδιαίο Πρόγραμμα {workingProgram.dateFrom} - {workingProgram.dateTo}</div>
                            {workingProgramEmpty ? (
                                <div>Το πρόγραμμα είναι άδειο, δεν βρέθηκε κανένας ενεργός εργαζόμενος.</div>
                            ) : (
                                <React.Fragment>
                                    {workingProgram.sentToErgani ? (
                                        <div style={{fontWeight: "600", fontSize: "16px", color: "green"}}>Το πρόγραμμα έχει σταλεί επιτυχώς στην Εργάνη.</div>
                                    ) : (
                                        <div style={{fontWeight: "600", fontSize: "16px", color: "red"}}>Προσοχή! Το πρόγραμμα δεν έχει σταλθεί στην Εργάνη!</div>
                                    )}
                                    {workingProgramNeedsSave && (
                                        <div style={{fontWeight: "600", fontSize: "14px"}} className={"mt-1"}>Το πρόγραμμα έχει τροποποιηθεί και χρειάζεται αποθήκευση</div>
                                    )}
                                </React.Fragment>
                            )}
                            {workingProgramOnlyView && (
                                <Popover placement={"bottom"} content={
                                    <>
                                        Υπάρχει εβδομαδιαίο πρόγραμμα που δεν έχει σταλεί στην Εργάνη.
                                    </>
                                }>
                                    <div style={{fontWeight: "600", fontSize: "14px"}} className={"mt-1"}>***ΜΟΝΟ ΠΡΟΒΟΛΗ***</div>
                                </Popover>
                            )}
                        </Col>
                        <Col md={2} style={{textAlign: "left"}} className={"mt-2"}>
                            <Button size={"sm"} onClick={() => handleChangeWeekButton("next")} disabled={loadingWeekChange}>
                                Επόμενη Εβδομάδα {">>"}
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{opacity: workingProgramOnlyView ? "0.8" : "1"}} hidden={loadingWeekChange}>
                        <Col md={12}>
                            <table className={"employeeProgramTable mb-3"} style={{fontSize: "12px"}}>
                                <thead style={{backgroundColor: "var(--light-skyblue)", fontSize: "14px"}}>
                                <tr>
                                    {tableColumns.map((col, idx) => {
                                        if (moment().format("DD/MM/YYYY") === col.date) {
                                            return (
                                                <th key={`col-${idx}`} style={{borderLeft: todayBorderStyle, borderRight: todayBorderStyle, borderTop: todayBorderStyle, backgroundColor: "#68a0fc", color: "white"}}>{col.columnDisplay}</th>
                                            )
                                        } else {
                                            return (
                                                <th key={`col-${idx}`}>{col.columnDisplay}</th>
                                            )
                                        }
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {workingProgram && workingProgram?.employees && workingProgram?.employees?.map((emp, rowIdx) => (
                                    <React.Fragment>
                                        {renderEmployeeRow(emp, rowIdx + 1 === workingProgram.employees.length)}
                                    </React.Fragment>
                                ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    {(!workingProgramOnlyView && !workingProgramEmpty) && (
                        <Row className={"justify-content-md-end"} style={{position: "sticky", bottom: 0, right: "10px"}}>
                            <Col md={12} className={"d-flex justify-content-end"} >
                                <div style={{backgroundColor: "white"}}>
                                    <Button onClick={() => handleERPSave()} className={"mr-1"}
                                            disabled={loadingERPSave || loadingErganiSave || loadingWeekChange} size={"sm"}>Αποθήκευση Εβδομαδιαίου Προγράμματος στο ERP {loadingERPSave &&
                                        <Spinner className={"ml-2"} animation="border" variant="dark"/>}</Button>
                                    <Button onClick={() => handleErganiSave()} disabled={loadingERPSave || loadingErganiSave || loadingWeekChange || workingProgramNeedsSave} size={"sm"}>Αποστολή Εβδομαδιαίου Προγράμματος
                                        στην Εργάνη {loadingErganiSave &&
                                            <Spinner className={"ml-2"} animation="border" variant="dark"/>}</Button>
                                </div>
                            </Col>
                        </Row>
                    )}
                </React.Fragment>
            )}
            {!workingProgram && (
                <div>Η σελίδα φορτώνει, παρακαλώ περιμένετε...</div>
            )}
            <Modal backdrop={"static"} show={changeWeekModal} onHide={() => setChangeWeekModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Απαιτείται αποθήκευση</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Το πρόγραμμα δεν έχει αποθηκευτεί, αλλάζοντας εβδομάδα θα χάσετε τα δεδομένα που έχουν αλλαχθεί. Είσαστε σίγουροι ότι θέλετε να αλλάξετε εβδομάδα χωρίς να αποθήκευση;
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setChangeWeekModal(false)}>
                        Όχι, κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => handleConfirmWeekChange()}>
                        Ναι, συνέχεια
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop={"static"} show={updatedAtModal} onHide={() => setUpdatedAtModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Αποτυχημένη αποθήκευση</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Το πρόγραμμα έχει ήδη τροποποιηθεί με αποτέλεσμα η αποθήκευση/μεταβολή του να μην μπορεί να πραγματοποιηθεί.
                    Πατώντας κλείσιμο, θα κλείσει το παρόν παράθυρο χωρίς να είναι ικανή η αποθήκευση του προγράμματος.
                    Πατώντας λήψη θα φορτωθεί εκ νέου το πρόγραμμα απορρίπτοντας τις δικές σας αλλαγές.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setUpdatedAtModal(false)}>
                        Κλείσιμο
                    </Button>
                    <Button variant="primary" onClick={() => fetchWorkingProgram("0")}>
                        Λήψη νέου προγράμματος
                    </Button>
                </Modal.Footer>
            </Modal>

            <EmployeeInfoModal
                employeeId={employeeInfoModalId}
                show={showEmployeeInfoModal}
                setShow={setShowEmployeeInfoModal}
            />
        </div>
    )
}

export default WorkingProgram
