import {createSlice} from "@reduxjs/toolkit";

const initialState = false;

export const MobileSidebarSlice = createSlice({
    name: "Mobile Sidebar Toggle",
    initialState,
    reducers: {
        mobileSidebarToggle: (state) => !state,
    },
});

// Exporting action
export const {mobileSidebarToggle} = MobileSidebarSlice.actions;
