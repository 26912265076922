import React from "react";
import "../Components/styles.css";

const SimpleContextMenu = ({show, posX, posY, onOpenInCloud, onDecorrelate, onFileDelete}) => {
    const contextMenuStyle = {
        display: show ? "" : "none",
        opacity: show ? 1 : 0,
        position: "absolute",
        left: `${posX}px`,
        top: `${posY}px`,
        zIndex: "1000",
        border: "1px solid lightgray",
        backgroundColor: "white",
        width: "120px",
        fontSize: "12px",
        userSelect: "none",
    }

    return (
        <React.Fragment>
            <div style={contextMenuStyle} className={"contextMenu"}>
                <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"} onClick={() => onOpenInCloud()}>Άνοιγμα στο Cloud</div>
                <div style={{borderBottom: "1px solid lightgray"}} className={"contextMenuOption"} onClick={() => onDecorrelate()}>Αποσυσχέτιση</div>
                <div className={"contextMenuOption"} onClick={() => onFileDelete()}>Αποσυσχέτιση & Διαγραφή</div>
            </div>
        </React.Fragment>
    )
}

export default SimpleContextMenu
