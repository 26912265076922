import React, {useEffect, useState} from "react";
import {
    checkPermission,
    getDifficultyOptions,
    getGreekCurrencyFormat,
    getStockholderPositions,
    isEmpty
} from "../../../_helpers/commonFunctions";
import {useTranslation} from "react-i18next";
import EditableTable from "../../primer/Editable-Table";
import {Col} from "react-bootstrap";
import AccountingBox from "../../primer/AccountingBox";
import moment from "moment";

const EmronClientData = ({clientData}) => {
    const [t] = useTranslation('common');
    const [incrKey, setIncrKey] = useState(0);

    useEffect(() => {
        setIncrKey(incrKey + 1);
    }, [clientData.contactPoints])

    const clientContactPointsColumns = [
        {
            name: "No",
            field: "no",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsGeneralEdit.table.contactName'),
            field: "name",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsGeneralEdit.table.mobilePhone'),
            field: "mobile",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsGeneralEdit.table.landLine'),
            field: "landline",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsGeneralEdit.table.otherPhone'),
            field: "otherPhone",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsGeneralEdit.table.email'),
            field: "email",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsGeneralEdit.table.contactNotes'),
            field: "contactNotes",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t('ClientsGeneralEdit.table.acceptNotifications'),
            field: "acceptsNotifications",
            editable: true,
            inputType: {
                type: "checkbox",
            },
        },
    ]

    return (
        <React.Fragment>
            <div>
                <p className="text-muted"><i>{t('ClientsGeneralEdit.table.title')}</i></p>
                <EditableTable
                    tableName="Edit Client Contact Points"
                    key={"Edit Client Contact Points" + incrKey}
                    allowActions={false}
                    allowToggleColumns={false}
                    columns={clientContactPointsColumns}
                    data={clientData.contactPoints}
                    allowInsertRow={false}
                    enableNewRowCheckbox={false}
                    disabled={true}
                />

                <p className="text-muted"><i>{t('ClientsNewEmronEdit.password')}</i></p>
                <div>
                    <table className={"simpleClassicTable"}>
                        <colgroup>
                            <col span={1} style={{width: "10%"}}></col>
                            <col span={1} style={{width: "20%"}}></col>
                            <col span={1} style={{width: "20%"}}></col>
                            <col span={1} style={{width: "20%"}}></col>
                            <col span={1} style={{width: "30%"}}></col>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Αρ.</th>
                            <th>Τύπος</th>
                            <th>Όνομα Χρήστη</th>
                            <th>Κωδικός</th>
                            <th>Σημειώσεις</th>
                        </tr>
                        </thead>
                        <tbody>
                        {clientData?.emronPasswords.map((row, idx) => (
                            <tr key={`table-ePs${idx}`} style={{wordBreak: "break-all"}}>
                                <td>{row["no"]}</td>
                                <td>{row["type"]}</td>
                                <td>{row["username"]}</td>
                                <td>{row["password"]}</td>
                                <td>{row["notes"]}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <br/>
                <p className="text-muted"><i>{t('ClientsNewEmronEdit.stockholders')}</i></p>
                <Col sm='12' md="3">
                    <div className="mb-3">
                        <label className="" htmlFor="capital">{t('ClientsNewEmronEdit.capital')}</label>
                        <AccountingBox
                            name="capital"
                            className="mb-2 form-control"
                            placeholder={t('ProductsNewGeneralEdit.preferredPrice.placeholder')}
                            defaultValue={clientData?.capital}
                            disabled={true}
                        />
                    </div>
                </Col>
                <div>
                    <table className={"simpleClassicTable"}>
                        <colgroup>
                            <col span={1} style={{width: "5%"}}></col>
                            <col span={1} style={{width: "15%"}}></col>
                            <col span={1} style={{width: "15%"}}></col>
                            <col span={1} style={{width: "15%"}}></col>
                            <col span={1} style={{width: "10%"}}></col>
                            <col span={1} style={{width: "10%"}}></col>
                            <col span={1} style={{width: "10%"}}></col>
                            <col span={1} style={{width: "10%"}}></col>
                            <col span={1} style={{width: "10%"}}></col>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Αρ.</th>
                            <th>Όνομα</th>
                            <th>Επώνυμο</th>
                            <th>ΑΦΜ</th>
                            <th>{t('ClientsNewEmronEdit.table2.position')}</th>
                            <th>{t('ClientsNewEmronEdit.table2.percentage')}</th>
                            <th>{t('ClientsNewEmronEdit.table2.stockValue')}</th>
                            <th>{t('ClientsNewEmronEdit.table2.dateFrom')}</th>
                            <th>{t('ClientsNewEmronEdit.table2.dateTo')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {clientData?.stockholders.map((row, idx) => (
                            <tr key={`table-ePs2${idx}`} style={{wordBreak: "break-all"}}>
                                <td>{row["no"]}</td>
                                <td>{row["name"]}</td>
                                <td>{row["surname"]}</td>
                                <td>{row["vat"]}</td>
                                <td>{getStockholderPositions().find((el) => el.value === row["position"])?.label}</td>
                                <td>{row["percentage"]} %</td>
                                <td>{getGreekCurrencyFormat(row["stockValue"])}</td>
                                <td>{isEmpty(row["dateFrom"]) ? "" : moment(row["dateFrom"]).format("DD/MM/YYYY")}</td>
                                <td>{isEmpty(row["dateTo"]) ? "" : moment(row["dateTo"]).format("DD/MM/YYYY")}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {checkPermission("fulladmin", []) && (
                    <React.Fragment>
                        <br/>
                        <p className="text-muted">
                            <i>Μηνιαίες Χρεώσεις</i>
                        </p>
                        <div>
                            <table className={"simpleClassicTable"}>
                                <colgroup>
                                    <col span={1} style={{width: "5%"}}></col>
                                    <col span={1} style={{width: "20%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                    <col span={1} style={{width: "15%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                    <col span={1} style={{width: "10%"}}></col>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>Αρ.</th>
                                    <th>Εγκατάσταση</th>
                                    <th>Μήνας από</th>
                                    <th>Μήνας μέχρι</th>
                                    <th>Ποσό</th>
                                    <th>Σημειώσεις</th>
                                    <th>Δυσκολία</th>
                                    <th>Σημειώσεις Δυσκολίας</th>
                                    <th>Αποδοχή πληρωμών μέσω κάρτας</th>
                                </tr>
                                </thead>
                                <tbody>
                                {clientData?.emronPayments.map((row, idx) => (
                                    <tr key={`table-ePs3${idx}`} style={{wordBreak: "break-all"}}>
                                        <td>{row["no"]}</td>
                                        <td>{row["installation"]}</td>
                                        <td>{moment(row["month"]).format("MM/YYYY")}</td>
                                        <td>{isEmpty(row["monthTo"]) ? "" : moment(row["monthTo"]).format("MM/YYYY")}</td>
                                        <td>{getGreekCurrencyFormat(row["amount"])}</td>
                                        <td>{row["notes"]}</td>
                                        <td>{getDifficultyOptions().find((el) => el.value === row["difficulty"])?.label}</td>
                                        <td>{row["difficultyNotes"]}</td>
                                        <td>{isEmpty(row["acceptCardPayments"]) || row["acceptCardPayments"] === "false" ? "Όχι" : "Ναι"}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </div>
            <div style={{textAlign: "center"}} className={"mt-2"}>
                <p>(Μόνο προβολή, τροποποίηση κατόπιν συννενόησης με την γραμματεία)</p>
            </div>
        </React.Fragment>
    )
}

export default EmronClientData;
