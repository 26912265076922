import React from "react";
import "../Components/styles.css";
import {formatBigName, getFileImageDisplay, popExtension} from "../Components/constants";

const SimpleFile = ({name, selected, onContextMenu}) => {
    return (
        <div className={`fileFolderStyle ${selected ? "fileFolderStyleSelected" : ""}`}
             onContextMenu={(e) => onContextMenu(e, name)}>
            <div style={{position: "relative"}}>
                <img src={getFileImageDisplay(popExtension(name))} alt={"File"} style={{height: "56px", width: "56px"}} draggable={false} />
            </div>
            <div className={"mt-1"} style={{wordBreak: "break-all", fontSize: "12px"}}>
                {formatBigName(name)}
            </div>
        </div>
    )
}

export default SimpleFile;
