import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Badge, Col, Row} from "react-bootstrap";
import DateBox from "../../../primer/DateBox";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import {
    setEmronMonthlyReportRequestData,
    setEmronMonthlyReportResponseData,
} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import axios from "axios";
import "jspdf-autotable";
import EditableTable from "../../../primer/Editable-Table";
import Loader from "../../../primer/POPUP-COMPONENTS/Loader/PrimerLoader";
import {checkPermission, getGreekCurrencyFormat, getGreekMonthName} from "../../../../_helpers/commonFunctions";
import moment from "moment";
import Dropdown from "../../../primer/Dropdown";
import BetterCheckBox from "../../../common/BetterCheckBox";
import {Popover} from "antd";

const EmronPayments = () => {
    const [t] = useTranslation('common');
    const dispatch = useDispatch();
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
    const requestData = useSelector((state) => state.CLIENTS_DATA.emronMonthlyReportRequestData);
    const responseData = useSelector((state) => state.CLIENTS_DATA.emronMonthlyReportResponseData);
    const [pageChanged, setPageChanged] = useState(false);
    const [refreshColumns, setRefreshColumns] = useState(false);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    useEffect(() => {
        loadInitialClientsListData();
        setRefreshColumns(true);
    }, [])

    useEffect(() => {
        if (refreshColumns) {
            setRefreshColumns(false);
            refreshColumnsFunction();
        }
    }, [refreshColumns])

    useEffect(() => {
        if (pageChanged === true) {
            setPageChanged(false);
            fetchReport(true);
        }
    }, [pageChanged])

    const cardFilterOptions = [
        {label: "Προβολή όλων", value: "both"},
        {label: "Μόνο με κάρτα", value: "card"},
        {label: "Μόνο χωρίς κάρτα", value: "no_card"}
    ]

    const cardFilterStatusOptions = [
        {label: "Κενό", value: ""},
        {label: "Πληρωμένη", value: "paid"},
        {label: "Εκκρεμεί πληρωμή", value: "pending"},
        {label: "Αποτυχία πληρωμής", value: "failed"},
    ]

    const refreshColumnsFunction = () => {
        if (responseData?.months?.length > 0) {
            let tcCol = [...tableColumns];
            tcCol = tcCol.filter((el) => el.isForMonthYear !== true);
            for (let monthYear of responseData.months) {
                tcCol.push({
                    name: `${getGreekMonthName(monthYear.split("/")[0])} ${monthYear.split("/")[1]}`,
                    field: monthYear,
                    editable: true,
                    disabled: true,
                    hiddenColumn: false,
                    inputType: {
                        type: 'accounting',
                        config: {
                            suffix: "€",
                        }
                    },
                    isForMonthYear: true,
                })
            }
            setTableColumns(tcCol);
        }
    }

    const columns = [
        {
            name: "Αρ.",
            field: "no",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Όνομα Πελάτη",
            field: "clientName",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Εγκατάσταση",
            field: "installation",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Δυσκολία",
            field: "difficulty",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Σημειώσεις",
            field: "notes",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Σημειώσεις Δυσκολίας",
            field: "difficultyNotes",
            editable: false,
            hiddenColumn: true,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Αποδοχή Πληρωμών με κάρτα",
            field: "acceptCardPayments",
            editable: false,
            hiddenColumn: false,
            inputType: {
                type: "text",
            },
        }
    ]
    const [tableColumns, setTableColumns] = useState([...columns]);

    const [clientsList, setClientsList] = useState([]);
    const [reportLoader, setReportLoader] = useState(false);

    useEffect(() => {
        let passedData = CLIENTS_DATA.selectedEmronPaymentsFilters;
        if(passedData) {
            const reqData = {
                ...requestData,
                company: company.id,
                year: company.year,
                clientName: passedData.clientName,
                page: 1,
            }
            dispatch(setEmronMonthlyReportRequestData(reqData));
            setPageChanged(true);
        }
    },[CLIENTS_DATA.selectedEmronPaymentsFilters])

    const onChangeSearch = (keyword, setData) => {
        if (keyword.length >= 2) {
            loadClientsListData(keyword, setData);
        }
    }

    const loadInitialClientsListData = () => {
        axios.post(process.env.REACT_APP_API_URL2 + `/client/list-client`,{ company: company.id }, {
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    setClientsList(res.data.data);
                } else {
                    setClientsList([]);
                }
            } else {
                setClientsList([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }
    const loadClientsListData = (name, setData) => {
        axios.post(process.env.REACT_APP_API_URL2 + `/client/list-client`, { name: name, company: company.id }, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            if (res.data && res.data.status === "200") {
                if (res.data.data) {
                    if (setData) setData(res.data.data);
                } else {
                    if (setData) setData([]);
                }
            } else {
                if (setData) setData([]);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const clientDropdownColumns = [
        {
            label: t('SalesBalanceSheet.table3.clientName'),
            name: "name",
        },
        {
            label: t('SalesBalanceSheet.table3.vatNumber'),
            name: "vatNumber",
        },
        {
            label: t('SalesBalanceSheet.table3.phoneNumber'),
            name: "phone",
        },
    ];

    const handleOnChange = (e, type, name) => {
        if (type === "datebox") {
            if (!moment(e).isValid()) {
                dispatch(setEmronMonthlyReportRequestData({...requestData, [name] : moment().toDate()}));
            } else {
                dispatch(setEmronMonthlyReportRequestData({...requestData, [name] : moment(e).toDate()}));
            }
        } else if (type === "acdd") {
            if (name === "clientName") {
                dispatch(setEmronMonthlyReportRequestData({...requestData, [name] : e.row.name}));
            }
        } else if (type === "checkbox") {
            if (name === "groupClients" && e.target.checked === true) {
                dispatch(setEmronMonthlyReportRequestData({...requestData, groupClients : "active", groupInstallations: "active"}));
            } else {
                dispatch(setEmronMonthlyReportRequestData({...requestData, [name] : e.target.checked ? "active" : "inactive"}))
            }
        } else if (type === "dropdown") {
            dispatch(setEmronMonthlyReportRequestData({...requestData, [name] : e.value}));
        }
    }

    const fetchReport = (fromPagination = false) => {
        setReportLoader(true);
        let reqData = {
            ...requestData,
            year: company.year,
            company: company.id,
        }
        if (!fromPagination) {
            reqData.page = 1;
            dispatch(setEmronMonthlyReportRequestData({...requestData, page: 1}));
        }
        axios.post(process.env.REACT_APP_API_URL2 + "/report/emron-payments-report", reqData, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => {
            setReportLoader(false);
            if (res.data.status === "200") {
                dispatch(setEmronMonthlyReportResponseData({...responseData, data: [...res.data.data], totalPages: res.data.totalPages, months: res.data.months, totals: res.data.totals}));
                setRefreshColumns(true);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setReportLoader(false);
            console.log(err);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
        })
    }

    const toFirstPage = () => {
        dispatch(setEmronMonthlyReportRequestData({...requestData, page: 1}));
        setPageChanged(true);
    }

    const toNextPage = () => {
        dispatch(setEmronMonthlyReportRequestData({...requestData, page: requestData.page + 1}));
        setPageChanged(true);
    }
    const toPreviousPage = () => {
        dispatch(setEmronMonthlyReportRequestData({...requestData, page: requestData.page - 1}));
        setPageChanged(true);
    }
    const toLastPage = () => {
        dispatch(setEmronMonthlyReportRequestData({...requestData, page: responseData.totalPages}));
        setPageChanged(true);
    }

    return (
        <div className="search-client">
            {reportLoader ? <Loader/> :
                <div>
                    <div className="no-print">
                        <Row>
                            <Col md={2}>
                                <DateBox
                                    dateFormat="MM-yyyy"
                                    name="month"
                                    allowOtherYears={true}
                                    showMonthYearPicker={true}
                                    selected={requestData?.month ? requestData?.month : new Date()}
                                    label="Επιλέξτε μήνα"
                                    onChange={(e) => handleOnChange(e, "datebox", "month")}
                                />
                            </Col>
                            <Col md={3}>
                                <AdvancedCombobox
                                    ACName="Sales Client"
                                    className="w-100 ac-select-dropdown"
                                    searchableDropdownEnable={false}
                                    defaultValue={requestData.clientName}
                                    key={Math.random()}
                                    label={t('General.clientName')}
                                    data={clientsList}
                                    onChange={(e) => handleOnChange(e, "acdd", "clientName")}
                                    resetFilter={() => dispatch(setEmronMonthlyReportRequestData({...requestData, clientName: ""}))}
                                    onChangeSearch={onChangeSearch}
                                    excludeColumns={[]}
                                    columns={clientDropdownColumns}
                                    editEnable={false}
                                    deleteEnable={false}
                                    searchTableEnable={false}
                                    showTableOptionOnSelect={true}
                                    placeholder={t('SalesBalanceSheet.clientFrom.placeholder')}
                                    required={true}
                                    name="clientName"
                                    showInsertAddButton={true}
                                    hideSaveButton={true}
                                    hideInsertNewRow={true}
                                    inputFieldClasses="form-control"
                                />
                            </Col>
                            <Col md={2}>
                                <Dropdown
                                    name={"cardPayments"}
                                    label={"Φιλτράρισμα τρόπου πληρωμής"}
                                    options={cardFilterOptions}
                                    defaultValue={cardFilterOptions.find((el) => requestData.cardPayments === el.value)}
                                    key={Math.random()}
                                    onChange={(e) => handleOnChange(e, "dropdown", "cardPayments")}
                                />
                            </Col>
                            {requestData.cardPayments === "card" && (
                                <Col md={2}>
                                    <Dropdown
                                        name={"cardPaymentsStatus"}
                                        label={"Φιλτράρισμα κατάστασης πληρωμής"}
                                        options={cardFilterStatusOptions}
                                        defaultValue={cardFilterStatusOptions.find((el) => requestData.cardPaymentsStatus === el.value)}
                                        key={Math.random()}
                                        onChange={(e) => handleOnChange(e, "dropdown", "cardPaymentsStatus")}
                                    />
                                </Col>
                            )}
                            <Col md={3}>
                                <BetterCheckBox
                                    name={"groupInstallations"}
                                    className={"mt-4"}
                                    text={"Ομαδοποίηση κατά εγκατάσταση"}
                                    disabled={requestData.groupClients === "active"}
                                    checked={requestData.groupInstallations === "active"}
                                    onChange={(e) => handleOnChange(e, "checkbox", "groupInstallations")}
                                />
                            </Col>
                            <Col md={2}>
                                <BetterCheckBox
                                    name={"groupClients"}
                                    className={"mt-4"}
                                    text={"Ομαδοποίηση κατά πελάτη"}
                                    checked={requestData.groupClients === "active"}
                                    onChange={(e) => handleOnChange(e, "checkbox", "groupClients")}
                                />
                            </Col>
                            <Col md={3}>
                                <Popover placement={"bottom"} content={
                                    <>
                                        Ο έλεγχος μηδενικού μηνός γίνεται για τον παρόν μήνα.<br/>
                                        Σε περίπτωση που ο τωρινός μήνας έχει μηδενική χρέωση<br/>
                                        και το παρόν πεδίο δεν είναι επιλεγμένο, τότε δεν<br/>
                                        συμπεριλαμβάνονται ούτε οι προηγούμενοι μήνες<br/>
                                        γραμμή της μηνιαίας χρέωσης.
                                    </>
                                } title={<div style={{textAlign: "center"}}>Πληροφορίες</div>}>
                                    <React.Fragment>
                                        <BetterCheckBox
                                            name={"showZero"}
                                            className={"mt-4"}
                                            text={"Συμπερίληψη μηδενικών χρεώσεων"}
                                            checked={requestData.showZero === "active"}
                                            onChange={(e) => handleOnChange(e, "checkbox", "showZero")}
                                        />
                                    </React.Fragment>
                                </Popover>

                            </Col>
                        </Row>
                        {checkPermission("clients-reports/emronpayments-read", permissionsData) &&
                            <Row>
                                <Col md={12} className="ml-auto">
                                    <Button className="btn btn-primary float-right" onClick={() => fetchReport(false)}>
                                        {t('SalesBalanceSheet.applyFilter')}
                                    </Button>
                                </Col>
                            </Row>
                        }
                        <hr style={{color: "gray", height: 1}}/>
                    </div>
                    <div>
                        <React.Fragment>
                            <Row>
                                <h3 style={{textAlign: "center", alignItems: "center", fontWeight: "bold"}}>Μηνιαίες Χρεώσεις</h3>
                            </Row>
                            {responseData?.totalPages > 0 && (
                                <React.Fragment>
                                    <div className="d-flex justify-content-end mb-2">
                                        <Button
                                            size={"sm"}
                                            className={"mr-1 paginate" + (requestData.page !== 1 ? "yes" : "no")}
                                            onClick={() => toFirstPage()}
                                            disabled={requestData.page === 1}
                                        >
                                            {t('SalesBalanceSheet.first')}
                                        </Button>
                                        <Button
                                            size={"sm"}
                                            className={"mr-1 paginate" + (requestData.page !== 1 ? "yes" : "no")}
                                            onClick={() => toPreviousPage()}
                                            disabled={requestData.page === 1}
                                        >
                                            {t('SalesBalanceSheet.previous')}
                                        </Button>
                                        <Badge style={{fontSize: "12px", lineHeight: "20px"}} className="mr-1"
                                               bg="primary">{t('SalesBalanceSheet.page')} {requestData.page} / {responseData.totalPages}</Badge>
                                        <Button
                                            size={"sm"}
                                            className={"mr-1 paginate" + (requestData.page !== responseData.totalPages ? "yes" : "no")}
                                            onClick={() => toNextPage()}
                                            disabled={requestData.page === responseData.totalPages}
                                        >
                                            {t('SalesBalanceSheet.next')}
                                        </Button>
                                        <Button
                                            size={"sm"}
                                            className={"mr-1 paginate" + (requestData.page !== responseData.totalPages ? "yes" : "no")}
                                            onClick={() => toLastPage()}
                                            disabled={requestData.page === responseData.totalPages}
                                        >
                                            {t('SalesBalanceSheet.last')}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                            <EditableTable
                                key={Math.random()}
                                id="EmronPaymentsReport"
                                tableName="Emron Payments Report"
                                data={responseData.data}
                                allowPagination={false}
                                allowToggleColumns={true}
                                allowInsertRow={false}
                                enableNewRowCheckbox={false}
                                emronPaymentsMonth={responseData.months[2]}
                                //toggleColumns={handleColumnShowHide}
                                allowBulkActions={true}
                                pageTotals={false}
                                editRedirectAllowed={true}
                                reportExcelPrint={true}
                                company={company}
                                columns={tableColumns}
                                filters={requestData}
                                allowActions={true}
                                actionButtons={{
                                    print: false,
                                    delete: false,
                                    export: false,
                                    edit: false,
                                }}
                                allowPrintResults={checkPermission("clients-reports/emronpayments-emronpayments-print", permissionsData) === true}
                            />
                            {responseData.totals && Object.keys(responseData.totals).length > 0 && (
                                <Row className="justify-content-end">
                                    <Col sm="12" md="4" className="d-flex justify-content-end">
                                        <table className="table table-bordered mb-3" style={{width: '70%'}}>
                                            <thead>
                                            <tr>
                                                <th key="total">Σύνολα</th>
                                                {tableColumns.filter((el) => el.isForMonthYear === true).map((header) =>
                                                    <th key={"th" + header.field}>{header.name}</th>)}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr key={"netValues"}>
                                                <td>Καθαρή Αξία</td>
                                                {Object.values(responseData.totals).map((amount) => (
                                                    <td>{getGreekCurrencyFormat(Number(amount))}</td>
                                                ))}
                                            </tr>
                                            <tr key={"vatValues"}>
                                                <td>Αξία Φ.Π.Α.</td>
                                                {Object.values(responseData.totals).map((amount) => (
                                                    <td>{getGreekCurrencyFormat(Number(amount * 0.24))}</td>
                                                ))}
                                            </tr>
                                            <tr key={"totalValues"}>
                                                <td>Τελική Αξία</td>
                                                {Object.values(responseData.totals).map((amount) => (
                                                    <td>{getGreekCurrencyFormat(Number(amount * 1.24))}</td>
                                                ))}
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            )}
                        </React.Fragment>
                    </div>
                </div>
            }
        </div>
    )
}

export default EmronPayments;
