import React, {useState, useEffect, useMemo} from "react";
import {Row, Col, Button, Modal} from "react-bootstrap";
import Dropdown from "../../../primer/Dropdown";
import Input from "../../../common/Input";
import DateBox from "../../../primer/DateBox";
import {
    fetchAdvancedTypesBuys,
    fetchBuysGeneralSettings, fetchCashRegisters,
    fetchSupplierPaymentData, postSupplier, sendInvoiceToMyDataBuy,
} from "../../../../_apis/api";
import EditableTable from "../../../primer/Editable-Table";
import {useSelector, useDispatch} from "react-redux";
import {
    getDefaultOptionByValue,
    getOptionsByData,
    getVatCategoryOptions,
    buysMyDataStatus,
    getCurrentUser,
    getDOYOptions,
    getInstallationsTypes,
    isAlreadyExists,
    getGreekCurrencyFormat,
    getEmployeeCategoryOptions,
    getEmployeeTypeOptions,
    isEmpty,
    checkPermissionMasterParentStartingTree,
} from "../../../../_helpers/commonFunctions";
import {setSaveEditBuys} from "../../../../_reducers/activitySlice";
import {
    deleteObjectFromArrayOfObjects,
    getLabelByValue,
    getObjectFromArrayOfObjects
} from "../../../../_helpers/helperFunctions";
import {
    setEditBuysNewProduct,
    setEditBuys, setEditBuySupplierCustomData,
} from "../../../../_reducers/DataPanel/Buys/buysSlice";
import TextArea from "../../../common/TextArea";
import {toast} from "react-toastify";
import getMyData from "../../../../_helpers/sharedmydata";
import axios from "axios";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import {useTranslation} from "react-i18next";
import {
    getDiscountExclusiveVat, getNetValue, getNetValueByTotalValue,
    getPriceExclusiveVat, getProductFee, getProductOtherTax, getProductStamp, getProductWithholdTax,
    getBuysTaxesData,
    getBuysTotals, getTaxes, getTotalValue, getUnitPrice,
    getVatPercent, getVatValue,
    parseNumber, buysDuplicateProductCheck
} from "../../../../_helpers/product-buys-calculations";
import {vatCategoryData} from "../../../../_data/mydata/vatCategory";
import NewSupplierModal from "../Suppliers/new-supplier-modal";
import {AccountingView} from "../../../primer/AccountingView/AccountingView";
import LogTable from "../../../primer/LogTable";
import moment from "moment";
import HourPicker from "../../../primer/HourPicker";
import CloudHooksModal from "../Cloud/Modal Components/CloudHooksModal";
import Alert from "react-bootstrap/Alert";
import AnamorfosisModal from "../../../primer/POPUP-COMPONENTS/AnamorfosisModal/anamorfosisModal";

const apiURL2 = process.env.REACT_APP_API_URL2;

const EditBuys = () => {
    const [t] = useTranslation('common');
    let date = new Date();
    const currentTime = date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0');
    const dispatch = useDispatch();
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const PRODUCTS_DATA = useSelector((state) => state.PRODUCTS_DATA);
    const BUYS = useSelector((state) => state.BUYS);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const SUPPLIERS_DATA = useSelector((state) => state.SUPPLIERS_DATA);
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const BUYS_SETTINGS = useSelector((state) => state.BUYS_SETTINGS);
    const TABS_DATA = useSelector((state) => state.TABS_REDUCER);
    const CASH_DATA = useSelector((state) => state.CASH_DATA);

    const editBuySupplierCustomData = useSelector((state) => state.BUYS.editBuySupplierCustomData);
    const supplierVatCategory = editBuySupplierCustomData.supplierVatCategory;
    const supplierVatReason = editBuySupplierCustomData.supplierVatReason;
    const supplierCustomVat = editBuySupplierCustomData.supplierCustomVat;

    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [showAnamorfosis, setShowAnamorfosis] = useState(false);

    const requestData = {
        company: company.id
    };
    const requestData1 = {
        year: company.year.toString(),
        company: company.id
    };
    const currentUser = getCurrentUser();
    const requestData2 = {
        company: company.id,
        year: company.year.toString(),
        isActive: "active",
        userId: currentUser.id
    }
    const productCodeColumns = [
        {
            label: t("General.code"),
            name: "code",
        },
        {
            label: t("General.alternateCode"),
            name: "alternateCode",
        },
        {
            label: t("General.productName"),
            name: "name",
        },
        {
            label: t("General.alternateName"),
            name: "alternateName",
        },
    ];

    const supplierDropdownColumns = [
        {
            label: t("General.code"),
            name: "systemCode",
        },
        {
            label: t("General.supplierName"),
            name: "name",
        },
        {
            label: t("General.phone"),
            name: "phone",
        },
        {
            label: t("General.email"),
            name: "email",
        },
        {
            label: t("General.vatNumber"),
            name: "vatNumber",
        },
    ];

    const employeeDropdownColumns = [
        {
            label: "Όνομα",
            name: "firstName",
        },
        {
            label: "Επώνυμο",
            name: "lastName",
        },
        {
            label: "Α.Φ.Μ.",
            name: "vatNumber",
        },
        {
            label: "Τηλέφωνο",
            name: "phone",
        }
    ];
    const supplierInstallationsDropdownColumns = [
        {
            label: t("General.type"),
            name: "type",
        },
        {
            label: t("General.address"),
            name: "address",
        }
    ];
    const [refreshPage, setRefreshPage] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [showCateogryConfirmation, setShowCateogryConfirmation] = useState(false);
    const [tempCatValue, setTempCatValue] = useState({});
    const [showProductTableSpinner, setProductTableSpinner] = useState('false');
    const [buysProductList, setBuysProductList] = useState(PRODUCTS_DATA.products.slice());
    const [suppliersListData, setSuppliersListData] = useState(SUPPLIERS_DATA.suppliers.slice());
    const [employeeListData, setEmployeeListData] = useState([]);
    const [supplierInstallationListData, setSupplierInstallationListData] = useState(SUPPLIERS_DATA.installations.slice());
    const [pRequestData, setPRequestData] = useState({});
    const [updateBuyAmounts, setUpdateBuyAmounts] = useState(false);
    const installations = useSelector((state) => state.COMPANY_DATA.companyInstallations);
    const installationOptions = getOptionsByData(installations, "installationMasterId", "address");
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            dispatch(fetchAdvancedTypesBuys(requestData));
            dispatch(fetchBuysGeneralSettings(requestData2));
            dispatch(fetchSupplierPaymentData(requestData1));
            dispatch(fetchCashRegisters(requestData2));

            if (Array.isArray(BUYS.editBuys.extraData)) {
                dispatch(setEditBuys({...BUYS.editBuys, extraData: BUYS.editBuys.extraData[0]}))
            }
        }
    }, []);

    useEffect(() => {
        // Load Product based on selected advanced type if my data rule applies
        if (BUYS.editBuys.documentType !== "") {
            loadSuppliersListData(BUYS.editBuys.documentType);
            loadProductListData(BUYS.editBuys.documentType);
            loadEmployeeListData(BUYS.editBuys.documentType);
        }
    }, [])

    // Options for Document Type Dropdown
    let pMDDSettings = SETTINGS.primerMyDataData;
    const documentTypesOptions = useMemo(() => getOptionsByData(SETTINGS.settings.advancedTypesBuys, "_id", "name"), [SETTINGS.settings.advancedTypesBuys]);
    const waysOfSendingGoodsOptions = useMemo(() => getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.sendingGoods, "_id", "name"), [BUYS_SETTINGS.buysGeneralSettings.sendingGoods]);
    const carNumbersOptions = useMemo(() => getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.carNumbers, "_id", "number"), [BUYS_SETTINGS.buysGeneralSettings.carNumbers]);
    const paymentTypeOptions = useMemo(() => getOptionsByData(BUYS_SETTINGS.buysGeneralSettings.paymentTypes, "_id", "name"), [BUYS_SETTINGS.buysGeneralSettings.paymentTypes]);
    let otherTaxesOptions = useMemo(() => getOptionsByData(pMDDSettings?.otherTaxes !== undefined && pMDDSettings?.otherTaxes !== '' ? pMDDSettings.otherTaxes.filter(otherTax => otherTax.taxPer === "true" && otherTax.taxLevel === 1) : SETTINGS.otherHoldTaxes, 'code', 'name'), [pMDDSettings, SETTINGS.otherHoldTaxes]);
    let withHoldTaxesOptions = useMemo(() => getOptionsByData(pMDDSettings?.withHoldTaxes !== undefined && pMDDSettings?.withHoldTaxes !== '' ? pMDDSettings.withHoldTaxes.filter(withHoldTax => withHoldTax.taxPer === "true" && withHoldTax.taxLevel === 1) : SETTINGS.withHoldTaxes, 'code', 'name'), [pMDDSettings, SETTINGS.withHoldTaxes]);
    let stampOptions = useMemo(() => getOptionsByData(pMDDSettings?.stamp !== undefined && pMDDSettings?.stamp !== '' ? pMDDSettings.stamp.filter(stamp => stamp.taxPer === "true" && stamp.taxLevel === 1) : SETTINGS.stamp, 'code', 'name'), [pMDDSettings, SETTINGS.stamp]);
    let feesOptions = useMemo(() => getOptionsByData(pMDDSettings?.fees !== undefined && pMDDSettings?.fees !== '' ? pMDDSettings.fees.filter(fee => fee.taxPer === "true" && fee.taxLevel === 1) : SETTINGS.fees, 'code', 'name'), [pMDDSettings, SETTINGS.fees]);
    otherTaxesOptions = [{value: "", label: t("General.empty"), color: "#76AEB7"}, ...otherTaxesOptions];
    withHoldTaxesOptions = [{value: "", label: t("General.empty"), color: "#76AEB7"}, ...withHoldTaxesOptions];
    stampOptions = [{value: "", label: t("General.empty"), color: "#76AEB7"}, ...stampOptions];
    feesOptions = [{value: "", label: t("General.empty"), color: "#76AEB7"}, ...feesOptions];
    const measurementUnitOptions = getMyData({type: 'mu', settings: SETTINGS});
    const buysMyDataStatusOptions = buysMyDataStatus();
    const [requiredPaymentType, setRequiredPaymentType] = useState(false);

    const disabledFields = isEmpty(BUYS.editBuys.documentType);
    const disableEditFields = BUYS.editBuys.myDataStatus === "sent" || !isEmpty(BUYS.editBuys.mark);

    const supplierProductsColumns = [
        {
            name: t("General.no"),
            field: "no",
            width: "40px",
            editable: false,
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.productCode"),
            field: "code",
            width: "130px",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: productCodeColumns,
                    data: buysProductList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t("Placeholders.selectProductCode")
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t("General.productName"),
            field: "name",
            width: "400px",
            editable: true,
            inputType: {
                config: {
                    excludeColumns: [],
                    columns: productCodeColumns,
                    data: buysProductList,
                    editEnable: false,
                    deleteEnable: false,
                    searchTableEnable: false,
                    showTableOptionOnSelect: true,
                    placeholder: t("Placeholders.selectProductName")
                },
                type: 'advanced-combobox',
            }
        },
        {
            name: t("General.quantity"),
            field: "quantity",
            width: "100px",
            editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
            inputType: {
                type: 'accounting',
                config: {
                    maxLength: 6
                }
            }
        },
        {
            name: t("General.measurementUnit"),
            field: "muLabel",
            editable: false,
            width: "60px",
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.unitPrice"),
            field: "unitPrice",
            editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: t("General.discount"),
            field: "discount",
            editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: "%",
            field: "isPerDiscount",
            editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
            inputType: {
                type: 'radio',
                name: 'discountType',
                radioLogic: 'oneperrow',
                options: [{
                    value: true,
                    label: 'True'
                }, {
                    value: false,
                    label: 'False'
                }]
            }
        },
        {
            name: "€",
            field: "isDiscountAmount",
            editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
            inputType: {
                type: 'radio',
                radioLogic: 'oneperrow',
                name: 'discountType',
                options: [{
                    value: true,
                    label: 'True'
                }, {
                    value: false,
                    label: 'False'
                }]
            }
        },
        {
            name: t("General.vatPercentage"),
            field: "vatPercentage",
            inputType: {
                type: 'text',
            }
        },
        {
            name: t("General.netValue"),
            field: "netValue",
            editable: BUYS_SETTINGS.buysGeneralSettings?.forbitChange !== "active",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: t("General.vatValue"),
            field: "vat",
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: t("General.taxes"),
            field: "taxes",
            editable: false,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
        {
            name: t("General.totalValue"),
            field: "totalValue",
            editable: true,
            inputType: {
                type: 'accounting',
                config: {
                    focused: true,
                    suffix: "€",
                    maxLength: 7
                }
            }
        },
    ]

    const handleConfirmCategory = async () => {
        // Populate Selected Category Data
        let newBuysData = {};
        let value = tempCatValue.new;
        newBuysData.documentType = value;
        newBuysData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
        const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesBuys, value, "_id");
        if (selectedDocumentTypeData.length > 0) {
            let selectedDTData = selectedDocumentTypeData['0'];
            newBuysData.myDataType = selectedDTData.myDataType?.toString();
            if (newBuysData?.myDataType === "17.1") {
                newBuysData.supplierType = "Employee";
            } else {
                newBuysData.supplierType = "Existing";
            }
            // This applies only if selected check box is tick in the table.
            newBuysData.installation = selectedDTData?.proposedInstallation;
            loadProductListData(value);
            if (newBuysData?.myDataType === "17.1") {
                loadEmployeeListData(value);
            } else {
                loadSuppliersListData(value);
            }
            // Load Product based on selected advanced type if my data rule applies
            // Clear Product List on change
            newBuysData.products = [];
            //Clear Supplier
            newBuysData.supplier = "";
            newBuysData.supplierName = "";
            newBuysData.supplierCode = "";
            newBuysData.address = "";
            newBuysData.vat = "";
            newBuysData.doy = "";
            newBuysData.city = "";
            newBuysData.country = "";
            newBuysData.pc = "";
            newBuysData.email = "";
            newBuysData.phone = "";
            newBuysData.activity = "";
            newBuysData.vatCategory = "";
            setSupplierInstallationListData(SUPPLIERS_DATA.installations.slice());
            newBuysData.clientInstallationType = "";
            newBuysData.clientInstallationId = "";
            // Set Client Vat Category used for product vat calculation
            dispatch(setEditBuySupplierCustomData({
                supplierVatCategory: "Normal VAT",
                supplierVatReason: "",
                supplierCustomVat: 0,
            }))
        }
        dispatch(setEditBuys({...BUYS.editBuys, ...newBuysData}));
        setShowCateogryConfirmation(false);
    }

    const loadSuppliersListDataOnChange = (value, setData) => {
        let data = {
            id: BUYS.editBuys.documentType,
            name: value,
            company: company.id
        }
        axios.get(process.env.REACT_APP_API_URL2 + `/supplier/supplier-list-by-advanced-type-id/${data.id}`, {params: data})
            .then(res => {
                if (res.data && res.data.status === '200') {
                    if (res.data.data) {
                        // Remove none empty supplier name data
                        let filteredData = deleteObjectFromArrayOfObjects(res.data.data, '', 'name');
                        setData(filteredData);
                    } else {
                        setData([]);
                    }
                } else {
                    setData([]);
                }
            }).catch((err) => {
            console.log(err);
        })
    }
    const onChangeSearch = (keyword, setData) => {
        if (keyword.length >= 2) {
            loadSuppliersListDataOnChange(keyword, setData)
        }
    }

    const loadSuppliersListData = (value) => {
        const data = {
            id: value,
            company: company.id,
            name: ''
        }
        axios.get(process.env.REACT_APP_API_URL2 + `/supplier/supplier-list-by-advanced-type-id/${value}`, {params: data})
            .then(res => {
                if (res.data && res.data.status === '200') {
                    if (res.data.data) {
                        // Remove none empty supplier name data
                        let filteredData = deleteObjectFromArrayOfObjects(res.data.data, '', 'name');
                        setSuppliersListData([...filteredData]);
                    } else {
                        setSuppliersListData([]);
                    }
                } else {
                    setSuppliersListData([]);
                }
            }).catch((err) => {
            console.log(err);
        })
    }

    const loadEmployeeListData = (value) => {
        const data = {
            id: value,
            company: company.id,
            name: ''
        }
        axios.get(process.env.REACT_APP_API_URL2 + `/supplier/supplier-list-by-advanced-type-id/${value}`, {params: data})
            .then(res => {
                if (res.data && res.data.status === '200') {
                    if (res.data.data) {
                        // Remove none empty supplier name data
                        let filteredData = deleteObjectFromArrayOfObjects(res.data.data, '', 'name');
                        setEmployeeListData([...filteredData]);
                    } else {
                        setEmployeeListData([]);
                    }
                } else {
                    setEmployeeListData([]);
                }
            }).catch((err) => {
            console.log(err);
        })
    }

    const loadProductListData = (value) => {
        let data = {
            id: value,
            name: '',
            company: company.id
        }
        setPRequestData({...data})
        axios.get(process.env.REACT_APP_API_URL2 + `/buy/product-list-by-advanced-type-id/${data.id}`, {params: data})
            .then(res => {
                if (res.data && res.data.status === '200') {
                    if (res.data.data) {
                        setBuysProductList(res.data.data);
                    } else {
                        setBuysProductList([]);
                    }
                } else {
                    setBuysProductList([]);
                }
            }).catch((err) => {
            console.log(err);
        })
    }

    const handleOnChange = async (e, type = "default", ddname = '') => {
        // Set if editing the form
        if (!ACTIVITY.saveEditBuys) {
            dispatch(setSaveEditBuys(true));
        }
        let name;
        let value = '';
        if (type === 'dd' || type === 'ac') {
            value = e.value;
            name = ddname;
        } else if (type === 'dp') {
            value = e;
            name = ddname;
        } else if (type === 'acdd') {
            e = e.row;
            value = e._id;
            name = ddname;
        } else if (type === 'tp') {
            value = e;
            name = ddname;
        } else {
            value = e.target.value;
            name = e.target.name;
        }

        let buysData = Object.assign({}, BUYS);
        let newBuysData = {};
        if (name === "time") {
            if (value !== currentTime && value !== null) {
                newBuysData.customTime = "true";
            }
        }
        if (name === "date") {
            if (value !== date && value !== null) {
                newBuysData.customTime = "true";
            }
        }
        if (name === 'documentType' && buysData.editBuys.documentType !== value) {
            if (buysData.editBuys.documentType !== "") {
                // Show confirmation before category change.
                setShowCateogryConfirmation(true);
                setTempCatValue({e: e, new: value, old: buysData.editBuys.documentType});
            } else {
                // Populate Selected Category Data
                newBuysData[name] = value;
                newBuysData.documentTypeName = getLabelByValue(documentTypesOptions, value, 'value');
                const selectedDocumentTypeData = getObjectFromArrayOfObjects(SETTINGS.settings.advancedTypesBuys, value, "_id");
                if (selectedDocumentTypeData.length > 0) {
                    let selectedDTData = selectedDocumentTypeData['0'];
                    newBuysData.myDataType = selectedDTData.myDataType?.toString();
                    newBuysData.installation = selectedDTData?.proposedInstallation;
                    if (newBuysData?.myDataType === "17.1") {
                        newBuysData.supplierType = "Employee";
                    } else {
                        newBuysData.supplierType = "Existing";
                    }
                    // Load Product based on selected advanced type if my data rule applies
                    if (selectedDTData.enableMyDataRules && selectedDTData.enableMyDataRules === 'active') {
                        loadProductListData(value);
                        loadSuppliersListData(value);
                        setRequiredPaymentType(true);
                    }

                }
            }
        } else {
            newBuysData[name] = value;
        }
        if (name === 'supplier') {
            let selectedSupplier = getObjectFromArrayOfObjects(suppliersListData, value, '_id');
            await axios.get(apiURL2 + `/supplier/get-supplier-id-by/${value}`)
                .then((res) => {
                    if (res.data && res.data.status === "200") {
                        selectedSupplier['0'] = res.data.data;
                    }
                }).catch((err) => {
                    console.log(err);
                })
            if (selectedSupplier.length > 0) {
                let sSupplier = selectedSupplier['0'];
                newBuysData.supplierName = sSupplier.name;
                newBuysData.supplierCode = sSupplier.systemCode; // It is the supplier code
                newBuysData.address = sSupplier.address;
                newBuysData.vat = sSupplier.vatNumber;
                newBuysData.doy = getLabelByValue(getDOYOptions(), Number(sSupplier.DOY), 'value');
                newBuysData.city = sSupplier.city;
                newBuysData.country = sSupplier.country;
                newBuysData.pc = sSupplier.postalCode;
                newBuysData.email = sSupplier.email;
                newBuysData.phone = sSupplier.phone;
                newBuysData.activity = sSupplier.activity;
                newBuysData.vatCategory = sSupplier.vatCategory;
                sSupplier.installations.map(installation => {
                    installation.type = getLabelByValue(getInstallationsTypes(), installation.type, 'value');
                    return installation;
                })
                setSupplierInstallationListData(sSupplier.installations);
                newBuysData.supplierInstallationType = sSupplier.installations[0]?.type;
                newBuysData.supplierInstallationId = sSupplier.installations[0]?.no;
                // Set Supplier Vat Category used for product vat calculation
                dispatch(setEditBuySupplierCustomData({
                    supplierVatCategory: sSupplier?.vatCategory ? sSupplier.vatCategory : "Normal VAT",
                    supplierVatReason: sSupplier?.vatReason ? sSupplier.vatReason : "",
                    supplierCustomVat: sSupplier?.customVat ? sSupplier.customVat : 0,
                }))
            }
        }
        if (name === "employee") {
            let selectedEmployee = getObjectFromArrayOfObjects(employeeListData, value, '_id');
            await axios.get(apiURL2 + `/supplier/get-supplier-id-by/${value}`)
                .then((res) => {
                    if (res.data && res.data.status === "200") {
                        selectedEmployee['0'] = res.data.data;
                    }
                }).catch((err) => {
                    console.log(err);
                })
            if (selectedEmployee.length > 0) {
                let sEmployee = selectedEmployee['0'];
                newBuysData.employeeName = sEmployee.firstName + " " + sEmployee.lastName;
                newBuysData.vat = sEmployee.vatNumber;
                newBuysData.employeeType = sEmployee.employeeType;
                newBuysData.employeeCategory = sEmployee.employeeCategory;
                newBuysData.phone = sEmployee.phone;
            }
        }
        if (name === 'supplierInstallation') {
            let selectedSupplierInstallation = "";
            supplierInstallationListData.map(installation => {
                if (value === installation._id) {
                    selectedSupplierInstallation = installation;
                }
                return installation;
            })
            if (selectedSupplierInstallation !== "") {
                newBuysData.supplierInstallationType = selectedSupplierInstallation.type;
                newBuysData.supplierInstallationId = selectedSupplierInstallation.no;
                newBuysData.address = selectedSupplierInstallation.address;
                newBuysData.doy = getLabelByValue(getDOYOptions(), Number(selectedSupplierInstallation.DOY), 'value');
                newBuysData.city = selectedSupplierInstallation.city;
                newBuysData.pc = selectedSupplierInstallation.postalCode;
            }
        }
        if (name === 'paymentType') {
            newBuysData.paymentTypeName = getLabelByValue(paymentTypeOptions, value, 'value');
            // This will update the pre-selected POS / Bank
            let paymentTypesData = getObjectFromArrayOfObjects(BUYS_SETTINGS.buysGeneralSettings.paymentTypes, value, '_id');
            if (paymentTypesData && paymentTypesData.length > 0) {
                let selectedPaymentType = paymentTypesData['0'];
                newBuysData.myDataPaymentId = selectedPaymentType?.myDataPaymentId;
                if (selectedPaymentType?.type === "pos") {
                    newBuysData.bankAccount = selectedPaymentType.options?.preSelectedPosBankAccount;
                }
                if (selectedPaymentType?.type === "bank-account") {
                    newBuysData.bankAccount = selectedPaymentType.options?.preSelectedPosBankAccount;
                }
                if (selectedPaymentType?.type === "cash") {
                    newBuysData.bankAccount = "";
                }
            }
        }
        if (name === "date") {
            newBuysData['date'] = new Date(value);
        }
        dispatch(setEditBuys({...buysData.editBuys, ...newBuysData}));
    }

    const handleOnChangeExtraData = (e, type = "default", ddname = '') => {
        // Set if editing the form
        if (!ACTIVITY.saveEditBuys) {
            dispatch(setSaveEditBuys(true));
        }
        let name;
        let value;

        if (type === 'dd' || type === 'ac') {
            value = e.value;
            name = ddname;
        } else if (type === 'dp') {
            value = e;
            name = ddname;
        } else if (type === 'tp') {
            value = e;
            name = ddname;
        } else {
            value = e.target.value;
            name = e.target.name;
        }

        let extraData = {...BUYS.editBuys.extraData};
        extraData[name] = value;

        if (name === 'wayOfSendingGoods') {
            extraData.wayOfSendingGoodsName = getLabelByValue(waysOfSendingGoodsOptions, value, 'value');
        }

        if (name === 'carNumber') {
            extraData.carNumberName = getLabelByValue(carNumbersOptions, value, 'value');
        }
        if (name === 'otherTax') {
            extraData.otherTax = value;
        }
        if (name === 'withHoldTax') {
            extraData.withHoldTax = value;
        }
        if (name === 'fee') {
            extraData.fee = value;
        }
        if (name === 'stamp') {
            extraData.stamp = value;
        }
        let productTotals = getBuysTotals(BUYS.editBuys.products, SETTINGS);
        let productBuysTaxes = getBuysTaxesData(BUYS.editBuys.products, extraData, productTotals, SETTINGS);
        productBuysTaxes.map((buysTax) => {
            if (buysTax.vatPercentage === "Invoice Level Tax") {
                extraData.witholdTaxEuro = buysTax.withholdTaxes;
                extraData.otherTaxEuro = buysTax.otherTaxes;
                extraData.feeEuro = buysTax.fees;
                extraData.stampEuro = buysTax.stamp;
            }
            return buysTax;
        })
        let totalTaxes = 0;
        let withHoldTaxes = 0;
        if (productBuysTaxes.length > 0 && productBuysTaxes[0].vatPercentage === "Invoice Level Tax") {
            totalTaxes = parseFloat((Number(productBuysTaxes[0].otherTaxes) + Number(productBuysTaxes[0].fees) + Number(productBuysTaxes[0].stamp)).toString());
            withHoldTaxes = parseFloat(productBuysTaxes[0].withholdTaxes).toFixed(2);
        }
        if (totalTaxes !== 0 || withHoldTaxes !== 0) {
            productTotals.paymentAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
            productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes)).toFixed(2);
        }
        dispatch(setEditBuys({...BUYS.editBuys, buysTaxes: productBuysTaxes, ...productTotals, extraData: extraData}));
        //dispatch(setEditBuysExtraData(extraData));
    }

    const quickSupplierFields = [
        {
            name: "Supplier Name",
            field: "name",
            editable: true,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Vat Category",
            field: "vatCategory",
            editable: true,
            inputType: {
                options: getVatCategoryOptions(),
                type: 'select',
            }
        },
        {
            name: "Vat Number",
            field: "vatNumber",
            editable: true,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Phone Number",
            field: "phone",
            editable: true,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Email",
            field: "email",
            editable: true,
            inputType: {
                type: "text",
            },
        },
        {
            name: "Address",
            field: "address",
            editable: true,
            inputType: {
                type: "text",
            },
        },
        {
            name: "City",
            field: "city",
            editable: true,
            inputType: {
                type: "text",
            },
        },
    ];

    const buysProductListActions = {
        insert: async (e, data) => {
            setProductTableSpinner('true');
            // Set if editing the form
            if (!ACTIVITY.saveEditBuys) dispatch(setSaveEditBuys(true));

            let productData = null;
            // Get Product By Code
            if (data && data?.field === 'code') {
                await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(data.value)}`, {params: company})
                    .then(res => {
                        if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                            productData = res.data.data['0'];
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
            }
            // Get Product By Name
            if (data && data?.field === 'name') {
                await axios.get(process.env.REACT_APP_API_URL2 + `/category/get-product-by-name/${encodeURIComponent(data.value)}`, {params: company})
                    .then(res => {
                        if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                            productData = res.data.data['0'];
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
            }
            if (productData !== null && Object.keys(productData).length > 0) {
                let productExists = isAlreadyExists(BUYS.editBuys.products, data.field, data.value);
                if (productExists) {
                    toast.error('Product already added in the table.');
                } else {
                    if (e.key !== "Enter") {
                        let tempNewProduct = Object.assign({}, BUYS.editBuysNewProduct);
                        tempNewProduct.name = productData?.name;
                        tempNewProduct.code = productData?.code;
                        tempNewProduct.quantity = 1;
                        tempNewProduct.mu = productData?.measurementUnit;
                        tempNewProduct.muLabel = getLabelByValue(measurementUnitOptions, productData?.measurementUnit.toString(), 'value');
                        tempNewProduct.discount = 0;
                        tempNewProduct.taxes = 0;
                        tempNewProduct.isPerDiscount = "false";
                        tempNewProduct.isDiscountAmount = "true";

                        if (String(productData?.vatIncludedBuy) === "true") {
                            const price = parseFloat(productData.buyPrice);
                            const vat = isNaN(productData.vat) ? 0 : parseFloat(productData.vat);
                            if (vat > 0) {
                                tempNewProduct.unitPrice = Number(Number(price / (1 + vat / 100)).toFixed(2));
                            } else {
                                tempNewProduct.unitPrice = parseNumber(productData.buyPrice);
                            }
                        } else {
                            tempNewProduct.unitPrice = !isEmpty(productData.buyPrice) ? parseNumber(productData.buyPrice) : 0;
                        }

                        tempNewProduct[data.field] = data.value; // Updates from table edit
                        if (data.field === 'isPerDiscount' && (data.value === "on" || data.value === "true")) {
                            tempNewProduct.isPerDiscount = "true";
                            tempNewProduct.isDiscountAmount = "false";
                        }
                        if (data.field === 'isDiscountAmount' && (data.value === "on" || data.value === "true")) {
                            tempNewProduct.isPerDiscount = "false";
                            tempNewProduct.isDiscountAmount = "true";
                        }
                        // Fix for loading calculation on insert
                        let discountType = 'euro';
                        if (tempNewProduct.isPerDiscount === 'true') {
                            discountType = 'per';
                        }
                        const myDataData = productData.mydata.filter(myData => String(myData.invoiceType) === BUYS.editBuys.myDataType?.toString() && myData.rowType === "expense");
                        if (productData?.isTaxLine === "true") {
                            tempNewProduct.vatPercentage = getVatPercent(supplierVatCategory, productData.vat, supplierCustomVat);
                            tempNewProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
                            tempNewProduct.vatReason = supplierVatReason !== "" ? supplierVatReason : productData?.vatReason;
                            tempNewProduct.unitPrice = getPriceExclusiveVat(tempNewProduct.unitPrice, tempNewProduct?.vatPercentage, tempNewProduct?.vatType);
                            tempNewProduct.discount = getDiscountExclusiveVat(tempNewProduct.discount, tempNewProduct?.vatPercentage, tempNewProduct?.vatType, discountType);
                            tempNewProduct.netValue = 0;
                            tempNewProduct.withHoldTax = productData.withholdTax;
                            tempNewProduct.otherTax = productData.otherTaxes;
                            tempNewProduct.stamp = productData.stamp;
                            tempNewProduct.fee = productData.fee;
                            tempNewProduct._id = productData._id;
                            tempNewProduct.isTaxLine = productData.isTaxLine;
                            if (myDataData[0]) {
                                tempNewProduct.classificationCategory = myDataData[0].category;
                                tempNewProduct.classificationType = myDataData[0].type;
                                tempNewProduct.vatExpenseType = myDataData[0].vatExpenseType;
                            } else {
                                tempNewProduct.classificationCategory = '';
                                tempNewProduct.classificationType = '';
                                tempNewProduct.vatExpenseType = '';
                            }
                            tempNewProduct.isTaxLineWithoutVat = productData.isTaxLineWithoutVat;
                            tempNewProduct.taxes = getNetValue(tempNewProduct.unitPrice, tempNewProduct.quantity, tempNewProduct.discount, discountType);
                            tempNewProduct.otherTaxEuro = getProductOtherTax(tempNewProduct, SETTINGS);
                            tempNewProduct.witholdTaxEuro = getProductWithholdTax(tempNewProduct, SETTINGS);
                            tempNewProduct.feeEuro = getProductFee(tempNewProduct, SETTINGS);
                            tempNewProduct.stampEuro = getProductStamp(tempNewProduct, SETTINGS);
                            tempNewProduct.vat = getVatValue(tempNewProduct.taxes, tempNewProduct.vatPercentage);
                            tempNewProduct.totalValue = getTotalValue(tempNewProduct.netValue, tempNewProduct.vatPercentage, tempNewProduct.taxes);
                        } else {
                            tempNewProduct.vatPercentage = getVatPercent(supplierVatCategory, productData.vat, supplierCustomVat);
                            tempNewProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
                            tempNewProduct.vatReason = supplierVatReason !== "" ? supplierVatReason : productData?.vatReason;
                            tempNewProduct.unitPrice = getPriceExclusiveVat(tempNewProduct.unitPrice, tempNewProduct?.vatPercentage, tempNewProduct?.vatType);
                            tempNewProduct.discount = getDiscountExclusiveVat(tempNewProduct.discount, tempNewProduct?.vatPercentage, tempNewProduct?.vatType, discountType);
                            tempNewProduct.netValue = getNetValue(tempNewProduct.unitPrice, tempNewProduct.quantity, tempNewProduct.discount, discountType);
                            tempNewProduct.withHoldTax = productData.withholdTax;
                            tempNewProduct.otherTax = productData.otherTaxes;
                            tempNewProduct.stamp = productData.stamp;
                            tempNewProduct.fee = productData.fee;
                            tempNewProduct.stamp = productData.stamp;
                            tempNewProduct._id = productData._id;
                            if (myDataData[0]) {
                                tempNewProduct.classificationCategory = myDataData[0].category;
                                tempNewProduct.classificationType = myDataData[0].type;
                                tempNewProduct.vatExpenseType = myDataData[0].vatExpenseType;
                            } else {
                                tempNewProduct.classificationCategory = '';
                                tempNewProduct.classificationType = '';
                                tempNewProduct.vatExpenseType = '';
                            }
                            tempNewProduct.taxes = getTaxes(tempNewProduct, SETTINGS);
                            tempNewProduct.otherTaxEuro = getProductOtherTax(tempNewProduct, SETTINGS);
                            tempNewProduct.witholdTaxEuro = getProductWithholdTax(tempNewProduct, SETTINGS);
                            tempNewProduct.feeEuro = getProductFee(tempNewProduct, SETTINGS);
                            tempNewProduct.stampEuro = getProductStamp(tempNewProduct, SETTINGS);
                            if (productData.vatIncludedBuy === "true") {
                                let price = isNaN(Number(productData.buyPrice)) ? 0 : productData.buyPrice;
                                tempNewProduct.vat = getVatValue(tempNewProduct.netValue, tempNewProduct.vatPercentage, price);
                                tempNewProduct.totalValue = getTotalValue(tempNewProduct.netValue, tempNewProduct.vatPercentage, tempNewProduct.taxes, tempNewProduct.vat);
                            } else {
                                tempNewProduct.vat = getVatValue(tempNewProduct.netValue, tempNewProduct.vatPercentage);
                                tempNewProduct.totalValue = getTotalValue(tempNewProduct.netValue, tempNewProduct.vatPercentage, tempNewProduct.taxes);
                            }
                        }
                        if (productData.accountingNonDeductibleVAT) tempNewProduct.accountingNonDeductibleVAT = productData.accountingNonDeductibleVAT;
                        // If validated fields done add new row
                        let oldBuysProducts = BUYS.editBuys.products;
                        let updatePriceListNewProduct = Object.assign({}, tempNewProduct);
                        updatePriceListNewProduct.name = tempNewProduct.name;
                        updatePriceListNewProduct.code = tempNewProduct.code;
                        updatePriceListNewProduct.no = oldBuysProducts.length + 1;
                        updatePriceListNewProduct.id = oldBuysProducts.length + 1;
                        updatePriceListNewProduct._id = tempNewProduct._id
                        dispatch(setEditBuysNewProduct(tempNewProduct)); // Update redux
                        let pPlroducts = [...oldBuysProducts, updatePriceListNewProduct];
                        let productTotals = getBuysTotals(pPlroducts, SETTINGS);
                        let productBuysTaxes = getBuysTaxesData(pPlroducts, BUYS.editBuys.extraData, productTotals, SETTINGS);
                        let extraData = {...BUYS.editBuys.extraData};
                        if (productBuysTaxes.length > 0) {
                            productBuysTaxes.map((buysTax) => {
                                if (buysTax.vatPercentage === "Invoice Level Tax") {
                                    let extraData = {};
                                    extraData.witholdTaxEuro = buysTax.withholdTaxes;
                                    extraData.otherTaxEuro = buysTax.otherTaxes;
                                    extraData.feeEuro = buysTax.fees;
                                    extraData.stampEuro = buysTax.stamp;
                                }
                                return buysTax;
                            })
                        }
                        let totalTaxes = 0;
                        let withHoldTaxes = 0;
                        if (productBuysTaxes.length > 0 && productBuysTaxes[0].vatPercentage === "Invoice Level Tax") {
                            totalTaxes = parseFloat((Number(productBuysTaxes[0].otherTaxes) + Number(productBuysTaxes[0].fees) + Number(productBuysTaxes[0].stamp)).toString());
                            withHoldTaxes = parseFloat(productBuysTaxes[0].withholdTaxes).toFixed(2);
                        }
                        if (totalTaxes !== 0 || withHoldTaxes !== 0) {
                            productTotals.paymentAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
                            productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes)).toFixed(2);
                        }
                        dispatch(setEditBuysNewProduct(BUYS.newBuysNewProductInitialValue)); // Reset new category mydata
                        dispatch(setEditBuys({
                            ...BUYS.editBuys,
                            products: pPlroducts,
                            buysTaxes: productBuysTaxes, ...productTotals,
                            extraData: extraData
                        }));
                    }
                }
            }
            setProductTableSpinner('false');
        },
        update: async (data, id) => {
            // Set if editing the form
            if (!ACTIVITY.saveEditBuys) {
                dispatch(setSaveEditBuys(true));
            }
            // Set Loading true
            setProductTableSpinner('true');
            let quickEdit = false;
            let foundProduct;
            let productData = null;
            let plProducts = BUYS.editBuys.products.slice();
            // Quick Edit Product from the buys product table
            if (data.quickEdit) {
                let qeProduct = await getObjectFromArrayOfObjects(plProducts, id, '_id');
                if (qeProduct.length > 0) {
                    data.code = qeProduct['0'].code;
                }
                foundProduct = plProducts.find(item => item._id === id);
            } else {
                foundProduct = plProducts.find(item => item.id === id);
            }
            if (foundProduct.quickEdit === true) quickEdit = true;
            if (data.isPerDiscount && (data.isPerDiscount === "on" || data.isPerDiscount === "true")) {
                data.isPerDiscount = "true";
                data.isDiscountAmount = "false";
            }
            if (data.isDiscountAmount && (data.isDiscountAmount === "on" || data.isDiscountAmount === "true")) {
                data.isPerDiscount = "false";
                data.isDiscountAmount = "true";
            }
            if (data.name && data.name === '') {
                toast.error("Name is mandatory field.");
            } else if (data.code && data.code === '') {
                toast.error("Code is mandatory field.");
            } else {
                if (foundProduct) {
                    let papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(foundProduct.code)}`;
                    if (data.code && data.code !== '') {
                        papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-code/${encodeURIComponent(data.code)}`;
                    } else if (data.name && data.name !== '') {
                        papiUrl = process.env.REACT_APP_API_URL2 + `/category/get-product-by-name/${encodeURIComponent(data.name)}`;
                    }
                    let loadingProduct = true;
                    if (loadingProduct) {
                        // Get product data from api.
                        await axios.get(papiUrl, {params: company})
                            .then(res => {
                                if (res.data && res.data.data && (res.data.data?.length > 0) && res.data.status === '200') {
                                    productData = res.data.data['0'];
                                }
                            }).catch((err) => {
                                console.log(err);
                            })
                    }
                    if (data.name || data.code) {
                        quickEdit = false;
                    }
                    // Field auto population on change product
                    if (((data.code && data.code !== '') || (data.name && data.name !== ''))) {
                        data.withHoldTax = productData.withholdTax;
                        data.otherTax = productData.otherTaxes;
                        data.stamp = productData.stamp;
                        data.fee = productData.fee;
                    }
                    // Updated selected product with updated field data.
                    foundProduct = {...foundProduct, ...data}
                    foundProduct._id = productData._id;
                    let discountType = 'euro';
                    if (foundProduct.isPerDiscount === 'true') {
                        discountType = 'per'
                    }
                    // Note: For inclusive vat the value is reducing by the vat percentage.
                    if (data.discount && data.discount !== '') { // on discount field change
                        foundProduct.discount = getDiscountExclusiveVat(foundProduct.discount, foundProduct?.vatPercentage, foundProduct?.vatType, discountType);
                    }
                    // Re Calculate Unit price if net value changed
                    if (data.netValue && data.netValue !== '' && foundProduct.isTaxLine !== "true") {
                        // If user enters value here, you calculate "Unit Price" automatically (based on previous axiom: "Net Value" has to equal: "Quantity" times "Unit Price" minus "Discount")
                        foundProduct.unitPrice = getUnitPrice(data.netValue, foundProduct.quantity, foundProduct.discount, discountType);
                    } else {
                        if (foundProduct.isTaxLine === "true") {
                            foundProduct.netValue = 0;
                        } else {
                            foundProduct.netValue = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
                        }
                    }
                    foundProduct.witholdTaxEuro = getProductWithholdTax(foundProduct, SETTINGS);
                    foundProduct.otherTaxEuro = getProductOtherTax(foundProduct, SETTINGS);
                    foundProduct.feeEuro = getProductFee(foundProduct, SETTINGS);
                    foundProduct.stampEuro = getProductStamp(foundProduct, SETTINGS);
                    if (foundProduct.isTaxLine === "true") {
                        foundProduct.taxes = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
                    } else {
                        foundProduct.taxes = getTaxes(foundProduct, SETTINGS);
                    }
                    // Re Calculate Unit price and net value if total value changed
                    let userTotalValInput = 0; // For recalculating vat +-

                    if (data.totalValue && data.totalValue !== '') {
                        if (foundProduct.isTaxLine === "true") {
                            foundProduct.taxes = getNetValueByTotalValue(data.totalValue, foundProduct.vatPercentage, foundProduct.taxes);
                            foundProduct.unitPrice = getUnitPrice(foundProduct.taxes, foundProduct.quantity, foundProduct.discount, discountType);
                            foundProduct.netValue = 0;
                        } else {
                            // If user enters value here, you calculate "Unit Price" and "Net Value" automatically (based on axioms: "Net Value" has to equal: "Quantity" times "Unit Price" minus "Discount" and "Total Value" equals: "VAT %" times "Net Value" plus "Net Value")
                            foundProduct.netValue = getNetValueByTotalValue(data.totalValue, foundProduct.vatPercentage, foundProduct.taxes);
                            foundProduct.unitPrice = getUnitPrice(foundProduct.netValue, foundProduct.quantity, foundProduct.discount, discountType);
                        }
                        userTotalValInput = data.totalValue;
                        //foundProduct.totalValue = getTotalValue(foundProduct.netValue, foundProduct.vatPercentage, foundProduct.taxes);
                    } else {
                        foundProduct.totalValue = getTotalValue(foundProduct.netValue, foundProduct.vatPercentage, foundProduct.taxes);
                    }
                    if (foundProduct.isTaxLine === "true") {
                        foundProduct.vat = getVatValue(foundProduct.taxes, foundProduct.vatPercentage);
                    } else {
                        foundProduct.vat = getVatValue(foundProduct.netValue, foundProduct.vatPercentage, userTotalValInput);
                    }
                    const updatedPmProducts = {...plProducts[foundProduct.no - 1], ...foundProduct}
                    let filterFields = {
                        code: data.code ? data.code : plProducts.code,
                        name: data.name ? data.name : plProducts.name,
                    }
                    const myDataData = productData.mydata.filter(myData => String(myData.invoiceType) === BUYS.editBuys.myDataType?.toString() && myData.rowType === "expense");
                    if (productData?.isTaxLine === "true") {
                        if (!quickEdit) foundProduct.vatPercentage = getVatPercent(supplierVatCategory, productData.vat, supplierCustomVat);
                        if (!quickEdit) foundProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
                        if (!quickEdit) foundProduct.vatReason = supplierVatReason !== "" ? supplierVatReason : productData?.vatReason;
                        foundProduct.discount = getDiscountExclusiveVat(foundProduct.discount, foundProduct?.vatPercentage, foundProduct?.vatType, discountType);
                        foundProduct.netValue = 0;
                        foundProduct.withHoldTax = productData.withholdTax;
                        foundProduct.otherTax = productData.otherTaxes;
                        foundProduct.stamp = productData.stamp;
                        foundProduct.fee = productData.fee;
                        foundProduct.stamp = productData.stamp;
                        foundProduct._id = productData._id;
                        foundProduct.isTaxLine = productData.isTaxLine;
                        if (myDataData.length > 0) {
                            if (!quickEdit) foundProduct.classificationCategory = myDataData[0].category;
                            if (!quickEdit) foundProduct.classificationType = myDataData[0].type;
                        } else {
                            foundProduct.classificationCategory = '';
                            foundProduct.classificationType = '';
                        }

                        foundProduct.isTaxLineWithoutVat = productData.isTaxLineWithoutVat;
                        foundProduct.taxes = getNetValue(foundProduct.unitPrice, foundProduct.quantity, foundProduct.discount, discountType);
                        foundProduct.otherTaxEuro = getProductOtherTax(foundProduct, SETTINGS);
                        foundProduct.witholdTaxEuro = getProductWithholdTax(foundProduct, SETTINGS);
                        foundProduct.feeEuro = getProductFee(foundProduct, SETTINGS);
                        foundProduct.stampEuro = getProductStamp(foundProduct, SETTINGS);
                        foundProduct.vat = getVatValue(foundProduct.taxes, foundProduct.vatPercentage);
                        foundProduct.totalValue = getTotalValue(foundProduct.netValue, foundProduct.vatPercentage, foundProduct.taxes);
                    } else {
                        if (!quickEdit) foundProduct.vatPercentage = getVatPercent(supplierVatCategory, productData.vat, supplierCustomVat);
                        if (!quickEdit) foundProduct.vatId = getDefaultOptionByValue(vatCategoryData, productData?.vat, 'percentage', 'code', 'percentage').label;
                        if (!quickEdit) foundProduct.vatReason = supplierVatReason !== "" ? supplierVatReason : productData?.vatReason;
                        foundProduct.discount = getDiscountExclusiveVat(foundProduct.discount, foundProduct?.vatPercentage, foundProduct?.vatType, discountType);
                        foundProduct.withHoldTax = productData.withholdTax;
                        foundProduct.otherTax = productData.otherTaxes;
                        foundProduct.stamp = productData.stamp;
                        foundProduct.fee = productData.fee;
                        foundProduct.stamp = productData.stamp;
                        foundProduct._id = productData._id;
                        if (myDataData.length > 0) {
                            if (!quickEdit) foundProduct.classificationCategory = myDataData[0].category;
                            if (!quickEdit) foundProduct.classificationType = myDataData[0].type;
                        } else {
                            foundProduct.classificationCategory = '';
                            foundProduct.classificationType = '';
                        }
                        foundProduct.taxes = getTaxes(foundProduct, SETTINGS);
                        foundProduct.otherTaxEuro = getProductOtherTax(foundProduct, SETTINGS);
                        foundProduct.witholdTaxEuro = getProductWithholdTax(foundProduct, SETTINGS);
                        foundProduct.feeEuro = getProductFee(foundProduct, SETTINGS);
                        foundProduct.stampEuro = getProductStamp(foundProduct, SETTINGS);
                        foundProduct.vat = getVatValue(foundProduct.netValue, foundProduct.vatPercentage, userTotalValInput);
                        foundProduct.totalValue = getTotalValue(foundProduct.netValue, foundProduct.vatPercentage, foundProduct.taxes, foundProduct.vat);
                    }
                    if (productData.accountingNonDeductibleVAT && !quickEdit) foundProduct.accountingNonDeductibleVAT = productData.accountingNonDeductibleVAT;
                    let productListData = buysDuplicateProductCheck(plProducts, filterFields, foundProduct.no - 1);
                    // Logic to check duplicate product in the table.
                    let productBuysTaxes;
                    if (productListData && !data.quickEdit) {
                        toast.error('Duplicate products not allowed.');
                        let productTotals = getBuysTotals(plProducts, SETTINGS);
                        productBuysTaxes = getBuysTaxesData(plProducts, BUYS.editBuys.extraData, productTotals, SETTINGS);
                        let totalTaxes = 0;
                        let withHoldTaxes = 0;
                        if (productBuysTaxes.length > 0 && productBuysTaxes[0].vatPercentage === "Invoice Level Tax") {
                            totalTaxes = parseFloat((Number(productBuysTaxes[0].otherTaxes) + Number(productBuysTaxes[0].fees) + Number(productBuysTaxes[0].stamp)).toString());
                            withHoldTaxes = parseFloat(productBuysTaxes[0].withholdTaxes).toFixed(2);
                        }
                        if (totalTaxes !== 0 || withHoldTaxes !== 0) {
                            productTotals.paymentAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
                            productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes)).toFixed(2);
                        }
                        dispatch(setEditBuys({
                            ...BUYS.editBuys,
                            products: plProducts,
                            buysTaxes: productBuysTaxes, ...productTotals
                        })); // update data
                        setRefreshPage(refreshPage + 1); // Refresh page
                    } else {
                        plProducts[foundProduct.no - 1] = updatedPmProducts;
                        let productTotals = getBuysTotals(plProducts, SETTINGS);
                        productBuysTaxes = getBuysTaxesData(plProducts, BUYS.editBuys.extraData, productTotals, SETTINGS);
                        let extraData = {...BUYS.editBuys.extraData};
                        if (productBuysTaxes.length > 0) {
                            productBuysTaxes.map((buysTax) => {
                                if (buysTax.vatPercentage === "Invoice Level Tax") {
                                    let extraData = {};
                                    extraData.witholdTaxEuro = buysTax.withholdTaxes;
                                    extraData.otherTaxEuro = buysTax.otherTaxes;
                                    extraData.feeEuro = buysTax.fees;
                                    extraData.stampEuro = buysTax.stamp;
                                }
                                return buysTax;
                            })
                        }
                        let totalTaxes = 0;
                        let withHoldTaxes = 0;
                        if (productBuysTaxes.length > 0 && productBuysTaxes[0].vatPercentage === "Invoice Level Tax") {
                            totalTaxes = parseFloat((Number(productBuysTaxes[0].otherTaxes) + Number(productBuysTaxes[0].fees) + Number(productBuysTaxes[0].stamp)).toString());
                            withHoldTaxes = parseFloat(productBuysTaxes[0].withholdTaxes).toFixed(2);
                        }
                        if (totalTaxes !== 0 || withHoldTaxes !== 0) {
                            productTotals.paymentAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes)).toFixed(2);
                            productTotals.totalAmount = parseFloat(parseFloat(productTotals.totalAmount) + parseFloat(totalTaxes)).toFixed(2);
                        }
                        dispatch(setEditBuys({
                            ...BUYS.editBuys,
                            products: plProducts,
                            buysTaxes: productBuysTaxes, ...productTotals,
                            extraData: extraData
                        })); // update data
                        setRefreshPage(refreshPage + 1); // Refresh page
                    }
                    setProductTableSpinner('false');

                }
            }
        },
        selected: () => {
        },
        delete: (id, rowIndex) => {
            if (!ACTIVITY.saveEditBuys) dispatch(setSaveEditBuys(true));

            let cloneProducts = structuredClone(BUYS.editBuys.products);
            cloneProducts.splice(rowIndex, 1);
            cloneProducts.forEach((prod, idx) => {
                prod.no = idx + 1;
                prod.id = String(idx + 1);
            })
            dispatch(setEditBuys({...BUYS.editBuys, products: cloneProducts}))
            setUpdateBuyAmounts(true);
        }
    }
    const selectedDocumentType = getDefaultOptionByValue(documentTypesOptions, BUYS.editBuys.documentType, 'value', 'label', 'value');
    let tableWrClasses = "product-form sales-products-list position-relative";
    if (BUYS.editBuys.supplier !== '') {
        tableWrClasses += " normalIndex";
    }
    let cashRegistersData = CASH_DATA.cashRegisters.filter((register) => register.isActive === "active");
    let cashRegisterOptions = [{
        value: "",
        label: t("General.empty")
    }, ...getOptionsByData(cashRegistersData, "_id", "name")];

    const newBuysTab = [
        {
            tabName: t("General.products"),
            tabData: (
                <div className={tableWrClasses} style={{pointerEvents: disableEditFields ? "none" : ""}}>
                    {((BUYS.editBuys.supplier === '' && BUYS.editBuys.newSupplier === '') && BUYS.editBuys.employee === '') &&
                        <div className="overlay_w">{t("Required.selectSupplierFirst")}</div>}
                    {showProductTableSpinner && showProductTableSpinner === 'true' && <div className="overlay_w">
                        <div className="spinner-border" role="status"><span
                            className="sr-only">{t("General.loading")}</span></div>
                    </div>}
                    {BUYS.editBuys?.products && BUYS.editBuys?.products?.length === 0 &&
                        <span className="text-danger">{t("Required.productList")}</span>}
                    <EditableTable
                        tableName="Buys Products List"
                        key={Math.random()}
                        actionButtons={{
                            print: false,
                            delete: true,
                            export: false,
                            edit: true,
                        }}
                        columns={supplierProductsColumns}
                        pRequestData={pRequestData}
                        data={BUYS.editBuys.products}
                        allowInsertRow={true}
                        onUpdate={buysProductListActions}
                        allowActions={true}
                        allowToggleColumns={false}
                        allowPrintResults={false}
                    />
                </div>
            ),
        },
        {
            tabName: t("General.extraData"),
            tabData: (
                <>
                    <Row>
                        <Col sm={12} md={3} className="mb-2">
                            <Dropdown
                                label={t("General.wayOfSendingGoods")}
                                key={Math.random()}
                                defaultValue={getDefaultOptionByValue(waysOfSendingGoodsOptions, BUYS.editBuys?.extraData?.wayOfSendingGoods, 'value', 'label', 'value')}
                                name="wayOfSendingGoods"
                                options={waysOfSendingGoodsOptions}
                                onChange={(e) => handleOnChangeExtraData(e, 'dd', 'wayOfSendingGoods')}
                            />
                        </Col>
                        {BUYS.editBuys?.extraData?.deliveryLocation === "custom" && (
                            <Col></Col>
                        )}
                        <Col sm={12} md={3} className="mb-2">
                            <Dropdown
                                label={t("General.otherTaxes")}
                                key={Math.random()}
                                defaultValue={getDefaultOptionByValue(otherTaxesOptions, BUYS.editBuys?.extraData?.otherTax, 'value', 'label', 'value')}
                                name="otherTax"
                                options={otherTaxesOptions}
                                onChange={(e) => handleOnChangeExtraData(e, 'dd', 'otherTax')}
                            />
                        </Col>
                        <Col sm={12} md={3} className="mb-2">
                            <Dropdown
                                label={t("General.withholdTaxes")}
                                key={Math.random()}
                                defaultValue={getDefaultOptionByValue(withHoldTaxesOptions, BUYS.editBuys?.extraData?.withHoldTax, 'value', 'label', 'value')}
                                name="withHoldTax"
                                options={withHoldTaxesOptions}
                                onChange={(e) => handleOnChangeExtraData(e, 'dd', 'withHoldTax')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={3}>
                            <DateBox
                                classes="w-100 mb-2"
                                label={t("General.goodsSendDate")}
                                name="goodsSendDate"
                                selected={BUYS.editBuys?.extraData?.goodsSendDate}
                                onChange={(e) => handleOnChangeExtraData(e, 'dp', 'goodsSendDate')}
                            />
                        </Col>
                        <Col sm={12} md={3} className="mb-2">
                            <Dropdown
                                label={t("General.carNumber")}
                                key={Math.random()}
                                defaultValue={getDefaultOptionByValue(carNumbersOptions, BUYS.editBuys?.extraData?.carNumber, 'value', 'label', 'value')}
                                name="carNumber"
                                options={carNumbersOptions}
                                onChange={(e) => handleOnChangeExtraData(e, 'dd', 'carNumber')}
                            />
                        </Col>
                        <Col sm={12} md={3} className="mb-2">
                            <Dropdown
                                label={t("General.fees")}
                                key={Math.random()}
                                defaultValue={getDefaultOptionByValue(feesOptions, BUYS.editBuys?.extraData?.fee, 'value', 'label', 'value')}
                                name="fee"
                                options={feesOptions}
                                onChange={(e) => handleOnChangeExtraData(e, 'dd', 'fee')}
                            />
                        </Col>
                        <Col sm={12} md={3} className="mb-2">
                            <Dropdown
                                label={t("General.stampDuty")}
                                key={Math.random()}
                                defaultValue={getDefaultOptionByValue(stampOptions, BUYS.editBuys?.extraData?.stamp, 'value', 'label', 'value')}
                                name="stamp"
                                options={stampOptions}
                                onChange={(e) => handleOnChangeExtraData(e, 'dd', 'stamp')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <Dropdown
                                name={"cashRegister"}
                                label={t("General.cashRegister")}
                                key={Math.random()}
                                defaultValue={cashRegisterOptions.find((el) => el.value === BUYS.editBuys.extraData?.cashRegister) ? cashRegisterOptions.find((el) => el.value === BUYS.editBuys.extraData?.cashRegister) : ""}
                                options={cashRegisterOptions}
                                onChange={(e) => handleOnChangeExtraData(e, "dd", "cashRegister")}
                            />
                        </Col>
                        {checkPermissionMasterParentStartingTree("accounting", permissionsData) && (
                            <Col md={3}>
                                <Button size={"sm"} onClick={() => setShowAnamorfosis(true)} style={{marginTop: "30px"}}>Λογιστική Αναμόρφωση</Button>
                                <AnamorfosisModal
                                    from={"Buys"}
                                    show={showAnamorfosis}
                                    setShow={setShowAnamorfosis}
                                />
                            </Col>
                        )}
                    </Row>
                </>
            ),
        },
    ]

    const clearSupplier = () => {
        if (BUYS.editBuys.supplier !== '') {
            let newBuysData = {};
            let buysData = Object.assign({}, BUYS);
            newBuysData.supplier = "";
            newBuysData.supplierName = "";
            newBuysData.supplierCode = "";
            newBuysData.address = "";
            newBuysData.vat = "";
            newBuysData.doy = "";
            newBuysData.city = "";
            newBuysData.country = "";
            newBuysData.pc = "";
            newBuysData.email = "";
            newBuysData.phone = "";
            newBuysData.activity = "";
            newBuysData.vatCategory = "";
            setSupplierInstallationListData(SUPPLIERS_DATA.installations.slice());
            newBuysData.supplierInstallationType = "";
            newBuysData.supplierInstallationId = "";
            // Set Supplier Vat Category used for product vat calculation
            dispatch(setEditBuySupplierCustomData({
                supplierVatCategory: "Normal VAT",
                supplierVatReason: "",
                supplierCustomVat: 0,
            }))
            dispatch(setEditBuys({...buysData.editBuys, ...newBuysData}));
        }
    }

    const quickAddSupplier = (e, type, data) => {
        let quickSupplierData = Object.assign({}, SUPPLIERS_DATA.newClient);
        // Default Category Retails form sales page.
        quickSupplierData.category = '1';
        quickSupplierData.categoryName = 'Retail';
        quickSupplierData.name = data.name;
        quickSupplierData.vatCategory = data.vatCategory;
        quickSupplierData.vatNumber = data.vatNumber;
        quickSupplierData.address = data.address;
        quickSupplierData.city = data.city;
        quickSupplierData.email = data.email;
        quickSupplierData.phone = data.phone;
        quickSupplierData.country = data.country;
        quickSupplierData.clientInstallationId = "0";
        // Company and Year
        quickSupplierData.company = requestData.company;
        quickSupplierData.year = requestData.year;
        quickSupplierData.isActive = 'active'; // Active by default
        if ((data.email !== '' || data.phone !== '') && data.name !== '' && data.vatCategory !== '') {
            dispatch(postSupplier(quickSupplierData, requestData));
        } else {
            toast.error('Name, Email or Phone and Vat Category required fields.');
        }
    }

    // Data For Quick Edit Client Details on selection
    let quickEditSupplierData = {};
    if (BUYS.editBuys.supplierType === 'Existing') {
        if (BUYS.editBuys.client && BUYS.editBuys.client !== "") {
            quickEditSupplierData.name = BUYS.editBuys.clientName;
            quickEditSupplierData.id = BUYS.editBuys.client;
            quickEditSupplierData.vatCategory = BUYS.editBuys.vatCategory;
            quickEditSupplierData.address = BUYS.editBuys.address;
            quickEditSupplierData.vatNumber = BUYS.editBuys.vat;
            quickEditSupplierData.doy = getLabelByValue(getDOYOptions(), Number(BUYS.editBuys.doy), 'value');
            quickEditSupplierData.city = BUYS.editBuys.city;
            quickEditSupplierData.pc = BUYS.editBuys.pc;
            quickEditSupplierData.email = BUYS.editBuys.email;
            quickEditSupplierData.phone = BUYS.editBuys.phone;
        }
    }
    let buysTaxesColumns = [
        {
            name: t("General.vatPercentage"),
            field: "vatPercentage",
            editable: false,
        },
        {
            name: t("General.netValue"),
            field: "netValue",
            editable: false,
        },
        {
            name: t("General.vatValue"),
            field: "vatEuro",
            editable: false,
        },
        {
            name: t("General.withholdTaxes"),
            field: "withholdTaxes",
            editable: false,
        },
        {
            name: t("General.otherTaxes"),
            field: "otherTaxes",
            editable: false,
        },
        {
            name: t("General.fees"),
            field: "fees",
            editable: false,
        },
        {
            name: t("General.stampDuty"),
            field: "stamp",
            editable: false,
        }
    ];
    let totalTaxes = 0;
    let withHoldTaxes = 0;
    let productTotals = useMemo(() => getBuysTotals(BUYS.editBuys.products, SETTINGS), [BUYS.editBuys.products]);

    let buysTaxesData = [];
    if (BUYS.editBuys.extraData) {
        buysTaxesData = getBuysTaxesData(BUYS.editBuys.products, BUYS.editBuys.extraData, productTotals, SETTINGS)
    }
    if (buysTaxesData.length > 0) {
        if (buysTaxesData[0].vatPercentage === "Invoice Level Tax") {
            totalTaxes = parseFloat((Number(buysTaxesData[0].otherTaxes) + Number(buysTaxesData[0].fees) + Number(buysTaxesData[0].stamp)).toString());
            withHoldTaxes = parseFloat(buysTaxesData[0].withholdTaxes).toFixed(2);
        }
    }
    let totalQuantity = productTotals.totalQuantity;
    let totalBeforeDiscount = productTotals.totalBeforeDiscount;
    let totalDiscount = productTotals.totalDiscount;
    let totalNetValue = productTotals.totalNetValue;
    let totalAmount = productTotals.totalAmount;
    //let paymentAmount = productTotals.paymentAmount;
    let totalVatAmount = productTotals.totalVatValue;
    if (totalTaxes !== 0 || withHoldTaxes !== 0) {
        totalAmount = parseFloat(totalAmount) + parseFloat(totalTaxes);
        //paymentAmount = parseFloat(paymentAmount) + parseFloat(totalTaxes) - parseFloat(withHoldTaxes);
    }
    totalTaxes += Number(productTotals.totalTaxesAmount);
    withHoldTaxes = parseFloat(parseFloat(productTotals.withHoldTaxes) + parseFloat(withHoldTaxes));
    const selectPaymentTypeOptions = getDefaultOptionByValue(paymentTypeOptions, BUYS.editBuys.paymentType, 'value', 'label', 'value');
    const [loadingSendToMyData, setLoadingSendToMyData] = useState(false);
    const [showMyDataErrorModal, setShowMyDataErrorModal] = useState(false);

    useEffect(() => {
        if (updateBuyAmounts === true) {
            setUpdateBuyAmounts(false);
            let extraData = {};
            if (buysTaxesData.length > 0) {
                buysTaxesData.forEach((buysTax) => {
                    if (buysTax.vatPercentage === "Invoice Level Tax") {
                        buysTax.withHoldTaxEuro = buysTax.withholdTaxes;
                        buysTax.otherTaxEuro = buysTax.otherTaxes;
                        buysTax.feeEuro = buysTax.fees;
                        buysTax.stampEuro = buysTax.stamp;
                    }
                })
            }
            dispatch(setEditBuys({
                ...BUYS.editBuys,
                buysTaxes: buysTaxesData,
                extraData: {...BUYS.editBuys.extraData, ...extraData},
                ...productTotals,
            }))
        }
    }, [updateBuyAmounts])

    const sendToMyData = () => {
        let data = {
            companyId: company.id,
            year: company.year.toString(),
            buyId: BUYS.editBuys._id
        }
        dispatch(sendInvoiceToMyDataBuy(data, null, setLoadingSendToMyData, TABS_DATA.length));
    }

    return (
        <div className="new-buys">
            <div className="supplier-form product-form position-relative">
                <AccountingView document={BUYS.editBuys}/>
                {(BUYS.editBuys.myDataStatus === "sent" || !isEmpty(BUYS.editBuys.mark)) && (
                    <Alert variant={"secondary"}>
                        Η αγορά δεν είναι επεξεργάσιμη
                        επειδή {BUYS.editBuys.fromMyDataReport === "true" ? `αντιστοιχεί σε αγορά από το My Data.` : `έχει σταλεί στο My Data.`}
                        {BUYS.editBuys.mark && (
                            <React.Fragment>
                                {BUYS.editBuys.mark && (
                                    <React.Fragment>
                                        <br/>
                                        <span style={{fontWeight: "600"}}>MARK: </span><span>{BUYS.editBuys.mark}</span>
                                    </React.Fragment>
                                )}
                                {BUYS.editBuys.uid && (
                                    <React.Fragment>
                                        <br/>
                                        <span style={{fontWeight: "600"}}>UID: </span><span>{BUYS.editBuys.uid}</span>
                                    </React.Fragment>
                                )}
                                {BUYS.editBuys.authCode && (
                                    <React.Fragment>
                                        <br/>
                                        <span
                                            style={{fontWeight: "600"}}>AUTH CODE: </span><span>{BUYS.editBuys.authCode}</span>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </Alert>
                )}
                {disabledFields && <div className="overlay_w">{t("Required.selectDocumentFirst")}</div>}
                <Row className="border-bottom mb-2">
                    <Col sm={12} md={3} className="showWithOverlay">
                        <Dropdown
                            label={t('General.documentType')}
                            key={Math.random()}
                            defaultValue={selectedDocumentType}
                            required={true}
                            searchEnable={true}
                            name="documentType"
                            options={documentTypesOptions}
                            disabled={disableEditFields}
                            onChange={(e) => handleOnChange(e, 'dd', 'documentType')}
                        />
                    </Col>
                    <Col md={3} className={"mt-4"}>
                        {!isEmpty(BUYS.editBuys.myDataType) && (
                            <h6 style={{color: "red"}}>Προσοχή! Το παραστατικό θα σταλεί στο My Data!</h6>
                        )}
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="d-flex justify-content-end">
                            <CloudHooksModal
                                documentId={BUYS.editBuys._id}
                                section={"Buys"}
                                className={"mr-2 mt-2"}
                            />
                            <div className="mydata-status disabled-page">
                                <Dropdown
                                    label={t("General.myDataStatus")}
                                    options={buysMyDataStatusOptions}
                                    defaultValue={getDefaultOptionByValue(buysMyDataStatusOptions, BUYS.editBuys.myDataStatus, 'value', 'label', 'value')}
                                    name="myDataStatus"
                                    disabled={true}
                                    onChange={(e) => handleOnChange(e, 'dd', 'myDataStatus')}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className={"mb-2"}>
                    <Col sm="12" md="3">
                        {/* If "MyData rules" is enabled in "Settings-Document Types-Advanced Types", then FIELD IS READ ONLY. Select current date only. */}
                        <DateBox
                            classes="w-100"
                            label={t('General.date')}
                            name="date"
                            selected={moment(BUYS.editBuys.date).isValid() ? new Date(BUYS.editBuys.date) : null}
                            disabled={disableEditFields}
                            onChange={(e) => handleOnChange(e, 'dp', 'date')}
                        />
                        {ACTIVITY.saveEditBuys && isEmpty(BUYS.editBuys.date) &&
                            <div className="text-danger">Απαιτείται Ημερομηνία.</div>}
                    </Col>
                    <Col sm="12" md="3">
                        <HourPicker
                            label={t("General.time")}
                            selected={moment(BUYS.editBuys.time, "HH:mm").toDate()}
                            disabled={disableEditFields}
                            onChange={(e) => handleOnChange(e, "tp", "time")}
                        />
                    </Col>
                    <Col sm="12" md="6" className="text-right d-flex align-items-center justify-content-end">
                        {BUYS.editBuys?.myDataError !== "" && BUYS.editBuys?.myDataError !== undefined && (
                            <Button className="view-sales-buttons"
                                    disabled={BUYS.editBuys.myDataStatus === "sent" || isEmpty(BUYS.editBuys?.myDataType)}
                                    onClick={() => setShowMyDataErrorModal(true)}>Show MyData Error</Button>
                        )}
                        <Modal backdrop={"static"} className="themeModal" show={showMyDataErrorModal}>
                            <Modal.Header>
                                <Modal.Title>Warning!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>The document failed to be sent to MyData! <br/> The Error
                                is: <br/> {BUYS.editBuys?.myDataError}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-primary"
                                        onClick={() => setShowMyDataErrorModal(false)}>OK</Button>
                            </Modal.Footer>
                        </Modal>
                        <Button className="view-sales-buttons"
                                disabled={BUYS.editBuys.myDataStatus === "sent" || loadingSendToMyData}
                                onClick={() => sendToMyData()}>
                            {loadingSendToMyData && (
                                <span className="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                            )}{t("General.sendToMyData")}</Button>
                    </Col>
                </Row>
                <Row className={"mb-2"}>
                    <Col sm="12" md="4">
                        <Dropdown
                            label={t("General.installation")}
                            options={installationOptions}
                            value={getDefaultOptionByValue(installationOptions, BUYS.editBuys.installation, 'value', 'label', 'value')}
                            enabledValue={true}
                            searchEnable={true}
                            required={true}
                            name="installation"
                            disabled={disableEditFields}
                            onChange={(e) => handleOnChange(e, 'dd', 'installation')}
                        />
                        {ACTIVITY.saveEditBuys && isEmpty(BUYS.editBuys.installation) &&
                            <div className="text-danger">{t("Required.installation")}</div>}
                    </Col>
                    <Col sm="12" md="4">
                        <Input
                            className="mb-2 w-100"
                            label={t("General.preNumber")}
                            name="preNumber"
                            type="text"
                            required={true}
                            value={BUYS.editBuys.preNumber}
                            placeholder="Εισάγετε Σειρά"
                            onChange={(e) => handleOnChange(e)}
                            disabled={disableEditFields}
                        />
                        {ACTIVITY.saveEditBuys && isEmpty(BUYS.editBuys.preNumber) &&
                            <div className="text-danger">{t("Required.preNumber")}</div>}
                    </Col>
                    <Col sm="12" md="4">
                        <Input
                            className="w-100"
                            label={t("General.number")}
                            name="number"
                            required={true}
                            value={BUYS.editBuys.number}
                            placeholder="Εισάγετε Αριθμό"
                            onChange={(e) => handleOnChange(e)}
                            disabled={disableEditFields}
                        />
                        {ACTIVITY.saveEditBuys && isEmpty(BUYS.editBuys.number) &&
                            <div className="text-danger">{t("Required.number")}</div>}
                    </Col>
                </Row>
                <Row className={"mb-2"}>
                    {BUYS.editBuys.supplierType === 'Employee' &&
                        <Col sm="30" md="5">
                            <AdvancedCombobox
                                ACName="Buys Employee" // do not change name
                                className="w-200 ac-select-dropdown"
                                searchableDropdownEnable={false}
                                defaultValue={BUYS.editBuys.employeeName}
                                key={Math.random()}
                                label="Employee"
                                data={employeeListData}
                                onChangeSearch={onChangeSearch}
                                onChange={(e) => handleOnChange(e, 'acdd', 'employee')}
                                excludeColumns={[]}
                                columns={employeeDropdownColumns}
                                editEnable={false}
                                deleteEnable={false}
                                searchTableEnable={false}
                                showTableOptionOnSelect={true}
                                placeholder='Select Employee'
                                required={true}
                                showInsertAddButton={true}
                                hideSaveButton={true}
                                hideInsertNewRow={true}
                                inputFieldClasses="form-control"
                                disabled={disableEditFields}
                            />
                            {ACTIVITY.saveEditBuys && BUYS.editBuys.supplierType === 'Employee' && BUYS.editBuys.employee === "" &&
                                <div className="text-danger">Employee is required.</div>}
                        </Col>
                    }
                    {BUYS.editBuys.supplierType === 'Existing' &&
                        <Col sm="30" md="5">
                            <AdvancedCombobox
                                ACName="Buys Supplier" // do not change name
                                className="w-200 ac-select-dropdown"
                                searchableDropdownEnable={false}
                                defaultValue={BUYS.editBuys.supplierName}
                                key={Math.random()}
                                label={t("General.supplier")}
                                data={suppliersListData}
                                onChangeSearch={onChangeSearch}
                                onChange={(e) => handleOnChange(e, 'acdd', 'supplier')}
                                excludeColumns={[]}
                                columns={supplierDropdownColumns}
                                editEnable={false}
                                deleteEnable={false}
                                searchTableEnable={false}
                                showTableOptionOnSelect={true}
                                placeholder={t("Placeholders.selectSupplier")}
                                required={true}
                                showInsertAddButton={true}
                                hideSaveButton={true}
                                hideInsertNewRow={true}
                                inputFieldClasses="form-control"
                                disabled={disableEditFields}
                            />
                            {ACTIVITY.saveEditBuys && BUYS.editBuys.supplierType === 'New' && isEmpty(BUYS.editBuys.newSupplier) &&
                                <div className="text-danger">{t("Required.supplier")}</div>}
                            {ACTIVITY.saveEditBuys && BUYS.editBuys.supplierType === 'Existing' && isEmpty(BUYS.editBuys.supplier) &&
                                <div className="text-danger">{t("Required.supplier")}</div>}
                        </Col>
                    }
                    {BUYS.editBuys.supplierType === 'New' &&
                        <Col sm="30" md="5">
                            <Input
                                className="w-100 newsupplier-field"
                                label={"New Retail Supplier"}
                                name="newSupplier"
                                value={BUYS.editBuys.newSupplier}
                                placeholder="Add new retail supplier name"
                                onChange={(e) => handleOnChange(e)}
                            />
                        </Col>
                    }
                    {BUYS.editBuys.supplierType !== 'Employee' &&
                        <Col sm="1" md="1">
                            <div className="d-flex  mt-4">
                                <div className="quick-supplier-actions">
                                    {(BUYS.editBuys.supplier !== '' && BUYS.editBuys.supplierType === 'Existing' && !disableEditFields) &&
                                        <button className="btn quick-retail-supplierbtn btn-default btn-sm"
                                                title="Clear Supplier Fields"
                                                onClick={clearSupplier}><i className="fas fa-xmark"></i></button>}
                                    {((BUYS.editBuys.supplier === '' && BUYS.editBuys.newSupplier === '') || BUYS.editBuys.supplierType !== 'Existing') &&
                                        <NewSupplierModal title="Add new supplier" columns={quickSupplierFields}
                                                          data={{}} hideCurrentValue={true}
                                                          myDataType={BUYS.editBuys.myDataType}
                                                          updateData={quickAddSupplier}/>}
                                </div>
                            </div>
                        </Col>
                    }
                    {BUYS.editBuys.supplierType === 'Existing' && (
                        <Col sm="12" md="2">
                            <AdvancedCombobox
                                ACName="Supplier Installation" // do not change name
                                className="w-100 ac-select-dropdown"
                                searchableDropdownEnable={false}
                                defaultValue={BUYS.editBuys.supplierInstallationType}
                                key={Math.random()}
                                label={t("General.supplierInstallation")}
                                data={supplierInstallationListData}
                                onChangeSearch={onChangeSearch}
                                onChange={(e) => handleOnChange(e, 'acdd', 'supplierInstallation')}
                                excludeColumns={[]}
                                columns={supplierInstallationsDropdownColumns}
                                editEnable={false}
                                deleteEnable={false}
                                searchTableEnable={false}
                                showTableOptionOnSelect={true}
                                placeholder={t("General.supplierInstallation")}
                                required={true}
                                showInsertAddButton={true}
                                hideSaveButton={true}
                                hideInsertNewRow={true}
                                inputFieldClasses="form-control"
                                disabled={disableEditFields}
                            />
                        </Col>
                    )}
                    {(BUYS.editBuys.supplierType === 'Existing' || BUYS.editBuys.supplierType === 'Employee') && (
                        <Col sm="12" md="2">
                            <Input
                                className="w-100"
                                label={t("General.vatNumber")}
                                name="vat"
                                value={BUYS.editBuys.vat}
                                onChange={(e) => handleOnChange(e)}
                                disabled={true}
                                readonly
                            />
                        </Col>
                    )}
                    {/* Show if new supplier */}
                    {BUYS.editBuys.supplierType === 'New' &&
                        <Col sm="12" md="2">
                            <Input
                                className="w-100"
                                label={t("General.email")}
                                name="email"
                                required={BUYS.editBuys.supplierType === 'New' && BUYS.editBuys.phone === ''}
                                value={BUYS.editBuys.email}
                                onChange={(e) => handleOnChange(e)}
                                disabled={disableEditFields}
                            />
                            {ACTIVITY.saveEditBuys && BUYS.editBuys.supplierType === 'New' && BUYS.editBuys.email === "" && BUYS.editBuys.phone === "" &&
                                <div className="text-danger">Email or Phone is required.</div>}
                        </Col>
                    }
                    {/* Show if new supplier */}
                    {(BUYS.editBuys.supplierType === 'New' || BUYS.editBuys.supplierType === 'Employee') &&
                        <Col sm="12" md="2">
                            <Input
                                className="w-100"
                                label={t("General.phone")}
                                name="phone"
                                disabled={BUYS.editBuys.supplierType === 'Employee' || disableEditFields}
                                required={BUYS.editBuys.supplierType === 'New' && BUYS.editBuys.email === ''}
                                value={BUYS.editBuys.phone}
                                onChange={(e) => handleOnChange(e)}
                            />
                        </Col>
                    }
                    {BUYS.editBuys.supplierType !== 'Employee' &&
                        <Col sm="12" md="2">
                            <Input
                                className="w-100"
                                label={t("General.address")}
                                name="address"
                                disabled={BUYS.editBuys.supplierType === 'Employee' || disableEditFields}
                                value={BUYS.editBuys.address}
                                onChange={(e) => handleOnChange(e)}
                            />
                        </Col>
                    }
                </Row>
                <Row className={"mb-2"}>
                    {BUYS.editBuys.supplierType === 'Existing' && (
                        <Col sm="12" md="2">
                            <Input
                                className="w-100"
                                label={t("General.doy")}
                                name="doy"
                                value={BUYS.editBuys.doy}
                                onChange={(e) => handleOnChange(e)}
                                disabled={true}
                                readonly
                            />
                        </Col>
                    )}
                    {BUYS.editBuys.supplierType !== 'Employee' &&
                        <Col sm="12" md="2">
                            <Input
                                className="w-100"
                                label={t("General.city")}
                                name="city"
                                value={BUYS.editBuys.city}
                                onChange={(e) => handleOnChange(e)}
                                disabled={BUYS.editBuys.supplierType !== 'New'}
                                readonly
                            />
                        </Col>
                    }
                    {BUYS.editBuys.supplierType !== 'Employee' &&
                        <Col sm="12" md="2">
                            <Input
                                className="w-100"
                                label={t("General.postalCode")}
                                name="pc"
                                value={BUYS.editBuys.pc}
                                onChange={(e) => handleOnChange(e)}
                                disabled={BUYS.editBuys.supplierType !== 'New'}
                                readonly
                            />
                        </Col>
                    }
                    {BUYS.editBuys.supplierType === 'Employee' &&
                        <Col sm="12" md="3">
                            <Dropdown
                                label={t('EmployeeNew.employeeCategory.name')}
                                name="employeeCategory"
                                width="30%"
                                placeholder={t('EmployeeNew.employeeCategory.placeholder')}
                                options={getEmployeeCategoryOptions()}
                                enabledValue={true}
                                required={true}
                                disabled={true}
                                value={getDefaultOptionByValue(getEmployeeCategoryOptions(), BUYS.editBuys.employeeCategory, 'value', 'label', 'value')}
                            />
                        </Col>
                    }
                    {BUYS.editBuys.supplierType === 'Employee' &&
                        <Col sm="12" md="3">
                            <Dropdown
                                label={t('EmployeeNew.employeeType.name')}
                                name="employeeType"
                                width="30%"
                                placeholder={t('EmployeeNew.employeeType.placeholder')}
                                options={getEmployeeTypeOptions()}
                                enabledValue={true}
                                required={true}
                                disabled={true}
                                onChange={(e) => handleOnChange(e, "dd", "employeeType")}
                                value={getDefaultOptionByValue(getEmployeeTypeOptions(), BUYS.editBuys.employeeType, 'value', 'label', 'value')}
                            />
                        </Col>
                    }
                </Row>
                <Row>
                    <Col sm="12" md="4">
                        <Dropdown
                            label={t("General.paymentType")}
                            options={paymentTypeOptions}
                            value={Object.keys(selectPaymentTypeOptions).length !== 0 ? selectPaymentTypeOptions : ''}
                            enabledValue={true}
                            required={requiredPaymentType}
                            name="paymentType"
                            searchEnable={true}
                            disabled={disableEditFields}
                            onChange={(e) => handleOnChange(e, 'dd', 'paymentType')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="mb-2">
                        <ul className="react-tabs__tab-list tab-style tab-min">
                            {newBuysTab.map((tab, i) => (
                                <li key={i} className="react-tabs__tab">
                                    <button
                                        type="button"
                                        onClick={() => setActiveTab(i)}
                                        className={`customNavBtn ${activeTab === i && "btnActive"}`}
                                    >
                                        <span className="textWrapper">{tab.tabName}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <div className="innerTabPanel">{newBuysTab[activeTab].tabData}</div>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" md="8">
                        <EditableTable
                            className="buys-taxes-calculations"
                            allowActions={false}
                            key={Math.random()}
                            data={buysTaxesData}
                            pRequestData={pRequestData}
                            columns={buysTaxesColumns}
                            actionButtons={{
                                print: false,
                                delete: false,
                                export: false,
                                edit: false,
                            }}
                        />
                    </Col>
                    <Col sm="12" md="4" className="d-flex justify-content-end">
                        <span className="mr-3 fw-bold">{t("General.totals")}</span>
                        <table className="table table-bordered mb-2">
                            <tbody>
                            <tr>
                                <td className="fw-bold">{t("General.totalQuantity")}</td>
                                <td>{totalQuantity}</td>
                            </tr>
                            <tr>
                                <td className="fw-bold">{t("General.totalBeforeDiscount")}</td>
                                <td>{getGreekCurrencyFormat(totalBeforeDiscount)}</td>
                            </tr>
                            <tr>
                                <td className="fw-bold">{t("General.totalDiscount")}</td>
                                <td>{getGreekCurrencyFormat(totalDiscount)}</td>
                            </tr>
                            <tr>
                                <td className="fw-bold">{t("General.totalNetValue")}</td>
                                <td>{getGreekCurrencyFormat(totalNetValue)}</td>
                            </tr>
                            <tr>
                                <td className="fw-bold">{t("General.totalVatValue")}</td>
                                <td>{getGreekCurrencyFormat(totalVatAmount)}</td>
                            </tr>
                            <tr>
                                <td className="fw-bold">{t("General.totalWithheldAmount")}</td>
                                <td>{getGreekCurrencyFormat(withHoldTaxes)}</td>
                            </tr>
                            <tr>
                                <td className="fw-bold">{t("General.totalTaxesAmount")}</td>
                                <td>{getGreekCurrencyFormat(totalTaxes)}</td>
                            </tr>
                            <tr>
                                <td className="fw-bold">{t("General.totalAmount")}</td>
                                <td>{getGreekCurrencyFormat(totalAmount)}</td>
                            </tr>
                            <tr>
                                <td className="fw-bold">{t("General.paymentAmount")}</td>
                                <td>{getGreekCurrencyFormat(BUYS.editBuys.paymentAmount)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <div style={{width: "100%"}}>
                        <div className="text-muted mb-2"><i>{t("General.notes")}</i></div>
                        <TextArea
                            rows="4"
                            placeholder={t("Placeholders.notes")}
                            name="notes"
                            value={BUYS.editBuys.notes}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                </Row>
            </div>
            <Modal backdrop={"static"} className="themeModal" show={showCateogryConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("General.warning")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("General.warningDataLoss")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary"
                            onClick={() => setShowCateogryConfirmation(false)}>{t("General.noOption")}</Button>
                    <Button variant="primary" onClick={() => handleConfirmCategory()}>{t("General.yesOption")}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export const BuysEditLog = () => {
    const {t} = useTranslation('common');
    const BUYS_DATA = useSelector((state) => state.BUYS);

    return (
        <React.Fragment>
            <div className="text-muted mb-2"><i>{t('Logs.buyLog')}</i></div>
            <LogTable itemId={BUYS_DATA.editBuys._id}/>
        </React.Fragment>
    )
}

export default EditBuys;
