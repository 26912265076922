import React, {useEffect, useState} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import Dropdown from "../../Dropdown";
import AccountingBox from "../../AccountingBox";
import {isEmpty} from "../../../../_helpers/commonFunctions";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {setEditSales} from "../../../../_reducers/DataPanel/Sales/salesSlice";
import TipComponent from "../../TipComponent/TipComponent";
import {useReactToPrint} from "react-to-print";

const SendPayment = ({paymentTypes, posOptions, bankOptions, saleId, paymentRemainder, printRef, posData}) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [requestData, setRequestData] = useState({
        sendPaymentMethod: "", // 1,2,3 ... payment id
        sendPaymentAmount: "0.00",
        sendPaymentTipAmount: "0.00",
        sendPaymentOption: "", // id of pos or bank account
        sendPaymentType: "", // cash/pos/bank-account
    })
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const SALES = useSelector((state) => state.SALES);

    const handlePrint = useReactToPrint({
        content: () => {
            if (printRef) {
                return printRef.current
            }
        }
    });

    useEffect(() => {
        if (show === true) {
            setRequestData({...requestData, sendPaymentAmount: Number(paymentRemainder).toFixed(2) })
            if (paymentTypes && paymentTypes.length > 0) {
                let opt = [];
                let clonePaymentTypes = [...paymentTypes];
                clonePaymentTypes = clonePaymentTypes.filter((el) => el.type === "cash" || el.type === "bank-account" || el.type === "pos");
                for (let paymentType of clonePaymentTypes) {
                    opt.push({value: paymentType.id, label: paymentType.name, type: paymentType.type});
                }
                setPaymentOptions(opt);
            }
        }
    }, [show])

    const handleOnChange = (e, type, name) => {
        if (type === "accounting") {
            setRequestData({...requestData, [name] : Number(e.target.value).toFixed(2)});
        } else if (type === "dropdown") {
            let newReqData = {...requestData}
            newReqData[name] = e.value;
            if (name === "sendPaymentMethod") {
                newReqData.sendPaymentType = e.type;
                if (e.type !== requestData.sendPaymentType) {
                    newReqData.sendPaymentOption = "";
                    newReqData.sendPaymentTipAmount = "0.00";
                }
            }
            setRequestData(newReqData);
        }
    }
    const handleSend = () => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL2 + "/sale/send-payment", {...requestData, company: company.id, saleId: saleId}, {
            headers: {"Content-Type": "application/json"},
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                if (res.data.newSaleObject) dispatch(setEditSales(res.data.newSaleObject));
                setTimeout(() => {
                    if (printRef) handlePrint();
                }, 200)
                toast.success(res.data.message);
                setShow(false);
                setRequestData({
                    sendPaymentMethod: "",
                    sendPaymentAmount: "0.00",
                    sendPaymentTipAmount: "0.00",
                    sendPaymentOption: "",
                    sendPaymentType: "",
                })
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            console.log(err);
        })
    }
    const disableSendPaymentButton = () => {
        return isEmpty(requestData.sendPaymentMethod) || isEmpty(requestData.sendPaymentAmount) || Number(requestData.sendPaymentAmount) <= 0
        || ((requestData.sendPaymentType === "pos" || requestData.sendPaymentType === "bank-account") && isEmpty(requestData.sendPaymentOption));
    }

    return (
        <React.Fragment>
            <Button
                className={"mr-2"}
                style={{pointerEvents: "auto", padding: "20px", paddingLeft: "30px", paddingRight: "30px", height: "100%"}}
                onClick={() => setShow(true)}
                hidden={SALES.editSales.paymentStatus === "paid" || SALES.editSales.myDataPaymentId !== "5" || SALES.editSales?.myDataType === "5.1" || SALES.editSales?.myDataType === "5.2" || SALES.editSales?.myDataType === "11.4"}
            >
                Πληρωμή
            </Button>
            <Modal backdrop={"static"} show={show} backdrop="static" onHide={() => setShow(false)}>
                <Modal.Header closeButton={!loading}>
                    <Modal.Title>Πληρωμή πώλησης</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown
                        name={"sendPaymentMethod"}
                        options={paymentOptions}
                        label={"Τρόπος πληρωμής"}
                        key={Math.random()}
                        defaultValue={paymentOptions.find((el) => el.value === requestData.sendPaymentMethod)}
                        onChange={(e) => handleOnChange(e, "dropdown", "sendPaymentMethod")}
                    />
                    {(requestData.sendPaymentType === "bank-account" || requestData.sendPaymentType === "pos") && (
                        <Dropdown
                            name={"sendPaymentOption"}
                            options={requestData.sendPaymentType === "pos" ? posOptions : bankOptions}
                            label={requestData.sendPaymentType === "pos" ? "Επιλογή POS" : "Επιλογή τραπεζικού λογαριασμού"}
                            key={Math.random()}
                            defaultValue={requestData.sendPaymentType === "pos" ?
                                posOptions.find((el) => el.value === requestData.sendPaymentOption)
                                :
                                bankOptions.find((el) => el.value === requestData.sendPaymentOption)}
                            onChange={(e) => handleOnChange(e, "dropdown", "sendPaymentOption")}
                        />
                    )}
                    <label className="mr-1">
                        Ποσό πληρωμής
                        {paymentRemainder && (
                            <React.Fragment> (μέγιστο {Number(paymentRemainder).toFixed(2).replace(".", ",")} €)</React.Fragment>
                        )}
                    </label>
                    <AccountingBox
                        name={"sendPaymentAmount"}
                        value={requestData.sendPaymentAmount}
                        defaultValue={requestData.sendPaymentAmount}
                        key={requestData.sendPaymentAmount}
                        onBlur={(e) => handleOnChange(e, "accounting", "sendPaymentAmount")}
                    />
                    {(requestData.sendPaymentType === "pos" && posData.find((el) => el._id === requestData.sendPaymentOption)?.isETameiaki === "active") && (
                        <React.Fragment>
                            <span className={"mb-1"}>Φιλοδώρημα</span>
                            <TipComponent
                                value={Number(requestData.sendPaymentTipAmount)}
                                onChange={(amount) => setRequestData({
                                    ...requestData,
                                    sendPaymentTipAmount: Number(amount).toFixed(2)
                                })}
                                paymentAmount={Number(requestData.sendPaymentAmount)}
                            />
                        </React.Fragment>
                    )}
                    {(loading && SALES.editSales.myDataStatus === "sent" && SALES.editSales.myDataDestination === "provider" && requestData.sendPaymentType === "pos" && posData.find((el) => el._id === requestData.sendPaymentOption)?.isETameiaki === "active") && (
                        <img src={"assets/images/POS.gif"} style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "80%"}} alt={"Please check the POS"}/>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShow(false)} disabled={loading}>
                        Ακύρωση
                    </Button>
                    <Button variant="primary" onClick={() => handleSend()} disabled={disableSendPaymentButton() || loading}>
                        Πληρωμή {loading && <Spinner className={"ml-1"} animation="border" variant="dark" />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default SendPayment