import React, {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {fetchSearchCommunicationHistory} from "../../../../../_apis/api";
import {deleteObjectFromArrayOfObjects} from "../../../../../_helpers/helperFunctions";
import {
    clearEmptyValuesFromObject,
    getCurrentUser,
    getMultiSelectOptionsToArray,
    getOptionsByCommaString
} from "../../../../../_helpers/commonFunctions";
import EditableTable from "../../../../primer/Editable-Table";
import {Col, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import DateBox from "../../../../primer/DateBox";
import Dropdown from "../../../../primer/Dropdown";
import {setSaveEditClient} from "../../../../../_reducers/activitySlice";
import {
    resetNewCommunicationHistory,
    resetSearchCommunicationHistory,
    setClientsSettings,
    setEditClient,
    setNewCommunicationHistory
} from "../../../../../_reducers/DataPanel/Clients/clientSlice";
import {useTranslation} from "react-i18next";

const apiURL2 = process.env.REACT_APP_API_URL2;

function EditClientCommunicationHistory() {
    const [t] = useTranslation('common');
    const company = useSelector(state => state.COMPANY_DATA.company)
    const ACTIVITY = useSelector((state) => state.ACTIVITY);
    const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
    const clientSubjects = CLIENTS_DATA.clientsSettings.clientCommunicationHistory;
    const subjectsOptionsInit = getOptionsByCommaString(clientSubjects);
    const [subjectsOptions, setSubjectsOptions] = useState(subjectsOptionsInit);
    const [requestDataPage, setRequestDataPage] = useState({
        company: company.id,
        year: company.year,
        clientId: CLIENTS_DATA.editClient._id
    });
    const [totalItems, setTotalItems] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const dispatch = useDispatch();
    const [nextId, setNextId] = useState(1);
    let requestData = {company: company.id, year: company.year};
    let chColumns = [
        {
            name: "No",
            field: "no",
            width: "15px",
            editable: false,
        },
        {
            name: t('ClientsNewCommunicationHistoryEdit.table1.date'),
            field: "date",
            width: "80px",
            editable: true,
            inputType: {
                type: 'datebox',
            }
        },
        {
            name: t('ClientsNewCommunicationHistoryEdit.table1.time'),
            field: "time",
            width: "50px",
            editable: true,
            inputType: {
                type: 'time',
            }
        },
        {
            name: t('ClientsNewCommunicationHistoryEdit.table1.subject'),
            field: "subject",
            width: "100px",
            editable: true,
            inputType: {
                type: 'dropdown',
                options: subjectsOptions
            }
        },
        {
            name: t('ClientsNewCommunicationHistoryEdit.table1.details'),
            field: "details",
            editable: true,
            width: "400px",
            inputType: {
                type: 'textarea',
            }
        },
        /*{
          name: t('ClientsNewCommunicationHistoryEdit.table1.followUpDate'),
          field: "followUpDate",
          editable: true,
          width: "100px",
          inputType: {
            type: 'datebox',
          }
        },
        {
          name: t('ClientsNewCommunicationHistoryEdit.table1.done'),
          field: "done",
          editable: true,
          width: "90px",
          inputType: {
            type: 'checkbox',
            options: [{
              value: true,
              label: t('ClientsNewCommunicationHistoryEdit.table2.yes')
            }, {
              value: false,
              label: t('ClientsNewCommunicationHistoryEdit.table2.no')
            }]
          }
        },*/
    ]
    const [tableColumns, setTableColumns] = useState(chColumns.slice())
    const [filterFormData, setFilterFormData] = useState({});
    useEffect(() => {
        dispatch(fetchSearchCommunicationHistory({
            company: company.id,
            year: company.year,
            clientId: CLIENTS_DATA.editClient._id
        }, setTotalItems, setNextId));
        axios.post(apiURL2 + `/client/list-client-setting`, JSON.stringify({
            company: company.id,
            year: company.year.toString(),
            isActive: "active",
            userId: getCurrentUser.id
        }), {headers: {'Content-Type': 'application/json'}})
            .then(res => {
                if (res.data && res.data.status === '200') {
                    if (res.data.data.length > 0) {
                        dispatch(setClientsSettings(res.data.data[res.data.data.length - 1]));
                        sessionStorage.setItem('clientsSettingsData', JSON.stringify(res.data.data[res.data.data.length - 1]));
                        const subjectsOptionsUp = getOptionsByCommaString(res.data.data[res.data.data.length - 1]?.clientCommunicationHistory);
                        setSubjectsOptions(subjectsOptionsUp);
                    }
                }
            }).catch((err) => {
            console.log(err);
        });
        setTableColumns(chColumns); // set options
        dispatch(resetSearchCommunicationHistory());
    }, [company.id, company.year, dispatch]);

    const handleOnFilterChange = (e, type = "default", ddname = '') => {
        let name;
        let value;

        if (type === 'msdd') {
            name = ddname;
            value = getMultiSelectOptionsToArray(e);
        } else if (type === 'dd') {
            name = ddname;
            value = e.value;
        } else if (type === 'dp') {
            value = e;
            name = ddname;
        } else {
            name = e.target.name;
            value = e.target.value;
        }
        setFilterFormData({...filterFormData, [name]: value});
    }

    const tableActions = {
        insert: (e, data) => {
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            let tempCommunicationHistory = Object.assign({}, CLIENTS_DATA.newCommunicationHistory);
            tempCommunicationHistory[data.field] = data.value;
            dispatch(setNewCommunicationHistory(tempCommunicationHistory)); // Update redux
            // Save Data
            if (tempCommunicationHistory && tempCommunicationHistory.subject !== '' && tempCommunicationHistory.details !== "" && tempCommunicationHistory.date !== '' && tempCommunicationHistory.time !== '') {
                let UpdatedComHist = Object.assign({}, tempCommunicationHistory);
                let oldComHist = CLIENTS_DATA.editClient.communicationHistory;
                UpdatedComHist.no = nextId + 1;
                UpdatedComHist.id = nextId + 1;
                dispatch(setEditClient({
                    ...CLIENTS_DATA.editClient,
                    communicationHistory: [UpdatedComHist, ...oldComHist]
                }));
                setPerPage(perPage + 1);
                setTotalItems(totalItems + 1);
                dispatch(resetNewCommunicationHistory()); // Reset
            }

        },
        update: (data, id) => {
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            if (data.date && (data.date === "")) {
                toast.error("Date is mandatory field.");
            } else if (data.time && (data.time === "")) {
                toast.error("Time  is mandatory field.");
            } else {
                let communicationHistory = CLIENTS_DATA.editClient.communicationHistory.slice();
                const index = communicationHistory.findIndex(item => item.id === id);
                if (index !== -1) {
                    // Update Code
                    communicationHistory[index] = {...communicationHistory[index], ...data};
                    dispatch(setEditClient({...CLIENTS_DATA.editClient, communicationHistory: communicationHistory})); // update data
                }
            }
        },
        selected: () => {},
        delete: (id) => {
            if (!ACTIVITY.saveEditClient) dispatch(setSaveEditClient(true));

            let communicationHistory = CLIENTS_DATA.editClient.communicationHistory.slice();
            if (id !== '' && Number.isInteger(id)) {
                communicationHistory = deleteObjectFromArrayOfObjects(communicationHistory, id, 'id');
                dispatch(setEditClient({...CLIENTS_DATA.editClient, communicationHistory: communicationHistory}))
            }
            if (id !== '' && !Number.isInteger(id)) {
                communicationHistory = deleteObjectFromArrayOfObjects(communicationHistory, id, '_id');
                dispatch(setEditClient({...CLIENTS_DATA.editClient, communicationHistory: communicationHistory}))
            }
            //reset table
            let cchRequestData = {...filterFormData, ...requestData};
            cchRequestData.clientId = CLIENTS_DATA.editClient._id;
            cchRequestData.page = 1;
            cchRequestData = clearEmptyValuesFromObject(cchRequestData); // clear empty values before sending to the api.
            setRequestDataPage(cchRequestData);
            setPerPage(5);
        }
    }
    const handleApplyFilter = (e) => {
        e.preventDefault();
        toast.info('Loading search result.');
        let cchRequestData = {...filterFormData, ...requestData};
        cchRequestData.clientId = CLIENTS_DATA.editClient._id;
        cchRequestData.page = 1;
        cchRequestData = clearEmptyValuesFromObject(cchRequestData); // clear empty values before sending to the api.
        setRequestDataPage(cchRequestData);
        setPerPage(5);
        dispatch(fetchSearchCommunicationHistory(cchRequestData, setTotalItems, setNextId));
    }
    return (
        <div className="search-products">
            <Row>
                <Col sm={6} md={4}>
                    <DateBox
                        name="dateFrom"
                        selected={filterFormData?.dateFrom}
                        label={t('ClientsNewCommunicationHistoryEdit.dateFrom')}
                        onChange={(date) => handleOnFilterChange(date, 'dp', 'dateFrom')}
                    />
                </Col>
                <Col sm={6} md={4}>
                    <DateBox
                        name="dateTo"
                        selected={filterFormData?.dateTo}
                        label={t('ClientsNewCommunicationHistoryEdit.dateTo')}
                        onChange={(date) => handleOnFilterChange(date, 'dp', 'dateTo')}
                    />
                </Col>
                <Col sm={6} md={4}>
                    <Dropdown
                        multiSelect={true}
                        label={t('ClientsNewCommunicationHistoryEdit.subject')}
                        placeholder='Select subjects.'
                        name="subject"
                        options={subjectsOptions}
                        onChange={(e) => handleOnFilterChange(e, 'msdd', 'subject')}
                    />
                </Col>
            </Row>
            {/*
            <Row>
                <Col sm={6} md={4}>
                    <DateBox
                        name="followupFrom"
                        selected={filterFormData?.followupFrom}
                        label={t('ClientsNewCommunicationHistoryEdit.followUpDateFrom')}
                        onChange={(date) => handleOnFilterChange(date, 'dp', 'followupFrom')}
                    />
                </Col>
                <Col sm={6} md={4}>
                    <DateBox
                        name="followupTo"
                        selected={filterFormData?.followupTo}
                        label={t('ClientsNewCommunicationHistoryEdit.followUpDateTo')}
                        onChange={(date) => handleOnFilterChange(date, 'dp', 'followupTo')}
                    />
                </Col>
                <Col sm={6} md={4}>
                    <Dropdown
                        label={t('ClientsNewCommunicationHistoryEdit.done')}
                        placeholder={t('ClientsNewCommunicationHistoryEdit.selectSubjects')}
                        name="done"
                        options={doneOptions}
                        onChange={(e) => handleOnFilterChange(e, 'dd', 'done')}
                    />
                </Col>
            </Row>
            */}
            <Row>
                <div className="col-12 ml-auto">
                    <button
                        type="submit"
                        onClick={(e) => handleApplyFilter(e)}
                        className="btn btn-primary mb-4 float-right"
                    > {t('ClientsNewCommunicationHistoryEdit.applyFilter')}
                    </button>
                </div>
            </Row>
            <EditableTable
                className="client-communication-history"
                tableName="Client Communication History"
                allowActions={true}
                key={Math.random()}
                actionButtons={{
                    edit: false,
                    print: false,
                    export: false,
                    delete: true,
                }}
                allowFilter={true}
                allowPagination={true}
                allowBulkActions={true}
                allowPrintResults={false}
                columns={tableColumns}
                requestData={requestDataPage}
                allowInsertRowFromTop={true}
                allowFilterTable={false}
                data={CLIENTS_DATA.editClient?.communicationHistory ? CLIENTS_DATA.editClient.communicationHistory.slice() : []}
                totalItems={totalItems}
                perPage={perPage}
                onUpdate={tableActions}
                enableNewRowCheckbox={true}
            />
        </div>
    )
}

export default EditClientCommunicationHistory;
