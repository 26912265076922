import React, {forwardRef, useRef} from "react";
import "./styles.css";
import {formatBigName, getFileImageDisplay, popExtension} from "./constants";

const File = forwardRef(({name, selected, onFileLeftClick, onContextMenu, onFileDoubleClick}, ref) => {
    const clickTimeout = useRef(null);

    const handleClick = (e) => {
        if (clickTimeout.current) return;

        clickTimeout.current = setTimeout(() => {
            clickTimeout.current = null;
            onFileLeftClick(e, name);
        }, 200); // Delay to differentiate single and double clicks
    }

    const handleDoubleClick = () => {
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current); // Cancel single click
            clickTimeout.current = null;
            onFileDoubleClick(name);
        }
    }

    return (
        <div className={`fileFolderStyle ${selected ? "fileFolderStyleSelected" : ""}`}
             onClick={(e) => handleClick(e)} style={{userSelect: "none", position: "relative"}}
             onDoubleClick={() => handleDoubleClick()}
             onContextMenu={(e) => onContextMenu(e, "file", name)}
             ref={ref}
        >
            <div style={{position: "relative"}}>
                <img src={getFileImageDisplay(popExtension(name))} alt={"File"} style={{height: "56px", width: "56px"}} draggable={false} />
            </div>
            <div className={"mt-1"} style={{wordBreak: "break-all", fontSize: "12px"}}>
                {formatBigName(name)}
            </div>
        </div>
    )
})

export default File;
