import React from "react";
import {getFilePreviewerType} from "./constants";
import {isEmpty} from "../../../../../_helpers/commonFunctions";

const FilePreviewerContainer = ({fileName, previewData, containerHeight}) => {
    const iframeStyle = {
        display: "block",
        width: "100%",
        height: containerHeight,
        maxWidth: "100%",
        margin: 0,
        padding: 0,
        border: "0 none",
        boxSizing: "border-box",
    }

    return (
        <div style={{height: "100%", width: "100%"}}>
            {getFilePreviewerType(fileName) === "blobURL" && (
                <React.Fragment>
                    {!isEmpty(previewData) && (
                        <iframe src={previewData} style={iframeStyle}
                                title={"File Previewer"}></iframe>
                    )}
                </React.Fragment>
            )}
            {getFilePreviewerType(fileName) === "videoPlayer" && (
                <React.Fragment>
                    <video controls src={previewData}
                           style={{maxWidth: "100%", maxHeight: "100%"}} draggable={false}></video>
                </React.Fragment>
            )}
            {getFilePreviewerType(fileName) === "imageViewer" && (
                <React.Fragment>
                    <img src={previewData} alt={"Preview"} style={{maxWidth: "100%"}}
                         draggable={false}/>
                </React.Fragment>
            )}
            {getFilePreviewerType(fileName) === "audioPlayer" && (
                <React.Fragment>
                    <audio controls src={previewData} draggable={false}/>
                </React.Fragment>
            )}
            {getFilePreviewerType(fileName) === "none" && (
                <React.Fragment>
                    <div style={{textAlign: "center"}}>
                        Δεν είναι δυνατή η προεπισκόπηση του αρχείου <strong>{fileName}</strong>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default FilePreviewerContainer
