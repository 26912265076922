import React, {useEffect, useState} from "react";
import {Button, Col, Form, FormLabel, Modal, Row} from "react-bootstrap";
import Input from "../../../common/Input";
import Dropdown from "../../../primer/Dropdown";
import {
  fetchAdvancedTypes,
  fetchBankAccounts,
  fetchClientPaymentData,
  fetchClients,
  fetchInitialClients,
  fetchPOS,
  fetchSalesGeneralSettings
} from "../../../../_apis/api";
import {useDispatch, useSelector} from "react-redux";
import {
  getDefaultOptionByValue,
  getModeByInstallation,
  getNumberByInstallation,
  getOptionsByData,
  getPreNumberByInstallation,
  getSelectedInstallation,
  getSelectedPreNumbers,
  isEmpty,
  removeDuplicateObjects,
} from "../../../../_helpers/commonFunctions";
import "react-datepicker/dist/react-datepicker.css";
import {setSaveClientPayments} from "../../../../_reducers/activitySlice";
import {getLabelByValue, getObjectFromArrayOfObjects,} from "../../../../_helpers/helperFunctions";
import {setNewClientPayment} from "../../../../_reducers/DataPanel/Clients/clientSlice";
import AdvancedCombobox from "../../../primer/AdvancedCombobox";
import TextArea from "antd/lib/input/TextArea";
import EditableTable from "../../../primer/Editable-Table";
import {toast} from "react-toastify";
import {preNumberGreek} from "../../../../_data/settings/advanced-types-search";
import AccountingBox from "../../../primer/AccountingBox";
import DateBox from "../../../primer/DateBox";
import {useTranslation} from "react-i18next";
import axios from "axios";
import moment from "moment";
import TipComponent from "../../../primer/TipComponent/TipComponent";

function ClientPaymentsNew() {
  const [t] = useTranslation('common');
  const [showPaymentTypeConfirmation, setShowPaymentTypeConfirmation] = useState(false);
  const [paymentTypeTempData, setPaymentTypeTempData] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const dispatch = useDispatch();
  const company = useSelector((state) => state.COMPANY_DATA.company);
  const CLIENTS_DATA = useSelector((state) => state.CLIENTS_DATA);
  const ACTIVITY = useSelector((state) => state.ACTIVITY);
  const SETTINGS = useSelector((state) => state.SETTINGS);
  const SALES_SETTINGS = useSelector((state) => state.SALES_SETTINGS);
  const CASH_DATA = useSelector((state) => state.CASH_DATA);

  let selectedClintData = [];

  let clientsListData = CLIENTS_DATA.clients.slice();
  const requestData = {
    company: company.id,
    year: company.year.toString(),
  };
  let requestDataF = {
    company: company.id,
    year: company.year.toString(),
    name: ''
  };
  const requestData2 = {
    company: company.id,
    year: company.year.toString(),
    isActive: "active"
  };
  // Options for Document Type Dropdown
  const allDocumentTypes = SETTINGS.settings.advancedTypes;
  const clientPaymentsDT = getObjectFromArrayOfObjects(
    allDocumentTypes,
    "Client Payments",
    "section"
  );
  const documentTypesOptions = getOptionsByData(
    clientPaymentsDT,
    "_id",
    "name"
  );
  const [preNumberGreekOptions, setPreNumberGreekOptions] = useState([]);
  let selectedDocumentTypeData = [];
  let dtInstallationsOptions = [];
  useEffect(() => {
    dispatch(fetchAdvancedTypes(requestData));
    dispatch(fetchClientPaymentData(requestData));
    dispatch(fetchSalesGeneralSettings(requestData2));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchPOS(requestData2));
    dispatch(fetchBankAccounts(requestData2));
    dispatch(fetchInitialClients(requestData));
    if(CLIENTS_DATA.newClientPayment.installation !== undefined && CLIENTS_DATA.newClientPayment.installation !== ''){
      let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData['documentNumbering'], preNumberGreek, CLIENTS_DATA.newClientPayment.installation);
      setPreNumberGreekOptions(newPreNumbers);
    }
  }, []);

  const [returnablePayments, setReturnablePayments] = useState([]);
  useEffect(() => {
    if (CLIENTS_DATA.newClientPayment.myDataType === "8.5") {
      axios.post(process.env.REACT_APP_API_URL2 + "/client/get-returnable-clientpayments", {company: company.id}, {
        headers: {"Content-Type": "application/json"},
      }).then((res) => {
        if (res.data.status === "200") {
          setReturnablePayments(res.data.data);
        }
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [CLIENTS_DATA.newClientPayment.myDataType])

  const [defaultForSaleData, setDefaultForSaleData] = useState([]);
  useEffect(() => {
    if(CLIENTS_DATA.newClientPayment.client && CLIENTS_DATA.newClientPayment.installation) {
      loadDefaultUnpaidSales();
    }
  }, [CLIENTS_DATA.newClientPayment.client])
  const paymentTypeOptions = getOptionsByData(
    SALES_SETTINGS.salesGeneralSettings.paymentTypes?.filter((el) => el.type !== "credit"),
    "_id",
    "name"
  );

  let disabledFields = false;
  if (
    CLIENTS_DATA.newClientPayment.documentType === undefined ||
    CLIENTS_DATA.newClientPayment.documentType === ""
  ) {
    disabledFields = true;
  } else {
    let dtData = getObjectFromArrayOfObjects(
      clientPaymentsDT,
      CLIENTS_DATA.newClientPayment.documentType,
      "_id"
    );
    if (dtData.length > 0) {
      selectedDocumentTypeData = dtData["0"];
      dtInstallationsOptions = getOptionsByData(selectedDocumentTypeData['documentNumbering'], "installationMasterId", "installation");
      dtInstallationsOptions = removeDuplicateObjects(dtInstallationsOptions, 'value');
    }
  }
  const handlePaymentTypeConfirmation = () => {
    // Populate Selected Category Data
    let newClientPaymentData = {};
    let type = paymentTypeTempData.type;
    newClientPaymentData.paymentType = paymentTypeTempData.paymentType;
    newClientPaymentData.paymentTypeName = paymentTypeTempData.name;
    newClientPaymentData.paymentTypeType = type;
    if (type === "cash" || type === "iris") {
      newClientPaymentData.posDetails = CLIENTS_DATA.newClientPaymentInitialValue.posDetails;
      newClientPaymentData.bankDetails = CLIENTS_DATA.newClientPaymentInitialValue.bankDetails;
    }
    if (type === "pos") {
      newClientPaymentData.amount = "";
      newClientPaymentData.bankDetails = CLIENTS_DATA.newClientPaymentInitialValue.bankDetails;
    }
    if (type === "bank-account" || type === "web-banking" || type === "bank-account-outside") {
      newClientPaymentData.amount = "";
      newClientPaymentData.posDetails = CLIENTS_DATA.newClientPaymentInitialValue.posDetails;
    }
    dispatch(setNewClientPayment({...CLIENTS_DATA.newClientPayment, ...newClientPaymentData}));
    setShowPaymentTypeConfirmation(false);
  };

  const handleOnChange = (e, type = "default", ddname = "") => {
    setShowErrors(true);
    if (!ACTIVITY.saveClientPayments) dispatch(setSaveClientPayments(true));
    let name;
    let value;
    let salesData = Object.assign({}, CLIENTS_DATA.newClientPayment);
    let newClientPaymentData = {};
    if (type === "dd") {
      value = e.value;
      name = ddname;
    } else if (type === 'ac') {
      value = e.target.value;
      name = ddname;
    } else if (type === "dp") {
      value = e;
      name = ddname;
    } else if (type === "acdd") {
      if (ddname === "returnPayment") {
        newClientPaymentData.returnPayment = e.row._id;
        newClientPaymentData.returnPaymentLabel = e.row.date + " | " + e.row.documentTypeName + " | " + e.row.number;
        const cloneFields = ["paymentType", "paymentTypeName", "paymentTypeType", "client", "clientName", "vatNumber", "address", "country",
          "phone", "email", "amount", "posDetails", "bankDetails", "chequeDetails", "clientCode", "installation"];
        for (let key of cloneFields) {
          newClientPaymentData[key] = e.row[key];
        }
      } else {
        selectedClintData = []
        console.log('e.row', e.row);
        e = e.row;
        selectedClintData.push(e)
        value = e._id;
        name = ddname;
      }
    } else if (type === "acdd2") { // forSale
      value = e.row.value;
      name = "forSale";
    } else if (type === "tp") {
      value = e;
      name = ddname;
    } else {
      value = e.target.value;
      name = e.target.name;
    }

    if (name !== "paymentType") {
      newClientPaymentData[name] = value;
    }

    if (name === "documentType") {
      //newClientPaymentData.chequeDetails = [];
      newClientPaymentData[name] = value;
      newClientPaymentData.documentTypeName = getLabelByValue(documentTypesOptions, value, "value");
      let selectedDocumentTypeData = getObjectFromArrayOfObjects(clientPaymentsDT, value, "_id");
      if (selectedDocumentTypeData.length > 0) {
        let selectedDTData = selectedDocumentTypeData["0"];
        let selectedInstallation = getSelectedInstallation(selectedDTData["documentNumbering"]);
        newClientPaymentData.myDataType = selectedDTData.myDataType;
        newClientPaymentData.returnPayment = "";
        newClientPaymentData.returnPaymentLabel = "";
        if (selectedDTData.myDataType === "8.4" || selectedDTData.myDataType === "8.5") {
          newClientPaymentData.date = moment().toDate();
          newClientPaymentData.forSale = "";
          newClientPaymentData.forSaleInputLabel = "";
          newClientPaymentData.openPOS = "";
        }
        if (Object.keys(selectedInstallation).length > 0) {
          newClientPaymentData.installation = selectedInstallation.installationMasterId;
          newClientPaymentData.preNumber = selectedInstallation.preNumber;
          newClientPaymentData.number = selectedInstallation.number + 1;
          newClientPaymentData.installationMode = selectedInstallation.numberingMode;
          newClientPaymentData.numberingMode = selectedInstallation.numberingMode;
        }
        const paymentObjArr = SALES_SETTINGS.salesGeneralSettings.paymentTypes.filter((el) => el._id === selectedDTData.proposedPaymentType);
        if (paymentObjArr.length > 0) {
          const paymentObj = paymentObjArr[0];
          newClientPaymentData.paymentType = paymentObj._id;
          newClientPaymentData.paymentTypeName = paymentObj.name;
          newClientPaymentData.paymentTypeType = paymentObj.type;
        }
        if (selectedDTData.proposedPosBankAccount !== undefined && selectedDTData.proposedPosBankAccount !== "") {
          if (CASH_DATA.posList.length > 0 || CASH_DATA.bankAccounts.length > 0) {
            if (selectedDTData.proposedPaymentTypeType === "pos") {
              const selectedPos = CASH_DATA.posList.filter(pos => pos._id === selectedDTData.proposedPosBankAccount)[0];
              let selectedPosDetails = {
                _id: selectedPos._id,
                id: "1",
                no: "1",
                name: selectedPos.name,
                registrationNumber: selectedPos.serialNumber,
                amount: 0,
                notes: ""
              }
              newClientPaymentData.posDetails = [];
              newClientPaymentData.posDetails.push(selectedPosDetails);
            } else if (selectedDTData.proposedPaymentTypeType === "bank-account" || selectedDTData.proposedPaymentTypeType === "web-banking" || selectedDTData.proposedPaymentTypeType === "bank-account-outside") {
              const selectedBank = CASH_DATA.bankAccounts.filter(bank => bank._id === selectedDTData.proposedPosBankAccount)[0];
              let selectedBankDetails = {
                _id: selectedBank._id,
                id: "1",
                no: "1",
                name: selectedBank.name,
                accountNumber: selectedBank.iban,
                amount: 0,
                notes: ""
              }
              newClientPaymentData.bankDetails = [];
              newClientPaymentData.bankDetails.push(selectedBankDetails);
            }
          }
        }
        // newClientPaymentData.bankDetails[0] = selectedDTData.proposedPosBankAccount;
      }
      let p = newClientPaymentData.preNumber;
      // This will pre number given in document number table
      let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData["documentNumbering"], preNumberGreek);
      newPreNumbers = newPreNumbers.flatMap((item) => {
        if (p !== item.value) {
          return []
        } else {
          return item
        }
      });
      setPreNumberGreekOptions(newPreNumbers);
    }
    if (name === "date") {
      newClientPaymentData.date = value;
    }

    if (name === 'installation') {
      newClientPaymentData.installation = value;
      newClientPaymentData.preNumber = getPreNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      newClientPaymentData.installationMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      newClientPaymentData.numberingMode = getModeByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId');
      newClientPaymentData.number = getNumberByInstallation(selectedDocumentTypeData['documentNumbering'], value, 'installationMasterId') + 1;
      let p = newClientPaymentData.preNumber;
      // This will pre number given in document number table
      let newPreNumbers = getSelectedPreNumbers(selectedDocumentTypeData["documentNumbering"], preNumberGreek);
      newPreNumbers = newPreNumbers.flatMap((item) => {
        if (p !== item.value) {
          return []
        } else {
          return item
        }
      });
      setPreNumberGreekOptions(newPreNumbers);
      newClientPaymentData.forSale = "";
      newClientPaymentData.forSaleInputLabel = "";
      newClientPaymentData.openPOS = "";
    }

    if (name === "client") {
      // populating client values on the sales form
      let selectedClient = getObjectFromArrayOfObjects(clientsListData, value, "_id");
      if (selectedClient.length === 0) {
        selectedClient = [...selectedClintData]
      }
      if (selectedClient.length > 0) {
        let sClient = selectedClient["0"];
        newClientPaymentData.clientName = sClient.name;
        newClientPaymentData.clientCode = sClient.systemCode; // It is the client code
        newClientPaymentData.address = sClient.address;
        newClientPaymentData.vatNumber = sClient.vatNumber;
        newClientPaymentData.doy = sClient.DOY;
        newClientPaymentData.city = sClient.city;
        newClientPaymentData.pc = sClient.postalCode;
        newClientPaymentData.country = sClient.country;
        newClientPaymentData.phone = sClient.phone;
        newClientPaymentData.email = sClient.email.replaceAll("mailto:", "");
        newClientPaymentData.forSale = "";
        newClientPaymentData.forSaleInputLabel = "";
        newClientPaymentData.openPOS = "";
      }
    }

    if (name === "paymentType") {
      let paymentTypesData = getObjectFromArrayOfObjects(SALES_SETTINGS.salesGeneralSettings.paymentTypes, value, "_id");
      if (paymentTypesData && paymentTypesData.length > 0) {
        // Show warning before change
        if (salesData.paymentType !== "" && salesData.paymentType !== undefined && salesData.paymentTypeType !== paymentTypesData["0"].type) {
          setShowPaymentTypeConfirmation(true);
          let paymentTypeName = getLabelByValue(paymentTypeOptions, value, "value");
          setPaymentTypeTempData({name: paymentTypeName, paymentType: value, type: paymentTypesData["0"].type,});
        } else {
          newClientPaymentData[name] = value;
          newClientPaymentData.paymentTypeName = getLabelByValue(paymentTypeOptions, value, "value");
          newClientPaymentData.paymentTypeType = paymentTypesData["0"].type;
        }
      }
    }

    if (name === "forSale") {
      newClientPaymentData.forSaleInputLabel = e.row.documentTypeName + " " + e.row.number;
      if (e.row?.myDataDestination === "provider" && e.row?.myDataPaymentId === "5") {
        newClientPaymentData.openPOS = "true";
      } else {
        newClientPaymentData.openPOS = "false";
      }
    }
    dispatch(setNewClientPayment({...salesData, ...newClientPaymentData}));
  }

  const clientDropdownColumns = [
    {
      label: t('SalesClientPaymentsNew.dropTable.code'),
      name: "systemCode",
    },
    {
      label: t('SalesClientPaymentsNew.dropTable.name'),
      name: "name",
    },
    {
      label: t('SalesClientPaymentsNew.dropTable.phone'),
      name: "phone",
    },
    {
      label: t('SalesClientPaymentsNew.dropTable.email'),
      name: "email",
    },
  ];

  const posFieldColumns = [
    {
      label: t('SalesClientPaymentsNew.smallTable.name'),
      name: "name",
    },
    {
      label: t('SalesClientPaymentsNew.smallTable.serialNumber'),
      name: "serialNumber",
    },
  ];

  const bankFieldColumns = [
    {
      label: t('SalesClientPaymentsNew.bankTable.accountName'),
      name: "name",
    },
    {
      label: t('SalesClientPaymentsNew.bankTable.bankName'),
      name: "bankName",
    },
    {
      label: t('SalesClientPaymentsNew.bankTable.bankAccountName'),
      name: "iban",
    },
  ];

  const posColumns = [
    {
      name: t('SalesClientPaymentsNew.posTable.posName'),
      field: "name",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: posFieldColumns,
          data: CASH_DATA.posList,
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: "Select POS",
        },
        type: "advanced-combobox",
      },
    },
    {
      name: t('SalesClientPaymentsNew.posTable.posRegistrationNumber'),
      field: "registrationNumber",
      editable: false,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesClientPaymentsNew.posTable.amount'),
      field: "amount",
      editable: true,
      inputType: {
        type: "accounting",
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10,
        },
      },
    },
    {
      name: t('SalesClientPaymentsNew.posTable.notes'),
      field: "notes",
      editable: true,
      inputType: {
        type: "text",
      },
    },
  ];

  const bankAccountColumns = [
    {
      name: t('SalesClientPaymentsNew.bank2Table.bankAccount'),
      field: "name",
      editable: true,
      inputType: {
        config: {
          excludeColumns: [],
          columns: bankFieldColumns,
          data: CASH_DATA.bankAccounts,
          editEnable: false,
          deleteEnable: false,
          searchTableEnable: false,
          showTableOptionOnSelect: true,
          placeholder: "Select Bank",
        },
        type: "advanced-combobox",
      },
    },
    {
      name: t('SalesClientPaymentsNew.bank2Table.accountNumber'),
      field: "accountNumber",
      editable: false,
      inputType: {
        type: "text",
      },
    },
    {
      name: t('SalesClientPaymentsNew.bank2Table.amount'),
      field: "amount",
      editable: true,
      inputType: {
        type: "accounting",
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10,
        },
      },
    },
    {
      name: t('SalesClientPaymentsNew.bank2Table.notes'),
      field: "notes",
      editable: true,
      inputType: {
        type: "text",
      },
    },
  ];

  const CheckColumns = [
    {
      name: t('SalesClientPaymentsNew.checkTable.startDate'),
      field: "startDate",
      editable: true,
      inputType: {
        type: "datebox",
      },
    },
    {
      name: t('SalesClientPaymentsNew.checkTable.endDate'),
      field: "endDate",
      editable: true,
      inputType: {
        type: "datebox",
      },
    },
    {
      name: t('SalesClientPaymentsNew.checkTable.amount'),
      field: "amount",
      editable: true,
      inputType: {
        type: "accounting",
        config: {
          focused: true,
          suffix: "€",
          maxLength: 10,
        },
      },
    },
    {
      name: t('SalesClientPaymentsNew.checkTable.notes'),
      field: "notes",
      editable: true,
      inputType: {
        type: "text",
      },
    },
  ];

  const posDetailsActions = {
    insert: () => {},
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveClientPayments) {
        dispatch(setSaveClientPayments(true));
      }
      if (data.name && data.name === "") {
        toast.error("Name is mandatory field.");
      } else {
        let cpPosDetails = CLIENTS_DATA.newClientPayment.posDetails.slice();
        let foundPos = cpPosDetails.find((item) => item.id === id);
        if (foundPos !== undefined) {
          let rowPosDetail = [];
          // special case update name by name
          if (data.name && data.name !== "") {
            rowPosDetail = getObjectFromArrayOfObjects(CASH_DATA.posList, data.name, "name");
          }
          // Field auto population on change product
          if (rowPosDetail.length > 0) {
            let rowPosDetailData = rowPosDetail["0"];
            data._id = rowPosDetailData._id;
            data.name = rowPosDetailData.name;
            data.registrationNumber = rowPosDetailData.serialNumber;
          }
          foundPos = { ...foundPos, ...data };
          const updateCpPos = { ...cpPosDetails[id - 1], ...foundPos };
          cpPosDetails[id - 1] = updateCpPos;
          let innerData = {...CLIENTS_DATA.newClientPayment};
          innerData.amount = updateCpPos.amount
          dispatch(setNewClientPayment({...innerData, posDetails: cpPosDetails}));
        }
      }
    },
    selected: () => {},
    delete: () => {},
  };

  const chequeDetailsActions = {
    insert: () => {},
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveClientPayments) {
        dispatch(setSaveClientPayments(true));
      }
        let chequeDetails = CLIENTS_DATA.newClientPayment.chequeDetails.slice();
        let foundCheck = chequeDetails.find((item) => item.id === id);
        if (foundCheck !== undefined) {
          foundCheck = { ...foundCheck, ...data };
          const updateCheque = { ...chequeDetails[id - 1], ...foundCheck };
          chequeDetails[id - 1] = updateCheque;
          let innerData = {...CLIENTS_DATA.newClientPayment};
          innerData.amount = updateCheque.amount
          dispatch(setNewClientPayment({...innerData, chequeDetails: chequeDetails}));
          console.log(chequeDetails)
        }
    },
    selected: () => {},
    delete: () => {},
  }

  const bankDetailsActions = {
    insert: () => {},
    update: (data, id) => {
      // Set if editing the form
      if (!ACTIVITY.saveClientPayments) {
        dispatch(setSaveClientPayments(true));
      }
      if (data.name && data.name === "") {
        toast.error("Name is mandatory field.");
      } else {
        let cpBankDetails = CLIENTS_DATA.newClientPayment.bankDetails.slice();
        let foundBank = cpBankDetails.find((item) => item.id === id);
        if (foundBank !== undefined) {
          let rowBankDetail = [];
          if (data.name && data.name !== "") {
            rowBankDetail = getObjectFromArrayOfObjects(
              CASH_DATA.bankAccounts,
              data.name,
              "name"
            );
          }
          if (rowBankDetail.length > 0) {
            let rowBankDetailData = rowBankDetail["0"];
            data._id = rowBankDetailData._id;
            data.name = rowBankDetailData.name;
            data.accountNumber = rowBankDetailData.iban;
          }
          foundBank = { ...foundBank, ...data };
          const updateCpBank = { ...cpBankDetails[id - 1], ...foundBank };
          cpBankDetails[id - 1] = updateCpBank;
          let innerData = {...CLIENTS_DATA.newClientPayment};
          innerData.amount = updateCpBank.amount
          dispatch(setNewClientPayment({...innerData, bankDetails: cpBankDetails}));
        }
      }
    },
    selected: () => {},
    delete: () => {},
  };

  const selectedDocumentType = getDefaultOptionByValue(
    documentTypesOptions,
    CLIENTS_DATA.newClientPayment.documentType,
    "value",
    "label",
    "value"
  );
  const onChangeSearch = (keyword,setData) => {
    requestDataF.name = keyword
    if(keyword.length >= 2) {
      dispatch(fetchClients(requestDataF,setData))
    }
  }
  const loadUnpaidSales = (keyword, setData) => {
    axios.get(process.env.REACT_APP_API_URL2 + `/client/get-unpaid-list`, {
      params: {
        company: company.id,
        clientId: CLIENTS_DATA.newClientPayment.client,
        installation: CLIENTS_DATA.newClientPayment.installation,
        keyword: keyword
      }}).then(res => {
        if (res.data && res.data.data && res.data.status === '200') {
          let tArr = [];
          res.data.data.forEach((sale) => {
            tArr.push({
              number: sale.number.replace("noprefix", ""),
              totalAmount: sale.totalAmount,
              paymentRemainder: sale.paymentRemainder,
              documentTypeName: sale.documentTypeName,
              date: moment(sale.date).format("DD-MM-YYYY HH:mm"),
              value: String(sale._id),
              myDataDestination: sale?.myDataDestination,
              myDataPaymentId: sale?.myDataPaymentId
            })
          })
          setData(tArr);
        }
      }).catch((err) => {
        console.log(err)
      })
  }
  const loadDefaultUnpaidSales = () => {
    axios.get(process.env.REACT_APP_API_URL2 + `/client/get-unpaid-list`, {
      params: {
        company: company.id,
        clientId: CLIENTS_DATA.newClientPayment.client,
        installation: CLIENTS_DATA.newClientPayment.installation,
      }}).then(res => {
        if (res.data && res.data.data && res.data.status === '200') {
          let tArr = [];
          res.data.data.forEach((sale) => {
            tArr.push({
              number: sale.number.replace("noprefix", ""),
              totalAmount: sale.totalAmount,
              paymentRemainder: sale.paymentRemainder,
              documentTypeName: sale.documentTypeName,
              date: moment(sale.date).format("DD-MM-YYYY HH:mm"),
              value: String(sale._id),
              myDataDestination: sale?.myDataDestination,
              myDataPaymentId: sale?.myDataPaymentId
            })
          })
          setDefaultForSaleData(tArr);
        }
      }).catch((err) => {
        console.log(err);
      })
  }
  const onChangeSearchUnpaidSales = (keyword, setData) => {
    loadUnpaidSales(keyword, setData);
  }
  const onChangeSearchReturnablePayments = (keyword, setData) => {
    axios.post(process.env.REACT_APP_API_URL2 + "/client/get-returnable-clientpayments", {keyword: keyword, company: company.id}, {
      headers: {"Content-Type": "application/json"},
    }).then((res) => {
      if (res.data.status === "200") {
        setData(res.data.data);
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  const clearForSale = () => {
    dispatch(setNewClientPayment({...CLIENTS_DATA.newClientPayment, forSale: "", forSaleInputLabel: ""}));
  }
  const clearReturnPayment = () => {
    dispatch(setNewClientPayment({...CLIENTS_DATA.newClientPayment, returnPayment: "", returnPaymentLabel: ""}));
  }
  const selectDocumentInstallation = getDefaultOptionByValue(dtInstallationsOptions, CLIENTS_DATA.newClientPayment.installation, "value", "label", "value");
  const selectedPaymentType = getDefaultOptionByValue(paymentTypeOptions, CLIENTS_DATA.newClientPayment.paymentType, "value", "label", "value");

  const isInvulnerable  = String(CLIENTS_DATA.newClientPayment?.returnPayment).length === 24;

  const isTipable = CLIENTS_DATA.newClientPayment.myDataType === "8.4"
      || (CASH_DATA.posList.find((el) => el._id === CLIENTS_DATA.newClientPayment.posDetails[0]._id)?.isETameiaki === "active"
          && !isEmpty(CLIENTS_DATA.newClientPayment.forSale) && CLIENTS_DATA.newClientPayment?.openPOS === "true");

  return (
    <div className="new-sales">
      <Form
        className="client-form product-form position-relative"
        autoComplete="off"
      >
        {disabledFields && (
          <div className="overlay_w">{t('SalesClientPaymentsNew.message')}</div>
        )}
        <Row className="border-bottom mb-3">
          <Col sm={12} md={3} className="showWithOverlay">
            <Dropdown
              label={t('SalesClientPaymentsNew.documentTypes')}
              key={selectedDocumentType.value}
              defaultValue={selectedDocumentType}
              required={true}
              name="documentType"
              disabled={isInvulnerable}
              options={documentTypesOptions}
              onChange={(e) => handleOnChange(e, "dd", "documentType")}
            />
          </Col>
          {(CLIENTS_DATA.newClientPayment?.myDataType === "8.5") ? (
              <React.Fragment>
                <Col sm={12} md={5}>
                  <AdvancedCombobox
                      className="w-100 ac-select-dropdown"
                      searchableDropdownEnable={false}
                      label={"Συσχετιζόμενη Είσπραξη"}
                      placeholder={"Εισάγετε συσχετιζόμενη είσπραξη"}
                      defaultValue={CLIENTS_DATA.newClientPayment.returnPaymentLabel}
                      data={returnablePayments}
                      onChange={(e) => handleOnChange(e, "acdd", "returnPayment")}
                      excludeColumns={[]}
                      columns={[
                        {label: "Ημερομηνία", name: "date"},
                        {label: "Τύπος Παραστατικού", name: "documentTypeName"},
                        {label: "Αρίθμηση", name: "number"},
                        {label: "Ποσό", name: "amount"},
                      ]}
                      required={CLIENTS_DATA.newClientPayment?.myDataType === "8.5"}
                      editEnable={false}
                      deleteEnable={false}
                      searchTableEnable={false}
                      showTableOptionOnSelect={true}
                      key={Math.random()}
                      disabled={isInvulnerable}
                      onChangeSearch={onChangeSearchReturnablePayments}
                  />
                </Col>
                <Col md={1}>
                  {String(CLIENTS_DATA.newClientPayment?.returnPayment).length === 24 &&
                      <button style={{position: "absolute", bottom: "16px"}} className="btn btn-sm" title="Καθαρισμός συσχετιζόμενης είσπραξης" onClick={() => clearReturnPayment()}>
                        <i className="fas fa-xmark"></i></button>
                  }
                </Col>
              </React.Fragment>
          ) : (
              <Col md={6}></Col>
          )}
        </Row>
        <Row className={"mb-3"}>
          <Col sm="12" md="3">
            <DateBox
                classes="w-100"
                label={t('SalesClientPaymentsNew.date')}
                name="date"
                disabled={isInvulnerable || ["8.4", "8.5"].includes(CLIENTS_DATA.newClientPayment?.myDataType)}
                selected={moment(CLIENTS_DATA.newClientPayment.date).isValid() ? new Date(CLIENTS_DATA.newClientPayment.date) : null}
                onChange={(e) => handleOnChange(e, 'dp', 'date')}
            />
            {isEmpty(CLIENTS_DATA.newClientPayment.date) && <div className={"text-danger"}>Απαιτείται Ημερομηνία.</div> }
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3} className="mb-2">
            <div className="mb-3">
              <Dropdown
                label={t('SalesClientPaymentsNew.installation.installation')}
                options={dtInstallationsOptions}
                value={Object.keys(selectDocumentInstallation).length !== 0 ? selectDocumentInstallation : ''}
                enabledValue={true}
                required={true}
                name="installation"
                classes="w-100"
                disabled={isInvulnerable}
                onChange={(e) => handleOnChange(e, "dd", "installation")}
              />
              {showErrors &&
                CLIENTS_DATA.newClientPayment.installation === "" && (
                  <div className="text-danger">{t('SalesClientPaymentsNew.installation.required')}</div>
                )}
            </div>
          </Col>
          <Col sm="12" md="3">
            <div className="mb-3">
              <Dropdown
                label={t('SalesClientPaymentsNew.preNumber')}
                name="preNumber"
                key={Math.random()}
                defaultValue={getDefaultOptionByValue(
                  preNumberGreekOptions,
                  CLIENTS_DATA.newClientPayment.preNumber,
                  "value",
                  "label",
                  "value"
                )}
                options={preNumberGreekOptions}
                disabled={CLIENTS_DATA.newClientPayment.numberingMode === "manual"}
                onChange={(e) => handleOnChange(e, "dd", "preNumber")}
              />
            </div>
          </Col>
          <Col sm="12" md="3">
            <Input
              className="mb-3 w-100"
              label={t('SalesClientPaymentsNew.number.number')}
              name="number"
              value={CLIENTS_DATA.newClientPayment.number}
              placeholder={t('SalesClientPaymentsNew.number.placeholder')}
              onChange={(e) => handleOnChange(e)}
              disabled={CLIENTS_DATA.newClientPayment.numberingMode !== "manual"}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3} className="mb-2">
            <Dropdown
              label={t('SalesClientPaymentsNew.paymentType')}
              options={paymentTypeOptions}
              value={Object.keys(selectedPaymentType).length !== 0 ? selectedPaymentType : ''}
              enabledValue={true}
              name="paymentType"
              disabled={CLIENTS_DATA.newClientPayment?.myDataType === "8.4" || CLIENTS_DATA.newClientPayment?.myDataType === "8.5" || isInvulnerable}
              searchEnable={true}
              onChange={(e) => handleOnChange(e, "dd", "paymentType")}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3} className="mb-2">
            <AdvancedCombobox
              ACName="Client Payment Client" // do not change name
              className="w-100 ac-select-dropdown"
              searchableDropdownEnable={false}
              defaultValue={CLIENTS_DATA.newClientPayment.clientName}
              key={Math.random()}
              label={t('SalesClientPaymentsNew.client.client')}
              data={clientsListData}
              onChange={(e) => {
                handleOnChange(e, "acdd", "client");
              }}
              onChangeSearch = {onChangeSearch}
              excludeColumns={[]}
              columns={clientDropdownColumns}
              editEnable={false}
              deleteEnable={false}
              searchTableEnable={false}
              showTableOptionOnSelect={true}
              placeholder={t('SalesClientPaymentsNew.client.placeholder')}
              required={true}
              showInsertAddButton={true}
              hideSaveButton={true}
              hideInsertNewRow={true}
              disabled={isInvulnerable}
            />
            {showErrors && CLIENTS_DATA.newClientPayment.client === "" && (
              <div className="text-danger">{t('SalesClientPaymentsNew.client.required')}</div>
            )}
          </Col>
          <Col sm="12" md="3">
            <div className="mb-3">
              <Input
                className="w-100"
                label={t('SalesClientPaymentsNew.vatNumber')}
                name="vat"
                value={CLIENTS_DATA.newClientPayment.vatNumber}
                onChange={(e) => handleOnChange(e)}
                disabled={true}
                readonly
              />
            </div>
          </Col>
          <Col sm="12" md="3">
            <div className="d-flex">
              <Input
                label={t('SalesClientPaymentsNew.address')}
                className="w-100"
                name="address"
                value={CLIENTS_DATA.newClientPayment.address}
                onChange={(e) => handleOnChange(e)}
                disabled={true}
                readonly
              />
            </div>
          </Col>
          <Col sm="12" md="3">
            <div className="d-flex">
              <Input
                label={t('SalesClientPaymentsNew.country')}
                className="w-100"
                name="country"
                value={CLIENTS_DATA.newClientPayment.country}
                onChange={(e) => handleOnChange(e)}
                disabled={true}
                readonly
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="3">
            <div className="d-flex">
              <Input
                label={t('SalesClientPaymentsNew.phone')}
                className="w-100"
                name="phone"
                value={CLIENTS_DATA.newClientPayment.phone}
                onChange={(e) => handleOnChange(e)}
                disabled={true}
                readonly
              />
            </div>
          </Col>
          <Col sm="12" md="3">
            <div className="d-flex">
              <Input
                label={t('SalesClientPaymentsNew.email')}
                className="w-100"
                name="email"
                value={CLIENTS_DATA.newClientPayment.email}
                onChange={(e) => handleOnChange(e)}
                disabled={true}
                readonly
              />
            </div>
          </Col>
          {(CLIENTS_DATA.newClientPayment?.myDataType !== "8.4" && CLIENTS_DATA.newClientPayment.myDataType !== "8.5") && (
              <React.Fragment>
                <Col sm="12" md="3">
                  <AdvancedCombobox
                      className="w-100 ac-select-dropdown"
                      searchableDropdownEnable={false}
                      label={"Αφορά την πώληση"}
                      placeholder={"Προσθέστε πώληση"}
                      defaultValue={CLIENTS_DATA.newClientPayment.forSaleInputLabel}
                      data={defaultForSaleData}
                      onChange={(e) => handleOnChange(e, "acdd2")}
                      excludeColumns={[]}
                      columns={[
                        {label: "Ημερομηνία", name: "date"},
                        {label: "Τύπος Παραστατικού", name: "documentTypeName"},
                        {label: "Αρίθμηση", name: "number"},
                        {label: "Πληρωτέο Ποσό", name: "totalAmount"},
                        {label: "Υπολ. Ποσό", name: "paymentRemainder"},
                      ]}
                      editEnable={false}
                      deleteEnable={false}
                      searchTableEnable={false}
                      showTableOptionOnSelect={true}
                      key={Math.random()}
                      disabled={!CLIENTS_DATA.newClientPayment.installation || !CLIENTS_DATA.newClientPayment.client || isInvulnerable || String(CLIENTS_DATA.newClientPayment.forSale).length === 24}
                      onChangeSearch={onChangeSearchUnpaidSales}
                  />
                </Col>
                <Col sm="12" md="3">
                  {String(CLIENTS_DATA.newClientPayment.forSale).length === 24 &&
                      <button style={{position: "absolute", bottom: "13px"}} className="btn btn-sm" title="Καθαρισμός επιλεγμένης πώλησης" onClick={() => clearForSale()}><i className="fas fa-xmark"></i></button>
                  }
                </Col>
              </React.Fragment>
          )}
        </Row>
        <br />
        <Row>
          <Col md={12}>
            <Input
              label={t('SalesClientPaymentsNew.reason')}
              className="w-100 mb-3"
              name="reason"
              value={CLIENTS_DATA.newClientPayment.reason}
              onChange={(e) => handleOnChange(e)}
            />
          </Col>
        </Row>

        {(CLIENTS_DATA.newClientPayment.paymentTypeType === "cash" || CLIENTS_DATA.newClientPayment.paymentTypeType === "iris") && (
          <Row>
            <Col sm="12" md="4">
              <div className="mb-3">
                <FormLabel className="" htmlFor="amount">{t('SalesClientPaymentsNew.paymentAmount.paymentAmount')}</FormLabel>
                <AccountingBox
                    name="amount"
                    label="Amount"
                    placeholder={t('SalesClientPaymentsNew.paymentAmount.placeholder')}
                    defaultValue={CLIENTS_DATA.newClientPayment.amount}
                    key={CLIENTS_DATA.newClientPayment.amount}
                    onBlur={(e) => handleOnChange(e, 'ac', 'amount')}
                    disabled={isInvulnerable}
                />
              </div>
            </Col>
          </Row>
        )}

        <Row style={{pointerEvents: isInvulnerable ? "none" : ""}}>
        {(CLIENTS_DATA.newClientPayment.paymentTypeType === "bank-account" || CLIENTS_DATA.newClientPayment.paymentTypeType === "web-banking" || CLIENTS_DATA.newClientPayment.paymentTypeType === "bank-account-outside") && (
          <div className="bank-data">
            <div className="text-muted mb-2">
              <i>{t('SalesClientPaymentsNew.bankAccountData')}</i>
            </div>
            <EditableTable
              reqName="bank"
              key={Math.random()}
              tableName="Client Payment Bank Account Data Table" // do not change name
              allowToggleColumns={true}
              allowActions={true}
              allowInsertRow={false}
              allowPagination={false}
              allowBulkDelete={false}
              allowSorting={false}
              allowSelectionCheckbox={false}
              columns={bankAccountColumns}
              allowPrintResults={false}
              enableNewRowCheckbox={true}
              onUpdate={bankDetailsActions}
              data={CLIENTS_DATA.newClientPayment.bankDetails}
            />
          </div>
        )}

        {CLIENTS_DATA.newClientPayment.paymentTypeType === "pos" && (
          <div className="pos-data">
            <div className="text-muted mb-2">
              <i>{t('SalesClientPaymentsNew.posData')}</i>
            </div>
            <EditableTable
              reqName="pos"
              key={Math.random()}
              tableName="Client Payment POS Data Table" // do not change name
              allowToggleColumns={true}
              allowActions={true}
              allowInsertRow={false}
              allowPagination={false}
              allowBulkDelete={false}
              allowSorting={false}
              allowSelectionCheckbox={false}
              columns={posColumns}
              enableNewRowCheckbox={true}
              allowPrintResults={false}
              onUpdate={posDetailsActions}
              data={CLIENTS_DATA.newClientPayment.posDetails}
            />
          </div>
        )}

        {CLIENTS_DATA.newClientPayment.paymentTypeType === "cheque" && (
          <div className="cheque-data">
            <div className="text-muted mb-2">
              <i>{t('SalesClientPaymentsNew.chequeData')}</i>
            </div>
            <EditableTable
              key={Math.random()}
              tableName="Client Payment Cheque Data Table" // do not change name
              allowToggleColumns={true}
              allowActions={true}
              allowInsertRow={false}
              allowPagination={false}
              allowBulkDelete={false}
              allowSorting={false}
              allowSelectionCheckbox={false}
              columns={CheckColumns}
              enableNewRowCheckbox={true}
              allowPrintResults={false}
              onUpdate={chequeDetailsActions}
              data={CLIENTS_DATA.newClientPayment.chequeDetails}
            />
          </div>
        )}
        </Row>
        <Row className={"mb-2"}>
          <Col md={isTipable ? 9 : 12}>
            <div className="text-muted mb-2">
              <i>{t('SalesClientPaymentsNew.paymentNotes')}</i>
            </div>
            <TextArea
                rows="4"
                className="form-control"
                placeholder={t('SalesClientPaymentsNew.addNotes')}
                limit="1000"
                name="paymentNotes"
                value={CLIENTS_DATA.newClientPayment.paymentNotes}
                onChange={(e) => handleOnChange(e)}
            />
          </Col>
          {(isTipable) && (
              <Col md={3}>
                <span>Φιλοδώρημα</span>
                <TipComponent
                    value={CLIENTS_DATA.newClientPayment.tipAmount}
                    onChange={(amount) => dispatch(setNewClientPayment({
                      ...CLIENTS_DATA.newClientPayment,
                      tipAmount: amount
                    }))}
                    paymentAmount={CLIENTS_DATA.newClientPayment.amount}
                />
              </Col>
          )}
        </Row>
      </Form>

      {/* Confirm Category Change */}
      <Modal backdrop={"static"} className="themeModal" show={showPaymentTypeConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>{t('SalesClientPaymentsNew.warning')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('SalesClientPaymentsNew.message2')}
        </Modal.Body>
        <Modal.Footer>
          <Button
              variant="outline-primary"
              onClick={() => setShowPaymentTypeConfirmation(false)}
          >
            {t('SalesClientPaymentsNew.no')}
          </Button>
          <Button
            variant="primary"
            onClick={() => handlePaymentTypeConfirmation()}
          >
            {t('SalesClientPaymentsNew.yes')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ClientPaymentsNew;
